import { camelizeKeys } from "humps";
import { omit } from "ramda";

import { MemberSerialized, IShippingInfoViewModel, Provider } from "typings";

type MemberViewModelCreator = (
  member: MemberSerialized
) => {
  email: string;
  shippingInfo: IShippingInfoViewModel;
  hasPassword: boolean;
  connectedSocialAccounts: Provider[];
};

export const memberViewModelCreator: MemberViewModelCreator = member => {
  const {
    shipping_infos: [shippingInfo]
  } = member;

  if (shippingInfo && !shippingInfo.message) Object.assign(shippingInfo, { message: "" });

  return camelizeKeys({
    ...omit(["shipping_infos"], member),
    shippingInfo: shippingInfo || {
      id: 0,
      name: "",
      phoneNumber: "",
      address: {
        zipcode: "",
        main: "",
        detail: ""
      },
      message: ""
    }
  }) as any;
};
