import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { camelizeKeys } from "humps";
import { debounce } from "debounce";

import { get__stores } from "api";

import { StoreSerializedCamel, IStore } from "typings";

export const useShoppingmalls = () => {
  const [shoppingmalls, setShoppingmalls] = useState<StoreSerializedCamel[]>([]);
  const [page, setPage] = useState(1);
  const [done, setDone] = useState(false);

  const genderId = useSelector<IStore, IStore["ui"]["genderId"]>(({ ui: { genderId } }) => genderId);

  useEffect(() => {
    (async () => {
      if (done) return;
      const response = await get__stores({ page, genderId });

      if (response.length) {
        if (page === 1) {
          setShoppingmalls(camelizeKeys(response) as StoreSerializedCamel[]);
        } else {
          setShoppingmalls(shoppingmalls.concat(camelizeKeys(response) as StoreSerializedCamel[]));
        }
      }

      if (response.length < 25) setDone(true);
    })();
  }, [done, page, genderId]); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    setDone(false);
    setPage(1);
  }, [genderId]);

  useEffect(() => {
    const handler = debounce(() => {
      const { scrollTop, clientHeight, offsetHeight } = document.documentElement;
      const bottomY = scrollTop + clientHeight;

      if (bottomY + 800 >= offsetHeight) {
        setPage(page => page + 1);
      }
    }, 100);

    window.addEventListener("scroll", handler);

    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  return { shoppingmalls };
};
