import { uniq } from "ramda";

import * as api from "api";

import { issuedDiscountCouponsViewModelCreator, possibleCouponsViewModelCreator } from "view-model-creator";

import {
  IIssuedDiscountCouponViewModel,
  IPartnerOptionViewModel,
  CouponSelectHelper,
  FindCoupon,
  FindAppliableOptionIds
} from "typings";

type GetCouponSelectHelper = (options: IPartnerOptionViewModel[]) => Promise<CouponSelectHelper>;

export const getCouponSelectHelper: GetCouponSelectHelper = async options => {
  const [couponsResponse, possibleCouponsResponse] = await Promise.all([
    api.get__members__issued_discount_coupons(),
    api.get__members__issued_discount_coupons__possible_coupons({
      productOptionIds: options.flatMap(({ optionId, selectedCount }) => Array(selectedCount).fill(optionId))
    })
  ]);

  const findCoupon = issuedDiscountCouponsViewModelCreator(couponsResponse).reduce<FindCoupon>((acc, v) => {
    acc[v.couponId] = v;
    return acc;
  }, {});

  const possibilities = possibleCouponsViewModelCreator(possibleCouponsResponse);
  const usableCouponIds = uniq(
    possibilities.map(({ issuedDiscountCouponIds }) => issuedDiscountCouponIds).reduce((acc, v) => acc.concat(v), [])
  );
  const findPossibleCouponIds = new Map(
    possibilities.map(({ productOptionId, issuedDiscountCouponIds }) => [
      productOptionId,
      issuedDiscountCouponIds.map(id => findCoupon[id] as IIssuedDiscountCouponViewModel)
    ])
  );

  const findAppliableOptionIds = usableCouponIds.reduce<FindAppliableOptionIds>((acc, couponId) => {
    acc[couponId] = possibilities
      .filter(({ issuedDiscountCouponIds }) => issuedDiscountCouponIds.includes(couponId))
      .map(({ productOptionId }) => productOptionId as number);
    return acc;
  }, {});

  return { findCoupon, findAppliableOptionIds, usableCouponIds, findPossibleCouponIds };
};
