import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames/bind";
import { useScrollHidenEvent } from "hooks";
import { FilterColorIcon, FilterBlackIcon } from "assets/icon";
import styles from "./PinFilter.module.scss";
import { PinFilterContent } from "./PinFilterContent";

interface IProp {
  categories: string[];
  selectCategories: string[];
  onFilterSubmit: (props: { categories: string[]; viewOnlySale: boolean }) => void;
  isViewSoldOut: boolean;
  isFilting: boolean;
}
const cx = classNames.bind(styles);
export const PinFilter: FC<IProp> = ({ categories, selectCategories, onFilterSubmit, isViewSoldOut, isFilting }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeFilter = useCallback(() => setIsOpen(false), []);
  const FilterIcon = useMemo(() => (isFilting ? FilterColorIcon : FilterBlackIcon), [isFilting]);
  useScrollHidenEvent(isOpen);

  useEffect(() => {
    window.addEventListener("click", closeFilter);
    return () => window.removeEventListener("click", closeFilter);
  }, [closeFilter]);

  return (
    <div className={cx("wrapper")}>
      <button
        className={cx("filter-button")}
        type="button"
        onClick={e => {
          e.stopPropagation();
          setIsOpen(prev => !prev);
        }}
      >
        <FilterIcon />
        <span className={cx("filter-text", { isFilting })}>필터</span>
      </button>
      {isOpen && (
        <PinFilterContent
          categories={categories}
          selectCategories={selectCategories}
          onFilterSubmit={onFilterSubmit}
          isViewSoldOut={isViewSoldOut}
          closeFilter={closeFilter}
        />
      )}
    </div>
  );
};
