import { fetcher } from "utils";
import { IJSendSuccessResponse } from "typings";

type ImageUpload = (
  payload: Array<File | Blob>
) => Promise<
  IJSendSuccessResponse<{
    payload: {
      images: string[];
    };
  }>
>;

export const post__image_upload: ImageUpload = payload => {
  const formData = new FormData();
  payload.map(image => formData.append("images[]", image, "untitle.jpg"));
  return fetcher.post("/common/upload", formData);
};
