import { IMembersOrderPoints } from "typings";
import { formatDateTime } from "utils";

export interface IMembersOrderPointsViewModel {
  pointsId: number;
  createdAtDesc: string;
  message: string;
  pointValueDesc: string;
  productPrice: number | string | null;
}

export const membersOrderPointsViewModelCreator = (
  orderPoints: IMembersOrderPoints[]
): IMembersOrderPointsViewModel[] =>
  orderPoints.map(({ id, created_at, message, value, price }) => ({
    pointsId: id,
    createdAtDesc: formatDateTime(created_at),
    message,
    pointValueDesc: value < 0 ? `${value.toLocaleString()}원` : `+${value.toLocaleString()}원`,
    productPrice: price ? `${price.toLocaleString()}원` : "-"
  }));
