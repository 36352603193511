import React, { ChangeEvent, FC } from "react";
import classnames from "classnames/bind";

import styles from "./SelectShare.module.scss";

const cx = classnames.bind(styles);

interface IProps {
  categories: string[];
  category: string;
  handleCategory: (event: ChangeEvent) => void;
}

export const SelectCategory: FC<IProps> = ({ categories, category, handleCategory }) => (
  <select className={cx("select")} value={category} onChange={handleCategory}>
    {categories.map(name => (
      <option key={name} value={name}>
        {name}
      </option>
    ))}
  </select>
);
