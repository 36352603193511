import React, { useState } from "react";
import { modalOff } from "actions/actionCreators";
import { UI__FORM_FAILURE_EMAIL_INVALID } from "constant";
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { formChecker, useInputs } from "utils";
import * as api from "api";

import classNames from "classnames/bind";
import styles from "../../ModalWrapper.module.scss";

const cx = classNames.bind(styles);

export function FindPassword() {
  const dispatch = useDispatch();
  const injectedEmail = useSelector(({ authentication, member }) => authentication.email || member.email || "");
  const [state, onChange] = useInputs({ email: injectedEmail });
  const [formFailure, setFormFailure] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const checker = formChecker(setFormFailure);
  const { email } = state;

  const handleSubmit = event => {
    event.preventDefault();

    if (checker(state)) {
      setIsLoading(true);

      api
        .post__member__auth__password({ email })
        .then(response => {
          alert(response);
          dispatch(modalOff());
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className={cx("container")}>
      <div className={cx("header")}>
        비밀번호 재설정
        <button className={cx("close")} onClick={() => dispatch(modalOff())} type="button">
          {" "}
        </button>
      </div>

      <div className={cx("contents")}>
        <form onSubmit={handleSubmit}>
          <p>
            가입하신 이메일 주소로 비밀번호 재설정 메일을
            <br />
            보내드립니다.
          </p>

          <input type="email" placeholder="이메일 주소" name="email" value={email} onChange={onChange} />
          {formFailure.UI__FORM_FAILURE_EMAIL_INVALID && (
            <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_EMAIL_INVALID}</div>
          )}

          <div className={cx("btnWrapper")}>
            <button className={cx("modify02")} type="submit" disabled={isLoading}>
              {isLoading ? <Loader type="Oval" color="#ffffff" height={12} width={12} /> : "비밀번호 변경 요청"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
