import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames/bind";

import { clearAuthentication, disconnectSocialAccount, userPasswordChange, modalOff } from "actions/actionCreators";

import { formChecker, useInputs } from "utils";

import {
  UI__FORM_FAILURE_PASSWORD_INVALID,
  UI__FORM_FAILURE_PASSWORD_CONFIRM_INVALID,
  UI__FORM_FAILURE_PASSWORD_NOT_EQUAL
} from "constant";

import styles from "../../ModalWrapper.module.scss";

const cx = classNames.bind(styles);

export function DisconnectProvider() {
  const dispatch = useDispatch();
  const provider = useSelector(({ authentication: { provider } }) => provider);
  const { connectedSocialAccounts, hasPassword } = useSelector(({ member }) => member);
  const [state, onChange] = useInputs({
    password: "",
    passwordConfirm: ""
  });
  const [formFailure, setFormFailure] = useState({});
  const checker = formChecker(setFormFailure);
  const { password, passwordConfirm } = state;

  const directDisconnectCondition = hasPassword || connectedSocialAccounts.length > 1;

  const disconnect = async () => {
    try {
      await dispatch(disconnectSocialAccount(provider));
      await dispatch(clearAuthentication);
      await dispatch(modalOff());

      alert("연동이 해제되었습니다.");
    } catch (e) {
      console.error(e);
    }
  };

  const changeAndDisconnect = async () => {
    try {
      await dispatch(userPasswordChange(password));

      alert(`비밀번호가 성공적으로 ${hasPassword ? "변경" : "설정"}되었습니다!`);
      disconnect();
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (directDisconnectCondition) disconnect();
    else if (checker(state)) changeAndDisconnect();
  };

  const providers = { kakao: "카카오", naver: "네이버" };
  const ko = providers[provider];

  return (
    <div className={cx("container")}>
      <div className={cx("header")}>
        {ko} 연동 해제
        <button className={cx("close")} onClick={() => dispatch(modalOff())} type="button">
          {" "}
        </button>
      </div>
      <div className={cx("contents")}>
        <form onSubmit={handleSubmit}>
          <p>
            {directDisconnectCondition ? (
              <span>
                {ko} 연동을 해제하시겠습니까?
                <br />
                연동이 즉시 해제됩니다.
              </span>
            ) : (
              <span>
                연동을 해제하실 경우, 이메일로 로그인해야합니다.
                <br />
                이메일 로그인에 필요한 비밀번호를 입력해주세요.
              </span>
            )}
          </p>

          {!hasPassword && (
            <>
              <input
                placeholder="신규 비밀번호 (8~20자 영문, 숫자 조합)"
                type="password"
                name="password"
                value={password}
                onChange={onChange}
              />
              {formFailure.UI__FORM_FAILURE_PASSWORD_INVALID && (
                <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_PASSWORD_INVALID}</div>
              )}

              <input
                placeholder="비밀번호 확인"
                type="password"
                name="passwordConfirm"
                value={passwordConfirm}
                onChange={onChange}
              />
              {formFailure.UI__FORM_FAILURE_PASSWORD_CONFIRM_INVALID && (
                <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_PASSWORD_CONFIRM_INVALID}</div>
              )}
              {formFailure.UI__FORM_FAILURE_PASSWORD_NOT_EQUAL && (
                <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_PASSWORD_NOT_EQUAL}</div>
              )}
            </>
          )}

          <div className={cx("btnWrapper-line")}>
            <button className={cx("cancel")} type="button" onClick={() => dispatch(modalOff())}>
              취소
            </button>
            <button className={cx("modify")} type="submit">
              해제하기
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
