import { fetcher } from "utils";

import { CoordinationSerialized, GetCoordiRequest } from "typings";

type GetCoordi = (params: GetCoordiRequest) => Promise<CoordinationSerialized[]>;

export const get__coordi: GetCoordi = params =>
  fetcher.get("/coordi", {
    ...params,
    ...(!params.displayType && { displayType: "lookpin_pay" })
  });
