import React, { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames/bind";

import { modalOff, updateCoupons } from "actions/actionCreators";

import { post__discount_coupons__issue } from "api";

import { IconArrowLeft } from "assets/icon";

import styles from "./CouponRegister.module.scss";

const cx = classNames.bind(styles);

export const CouponRegister: FC = () => {
  const dispatch = useDispatch();

  const [code, setCode] = useState("");

  const closeModal = useCallback(() => dispatch(modalOff()), [dispatch]);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();

      const response = await post__discount_coupons__issue({ code });

      if (response.status !== "success") return;
      dispatch(updateCoupons());
      dispatch(closeModal());
    },
    [closeModal, code, dispatch]
  );

  const handleChange = event => {
    setCode(event.target.value.replace(/ /g, ""));
  };

  return (
    <div className={cx("container")}>
      <div className={cx("header")}>
        <button className={cx("previous")} onClick={closeModal} type="button">
          <IconArrowLeft />
        </button>
        쿠폰등록
        <button className={cx("close")} onClick={closeModal} type="button">
          {" "}
        </button>
      </div>

      <div className={cx("notice")}>쿠폰 코드를 아래 창에 입력해주세요.</div>

      <form onSubmit={handleSubmit} className={cx("contents")}>
        <input
          className={cx("code")}
          type="input"
          placeholder="쿠폰 코드"
          name="code"
          value={code}
          onChange={handleChange}
        />

        <button className={cx("submit")} type="submit" disabled={!code}>
          등록하기
        </button>
      </form>
    </div>
  );
};
