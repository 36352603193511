import React, { FC } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames/bind";

import { BookmarkButton } from "components";

import { imageResizeHelper } from "utils";

import { StoreSerializedCamel, IStore } from "typings";

import styles from "./StoreRowWithBookmark.module.scss";
import { useSelector, shallowEqual } from "react-redux";

const cx = classnames.bind(styles);

interface IProps {
  id: StoreSerializedCamel["id"];
  name: StoreSerializedCamel["name"];
  group: StoreSerializedCamel["group"];
  photoUrl: StoreSerializedCamel["photoUrl"];
  serializedTags: StoreSerializedCamel["serializedTags"];
  genderId?: StoreSerializedCamel["genderId"];
}

export const StoreRowWithBookmark: FC<IProps> = ({ id, group, name, photoUrl, serializedTags }) => {
  const { isMobile } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui, shallowEqual);

  return isMobile ? (
    <div key={id} className={cx("store", "mobile")}>
      <div className={cx("leftWrapper")}>
        <Link to={{ pathname: `/stores/${id}` }} className={cx("link")}>
          <img className={cx("img")} src={imageResizeHelper(photoUrl, 48)} alt="store" />
        </Link>
        <div className={cx("info")}>
          <div className={cx("name-and-bookmark")}>
            <Link to={{ pathname: `/stores/${id}` }}>
              <span className={cx("name")}>{name}</span>
            </Link>
          </div>
          <div className={cx("tags")}>{serializedTags}</div>
        </div>
      </div>
      <BookmarkButton id={id} group={group} name={name} />
    </div>
  ) : (
    <div key={id} className={cx("store")}>
      <Link to={{ pathname: `/stores/${id}` }} className={cx("link")}>
        <img className={cx("img")} src={imageResizeHelper(photoUrl, 48)} alt="store" />
      </Link>
      <div className={cx("info")}>
        <div className={cx("name-and-bookmark")}>
          <Link to={{ pathname: `/stores/${id}` }}>
            <span className={cx("name")}>{name}</span>
          </Link>
          <BookmarkButton id={id} group={group} name={name} />
        </div>
        <div className={cx("tags")}>{serializedTags}</div>
      </div>
    </div>
  );
};
