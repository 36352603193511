import React, { FC, useMemo } from "react";
import { SimpleProductRow, ReviewWriteFooter, UserInput, SatisfactionInput, Evaluation } from "components";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames/bind";
import { useReviewWrite, useSets } from "hooks";
import { IReviewWrite, IStore } from "typings";
import { CloseQna } from "assets/icon";
import { modalOff } from "modules/ui";

import styles from "./SimpleReview.module.scss";

interface IProp {
  callbackFunction?: (arg: any) => void;
}

const cx = classNames.bind(styles);

export const SimpleReview: FC<IProp> = ({ callbackFunction }) => {
  const dispatch = useDispatch();
  const { id: reviewId, product, orderProductOrderNum } = useSelector<IStore, IStore["reviewWrite"]>(
    ({ reviewWrite }) => reviewWrite
  );
  const { getTargetMyWriteReview } = useSets();

  const targetReview = useMemo<IReviewWrite | undefined>(() => {
    if (reviewId !== undefined && reviewId !== null) {
      return getTargetMyWriteReview(reviewId);
    }
    return undefined;
  }, [getTargetMyWriteReview, reviewId]);

  const {
    color,
    setShipping,
    shipping,
    size,
    setSize,
    setColor,
    userableHeightChange,
    userableWeightChange,
    userableInfo,
    satisfaction,
    setSatisfaction,
    submitReview
  } = useReviewWrite(orderProductOrderNum, product?.productId, targetReview, callbackFunction);

  return (
    <div className={cx("wrapper")}>
      <section className={cx("header")}>
        <span className={cx("title")}>5초 리뷰</span>
        <button type="button" className={cx("button")} onClick={() => dispatch(modalOff({ isClear: true }))}>
          <CloseQna />
        </button>
      </section>
      <section className={cx("product-wrapper")}>{product && <SimpleProductRow product={product} />}</section>

      <section className={cx("user-wrapper")}>
        <div className={cx("sub-title")}>내정보</div>
        <div className={cx("user-info-input-wrapper")}>
          <UserInput value={userableInfo.height} onChange={userableHeightChange} title="키" unit="cm" />
          <UserInput value={userableInfo.weight} onChange={userableWeightChange} title="몸무게" unit="kg" />
        </div>
        <div className={cx("description")}>
          입력하신 정보(키, 몸무게 등)는 다른 구매 고객들에게 참고용으로 <span>상품 상세페이지 &gt; 구매후기</span> 에
          노출될 수 있습니다.
        </div>
      </section>
      <section>
        <div className={cx("sub-title", "normal-review-margin")}>
          일반리뷰 <span>(적립금 +300원)</span>
        </div>
        <div className={cx("input-wrapper")}>
          <div className={cx("input-title")}>만족도</div>
          <SatisfactionInput satisfaction={satisfaction} setSatisfaction={setSatisfaction} />
        </div>
      </section>

      <section>
        <div className={cx("evaluation-wrapper")}>
          <div className={cx("evaluation-title")}>사이즈</div>
          <Evaluation part="size" select={size} onChange={setSize} />
        </div>
        <div className={cx("evaluation-wrapper")}>
          <div className={cx("evaluation-title")}>색상</div>
          <Evaluation part="color" select={color} onChange={setColor} />
        </div>
        <div className={cx("evaluation-wrapper")}>
          <div className={cx("evaluation-title")}>배송</div>
          <Evaluation part="shipping" select={shipping} onChange={setShipping} />
        </div>
      </section>
      <section className={cx("submit-wrapper")}>
        <button className={cx("submit-button")} type="button" onClick={submitReview}>
          완료
        </button>
      </section>

      <ReviewWriteFooter />
    </div>
  );
};
