import React, { FC, useMemo } from "react";
import classNames from "classnames/bind";
import { IMemeberOrderViewModelReNewal, OrderProductCardType } from "typings";
import { Link } from "react-router-dom";
import { IconChat } from "assets/icon";
import styles from "./ProductDescription.module.scss";
import { useOrderAction } from "./useOrderAction";

interface IProp {
  order: IMemeberOrderViewModelReNewal;
  borderNone?: boolean;
  type?: OrderProductCardType;
}

const cx = classNames.bind(styles);

export const ProductDescription: FC<IProp> = ({ order, borderNone, type }) => {
  const { createOptionString } = useOrderAction();
  const {
    orderNum,
    productOrderNum,
    productImage,
    productName,
    productOptions,
    prevPrice,
    paidPrice,
    productSelectedCount,
    productId,
    store: { name, cellNumber }
  } = order;

  const productOptionText = useMemo(() => createOptionString(productOptions, productSelectedCount), [
    productOptions,
    productSelectedCount,
    createOptionString
  ]);

  return (
    <div className={cx("wrapper", { borderNone }, type)}>
      <Link to={`/products/${productId}`}>
        <img className={cx("image")} src={productImage} alt={productName} />
      </Link>
      <div className={cx("description-wrapper")}>
        {type === "review" && (
          <div className={cx("store", "review-type")}>
            <span className={cx("description")}>판매처: {name}</span>
          </div>
        )}
        <div className={cx("name")}>
          <Link to={`/products/${productId}`} className={cx("name-text")}>
            {productName}
          </Link>
        </div>
        <div className={cx("option")}>
          <Link to={`/products/${productId}`} className={cx("option-text")}>
            {productOptionText}{" "}
          </Link>
        </div>
        {type === "order" && (
          <div className={cx("price-wrapper")}>
            <Link to={`/products/${productId}`}>
              {prevPrice && <span className={cx("cancle-line")}>{prevPrice.toLocaleString()}원</span>}
              <span className={cx("price")}>{paidPrice.toLocaleString()}원</span>
            </Link>
          </div>
        )}
        {type === "order" && (
          <div className={cx("store")}>
            <span className={cx("description")}>{name}</span>
            <span className={cx("description")}>{cellNumber}</span>
            <Link className={cx("button")} to={`/member/orders/${orderNum}/${productOrderNum}/contact`}>
              <IconChat />
              <span className={cx("button-text")}>문의</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
