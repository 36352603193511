import React, { useRef, useEffect, useState, memo, FC } from "react";
import classNames from "classnames/bind";
import styles from "./LazyPhotoImage.module.scss";

interface IProp extends React.HTMLProps<HTMLImageElement> {}
const cx = classNames.bind(styles);

export const LazyPhotoImage: FC<IProp> = memo<IProp>(({ src, onClick, onMouseOver, onMouseOut, className, alt }) => {
  const ref = useRef<HTMLImageElement>(null);
  const [isLoad, setIsLoad] = useState<boolean>(false);

  useEffect(() => {
    if (ref.current) {
      if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window) {
        const io = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setIsLoad(true);
              observer.unobserve(entry.target);
            }
          });
        }, {});
        io.observe(ref.current);
      } else {
        setIsLoad(true);
      }
    }
  }, []);

  return (
    <img
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      ref={ref}
      className={`${className} ${cx("photoContents")}`}
      data-src={src}
      alt={alt}
      src={isLoad ? src : "https://s3.ap-northeast-2.amazonaws.com/lookpin-static/web_thumbnail.png"}
    />
  );
});
