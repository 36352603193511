import { memberViewModelCreator } from "view-model-creator";

import { historyContext, storageContext } from "contexts";

import { INITIALIZE, LOGOUT } from "constant";

import { ImemberState } from "typings";
import { Airbridge, AirbridgeEvent, deleteCookie, getDomain, getPreCookieKeyString, setCookie, Tracking } from "utils";

// TODO: 리덕스와 무관한 곳에서는 localStorage를 바라보도록 하더라도, 컴포넌트에서는 모두 token값을 기준으로 로그인 여부를 판단하고 그에 따라 데이터를 다루도록 수정할 것
const initialState: ImemberState = {
  id: 0,
  email: "",
  genderId: null,
  token: "",
  hasPassword: true,
  connectedSocialAccounts: [],
  shippingInfo: {
    id: 0,
    name: "",
    phoneNumber: "",
    address: {
      zipcode: "",
      main: "",
      detail: ""
    },
    message: ""
  },
  phoneNumber: null
};

export default function member(state = initialState, { type, payload }) {
  if (type === INITIALIZE && payload.member) {
    const { member } = payload;

    storageContext.token = member.token;
    if (member.genderId) storageContext.genderId = member.genderId;
    if (storageContext.token) {
      setCookie({
        key: getPreCookieKeyString("__look_pin_user", process.env.REACT_APP_ENV),
        value: "member",
        domain: getDomain(process.env.REACT_APP_ENV),
        secure: process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "development"
      });
      setCookie({
        key: getPreCookieKeyString("__look_pin_uuid", process.env.REACT_APP_ENV),
        value: storageContext.uuid,
        domain: getDomain(process.env.REACT_APP_ENV),
        secure: process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "development"
      });
      setCookie({
        key: getPreCookieKeyString("__look_pin_token", process.env.REACT_APP_ENV),
        value: storageContext.token,
        domain: getDomain(process.env.REACT_APP_ENV),
        secure: process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "development"
      });
    }

    if (process.env.REACT_APP_ENV === "production")
      Tracking.memeberSetting({ email: member.email, phoneNumber: member.phone_number });
    Airbridge.memberSetting({ email: member.email, id: member.id, phoneNumber: member.phone_number });

    const redirectURL = window.history.state?.state?.redirect;
    if (redirectURL) {
      window.location.href = decodeURIComponent(redirectURL);
    }
    return memberViewModelCreator(member);
  }

  if (type === LOGOUT) {
    storageContext.token = null;
    deleteCookie(getPreCookieKeyString("__look_pin_user", process.env.REACT_APP_ENV));
    deleteCookie(getPreCookieKeyString("__look_pin_uuid", process.env.REACT_APP_ENV));
    deleteCookie(getPreCookieKeyString("__look_pin_token", process.env.REACT_APP_ENV));
    if (process.env.REACT_APP_ENV === "production") Tracking.memeberSetting({ email: null, phoneNumber: null });
    AirbridgeEvent.signout();

    historyContext.replace("/");
    window.scrollTo(0, 0);

    return initialState;
  }

  return state;
}
