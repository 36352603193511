import React, { useState, FC } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames/bind";
import Slider from "react-slick";
import qs from "qs";

import { IconChevronDown } from "assets/icon";

import styles from "./PopularSearchTermMobile.module.scss";

const cx = classnames.bind(styles);

interface IProps {
  keywords: string[];
}

export const PopularSearchTermMobile: FC<IProps> = ({ keywords }) => {
  const [visible, setVisible] = useState(false);

  const toggleTerms = () => setVisible(!visible);

  return (
    <>
      <div role="button" tabIndex={0} className={cx("wrapper")} onClick={toggleTerms} onKeyDown={toggleTerms}>
        <div className={cx("label")}>인기검색어</div>

        <div className={cx("keywords")}>
          <Slider
            className={cx("slider")}
            responsive={[
              {
                breakpoint: 415,
                settings: {
                  dots: false,
                  infinite: true,
                  speed: 500,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay: true,
                  vertical: true,
                  arrows: false
                }
              }
            ]}
          >
            {keywords.map((keyword, index) => (
              <div className={cx("keyword")} key={keyword}>
                <span className={cx("rank")}>{index + 1}.</span>
                &nbsp;{keyword}
              </div>
            ))}
          </Slider>
        </div>

        <i className={cx("more")}>
          <IconChevronDown />
        </i>
      </div>

      <section className={cx("terms-section", { visible })}>
        <ul className={cx("terms")}>
          {keywords.slice(0, 5).map((keyword, index) => (
            <Link
              to={{ pathname: "/search/product", search: qs.stringify({ keyword }) }}
              className={cx("term")}
              key={keyword}
            >
              <span className={cx("rank")}>{index + 1}.</span>
              &nbsp;{keyword}
            </Link>
          ))}
        </ul>

        <ul className={cx("terms")}>
          {keywords.slice(5).map((keyword, index) => (
            <Link
              to={{ pathname: "/search/product", search: qs.stringify({ keyword }) }}
              className={cx("term")}
              key={keyword}
            >
              <span className={cx("rank")}>{index + 5 + 1}.</span>
              &nbsp;{keyword}
            </Link>
          ))}
        </ul>
      </section>
    </>
  );
};
