import {
  ApiV1CoordiSearchSerialized,
  GetApiV1CoordiSearchRequest,
  IJSendSuccessResponse,
  IPageMeta,
  IWithMeta
} from "typings";
import { fetcher } from "utils";

type ApiV1CoordiSearchRequest = (
  params: GetApiV1CoordiSearchRequest
) => Promise<IJSendSuccessResponse<IWithMeta<ApiV1CoordiSearchSerialized[], IPageMeta>>>;

export const api__v1__coordi__search_get: ApiV1CoordiSearchRequest = params => {
  return fetcher.get("/api/v1/coordi/search", params);
};
