import { IconChevronRight } from "assets/icon";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./MoreBox.module.scss";

interface IProp {
  to: string;
}

const cx = classNames.bind(styles);

export const MoreBox: FC<IProp> = ({ to }) => {
  return (
    <Link to={to} className={cx("link")}>
      <div className={cx("more-box")}>
        <span className={cx("more")}>더보기</span>
        <span className={cx("chevron")}>
          <IconChevronRight />
        </span>
      </div>
    </Link>
  );
};
