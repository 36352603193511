import React, { FC } from "react";
import { ChevronLeftGray, ChevronRightGray } from "assets/icon";
import classNames from "classnames/bind";
import styles from "./PhotoArrowNav.module.scss";

const cx = classNames.bind(styles);

interface IProp {
  fullPageSize: number;
  nowPage: number;
  changeDiffEvent: (diff: number) => void;
}

export const PhotoArrowNav: FC<IProp> = ({ fullPageSize, nowPage, changeDiffEvent }) => {
  return (
    <nav className={cx("photoArrowNavWrapper")}>
      <div key="gray-left-svg">
        {nowPage > 1 && (
          <div
            className={cx("arrowWrapper")}
            onClick={() => {
              changeDiffEvent(-1);
            }}
          >
            <ChevronLeftGray />
          </div>
        )}
      </div>
      <div key="gray-right-svg">
        {nowPage !== fullPageSize && (
          <div
            className={cx("arrowWrapper", "right")}
            onClick={() => {
              changeDiffEvent(1);
            }}
          >
            <ChevronRightGray />
          </div>
        )}
      </div>
    </nav>
  );
};
