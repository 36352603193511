import { fetcher } from "utils";

import { IJSendSuccessResponse, PayMethodEn } from "typings";

/* global IMP */

interface IPostMembersOrdersParams {
  optionIds: number[];
  payMethod: PayMethodEn;
  consumedPoint?: number;
  issuedDiscountCoupons?: Array<{
    id: number;
    optionIds: number[];
  }>;
  options: {
    platform?: "pcweb" & string;
  };
}

interface IPostMembersOrdersV3Params {
  shippingInfo: {
    shippingInfoId: number | null;
    name: string;
    phoneNumber: string;
    address: {
      main: string;
      detail: string;
      zipcode: string;
    };
    message: string;
    infoAlias: string | null;
    pccc: string | null;
  };
  options: {
    optionId: number;
    count: number;
    issuedDiscountCouponId?: number | null;
    // 웹 대응할 필요 없음
    trackingData: null;
  }[];
  payMethod: PayMethodEn;
  consumePoint: number;
  isPcWeb: boolean;
  totalPaidPrice: number;
}

export interface IPostMembersOrdersEntity {
  payload: {
    url: string;
    paymentObject: {
      pg: string;
      identificationCode: string;
      payMethod: PayMethodEn | null;
      merchantUid: number;
      name: string;
      amount: number;
      buyerEmail: string;
      buyerName: string;
      buyerTel: string;
      buyerAddr: string;
      appScheme: string;
      redirectUrl: string;
      noticeUrl: string;
      digital: boolean | null;
      naverpay_info: {
        isV2: boolean;
        popupMode: boolean;
        products: [
          {
            id: string;
            categoryType: string;
            categoryId: string;
            name: string;
            count: string;
          }
        ];
      } | null;
    };
  };
}

export const post__members__orders = (params: IPostMembersOrdersParams): Promise<IMP.IIamportParams> =>
  fetcher.post("/members/orders", { ...params, responseType: "object" });

export const post__members__orders__v3 = (
  params: IPostMembersOrdersV3Params
): Promise<IJSendSuccessResponse<IPostMembersOrdersEntity>> => fetcher.post("/api/v3/members/orders", params);
