import React, { FC } from "react";
import classnames from "classnames/bind";

import { useHover } from "hooks";

import { IconQuestionMark } from "assets/icon";

import styles from "./QuestionHoverBox.module.scss";

const cx = classnames.bind(styles);

interface IProps {
  info?: [string, string][];
}

export const QuestionHoverBox: FC<IProps> = ({ info }) => {
  const { isHover, hoverOn, hoverOff, handleHover } = useHover();

  return (
    <div className={cx("wrapper")}>
      <button type="button" onMouseEnter={hoverOn} onMouseLeave={hoverOff} onClick={handleHover}>
        <IconQuestionMark />
      </button>

      <div className={cx("box", { show: isHover })}>
        {!info ? (
          <div className={cx("warning")}>상점 정보를 불러오지 못했습니다.</div>
        ) : (
          <ul className={cx("list")}>
            {info.slice(1).map(([property, value]) => (
              <li key={property}>
                <div className={cx("property")}>{property}</div>
                <div className={cx("value")}>{value}</div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
