import { useCallback, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import * as api from "api";

import { modalOn, removeSetsIteAction, addSetsItemAction } from "actions/actionCreators";

import { UI__MODAL_LOGIN } from "constant";

import { IStore } from "typings";
import { useSets } from "./useSets";
import { AirbridgeCustomEvent } from "utils";

export const useBookmark = () => {
  const dispatch = useDispatch();
  const token = useSelector<IStore, IStore["member"]["token"]>(({ member: { token } }) => token, shallowEqual);
  const { bookmarks: bookmarksSet } = useSets();

  const [isBookmarkLoading, setIsBookmarkLoading] = useState(false);

  const getIsBookmark = useCallback(id => bookmarksSet.has(id), [bookmarksSet]);

  const deleteBookmark = useCallback(
    async (...ids: number[]) => {
      await Promise.all(ids.map(id => api.delete__users__stores(id)));

      ids.forEach(id => {
        dispatch(removeSetsIteAction({ key: "bookmarks", value: id }));
      });
    },
    [dispatch]
  );

  const toggleBookmark = useCallback(
    async event => {
      if (!isBookmarkLoading) {
        const { name, group, id: _id } = event.target.dataset;
        const id = Number(_id);
        setIsBookmarkLoading(true);
        if (!token) dispatch(modalOn(UI__MODAL_LOGIN));
        else if (getIsBookmark(id)) await deleteBookmark(id);
        else {
          await api.post__users__stores({ storeId: id });
          dispatch(addSetsItemAction({ key: "bookmarks", value: id }));
          AirbridgeCustomEvent.like("store", {
            id,
            name,
            type: group === "default" ? "store" : "brand"
          });
        }
        setIsBookmarkLoading(false);
      }
    },
    [dispatch, getIsBookmark, deleteBookmark, token, isBookmarkLoading]
  );

  return { getIsBookmark, deleteBookmark, toggleBookmark, isBookmarkLoading };
};
