import * as api from "api";
import { modalOff } from "modules/ui";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ProductQuestionType } from "typings";

export const useQnAWrite = () => {
  const dispatch = useDispatch();
  const history = useHistory<{ productId: string; productImage: string; productTitle: string; storeName: string }>();
  const product = history.location.state;
  const [contents, setContents] = useState<string>("");
  const [typeOf, setTypeOf] = useState<ProductQuestionType | "">("");
  const [hiddenFlag, setHiddenFlag] = useState<boolean>(false);
  const submitListner = useCallback(async () => {
    const productId = parseInt(product.productId, 10);

    if (!Number.isNaN(productId) && typeOf) {
      await api.api__v1_member_product_questions_post({
        productId,
        typeof: typeOf,
        contents,
        hidden: hiddenFlag
      });
      alert("문의 등록을 성공했습니다.");
      dispatch(modalOff({ isClear: true }));
    }
  }, [typeOf, contents, hiddenFlag, dispatch, product]);

  return {
    contents,
    setContents,
    typeOf,
    setTypeOf,
    submitListner,
    product,
    hiddenFlag,
    setHiddenFlag
  };
};
