import { Gender, IJSendSuccessResponse, MemberSerialized } from "typings";
import { fetcher } from "utils";

type MemberUpdate = (payload: {
  phone_number?: string;
  gender_id?: Gender;
  confirm_marketing_sms?: boolean;
}) => Promise<IJSendSuccessResponse<{ payload: MemberSerialized }>>;

export const api__member_put_update: MemberUpdate = payload => fetcher.put("/member", payload);
