export const imageLazyLoading = (root: HTMLElement) => {
  const images = root.querySelectorAll("img");
  images.forEach(image => {
    if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window) {
      const io = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const img = entry.target as HTMLImageElement;
              img.src = img.dataset.src || (img.dataset.original as string) || img.src;
              observer.unobserve(entry.target);
            }
          });
        },
        { rootMargin: "300px" }
      );
      io.observe(image!);
    } else {
      image.src = image.dataset.src || (image.dataset.original as string) || image.src;
    }
  });
};
