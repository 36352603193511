import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { always, pipe } from "ramda";
import { init as initNaver } from "services/naver";
import { init as initKakao } from "services/kakao";
import { IconSocialKakao, IconSocialNaver } from "assets/icon";
import { modalOn, setAuthentication } from "actions/actionCreators";
import { IStore, Provider } from "typings";
import {
  UI__MODAL_DISCONNECT_PROVIDER,
  UI__MODAL_FIND_PASSWORD,
  UI__MODAL_UPDATE_PASSWORD,
  NAVER,
  KAKAO
} from "constant";
import classNames from "classnames/bind";
import styles from "components/pages/member/accounts/Accounts.module.scss";

const cx = classNames.bind(styles);

export function Accounts() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { connectedSocialAccounts, hasPassword } = useSelector<IStore, IStore["member"]>(({ member }) => member);

  useEffect(() => {
    initKakao();
    initNaver();
  }, [history]);

  const connectProvider = useCallback((provider: Provider) => {
    const table = {
      [KAKAO]: document.querySelector("#kakaoIdLogin") as HTMLDivElement,
      [NAVER]: document.querySelector("#naverIdLogin_loginButton") as HTMLAnchorElement
    };

    table[provider].click();
  }, []);
  const handleDisconnect = useCallback(
    (provider: Provider) => {
      dispatch(setAuthentication({ provider }));
      dispatch(modalOn(UI__MODAL_DISCONNECT_PROVIDER));
    },
    [dispatch]
  );
  const handleConnect = useCallback((provider: Provider) => connectProvider(provider), [connectProvider]);
  const updatePassword = useCallback(() => dispatch(modalOn(UI__MODAL_UPDATE_PASSWORD)), [dispatch]);
  const findPassword = useCallback(() => dispatch(modalOn(UI__MODAL_FIND_PASSWORD)), [dispatch]);

  const infoText = useMemo(() => (hasPassword ? "변경" : "설정"), [hasPassword]);
  const kakaoConnect = useMemo(() => connectedSocialAccounts.includes(KAKAO), [connectedSocialAccounts]);
  const naverConnect = useMemo(() => connectedSocialAccounts.includes(NAVER), [connectedSocialAccounts]);

  return (
    <main className={cx("accounts-tab")}>
      <div className={cx("wrapper")}>
        <div className={cx("header")}>계정</div>
      </div>
      <div className={cx("wrapper")}>
        <div className={cx("title")}>내 정보</div>
        <ul className={cx("contentBox")}>
          <li>
            비밀번호 {infoText}
            <button className={cx("modify")} onClick={updatePassword} type="button">
              {infoText}
            </button>
          </li>
          <li>
            비밀번호 찾기
            <button className={cx("reset")} onClick={findPassword} type="button">
              찾기
            </button>
          </li>
          <li>
            회원탈퇴
            <span className={cx("description")}>
              룩핀 앱 로그인 후 MY룩핀 &gt; 환경설정 &gt; 탈퇴하기 버튼으로 탈퇴할 수 있습니다.
            </span>
          </li>
        </ul>
      </div>
      <div className={cx("wrapper")}>
        <div className={cx("title")}>소셜 계정 연동</div>
        <ul className={cx("contentBox")}>
          <li key="kakao">
            <IconSocialKakao />
            카카오
            <button
              className={cx("switch", { connect: kakaoConnect })}
              type="button"
              onClick={pipe(always(KAKAO), kakaoConnect ? handleDisconnect : handleConnect)}
            >
              {kakaoConnect ? "연동해제" : "연동하기"}
            </button>
          </li>

          <li key="naver">
            <IconSocialNaver />
            네이버
            <button
              className={cx("switch", { connect: naverConnect })}
              type="button"
              onClick={pipe(always(NAVER), naverConnect ? handleDisconnect : handleConnect)}
            >
              {naverConnect ? "연동해제" : "연동하기"}
            </button>
          </li>
        </ul>

        <div id="naverIdLogin" className={cx("hide")} />
        <div id="kakaoIdLogin" className={cx("hide")} />
      </div>
    </main>
  );
}
