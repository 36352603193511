import React, { FC, memo, useMemo } from "react";
import classNames from "classnames/bind";
import { useDispatch } from "react-redux";
import { modalOff } from "modules/ui";
import { LOOKPIN_KAKAO_LINK, SIGNUP_DESCRIPTION } from "constant";

import { NumberAuthCallBackFunction } from "typings";
import { Term } from "components/presentation/term/Term";
import styles from "./NumberAuth.module.scss";
import modalStyles from "../../../ModalWrapper.module.scss";
import { useNumberAuth } from "./useNumberAuth";

interface IProp {
  callbackFunction?: NumberAuthCallBackFunction;
}
const cx = classNames.bind(styles);
const ss = classNames.bind(modalStyles);

export const NumberAuth: FC<IProp> = memo(({ callbackFunction }) => {
  const dispatch = useDispatch();
  const {
    phoneNumber,
    phoneNumberChange,
    sendAuthNumber,
    isSendNumber,
    authNumber,
    authNumberChange,
    submitFormEvent,
    timeCount,
    isExpire,
    isAuthDone,
    doneClickEvent,
    sendNumberDisabled,
    necessaryAgreeMent,
    termAgreeCheck,
    agreeMent
  } = useNumberAuth();

  const timeCountMinutes = useMemo(() => Math.floor(timeCount / 60), [timeCount]);

  const timeCountSeconds = useMemo(() => {
    const seconds = timeCount % 60;
    return seconds > 9 ? seconds : `0${seconds}`;
  }, [timeCount]);

  const authConfirmDisabled = useMemo(() => isAuthDone || !isSendNumber || isExpire, [
    isSendNumber,
    isExpire,
    isAuthDone
  ]);
  const completeDisabled = useMemo(() => !isAuthDone || !necessaryAgreeMent, [isAuthDone, necessaryAgreeMent]);

  return (
    <div className={ss("container")}>
      <div className={ss("header")}>
        휴대폰 번호 인증
        <button
          className={ss("close")}
          onClick={() => {
            dispatch(modalOff());
          }}
          type="button"
        >
          {" "}
        </button>
      </div>
      <div className={cx("body")}>
        <div className={cx("input-wrapper")}>
          <div className={cx("input-container")}>
            <div className={cx("input-title")}>휴대폰 번호</div>
            <div className={cx("input-body")}>
              <input
                type="tel"
                className={cx("input", "phone-input")}
                placeholder="(-)없이 숫자 11자리입력"
                value={phoneNumber}
                onChange={phoneNumberChange}
              />
              <button
                type="button"
                id="request-auth"
                className={cx("button")}
                onClick={sendAuthNumber}
                disabled={sendNumberDisabled}
              >
                인증번호 받기
              </button>
            </div>
          </div>
          <form className={cx("submit-container")} onSubmit={submitFormEvent}>
            <div className={cx("input-container")}>
              <div className={cx("input-title")}>인증번호 4자리</div>
              <div className={cx("input-body")}>
                <input
                  type="tel"
                  placeholder="인증번호 4자리"
                  className={cx("input", "auth-input")}
                  value={authNumber}
                  onChange={authNumberChange}
                />
                <button id="send-auth" className={cx("button")} type="submit" disabled={authConfirmDisabled}>
                  인증확인
                </button>
              </div>

              <span className={cx("timer")}>
                {isSendNumber && `${timeCountMinutes}:${timeCountSeconds}`} {timeCount === 0 && "(시간 초과)"}
              </span>
            </div>
          </form>
          <div className={cx("term-box-wrapper")}>
            <Term onChange={termAgreeCheck} agreeMent={agreeMent} />
          </div>
          <button
            type="button"
            id="completed-button"
            className={ss("login")}
            onClick={() => doneClickEvent(callbackFunction)}
            disabled={completeDisabled}
          >
            완료
          </button>
        </div>
        <div className={cx("description-wrapper")}>
          <a className={cx("help-button")} href={LOOKPIN_KAKAO_LINK}>
            인증이 안돼요 (문의하기)
          </a>
          <pre className={cx("signup-description")}>{SIGNUP_DESCRIPTION}</pre>
        </div>
      </div>
    </div>
  );
});
