import { INITIALIZE, SYNC } from "constant";

import { IAddress } from "typings";

const initialState: IAddress = {
  main: "",
  detail: "",
  zipcode: ""
};

/* eslint-disable no-fallthrough */

export default function pickUpAddress(state = initialState, { type, payload }): IAddress {
  switch (type) {
    case INITIALIZE:
      if (payload?.member?.shipping_infos?.[0]?.address) return payload.member.shipping_infos[0].address;
      return state;
    case SYNC:
      if (payload?.pickUpAddress) return payload.pickUpAddress;
      return state;

    default:
      return state;
  }
}
