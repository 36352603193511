import React, { FC } from "react";
import Loader from "react-loader-spinner";
import classnames from "classnames/bind";

import { useBookmark } from "hooks";
import { StoreSerializedCamel } from "typings";

import { IconCheck } from "assets/icon";

import styles from "./BookmarkButton.module.scss";

interface IProps {
  id: StoreSerializedCamel["id"];
  name: StoreSerializedCamel["name"];
  group: StoreSerializedCamel["group"];
}

const cx = classnames.bind(styles);

export const BookmarkButton: FC<IProps> = ({ id, group, name }) => {
  const { getIsBookmark, toggleBookmark, isBookmarkLoading } = useBookmark();
  const isBookmark = getIsBookmark(id);

  return (
    <button
      type="button"
      className={cx("bookmark", { "btn-loading": isBookmarkLoading, isBookmark })}
      onClick={toggleBookmark}
      disabled={isBookmarkLoading}
      data-id={id}
      data-name={name}
      data-group={group}
    >
      {isBookmarkLoading ? (
        <Loader type="Oval" color={isBookmark ? "#c7cdd4" : "#4285f4"} height={13} width={13} />
      ) : (
        <>
          {isBookmark && (
            <>
              <IconCheck />
              &nbsp;&nbsp;
            </>
          )}
          즐겨찾기
          {isBookmark ? " 중" : ""}
        </>
      )}
    </button>
  );
};
