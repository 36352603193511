import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import qs from "qs";
import { times } from "ramda";
import { imageResizeHelper, Tracking, produce } from "utils";
import { useCoordi } from "hooks";
import { Checkbox, Pagination, Loading } from "components";
import { coordiViewModelCreator, coordiMetaViewModelCreator } from "view-model-creator";
import { get__users__coordi } from "api";
import { ICoordiViewModel } from "typings";

import classNames from "classnames/bind";
import share from "components/presentation/card/coordi/CoordiCard.module.scss";
import styles from "./CoordiLike.module.scss";

const cx = classNames.bind(styles);
const ss = classNames.bind(share);

const PER_PAGE = 50;

export function CoordiLike() {
  const history = useHistory();
  const mainRef = useRef<HTMLElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [coordi, setCoordi] = useState<ICoordiViewModel[]>([]);
  const [coordiMeta, setCoordiMeta] = useState({ totalCount: 0 });

  const [checkIds, setCheckIds] = useState<Set<number>>(new Set());

  const { deleteCoordiLike } = useCoordi();

  const hiddenFlexBoxes = useMemo(() => times(v => v + 1, coordi.length % 3 ? 3 - (coordi.length % 3) : 0), [
    coordi.length
  ]);

  const qsPageNumber = useMemo(() => {
    const { page } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    return page || 1;
  }, [history.location.search]);

  const handlePage = useCallback(
    number => {
      const search = qs.stringify({ page: number });
      history.push({ search });

      window.scrollTo(0, 0);
    },
    [history]
  );

  const getCoordi = useCallback(() => {
    const param = { page: { number: pageNumber, size: PER_PAGE } };

    setIsLoading(true);

    get__users__coordi(param).then(({ data: { coordinations, meta: coordiMeta } }) => {
      setCoordi(coordiViewModelCreator(coordinations));

      setCoordiMeta(coordiMetaViewModelCreator(coordiMeta));

      setIsLoading(false);

      if (mainRef.current) mainRef.current.style.minHeight = "auto";
    });
  }, [pageNumber]);

  const toggleCoordi = useCallback(
    event => {
      event.preventDefault();

      const id = Number(event.target.dataset.id);

      setCheckIds(
        produce(checkIds, draft => {
          if (checkIds.has(id)) draft.delete(id);
          else draft.add(id);
        })
      );
    },
    [checkIds]
  );

  const selectAll = useCallback(
    event => {
      event.preventDefault();

      setCheckIds(checkIds.size === coordi.length ? new Set() : new Set(coordi.map(({ id }) => id)));
    },
    [coordi, checkIds]
  );

  const handleDeleteCoordiLike = useCallback(async () => {
    if (checkIds.size === 0) return;
    await deleteCoordiLike(...checkIds.values());

    setCheckIds(new Set());
    getCoordi();
  }, [checkIds, deleteCoordiLike, getCoordi]);

  useEffect(() => {
    setPageNumber(Number(qsPageNumber));
  }, [qsPageNumber, history.location.search]);

  useEffect(getCoordi, [pageNumber]);

  useEffect(() => {
    Tracking.viewCoordiLike();
  }, []);

  return (
    <main className={cx("coordi-tab")} ref={mainRef}>
      <header className={cx("title")}>
        <div className={cx(["bold"])}>코디&nbsp;좋아요</div>
        <div className={cx("desc")}>{`${coordiMeta.totalCount}개의 상품이 있습니다.`}</div>
      </header>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={cx("remove")}>
            <Checkbox checked={!!coordi.length && checkIds.size === coordi.length} id="all" handleClick={selectAll} />

            <button type="button" className={cx("btn")} onClick={handleDeleteCoordiLike}>
              삭제
            </button>

            {checkIds.size > 0 && <div className={cx("checked")}>{checkIds.size}개 선택됨</div>}
          </div>

          <div className={cx("card-list")}>
            {coordi.map(({ id, photoUrl, title, tags }) => (
              <div className={cx("card")} key={id}>
                <div className={cx("checkbox")}>
                  <Checkbox checked={checkIds.has(id)} handleClick={toggleCoordi} id={id} />
                </div>

                <div className={ss("card-child")}>
                  <div className={ss("thumbnail")}>
                    <Link to={`/coordi/${id}`}>
                      <picture className={ss("picture")}>
                        <img src={imageResizeHelper(photoUrl, 270)} className={ss("img")} alt="coordi" />
                      </picture>
                    </Link>
                  </div>

                  <Link to={`/coordi/${id}`}>
                    <div className={ss("title")}>{title}</div>
                  </Link>

                  <div className={ss("tags")}>
                    {tags.map(tag => (
                      <Link
                        to={`/coordi${qs.stringify({ searchKeywords: [tag] }, { addQueryPrefix: true })}`}
                        className={ss("tag")}
                        key={tag}
                      >
                        {tag}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            ))}

            {hiddenFlexBoxes.map(v => (
              <div key={`hidden_coordi_${v}`} className={cx("card", "hidden")} />
            ))}
          </div>

          <Pagination totalCount={coordiMeta.totalCount} handler={handlePage} number={pageNumber} size={PER_PAGE} />
        </>
      )}
    </main>
  );
}
