import React, { ChangeEvent, FC } from "react";
import { map, reduce } from "ramda";
import classnames from "classnames/bind";

import share from "./SelectShare.module.scss";
import styles from "./SelectStoreCategory.module.scss";

const sx = classnames.bind(share);
const cx = classnames.bind(styles);

interface IProps {
  categories: [string, number][];
  category: string;
  handleCategory: (event: ChangeEvent) => void;
}

export const SelectStoreCategory: FC<IProps> = ({ categories, category, handleCategory }) => (
  <select className={[sx("select"), cx("store-category")].join(" ")} value={category} onChange={handleCategory}>
    <option key="전체 카테고리" value="">
      전체 카테고리 ({reduce((acc, [, count]) => acc + count, 0, categories)}개)
    </option>

    {map(
      ([name, count]) => (
        <option key={name} value={name}>
          {name} {`(${count}개)`}
        </option>
      ),
      categories
    )}
  </select>
);
