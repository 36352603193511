import React, { FC } from "react";
import className from "classnames/bind";

import styles from "./KeyValueTemplate.module.scss";

const cx = className.bind(styles);

interface IProps {
  list: Array<[string, string, string?]>;
  isBetween?: boolean;
}

export const KeyValueTemplate: FC<IProps> = ({ list, isBetween }) => (
  <ul className={cx("list")}>
    {list.map(([key, value, originalPrice]) => (
      <li key={key} className={isBetween ? cx("between") : cx("not-between")}>
        <span className={cx("key")}>{key}</span>
        <span className={cx("value")}>
          {value}
          {originalPrice && <span className={cx("original")}>{originalPrice}</span>}
        </span>
      </li>
    ))}
  </ul>
);
