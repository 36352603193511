import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classnames from "classnames/bind";

import { IconArrowLeft } from "assets/icon";

import { IStore } from "typings";

import styles from "./PreviousPageMobile.module.scss";

const cx = classnames.bind(styles);

export const PreviousPageMobile = () => {
  const isMobile = useSelector<IStore, IStore["ui"]["isMobile"]>(({ ui: { isMobile } }) => isMobile);

  const history = useHistory();

  if (!isMobile) return null;

  return (
    <>
      <button onClick={history.goBack} type="button" className={cx("back")}>
        <IconArrowLeft />
      </button>{" "}
    </>
  );
};
