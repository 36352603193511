import { fetcher } from "utils";

import { IJSendSuccessResponse, IRefundAccountV3 } from "typings";

// 생성 or 수정
export const post__members__refund__accounts_v3 = (
  params: IRefundAccountV3
): Promise<IJSendSuccessResponse<{ data: null }>> => fetcher.post("/api/v3/members/refund-bank-account", params);

// 계좌 검증
export const post__members__refund_bank_accounts_v3 = (
  params: IRefundAccountV3
): Promise<IJSendSuccessResponse<null>> => fetcher.post("/api/v3/members/orders/refund-bank-account", params);
