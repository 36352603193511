import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";

import { Airbridge } from "utils";
import "./LookpinBanner.scss";

const HOME_DEEP_LINK_DIC = {
  "/": "/home/home",
  "/search/ranking": "/home/ranking",
  "/stores": "/home/store",
  "/banners": "/home/sale"
};

const MY_LOOKPIN_DEEP_LINK_DIC = {
  "/member/qna": "/member/questions"
};

const REDIRECT_DIC = {
  ...HOME_DEEP_LINK_DIC,
  ...MY_LOOKPIN_DEEP_LINK_DIC
};

function refineSearchQueries(queries: string) {
  const queryObject = qs.parse(queries, { ignoreQueryPrefix: true });

  if (queryObject.filterGroup) {
    queryObject.filter_group = queryObject.filterGroup;
    delete queryObject.filterGroup;
  }

  if (queryObject.searchKeywords) {
    queryObject.multi_keyword = (queryObject.searchKeywords as string[]).join(",");
    delete queryObject.searchKeywords;
  }

  if (queryObject?.params?.["order"]) {
    queryObject.order = (queryObject.params as Record<string, string>).order;
    delete queryObject.params;
  }

  return qs.stringify(queryObject, { addQueryPrefix: true });
}

function refinePath(pathname: string, search: string) {
  let refinedPathname = REDIRECT_DIC[pathname] || pathname;
  const refinedSearchQueries = refineSearchQueries(search);

  if (refinedSearchQueries.includes("multi_keyword")) {
    refinedPathname = "/coordi/search";
  }

  return refinedPathname + refinedSearchQueries;
}

interface LookpinBannerProps {
  isBannerVisible: boolean;
  onCloseBanner: () => void;
}

const LookpinBanner = ({ isBannerVisible, onCloseBanner }: LookpinBannerProps) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    Airbridge.setDeepLinks("app_banner_btn", refinePath(pathname, search));
  }, [pathname, search]);

  return (
    <div className={`banner ${isBannerVisible ? "" : "hide"}`}>
      <button type="button" className="close" onClick={onCloseBanner}>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
          <path
            fill="rgba(0, 0, 0, .15)"
            d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
          />
        </svg>
      </button>
      <img
        src="https://play-lh.googleusercontent.com/nvH1MzU9vTQ9lj1I6FsZS_DGbl4yIv2kTiRLMdYeezp57ZJAj6FrPa7gfSxkW0n_qOuT"
        alt="banner-icon"
      />
      <div className="info">
        <p className="title">MZ세대 남자들의 쇼핑앱, 룩핀</p>
        <p className="desc">
          룩핀 앱을 설치하고
          <br />더 많은 혜택을 받아보세요.
        </p>
      </div>

      <button id="app_banner_btn">열기</button>
    </div>
  );
};

export default LookpinBanner;
