import React, { useCallback, useEffect, useRef } from "react";
import classNames from "classnames/bind";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "typings";
import { getMorePhotoReviewsRequest, openPhotoDetailModal, modalOff, modalOn } from "actions/actionCreators";
import { IconThinClose } from "assets/icon";
import { useThrottle } from "hooks";
import { ESC_KEYCODE, UI__MODAL_DETAIL_PHOTO_REVIEW } from "constant";
import { LazyPhotoImage } from "components";
import styles from "./GridPhotoReview.module.scss";

const cx = classNames.bind(styles);
export const GridPhotoReview = () => {
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const { photoReviews } = useSelector<IStore, IStore["photoReview"]>(({ photoReview }) => photoReview);

  const closeButtonClick = useCallback(() => {
    dispatch(modalOff({ isClear: true }));
  }, [dispatch]);

  const openPhotoReviewDetail = useCallback(
    index => () => {
      dispatch(modalOn(UI__MODAL_DETAIL_PHOTO_REVIEW, { scrollY: window.scrollY }));
      dispatch(openPhotoDetailModal({ reviewDetail: photoReviews[index], select: index + 1, isOpenGrid: true }));
    },
    [photoReviews, dispatch]
  );
  const getMorePhotoReviewsList = useCallback(() => {
    dispatch(getMorePhotoReviewsRequest());
  }, [dispatch]);

  const [throttleRequestReviews] = useThrottle(getMorePhotoReviewsList, 1000);
  const scorllEvent = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const { scrollHeight } = event.currentTarget;
      const { clientHeight } = event.currentTarget;
      const { scrollTop } = event.currentTarget;
      const scrollPos = scrollHeight - scrollTop;

      if (clientHeight + 200 >= scrollPos && mountedRef.current === true) {
        throttleRequestReviews();
        event.currentTarget.focus();
      }
    },
    [throttleRequestReviews]
  );

  const clickEvent = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  }, []);
  const keyEventListner = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === ESC_KEYCODE) closeButtonClick();
    },
    [closeButtonClick]
  );
  useEffect(() => {
    window.addEventListener("keydown", keyEventListner);
    return () => {
      mountedRef.current = false;
    };
  }, [keyEventListner]);
  return (
    <div className={cx("photoReviewGridWrapper")} onClick={clickEvent}>
      <header className={cx("photoReviewGridHeader")}>
        <span className={cx("photoGridTitleText")}>포토리뷰</span>
        <button type="button" className={cx("close-modal-button-wrapper")} onClick={closeButtonClick}>
          <IconThinClose />
        </button>
      </header>
      <section className={cx("photoBodyGridWrapper")} onScroll={scorllEvent}>
        <div className={cx("imageWrapper")}>
          <div className={cx("image-grid-wrapper")}>
            {photoReviews.map((review, index) => {
              return (
                <div className={cx("photo-wrapper")} key={`${review.images[0]}-photo-grid-${index}`}>
                  <LazyPhotoImage onClick={openPhotoReviewDetail(index)} src={review.images[0]} />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};
