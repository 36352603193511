/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useState } from "react";
import { BoldXmarkButton } from "assets/icon";
import * as api from "api";
import classNames from "classnames/bind";
import { storageContext } from "contexts";
import { useScrollHidenEvent } from "hooks";
import styles from "./PopUpNoticeModal.module.scss";

const cx = classNames.bind(styles);

interface PopupNotice {
  id: number;
  title: string;
  description: string;
}

export const PopUpNoticeModal = () => {
  if (window.location.pathname === "/2021chuseok") return null;
  const [notice, setNotice] = useState<PopupNotice | null>(null);

  const noticePopupRequest = useCallback(async () => {
    const res = await api.api__v1_get_notice_for_userables({ isPopup: true });
    const noticeResponse = res.data.payload.filter(notice => notice.id.toString() !== storageContext.doNotSeeNoticeId);
    if (noticeResponse.length > 0) {
      fetch(noticeResponse[0].html_url)
        .then(res => res.text())
        .then(data => {
          setNotice({ id: noticeResponse[0].id, title: noticeResponse[0].title, description: data });
        });
    } else {
      setNotice(null);
    }
  }, []);

  const neverSeeClickListner = useCallback((id: number) => {
    storageContext.setDoNotSeeNoticeId(id, true);
    setNotice(null);
  }, []);

  const closeButtonListner = useCallback((id: number) => {
    storageContext.setDoNotSeeNoticeId(id, false);
    setNotice(null);
  }, []);

  useScrollHidenEvent(notice !== null);

  useEffect(() => {
    noticePopupRequest();
  }, [noticePopupRequest]);

  return notice ? (
    <div className={cx("wrapper")}>
      <div className={cx("content-wrapper")}>
        <div className={cx("close-wrapper")}>
          <button className={cx("close-button")} type="button" onClick={() => closeButtonListner(notice.id)}>
            <BoldXmarkButton />
          </button>
        </div>
        <div className={cx("content")}>
          <div className={cx("title-wrapper")}>{notice.title}</div>
          <div className={cx("description-wrapper")} dangerouslySetInnerHTML={{ __html: notice.description }} />
        </div>
        <div className={cx("footer")}>
          <button type="button" onClick={() => neverSeeClickListner(notice.id)} className={cx("never-see")}>
            다시 보지 않기
          </button>
        </div>
      </div>
    </div>
  ) : null;
};
