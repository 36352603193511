import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatDateTime, imageResizeHelper } from "utils";
import { Loading, Pagination } from "components";
import classNames from "classnames/bind";

import { IStore } from "typings";

import { useQnARead } from "hooks";
import { BtnBack } from "assets/icon";
import styles from "./Qna.module.scss";

const cx = classNames.bind(styles);

export function Qna() {
  const { isMobile } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);
  const history = useHistory();
  const { qnaList, getMyProductQnaList, qnaPage, qnaMeta, setQnaPageAndScrollPage } = useQnARead();
  const [showAnswer, setShowAnswer] = useState<number[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleShowAnswer = useCallback(
    id => {
      const hasAnswer = showAnswer.includes(id);
      if (!hasAnswer) setShowAnswer(showAnswer => [...showAnswer, id]);
      else {
        const newShowAnswer = showAnswer.filter(checked => checked !== id);
        setShowAnswer(_ => [...newShowAnswer]);
      }
    },
    [showAnswer]
  );

  useEffect(() => {
    getMyProductQnaList({ page: qnaPage });
    setIsLoading(false);
  }, [qnaPage, getMyProductQnaList]);

  return (
    <>
      {isMobile ? (
        <main className={cx("qna-tab")}>
          <header className={cx(["bold"], "title")}>
            <div className={cx("close")} onClick={e => history.goBack()}>
              <BtnBack />
            </div>
            <div className={cx("title-text")}>상품 Q&A</div>
          </header>
          <div className={cx("divider")} />
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <section className={cx("card-container")}>
                {qnaList.length > 0 ? (
                  qnaList.map(({ id, product, contents, answer, createdAt, typeOf, store }) => (
                    <div className={cx("qna-wrapper")} key={id}>
                      <div className={cx("qna-typeOf")}>{`${typeOf} 문의`}</div>
                      <div className={cx("qna-content-wrapper")}>
                        <div className={cx("qna-product-image-wrapper")}>
                          {product && product.photoUrl && (
                            <img
                              className={cx("qna-product-image")}
                              alt="상품이미지"
                              src={imageResizeHelper(product.photoUrl, 26)}
                            />
                          )}
                        </div>
                        <div className={cx("qna-product-name")}>{product && product.name}</div>
                      </div>
                      <div className={cx("content-wrapper")}>{contents}</div>
                      <div className={cx("qna-footer")}>
                        <div onClick={e => handleShowAnswer(id)} className={cx("qna-answer")}>
                          {`답변 ${answer ? 1 : 0}`}{" "}
                        </div>
                        <div className={cx("qna-createdAt")}>
                          {`${formatDateTime(createdAt, "date")} ${formatDateTime(createdAt, "timeLocalStr")}`}
                        </div>
                      </div>
                      {showAnswer.includes(id) && answer && (
                        <div className={cx("qna-answer-wrapper")}>
                          <div className={cx("qna-answer-store")}>{`판매처: ${store.name}`}</div>
                          <div className={cx("qna-answer-content")}>{answer && answer.contents}</div>
                          <div className={cx("qna-answer-updateAt")}>
                            {answer &&
                              `${formatDateTime(answer.updatedAt, "date")} ${formatDateTime(
                                answer.updatedAt,
                                "timeLocalStr"
                              )}`}
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className={cx("row", "no-list")}>문의내역이&nbsp;없습니다.</div>
                )}
              </section>
              <section id="qna-wrapper" className={cx("qna-body")}>
                <div className={cx("pagination-wrapper")}>
                  {qnaMeta && (
                    <Pagination
                      totalCount={qnaMeta.totalCount}
                      number={qnaPage.number}
                      size={qnaPage.size}
                      handler={setQnaPageAndScrollPage}
                    />
                  )}
                </div>
              </section>
            </>
          )}
        </main>
      ) : (
        <main>
          <header className={cx(["bold"], "title")}>상품 Q&A</header>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <section className={cx("card-container")}>
                {qnaList.length > 0 ? (
                  qnaList.map(({ id, product, contents, answer, createdAt, typeOf, store }) => (
                    <div className={cx("qna-wrapper")} key={id}>
                      <div className={cx("qna-typeOf")}>{`${typeOf} 문의`}</div>
                      <div className={cx("qna-content-wrapper")}>
                        <div className={cx("qna-product-image-wrapper")}>
                          {product && product.photoUrl && (
                            <img
                              className={cx("qna-product-image")}
                              alt="상품이미지"
                              src={imageResizeHelper(product.photoUrl, 26)}
                            />
                          )}
                        </div>
                        <div className={cx("qna-product-name")}>{product && product.name}</div>
                      </div>
                      <div className={cx("content-wrapper")}>{contents}</div>
                      <div className={cx("qna-footer")}>
                        <div onClick={e => handleShowAnswer(id)} className={cx("qna-answer")}>
                          {`답변 ${answer ? 1 : 0}`}{" "}
                        </div>
                        <div className={cx("qna-createdAt")}>
                          {`${formatDateTime(createdAt, "date")} ${formatDateTime(createdAt, "timeLocalStr")}`}
                        </div>
                      </div>
                      {showAnswer.includes(id) && answer && (
                        <div className={cx("qna-answer-wrapper")}>
                          <div className={cx("qna-answer-store")}>{`판매처: ${store.name}`}</div>
                          <div className={cx("qna-answer-content")}>{answer && answer.contents}</div>
                          <div className={cx("qna-answer-updateAt")}>
                            {answer &&
                              `${formatDateTime(answer.updatedAt, "date")} ${formatDateTime(
                                answer.updatedAt,
                                "timeLocalStr"
                              )}`}
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className={cx("row", "no-list")}>문의내역이&nbsp;없습니다.</div>
                )}
              </section>
              <div id="qna-wrapper" className={cx("desktopQna-wrapper")}>
                <div className={cx("pagination-wrapper")}>
                  {qnaMeta && (
                    <Pagination
                      totalCount={qnaMeta.totalCount}
                      number={qnaPage.number}
                      size={qnaPage.size}
                      handler={setQnaPageAndScrollPage}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </main>
      )}
    </>
  );
}
