import React, { FC, ReactElement } from "react";

interface IProp {
  condition?: boolean;
  children: ReactElement | ReactElement[];
  FallBackComponent: () => JSX.Element;
}
export const ConditionComponent: FC<IProp> = ({ children, condition, FallBackComponent }) => {
  return <>{condition ? children : <FallBackComponent />}</>;
};
