import React, { FC, useCallback } from "react";
import classNames from "classnames/bind";
import { PhotoAddIcon } from "assets/icon";

import { resizeImage } from "utils";
import styles from "./AddPhotoInput.module.scss";

interface IProp {
  onFileUpload?: (e: File | Blob) => void;
}

const cx = classNames.bind(styles);

export const AddPhotoInput: FC<IProp> = ({ onFileUpload }) => {
  const fileChangeListner = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        if (onFileUpload) {
          const img = new Image();
          const url = URL.createObjectURL(event.target.files[0]);
          img.src = url;
          img.onload = () => {
            const resizedImage = resizeImage(img, img.width, img.height);
            onFileUpload(resizedImage);
            URL.revokeObjectURL(url);
          };
        }
      }
    },
    [onFileUpload]
  );

  return (
    <label>
      <figure className={cx("wrapper")}>
        <PhotoAddIcon />
      </figure>
      <input
        className={cx("input-none")}
        type="file"
        id="avatar"
        name="avatar"
        accept="image/*"
        onChange={fileChangeListner}
      />
    </label>
  );
};
