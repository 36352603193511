import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./CallButton.module.scss";
import { IconCall } from "assets/icon";
import { useCallAction } from "hooks";

interface IProp {
  cellNumber: string;
}

const cx = classNames.bind(styles);

export const CallButton: FC<IProp> = ({ cellNumber }) => {
  const { callButtonAction } = useCallAction();
  return (
    <div className={cx("button")} onClick={callButtonAction(cellNumber)}>
      <IconCall />
      <span className={cx("text")}>전화</span>
    </div>
  );
};
