import React, { ElementType } from "react";
import { Route, Redirect } from "react-router";
import { storageContext } from "contexts";
import qs from "qs";

interface IProps {
  component: ElementType;
  path: string;
  exact?: true;
}

// TODO: 밖에서 render로 주입받고 있는 경우에 어떻게 처리할지 고민해봐야 함
export default ({ component: Component, ...options }: IProps) => (
  <Route
    {...options}
    render={routeProps =>
      storageContext.token ? (
        <Component {...routeProps} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            search: qs.stringify({ login: "on" })
          }}
        />
      )
    }
  />
);
