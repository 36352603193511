import React, { FC, useMemo } from "react";
import classNames from "classnames/bind";
import styles from "./ClaimInfo.module.scss";

import { CLAIM_TEXT } from "constant";
import { ClaimPickUpInfo, DescriptionRow, DescriptionTotalPrice, MiddleBoder } from "components";
import { shallowEqual, useSelector } from "react-redux";
import { IStore } from "typings";
import { toDiscountLocaleString } from "utils";
interface IProp {
  claimGroup: string;
  status: {
    text: string;
    createdAt: string;
    payStatus: string;
    orderNum: string;
    payMethodDetail: string;
  };
  payInfo: {
    totalPrice: number;
    paidPrice: number;
    discountCoupon: number;
    consumedPoint: number;
  };
  pickupInfo: {
    autoPickUp?: boolean;
    userName?: string;
    userPhone?: string;
    userAddress?: string;
    storeAddress?: string;
    storeName?: string;
  };
}
const cx = classNames.bind(styles);
export const ClaimInfo: FC<IProp> = ({ claimGroup, status, payInfo, pickupInfo }) => {
  const isCancel = useMemo(() => claimGroup === "cancel", [claimGroup]);
  const isMobile = useSelector<IStore, IStore["ui"]["isMobile"]>(({ ui: { isMobile } }) => isMobile, shallowEqual);
  const totalPriceString = useMemo(() => `${payInfo.totalPrice.toLocaleString()}원`, [payInfo]);
  return (
    <div className={cx("wrapper")}>
      {!isCancel && (
        <ClaimPickUpInfo
          autoPickUp={pickupInfo.autoPickUp}
          storeRefundAddress={pickupInfo.storeAddress}
          storeName={pickupInfo.storeName}
          userAddress={pickupInfo.userAddress}
          userName={pickupInfo.userName}
          userPhone={pickupInfo.userPhone}
        />
      )}
      <div className={cx("info-wrapper")}>
        <div className={cx("info-body")}>
          <div className={cx("status-info")}>
            <div className={cx("info-header")}>{CLAIM_TEXT[claimGroup]} 정보</div>
            <DescriptionRow keyData="주문번호" value={status.orderNum} />
            <DescriptionRow keyData="결제방법" value={status.payMethodDetail} />
            <DescriptionRow keyData={status.text} value={status.createdAt} bold />
            <DescriptionRow keyData="결제상태" value={status.payStatus} />
          </div>
          {claimGroup !== "change" && (
            <div className={cx("pay-info")}>
              <div className={cx("info-header")}>{CLAIM_TEXT[claimGroup]} 금액</div>
              <DescriptionRow keyData="상품금액" value={totalPriceString} spaceBetween={!isMobile} />
              <DescriptionRow
                keyData="적립금 사용"
                value={toDiscountLocaleString(payInfo.consumedPoint)}
                spaceBetween={!isMobile}
              />
              <DescriptionRow
                keyData="쿠폰 사용"
                value={toDiscountLocaleString(payInfo.discountCoupon)}
                spaceBetween={!isMobile}
              />
              <MiddleBoder />
              <DescriptionTotalPrice title="환불(예정)금액" paidPrice={payInfo.paidPrice} titleColor="black" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
