import { fetcher } from "utils";

import { Gender, IJSendSuccessResponse } from "typings";

type GetProductMainCategories = (
  genderId: Gender
) => Promise<
  IJSendSuccessResponse<{
    product_main_categories: {
      name: string;
      gender_id: Gender;
      ordering: number;
      image_url: null | string;
    }[];
  }>
>;

export const get__products_main_categories: GetProductMainCategories = genderId =>
  fetcher.get(`/product_main_categories`, {
    type: "product",
    genderId
  });
