import React, { FC, Fragment } from "react";
import classnames from "classnames/bind";

import { imageResizeHelper } from "utils";

import { StoreSerializedCamel } from "typings";

import styles from "./StoreRow.module.scss";

const cx = classnames.bind(styles);

interface IProps {
  id: StoreSerializedCamel["id"];
  name: StoreSerializedCamel["name"];
  photoUrl: StoreSerializedCamel["photoUrl"];
  group: StoreSerializedCamel["group"];
  message: StoreSerializedCamel["message"];
  serializedTags: StoreSerializedCamel["serializedTags"];
  isStar: boolean;
}

export const StoreRow: FC<IProps> = ({ id, name, photoUrl, group, message, serializedTags, isStar }) => (
  <Fragment key={id}>
    <picture className={cx("picture")}>
      <img className={cx("img")} src={imageResizeHelper(photoUrl, 48)} alt="store" />
    </picture>

    <div className={cx("info")}>
      <div className={cx("name-and-star-and-message")}>
        <span className={cx("name")}>{name}</span>
        {isStar && <span className={cx("star")}>&#9733;</span>}
        <span className={cx("message")}>{message}</span>
      </div>

      <div className={cx("tags")}>{serializedTags}</div>
    </div>
  </Fragment>
);
