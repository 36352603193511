import { v4 as uuidv4 } from "uuid";

import { recentViewModelCreator } from "view-model-creator";

import { Gender, IRecentViewModel, ApiV1ProductSerialized, IPartnerOptionViewModel } from "typings";

class StorageConext {
  private storage: Storage;

  private sessionStorage: Storage;

  constructor() {
    this.storage = window.localStorage;
    this.sessionStorage = window.sessionStorage;
  }

  get doNotSeeNoticeId() {
    return this.storage.getItem("noticeId") || this.sessionStorage.getItem("noticeId");
  }

  setDoNotSeeNoticeId(id: number, isNeverSee: boolean) {
    if (isNeverSee) {
      this.storage.setItem("noticeId", id.toString());
    }
    this.sessionStorage.setItem("noticeId", id.toString());
  }

  get isPopUpClose() {
    const date = new Date().getUTCDate().toString();
    const localPopUpClose = this.storage.getItem("lpPopUpClose");
    if (date === localPopUpClose) return localPopUpClose;
    this.storage.removeItem("lpPopUpClose");
    return this.sessionStorage.getItem("lpPopUpClose");
  }

  popUpCloseCancel() {
    this.sessionStorage.removeItem("lpPopUpClose");
    this.storage.removeItem("lpPopUpClose");
  }

  todayPopUpNoSee() {
    const date = new Date().getUTCDate().toString();
    this.storage.setItem("lpPopUpClose", date);
  }

  sessionPopUpClose() {
    const date = new Date().getUTCDate().toString();
    this.sessionStorage.setItem("lpPopUpClose", date);
  }

  get suggestMobileAppLimit() {
    return this.storage.getItem("lpSuggestMobileAppLimit");
  }

  set suggestMobileAppLimit(suggestObj: string | null) {
    if (typeof suggestObj === "string") this.storage.setItem("lpSuggestMobileAppLimit", suggestObj);
  }

  get genderId(): Gender {
    return "men";
  }

  set genderId(_: Gender) {
    // 해당 기능은 무시함
  }

  get token() {
    return this.storage.getItem("lpUserToken");
  }

  set token(token: string | null) {
    if (typeof token === "string") this.storage.setItem("lpUserToken", token);
    else this.storage.removeItem("lpUserToken");
  }

  get uuid(): string {
    if (!this.storage.getItem("lpUUID")) this.uuid = uuidv4();

    return this.storage.getItem("lpUUID") as string;
  }

  set uuid(uuid: string) {
    this.storage.setItem("lpUUID", uuid);
  }

  get recents(): IRecentViewModel[] {
    if (!this.storage.getItem("lpRecents")) this.storage.setItem("lpRecents", JSON.stringify([]));

    return JSON.parse(this.storage.getItem("lpRecents") as string);
  }

  set recents(recents: IRecentViewModel[]) {
    this.storage.setItem("lpRecents", JSON.stringify(recents));
  }

  recentsUpdater(product: ApiV1ProductSerialized) {
    const recent = recentViewModelCreator(product);
    const isExist = this.recents.filter(({ productId }) => productId === recent.productId).length;

    if (isExist) {
      this.recents = this.recents.filter(({ productId }) => productId !== recent.productId);
    }

    if (this.recents.length < 6) {
      this.recents = [recent].concat(this.recents);
    } else {
      this.recents = [recent].concat(this.recents.slice(0, 5));
    }

    return this.recents;
  }

  setOrderOptions(payload: IPartnerOptionViewModel[]) {
    this.storage.setItem("orderOptions", JSON.stringify(payload));
  }

  getOrderOptions(): IPartnerOptionViewModel[] {
    const orderOptions = this.storage.getItem("orderOptions");

    let result;

    try {
      result = JSON.parse(orderOptions || "[]");
    } catch (err) {
      result = [];
    }

    return result;
  }

  removeOrderOptions() {
    this.storage.removeItem("orderOptions");
  }
}

export const storageContext = new StorageConext();
