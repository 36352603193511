import { Dispatch } from "redux";

import { pipeWith, then, objOf } from "ramda";

import { get__members__issued_discount_coupons } from "api";

import { initialize } from "actions/actionCreators";

import { issuedDiscountCouponsViewModelCreator } from "view-model-creator";

import { INITIALIZE, LOGOUT } from "constant";

import { IIssuedDiscountCouponViewModel } from "typings";

const initialState: IIssuedDiscountCouponViewModel[] = [];

export default function coupons(state = initialState, { type, payload }) {
  switch (type) {
    case INITIALIZE:
      if (!payload || !payload.coupons) return state;
      return issuedDiscountCouponsViewModelCreator(payload.coupons);

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}

export const updateCoupons = () => (dispatch: Dispatch) =>
  pipeWith(then)([get__members__issued_discount_coupons, objOf("coupons"), initialize, dispatch])(undefined);
