import { useEffect } from "react";

export const useScrollHidenEvent = (condition: boolean) => {
  useEffect(() => {
    const html = document.querySelector("html");
    if (condition) {
      html!.style.overflow = "hidden";
    }
    return () => {
      html!.style.overflow = "scroll";
    };
  }, [condition]);
};
