import React from "react";
import classNames from "classnames/bind";
import styles from "./HaveNotPin.module.scss";

const cx = classNames.bind(styles);
export const HaveNotPin = () => {
  return (
    <div className={cx("wrapper")}>
      <div className={cx("image-wrapper")}>
        <img src="https://static.lookpin.co.kr/20210106120806-0f81/84d3a937d8315ba260d1b983c0ae3d43.png" alt="no-pin" />
      </div>
      <div className={cx("title")}>핀 한 상품이 없습니다.</div>
      <div className={cx("description")}>
        <div className={cx("description-text")}>
          마음에드는 상품을 <span className={cx("bold")}>핀</span>하고
        </div>
        <div className={cx("description-text")}>나만의 코디를 완성해보세요!</div>
      </div>
    </div>
  );
};
