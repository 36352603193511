import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import * as api from "api";
import { produce } from "utils";
import qs from "qs";
import { useParams } from "react-router-dom";
import { camelizeKeys } from "humps";
import { map, path, pipeWith, then } from "ramda";

import { newProductViewModelCreator, reviewSummariesViewModelCreatorV3 } from "view-model-creator";

import {
  GetApiV1StoresStoreIdProductsParams,
  INewProductViewModel,
  Order,
  StoreDetailSerializedCamel,
  IStore,
  IReviewsSummariesViewModelV3
} from "typings";

import { historyContext } from "contexts";

import {
  setQuery,
  getConditions,
  handlePageHelper,
  handlePriceHelper,
  handlePriceResetHelper,
  updateStateByValueFromInputTag,
  changeName,
  processingQuery
} from "./productSearchShare";

interface ISearchParams extends GetApiV1StoresStoreIdProductsParams {
  order: Order;
}

interface IBase {
  params: ISearchParams;
  category?: string;
  keyword?: string;
}

export const useStoreProductSearch = (base: IBase) => {
  const mainRef = useRef<HTMLElement>(null);
  const match = useParams() as { storeId: string };
  const storeId = Number(match.storeId || 0);

  /* eslint-disable-next-line */
  const processedQuery = useMemo(() => processingQuery(historyContext.location.search, base), [
    base,
    historyContext.location.search
  ]);

  const {
    params,
    params: {
      order,
      price,
      page: { number, size }
    },
    keyword: kw,
    category: cg,
    main
  } = useMemo(() => processedQuery, [processedQuery]);

  const { genderId } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);

  const [products, setProducts] = useState<INewProductViewModel[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [gte, setGte] = useState(price?.gte ?? 0);
  const [lte, setLte] = useState(price?.lte ?? 0);
  const [maxPrice, setMaxPrice] = useState(100);
  const [keyword, setKeyword] = useState(kw || "");
  const [isLoading, setIsLoading] = useState(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [category, setCategory] = useState(cg);
  const [categories, setCategories] = useState<[string, number][]>([]);
  const [store, setStore] = useState<StoreDetailSerializedCamel>();
  const [reviews, setReviews] = useState<IReviewsSummariesViewModelV3[]>([]);

  const conditions = useMemo(() => getConditions({ keyword: kw, gte, lte }), [gte, kw, lte]);

  const query = qs.parse(historyContext.location.search, { ignoreQueryPrefix: true });

  const resetCondition = useCallback(() => setQuery({ ...base, ...query, params: { order }, keyword: "" }), [
    base,
    order,
    query
  ]);
  const changeKeyword = useMemo(() => updateStateByValueFromInputTag(setKeyword), []);
  const handlePage = useMemo(() => handlePageHelper(processedQuery), [processedQuery]);
  const handlePrice = useMemo(() => handlePriceHelper(processedQuery), [processedQuery]);
  const handlePriceReset = useMemo(() => handlePriceResetHelper(processedQuery), [processedQuery]);

  const handleKeyword = useCallback(() => {
    setQuery(produce(processedQuery, draft => changeName(draft, { category, keyword, main })));
  }, [category, keyword, main, processedQuery]);

  const handleCategory = useCallback(
    event => {
      setQuery(
        produce(processedQuery, draft => {
          changeName(draft, { category: event.target.value, keyword, main });
        })
      );
    },
    [keyword, main, processedQuery]
  );

  const handleOrder = useCallback(
    ({ target: { value } }) => {
      setQuery(
        produce(processedQuery, draft => {
          draft.params.order = value;
          draft.params.page.number = 1;
        })
      );
    },
    [processedQuery]
  );

  const setDataFromQuery = useCallback(() => {
    setIsPaginationLoading(true);

    setIsLoading(true);

    setKeyword(kw || "");
    setCategory(cg || "");
    setGte(price?.gte ?? 0);
    setLte(price?.lte ?? 0);

    const newParams = Object.assign({ ...params }, { serializer: "v1" });
    api.get__api__v1__stores__store_id__products(storeId, { ...newParams, genderId }).then(
      ({
        data: {
          payload,
          meta: { total_count, max_price }
        }
      }) => {
        setProducts(newProductViewModelCreator(payload));
        setTotalCount(total_count);
        setMaxPrice(max_price);
        setIsLoading(false);
        setIsPaginationLoading(false);

        if (mainRef.current) mainRef.current.style.minHeight = "auto";
      }
    );
  }, [cg, kw, params, price, storeId, genderId]);

  useEffect(() => {
    const productsIds = products.map(product => product.id);

    if (productsIds.length > 0) {
      pipeWith(then)([
        api.get__products__id__reviews_summaries_v3,
        path(["data", "payload"]),
        reviewSummariesViewModelCreatorV3,
        setReviews
      ])(productsIds);
    }
  }, [products]);

  useEffect(setDataFromQuery, [processedQuery.params, genderId]);

  useEffect(() => {
    (async () => {
      const response = await api.get__stores__id(storeId);

      const store = camelizeKeys(response) as StoreDetailSerializedCamel;

      setStore(store);

      pipeWith(then)([
        api.get__stores__store_id__categories,
        path(["data", "categories"]),
        map(({ name, count }) => [name, count]),
        setCategories
      ])({ id: storeId, genderId });
    })();
  }, [storeId, genderId]);

  return {
    category,
    conditions,
    handleCategory,
    changeKeyword,
    order,
    handleOrder,
    handlePage,
    handlePrice,
    handlePriceReset,
    gte,
    lte,
    isLoading,
    isPaginationLoading,
    keyword,
    number,
    size,
    products,
    reviews,
    resetCondition,
    totalCount,
    handleKeyword,
    maxPrice,
    categories,
    store,
    mainRef
  };
};
