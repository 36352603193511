import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { map } from "ramda";
import { useBookmark, useStoreSearch } from "hooks";
import { SearchBar, DisplayCondition, StoreRow, StoresNotExist } from "components";
import classnames from "classnames/bind";
import styles from "./StoreSearch.module.scss";

const cx = classnames.bind(styles);

export function StoreSearch() {
  const { getIsBookmark } = useBookmark();
  const { stores, keyword, changeKeyword, handleKeyword, handleEnter, resetKeyword } = useStoreSearch();

  return (
    <main className={cx("main")}>
      <div className={cx("title")}>스토어검색</div>

      <SearchBar
        keyword={keyword}
        changeKeyword={changeKeyword}
        handleKeyword={handleKeyword}
        handleEnter={handleEnter}
      />

      <div className={cx("mobile")}>
        <DisplayCondition conditions={keyword ? [keyword] : []} resetCondition={resetKeyword} />
      </div>

      <section className={cx("section")}>
        {stores.length === 0 ? (
          <StoresNotExist />
        ) : (
          map(
            ({ id, name, photoUrl, group, message, serializedTags }) => (
              <Link to={{ pathname: `/stores/${id}` }} className={cx("store")} key={id}>
                <StoreRow
                  key={id}
                  id={id}
                  name={name}
                  photoUrl={photoUrl}
                  group={group}
                  message={message}
                  serializedTags={serializedTags}
                  isStar={getIsBookmark(id)}
                />
              </Link>
            ),
            stores
          )
        )}
      </section>
    </main>
  );
}
