import React, { FC } from "react";
import classNames from "classnames/bind";
import { DescriptionRow, ConditionComponent } from "components";
import styles from "./ClaimPickUpInfo.module.scss";

interface IProp {
  autoPickUp?: boolean;
  storeRefundAddress?: string;
  storeName?: string;
  storePhone?: string;
  userAddress?: string;
  userName?: string;
  userPhone?: string;
}

const cx = classNames.bind(styles);
export const ClaimPickUpInfo: FC<IProp> = ({
  autoPickUp,
  storeRefundAddress,
  storeName,
  userName,
  userAddress,
  userPhone
}) => {
  const pickUpText = autoPickUp ? "수거지" : "반송지";
  return (
    <div className={cx("wrapper")}>
      <div className={cx("header")}>{pickUpText} 정보</div>
      <ConditionComponent
        condition={autoPickUp}
        FallBackComponent={() => <DescriptionRow keyData="업체명" value={storeName} keyBold />}
      >
        <DescriptionRow keyData="이름" value={userName} keyBold />
        <DescriptionRow keyData="연락처" value={userPhone} keyBold />
      </ConditionComponent>
      <DescriptionRow keyData={pickUpText} value={autoPickUp ? userAddress : storeRefundAddress} keyBold />
      <DescriptionRow keyData="수거방법" value={autoPickUp ? "자동수거" : "직접발송"} bold />
    </div>
  );
};
