import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { Loading } from "components";

import { connectSocialAccount, socialSignup } from "actions/actionCreators";

import { KAKAO } from "constant";

import { IStore } from "typings";

interface ILocation {
  accessToken: string;
  redirectPath: string;
}

export default function Kakao() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { accessToken, redirectPath } = useLocation<ILocation>().state || { accessToken: "", redirectPath: "/" };

  const { token, connectedSocialAccounts } = useSelector<IStore, IStore["member"]>(({ member }) => member);

  const link = useCallback(async () => {
    try {
      if (!accessToken) Error("provider token is empty.");

      await dispatch(connectSocialAccount(KAKAO, accessToken));
      alert("성공적으로 연동되었습니다!");
    } catch (error) {
      alert("연동이 실패하였습니다!");
    } finally {
      history.replace("/member/accounts");
    }
  }, [accessToken, dispatch, history]);

  const signup = useCallback(async () => {
    const isSuccess = await socialSignup(KAKAO, accessToken)(dispatch);

    if (isSuccess) history.replace(redirectPath);
  }, [accessToken, dispatch, history]);

  useEffect(() => {
    if (!token && accessToken) signup();
    else if (token && !connectedSocialAccounts.includes(KAKAO)) link();
  }, [accessToken, connectedSocialAccounts, link, signup, token]);

  return <Loading />;
}
