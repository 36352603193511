import React, { FC } from "react";
import { IMemeberOrderViewModelReNewal, IOrderProductNextStatusCamel, OrderProductCardType } from "typings";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import { GrayRightArrowIcon } from "assets/icon";
import { OrderAction, ProductDescription, ProductStatus } from "components";

import styles from "./OrderProductCard.module.scss";
import { ReviewWriteAction } from "./ReviewWriteAction";
import { BuyConfirmButton } from "./BuyConfirmButton";

interface IProp {
  order: IMemeberOrderViewModelReNewal;
  disabledLink?: boolean;
  actionClickEvent: (payload: IOrderProductNextStatusCamel) => void;
  reviewSubmitCallback?: () => void;
  type?: OrderProductCardType;
}

const cx = classNames.bind(styles);

export const OrderProductCard: FC<IProp> = ({ order, actionClickEvent, disabledLink, reviewSubmitCallback, type }) => {
  const {
    orderNum,
    productOrderNum,
    currentStatusText,
    shippingInfoFromPartner,
    nextStatus,
    beforeStatus,
    bankInfo,
    review,
    productId,
    productName,
    productImage,
    store,
    createdAt
  } = order;
  return (
    <div className={cx("wrapper")}>
      {disabledLink ? (
        <div className={cx("header")}>
          <div className={cx("anchor", "order-num")}>상품 주문번호 {productOrderNum}</div>
        </div>
      ) : (
        <Link className={cx("header", "link")} to={`/member/orders/${orderNum}/${productOrderNum}`}>
          <div className={cx("anchor", "order-num")}>상품 주문번호 {productOrderNum}</div>
          <div className={cx("anchor", "product-detail")}>
            {type === "review" ? (
              <span className={cx("created-at-text")}>{createdAt}</span>
            ) : (
              <>
                {" "}
                <span className={cx("margin", "order-detail")}>주문상세</span> <GrayRightArrowIcon />
              </>
            )}
          </div>
        </Link>
      )}
      <div className={cx("body")}>
        <ProductDescription order={order} type={type} borderNone={type === "review"} />
        {currentStatusText && type === "order" && (
          <ProductStatus
            currentStatusText={currentStatusText}
            bankInfo={bankInfo}
            trackingURL={shippingInfoFromPartner.trackingUrl}
            beforeStatus={beforeStatus}
          />
        )}
        {type === "order" && (
          <div className={cx("action-wrapper")}>
            <OrderAction nextStatus={nextStatus} onClick={actionClickEvent} />
          </div>
        )}
        {type === "review" && beforeStatus === "ship_ing" && (
          <div className={cx("action-wrapper", "review-type")}>
            <BuyConfirmButton onClick={() => actionClickEvent(nextStatus[2])} />
          </div>
        )}
        {type === "review" && beforeStatus === "confirm" && (
          <div className={cx("action-wrapper", "review-type")}>
            <ReviewWriteAction
              review={review}
              product={{ productId, productTitle: productName, productImage, storeName: store.name }}
              orderProductOrderNum={productOrderNum}
              reviewSubmitCallback={reviewSubmitCallback}
            />
          </div>
        )}
      </div>
    </div>
  );
};
