import React, { FC, useCallback, memo } from "react";

import { IOptionValue, IProductDetailViewModel } from "typings";
import { always, pipe, slice, concat, join, __ } from "ramda";
import classNames from "classnames/bind";
import styles from "./ChoiceOptions.module.scss";

const cx = classNames.bind(styles);

interface IChoiceOptions {
  options: {
    names: IProductDetailViewModel["info"]["options"]["names"];
    contents: IProductDetailViewModel["info"]["options"]["contents"];
  };
  optionChecker: Map<string, IOptionValue>;
  optionStack: string[];
  optionStackSetter: (event: any) => void;
}

const getOptionText = (optionStack: string[], index: number, content: string) =>
  pipe(always(optionStack), slice(0, index), concat(__, [content]), join(", "))();

export const ChoiceOptions: FC<IChoiceOptions> = memo(
  ({ options: { names, contents }, optionChecker, optionStack, optionStackSetter }) => {
    const createOptions = useCallback(
      (index: number) =>
        contents[index]
          .filter(content => {
            const optionText = getOptionText(optionStack, index, content);

            return optionChecker.has(optionText);
          })
          .map((content: string) => {
            const optionText = getOptionText(optionStack, index, content);
            const { stockCount, optionPrice } = optionChecker.get(optionText) as IOptionValue;
            const disabled = stockCount === 0;
            const price = optionPrice ? ` (+${optionPrice.toLocaleString()}원)` : ``;

            let stock = "";
            if (stockCount <= 10 && index === names.length) stock = ` (${stockCount}개 남음)`;
            else if (disabled) stock = " (품절)";

            return (
              <option key={content} value={content} disabled={disabled}>
                {content}
                {price}
                {stock}
              </option>
            );
          }),
      [contents, names.length, optionChecker, optionStack]
    );

    return (
      <>
        {names.map((name, index) => (
          <select
            className={cx("option-select")}
            required
            key={`option-${name}-${index}`}
            disabled={index > optionStack.length}
            onChange={optionStackSetter}
            data-index={index}
            value={optionStack[index] || ""}
          >
            <option disabled value="">
              {name}
            </option>

            {optionStack.length >= index && createOptions(index)}
          </select>
        ))}
      </>
    );
  }
);
