import { fetcher } from "utils";

import { Gender, IGetProductCombinationCategories } from "typings";

type GetProductCombinationCategories = (params: {
  genderId?: Gender;
  productMainCategoryName?: string;
}) => Promise<IGetProductCombinationCategories>;

export const get__product_combination_categories: GetProductCombinationCategories = params =>
  fetcher.get("/product_combination_categories", params);
