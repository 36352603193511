import React from "react";
import Lottie from "react-lottie";
import animationData from "assets/animation/ani_page_loading.json";
import classNames from "classnames/bind";

import styles from "./Loading.module.scss";

const cx = classNames.bind(styles);

export const Loading = () => (
  <div className={cx("wrapper")}>
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }}
      width={60}
      height={60}
    />
  </div>
);
