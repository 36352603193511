import React, { FC, useMemo } from "react";
import classNames from "classnames/bind";
import { IVbankInfo, FieldStatus } from "typings";
import { formatDateTime, statusClaimTest } from "utils";
import styles from "./ProductStatus.module.scss";
import { useOrderAction } from "./useOrderAction";

interface IProp {
  trackingURL?: string;
  currentStatusText: string;
  bankInfo: IVbankInfo | null;
  beforeStatus: FieldStatus;
}

const cx = classNames.bind(styles);

export const ProductStatus: FC<IProp> = ({ currentStatusText, beforeStatus, trackingURL, bankInfo }) => {
  const { deliveryInfoModalOpen } = useOrderAction();
  const isPayWait = useMemo(() => beforeStatus === "pay_wait", [beforeStatus]);
  const viewStatusText = useMemo(() => currentStatusText.split("("), [currentStatusText]);
  const statusClass = useMemo(() => (statusClaimTest(beforeStatus) ? "claim" : beforeStatus), [beforeStatus]);

  return (
    <div className={cx("wrapper")}>
      <div className={cx("content-wrapper")}>
        <div className={cx("status-text", statusClass)}>{viewStatusText[0]}</div>
        {trackingURL && (
          <div className={cx("tracking-button")} onClick={deliveryInfoModalOpen(trackingURL)}>
            배송조회
          </div>
        )}
        {isPayWait && bankInfo && (
          <div className={cx("bank-info")}>
            <div className={cx("bank-text")}>
              {bankInfo.name} {bankInfo.num}
            </div>{" "}
            <div className={cx("bank-text")}>
              {bankInfo.amount.toLocaleString()}원 ({formatDateTime(bankInfo.date, "dateStr")}까지)
            </div>
          </div>
        )}
        {viewStatusText[1] && <div className={cx("pay-expiration", statusClass)}>({viewStatusText[1]}</div>}
      </div>
    </div>
  );
};
