import { EmailSignUpSerialized } from "typings";
import { fetcher } from "utils";

type EmailSignUpPost = (payload: {
  email: string;
  password: string;
  passwordConfirmation: string;
  phoneNumber: string;
  confirmMarketingSms: boolean;
}) => Promise<EmailSignUpSerialized>;

export const post_members_auth: EmailSignUpPost = payload => {
  return fetcher.post("/members/auth", payload);
};
