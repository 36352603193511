import airbridge from "airbridge-web-sdk-loader";
import { AirbridgeAPI } from "airbridge-web-sdk-loader/build/airbridge_api";
import AirbridgeBaseAPI from "./AirbridgeBaseAPI";

const AIRBRIDGE_CUSTOM_EVENT_DIC = {
  order: "order",
  like: "like",
  review: "review",
  share: "share",
  mainScreen: "main_screen",
  cartRemove: "cart"
};

type MainScreenAction = "home_home" | "home_stores" | "home_newest" | "home_ranking" | "coordi" | "home_event";
type LikeAction = "product" | "coordi" | "store";
type ShareActions = "product" | "coordi";

interface Product {
  productID: number;
  name: string;
  price: number;
  currency: "KRW";
  quantity: number;
}
interface CartRemoveAttributes {
  cart_id: number;
  currency: "KRW";
  name: string;
  productID: number;
  quantity: number;
}
interface LikeAttributes {
  id: number;
  name: string;
  price?: number;
  type?: "store" | "brand";
}
interface ShareAttributes {
  channel: "링크" | "카카오" | "기타";
}
interface ReviewWriteAttributes {
  product_id: number;
  rating_value: number;
}
interface OrderStartAttributes {
  totalValue: number;
  totalQuantity: number;
  currency: "KRW";
  products: Product[];
}

class AirbridgeCustomEvent extends AirbridgeBaseAPI {
  constructor(protected airbridge: AirbridgeAPI) {
    super(airbridge);
  }

  orderStart(customAttributes: OrderStartAttributes) {
    this.sendEvents(AIRBRIDGE_CUSTOM_EVENT_DIC.order, {
      customAttributes,
      action: "start"
    });
  }

  like(action: LikeAction, customAttributes: LikeAttributes) {
    this.sendEvents(AIRBRIDGE_CUSTOM_EVENT_DIC.like, {
      customAttributes,
      action
    });
  }

  reviewWrite(customAttributes: ReviewWriteAttributes) {
    this.sendEvents(AIRBRIDGE_CUSTOM_EVENT_DIC.review, {
      customAttributes,
      action: "write"
    });
  }

  share(action: ShareActions, customAttributes: ShareAttributes) {
    this.sendEvents(AIRBRIDGE_CUSTOM_EVENT_DIC.share, {
      customAttributes,
      action
    });
  }

  mainScreen(action: MainScreenAction) {
    this.sendEvents(AIRBRIDGE_CUSTOM_EVENT_DIC.mainScreen, {
      action
    });
  }

  cartRemove(carts: CartRemoveAttributes[]) {
    this.sendEvents(AIRBRIDGE_CUSTOM_EVENT_DIC.cartRemove, {
      customAttributes: {
        carts
      },
      action: "remove"
    });
  }
}

export default new AirbridgeCustomEvent(airbridge);
