import { request } from "utils";

const sendParams = method => (url, params, isCustomErrorHandle) =>
  request({
    method,
    url,
    params,
    isCustomErrorHandle
  });

const sendData = method => (url, data, isCustomErrorHandle) =>
  request({
    method,
    url,
    data,
    isCustomErrorHandle
  });

export const fetcher = {
  get: sendParams("GET"),
  post: sendData("POST"),
  patch: sendParams("PATCH"),
  patchBody: sendData("PATCH"),
  put: sendData("PUT"),
  delete: sendParams("DELETE"),
  deleteBody: sendData("DELETE")
};
