import React, { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames/bind";
import { UI__MODAL_NORMAL_PHOTO_REVIEW, UI__MODAL_SIMPLE_REVIEW } from "constant";
import { modalCallbackFunctionSet, modalOn } from "modules/ui";
import { LightningIcon } from "assets/icon";
import { setReviewWriteAction } from "actions/actionCreators";
import { IStore } from "typings";
import styles from "./ReviewWriteAction.module.scss";

interface IProp {
  review: {
    id: number;
    type: {
      simple: boolean;
      content: boolean;
      photo: boolean;
    };
  } | null;
  product: {
    productId: number;
    productTitle: string;
    productImage?: string;
    storeName: string;
  };
  orderProductOrderNum: string;
  reviewSubmitCallback?: () => void;
  isSmall?: boolean;
}

const cx = classNames.bind(styles);
export const ReviewWriteAction: FC<IProp> = ({
  review,
  product,
  orderProductOrderNum,
  reviewSubmitCallback,
  isSmall
}) => {
  const dispatch = useDispatch();
  const { isMobile } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);

  const fund = useMemo(() => {
    let money = 0;
    if (!review?.type.simple && review?.type.content) return 300;
    if (!review?.type.simple) money += 300;
    if (!review?.type.photo) money += 400;
    if (!review?.type.content) money += 300;
    return money;
  }, [review]);

  const position = useMemo<"up" | "down" | "none">(() => {
    if (!review?.type.simple && !review?.type.photo && !review?.type.content) return "down";
    if (review?.type.simple && review?.type.photo && review?.type.content) return "none";
    if (!review?.type.simple) return "up";
    return "down";
  }, [review]);

  const fundText = useMemo<string>(() => {
    if (!review?.type.simple && !review?.type.photo && !review?.type.content) return "리뷰 쓰고 적립금 최대";
    if (!review?.type.simple) return "5초 만에 적립금";
    if (!review?.type.content) return "적립금 최대";
    return "사진 추가하고 적립금";
  }, [review]);

  const reviewSimpleReviewClick = useCallback(() => {
    dispatch(modalOn(UI__MODAL_SIMPLE_REVIEW));
    dispatch(setReviewWriteAction({ id: review?.id, product, orderProductOrderNum }));
    if (reviewSubmitCallback) dispatch(modalCallbackFunctionSet(reviewSubmitCallback));
  }, [dispatch, reviewSubmitCallback, orderProductOrderNum, product, review]);

  const reviewNormalPhotoReviewClick = useCallback(() => {
    dispatch(modalOn(UI__MODAL_NORMAL_PHOTO_REVIEW, { id: review?.id, product, orderProductOrderNum }));
    dispatch(setReviewWriteAction({ id: review?.id, product, orderProductOrderNum }));
    if (reviewSubmitCallback) dispatch(modalCallbackFunctionSet(reviewSubmitCallback));
  }, [dispatch, reviewSubmitCallback, orderProductOrderNum, product, review]);

  const normalPhotoReviewText = useMemo(() => {
    if (review) {
      if (review.type.content && !review.type.photo) return "포토";
      return "일반/포토";
    }
    return "일반 / 포토";
  }, [review]);

  return (
    <div className={cx("wrapper")}>
      <button
        disabled={review?.type.simple}
        type="button"
        className={cx("review-button", { small: isSmall })}
        onClick={reviewSimpleReviewClick}
      >
        {position === "up" && (
          <div className={cx("fund-wrapper", isMobile ? "up" : "down")}>
            {fundText} <span>{fund}원</span> 받기
          </div>
        )}
        5초 리뷰 작성 {!review?.type.simple ? <LightningIcon /> : "완료"}
      </button>
      <button
        disabled={review?.type.photo && review?.type.content}
        type="button"
        className={cx("review-button", { small: isSmall })}
        onClick={reviewNormalPhotoReviewClick}
      >
        {normalPhotoReviewText} 리뷰 작성{review?.type.photo && review?.type.content && "완료"}
        {position === "down" && (
          <div className={cx("fund-wrapper", "up")}>
            {fundText} <span>{fund}원</span> 받기
          </div>
        )}
      </button>
    </div>
  );
};
