import React, { useCallback, useEffect } from "react";
import classNames from "classnames/bind";
import { useDispatch } from "react-redux";
import { photoDetailChangeByArrow, modalOff } from "actions/actionCreators";
import { IconThinClose, LeftArrowIcon, RightArrowIcon } from "assets/icon";
import { LevelIcon, CountReviewSummary, RecommendButton, ReviewStars } from "components";

import { REVIEWTYPE, LOOKPIN_REVIEW, CREMA_REVIEW, RIGHT_KEYCODE, ESC_KEYCODE, LEFT_KEYCODE } from "constant";
import styles from "./DetailPhotoReviewPC.module.scss";
import { usePhotoReviews } from "./usePhotoReviews";

const cx = classNames.bind(styles);
export const DetailPhotoReviewPC = () => {
  const dispatch = useDispatch();
  const {
    photoModalGridStatus,
    photoModalDetail,
    imageIndex,
    setImageIndex,
    viewTotalCount,
    viewSelectNumber
  } = usePhotoReviews();

  const closeButtonClick = useCallback(() => {
    dispatch(modalOff());
  }, [dispatch]);

  const arrowButtonClick = useCallback(
    (diff: number) => {
      if (photoModalGridStatus) dispatch(photoDetailChangeByArrow(diff));
      else setImageIndex(imageIndex + diff);
    },
    [imageIndex, photoModalGridStatus, dispatch, setImageIndex]
  );

  const stopBubbleEvent = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  }, []);

  const keyEventListner = useCallback(
    (event: KeyboardEvent) => {
      switch (event.keyCode) {
        case ESC_KEYCODE:
          closeButtonClick();
          break;
        case RIGHT_KEYCODE:
          if (viewSelectNumber < viewTotalCount) arrowButtonClick(1);
          break;
        case LEFT_KEYCODE:
          if (viewSelectNumber > 1) arrowButtonClick(-1);
          break;
      }
    },
    [arrowButtonClick, closeButtonClick, viewSelectNumber, viewTotalCount]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyEventListner);
    return () => {
      window.removeEventListener("keydown", keyEventListner);
    };
  }, [keyEventListner]);

  const {
    id,
    satisfaction,
    options,
    images,
    type,
    contents,
    createdAt,
    author,
    authorLevel,
    height,
    weight,
    color,
    size,
    shipping,
    likeCount
  } = photoModalDetail!;
  return (
    <div className={cx("photoReviewDetailWrapper")} onClick={stopBubbleEvent}>
      <header className={cx("photoReviewDetailHeader")}>
        <div>
          <span className={cx("detailHeadTextStyle")}>포토리뷰</span>
          <span className={cx("countByNumber")}>
            <span className={cx("selectNumber")}>{viewSelectNumber}</span> / {viewTotalCount}
          </span>
        </div>
        <div className={cx("modal-button-wrapper")} onClick={closeButtonClick}>
          <IconThinClose />
        </div>
      </header>
      {photoModalDetail && (
        <section className={cx("phtoReviewdetailBody")}>
          <div className={cx("photoReviewdetailNavigation", "left")}>
            {viewSelectNumber > 1 && (
              <button type="button" className={cx("modal-button-wrapper")} onClick={() => arrowButtonClick(-1)}>
                <LeftArrowIcon />
              </button>
            )}
          </div>
          <div className={cx("photoReviewDetailContentsWrapper")}>
            <div className={cx("photoWrapper")}>
              <div className={cx("photoAbsoluteWrapper")}>
                <img src={images[imageIndex]} alt={`${imageIndex + 1}번째 이미지`} />
              </div>
            </div>
            <div className={cx("photoReviewDetailContents", { isCrema: type === CREMA_REVIEW })}>
              <div>
                <div className={cx("reviewAuthor")}>
                  {authorLevel && (
                    <div className={cx("levelWrapper")}>
                      <LevelIcon level={authorLevel} />
                    </div>
                  )}
                  <span className={cx("authorName")}>{author}</span>
                  {height && weight && (
                    <span className={cx("authorSpecifications")}>
                      {height}cm, {weight}kg
                    </span>
                  )}
                </div>
                <div className={cx("reviewSumary")}>
                  {photoModalDetail.type === LOOKPIN_REVIEW ? (
                    <CountReviewSummary
                      id={id}
                      satisfaction={satisfaction}
                      color={color}
                      size={size}
                      shipping={shipping}
                    />
                  ) : (
                    <ReviewStars reviewId={id} starRating={satisfaction} isSmall />
                  )}
                </div>
                <div className={cx("buyOptions")}>{options && <span>구매옵션: {options}</span>}</div>
                <div className={cx("reviewContents")}>
                  <pre>{contents}</pre>
                </div>
              </div>
              <section className={cx("bottom-wrapper")}>
                <div className={cx("reviewCreatedAt", { isCrema: type === CREMA_REVIEW })}>
                  <span>
                    {createdAt}&nbsp;
                    {REVIEWTYPE[type]}
                  </span>
                  {type === CREMA_REVIEW && <span className={cx("recommendText")}>추천 {likeCount}</span>}
                </div>
                <div className={cx("recommendWrapper")}>
                  {type === LOOKPIN_REVIEW && <RecommendButton key={id} reviewId={id} recommendCount={likeCount} />}
                </div>
              </section>
            </div>
          </div>
          <div className={cx("photoReviewdetailNavigation", "right")}>
            {viewSelectNumber !== viewTotalCount && (
              <button type="button" className={cx("modal-button-wrapper")} onClick={() => arrowButtonClick(1)}>
                <RightArrowIcon />
              </button>
            )}
          </div>
        </section>
      )}
    </div>
  );
};
