import { fetcher } from "utils";
import { IPage, IJSendSuccessResponse, IWithMeta, ApiV1ProductSerialized } from "typings";

export function get__api__v1__userables__products(params: {
  page: IPage;
  price?: {
    gte: number;
    lte: number;
  };
  name?: string;
}): Promise<
  IJSendSuccessResponse<
    IWithMeta<
      ApiV1ProductSerialized[],
      {
        total_price: number;
        current_page: number;
        next_page: number | null;
        prev_page: number | null;
        total_pages: number;
        total_count: number;
      }
    >
  >
> {
  return fetcher.get("/api/v1/userables/products", params);
}
