export default function copyToClipboard(text) {
  const copyMsg = `이 ${text}의 주소가 복사되었습니다.\nCtrl + V를 이용해 붙여넣기 해주세요!`;
  const newInput = document.createElement("input");
  newInput.value = window.location.href;
  document.body.appendChild(newInput);
  newInput.select();
  document.execCommand("copy");
  document.body.removeChild(newInput);
  alert(copyMsg);
}
