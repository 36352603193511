import React from "react";

export const IconSocialKakao = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd" transform="translate(4 4)">
      <circle cx="16" cy="16" r="16" fill="#FFEB00" fillRule="nonzero" />
      <path
        fill="#3C1E00"
        d="M16 10c3.865 0 7 2.488 7 5.56 0 3.071-3.135 5.56-7 5.56a8.86 8.86 0 0 1-1.26-.093l-2.837 1.927a.2.2 0 0 1-.122.046.206.206 0 0 1-.193-.27l.66-2.476C10.298 19.265 9 17.533 9 15.56 9 12.488 12.134 10 16 10z"
      />
    </g>
  </svg>
);

export const IconSocialNaver = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd" transform="translate(4 4)">
      <circle cx="16" cy="16" r="16" fill="#1EC800" fillRule="nonzero" />
      <path fill="#FFF" d="M18.481 8.853v7.21l-4.948-7.21H8.187v14.292h5.33v-7.21l4.947 7.21h5.347V8.853z" />
    </g>
  </svg>
);

export const IconPlus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
    <defs>
      <path id="a" d="M0 0h32v32H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#FFF" />
      <path stroke="#ADB5BD" d="M.5.5h31v31H.5z" />
      <g stroke="#343A40" strokeLinecap="square">
        <path d="M13 16h6M16 13v6" />
      </g>
    </g>
  </svg>
);

export const IconMinus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
    <defs>
      <path id="a" d="M0 0h32v32H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#FFF" />
      <path stroke="#ADB5BD" d="M.5.5h31v31H.5z" />
      <path stroke="#343A40" strokeLinecap="square" d="M13 16h6" />
    </g>
  </svg>
);

export const IconQuestionMark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd" transform="translate(0 -1)">
      <circle cx="9" cy="10" r="7.6" stroke="#868E96" strokeWidth=".8" />
      <text fill="#868E96" fontFamily="sans-serif" fontSize="12" fontWeight="bold">
        <tspan x="32%" y="85%">
          ?
        </tspan>
      </text>
    </g>
  </svg>
);

export const IconDownload = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14">
    <g fill="#FFF" fillRule="evenodd">
      <path d="M1.5 12.5h9v1h-9zM5.374 8.105V.5h1.252v7.605l3.8-3.799.885.886L6.003 10.5h-.005L.69 5.192l.885-.886z" />
    </g>
  </svg>
);

export const IconChat = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      fill="#868E96"
      fillRule="nonzero"
      d="M14.8 5H5.2c-.66 0-1.194.54-1.194 1.2L4 17l2.4-2.4h8.4c.66 0 1.2-.54 1.2-1.2V6.2c0-.66-.54-1.2-1.2-1.2zM6.4 9.2h7.2v1.2H6.4V9.2zm4.8 3H6.4V11h4.8v1.2zm2.4-3.6H6.4V7.4h7.2v1.2z"
    />
  </svg>
);

export const IconCall = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path
      fill="#868E96"
      fillRule="evenodd"
      d="M3.011 5.328c.8 1.572 2.089 2.855 3.661 3.66l1.222-1.221c.15-.15.373-.2.567-.134.622.206 1.295.317 1.983.317.306 0 .556.25.556.556v1.938c0 .306-.25.556-.556.556C5.228 11 1 6.772 1 1.556 1 1.25 1.25 1 1.556 1H3.5c.306 0 .556.25.556.556 0 .694.11 1.36.316 1.983.061.194.017.411-.139.567L3.011 5.328"
    />
  </svg>
);

export const IconCheck = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <path fill="currentColor" fillRule="evenodd" d="M0 7.192l1.4-1.346L5 9.308 12.6 2 14 3.346 5 12z" />
  </svg>
);

export const IconThinClose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 11.293l8.879-8.879.707.707L12.707 12l8.879 8.879-.707.707L12 12.707l-8.879 8.879-.707-.707L11.293 12 2.414 3.121l.707-.707z"
    />
  </svg>
);

export const IconPopUpClose = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <filter id="i1zqf9nzka" width="154.2%" height="154.2%" x="-27.1%" y="-22.9%" filterUnits="objectBoundingBox">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
      <path
        id="gdpztfzkxb"
        d="M21.818 0L24 2.182 14.181 12 24 21.818 21.818 24 12 14.181 2.182 24 0 21.818 9.818 12 0 2.182 2.182 0 12 9.818 21.818 0z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-915 -285) translate(485 265) translate(430 20)">
            <use fill="#000" filter="url(#i1zqf9nzka)" xlinkHref="#gdpztfzkxb" />
            <use fill="#FFF" xlinkHref="#gdpztfzkxb" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const IconSearch = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M15.193 16.607A7.002 7.002 0 0 1 6.05 6.05a7 7 0 0 1 10.557 9.143l4.292 4.292-1.414 1.414-4.292-4.292zM11 15.8a4.8 4.8 0 1 0 0-9.6 4.8 4.8 0 0 0 0 9.6z"
    />
  </svg>
);

export const IconPin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 10 18">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.578 3v2.849h.968v3.097L1 10.205v2.35h3.488v3.688L5 17l.512-.757v-3.688H9v-2.35l-1.544-1.26V5.85h.968V3z"
    />
  </svg>
);

export const IconThumb = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="currentColor" fillRule="evenodd">
      <path d="M13.875 7.82a1.126 1.126 0 0 0 0-2.249h-3.587V3.25A2.25 2.25 0 0 0 8.04 1a.422.422 0 0 0-.423.421v2.392L5.788 7.75v4.8c0 .65.527 1.176 1.176 1.176h5.364a1.126 1.126 0 0 0 .876-1.83 1.125 1.125 0 0 0 .835-1.839 1.125 1.125 0 0 0-.164-2.237M1 7.949v5.65a.42.42 0 0 0 .421.422h3.261V7.527h-3.26A.42.42 0 0 0 1 7.95" />
    </g>
  </svg>
);

export const IconChevronTop = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6">
    <g fill="none" fillRule="evenodd">
      <path d="M-298-17h944v40h-944z" />
      <g transform="translate(-158 -17)">
        <rect width="179" height="39" x=".5" y=".5" fill="#FFF" stroke="#C7CDD4" rx="4" />
        <path fill="#868E96" d="M158 22.143l.834.857L163 18.715 167.166 23l.834-.857L163 17z" />
      </g>
    </g>
  </svg>
);

export const IconChevronDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.999 17.334L10.665 16l.002-.001-6.666-6.665L5.335 8 12 14.666 18.665 8 20 9.334 13.334 16l-1.333 1.334v-.001z"
    />
  </svg>
);

export const IconChevronLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18">
    <path fill="currentColor" fillRule="evenodd" d="M11.5 14.998l1-1L7.501 9l5-4.999L11.5 3l-6 6z" />
  </svg>
);

export const IconChevronRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.574 3L12.5 8.996l-5.926 5.996L5.4 13.805l4.758-4.814-4.753-4.809z"
    />
  </svg>
);

export const IconArrowLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.346 11.013h12.169v2.003H7.345l6.079 6.079-1.417 1.416-8.493-8.493 8.493-8.5 1.417 1.417z"
    />
  </svg>
);

export const ResetIconBlack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path
      fill="#868E96"
      fillRule="evenodd"
      d="M6 3.5v-2L8.5 4 6 6.5v-2c-1.655 0-3 1.345-3 3s1.345 3 3 3 3-1.345 3-3h1c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4z"
    />
  </svg>
);

export const FillRateStar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="16px" viewBox="0 0 16 14">
    <path
      fill="#F2616B"
      d="M7 10.89L2.886 13.263 3.871 8.617 0.343 5.437 5.066 4.938 7 0.6 8.934 4.938 13.657 5.437 10.129 8.617 11.114 13.263z"
    />
  </svg>
);

export const EmptyRateStar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="16px" viewBox="0 0 16 14">
    <path
      fill="#E6EAED"
      d="M7 10.89L2.886 13.263 3.871 8.617 0.343 5.437 5.066 4.938 7 0.6 8.934 4.938 13.657 5.437 10.129 8.617 11.114 13.263z"
    />
  </svg>
);

export const FillRateStarInReview = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
    <path
      fill="#F2616B"
      fillRule="evenodd"
      d="M7 10.89L2.886 13.263 3.871 8.617 0.343 5.437 5.066 4.938 7 0.6 8.934 4.938 13.657 5.437 10.129 8.617 11.114 13.263z"
    />
  </svg>
);

export const EmptyRateStarInReview = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
    <path
      fill="#F9F9FA"
      fillRule="evenodd"
      stroke="#C7CDD4"
      strokeWidth=".613"
      d="M10.672 12.655l-.88-4.148 3.15-2.838-4.216-.445L7 1.352 5.274 5.224l-4.216.445 3.15 2.838-.88 4.148L7 10.536l3.672 2.119z"
    />
  </svg>
);

export const FillRecommend = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path
      fill="#F23538"
      fillRule="evenodd"
      d="M3.63 5.662V10.3H1.301c-.142 0-.262-.098-.293-.231L1 9.999V5.963c0-.143.1-.262.232-.293l.069-.008H3.63zM6.028 1c.838 0 1.526.642 1.6 1.46l.006.147v1.658h2.562c.444 0 .804.36.804.803 0 .444-.36.804-.804.804.444 0 .804.36.804.803 0 .405-.299.738-.687.795.115.138.184.317.184.511 0 .436-.347.79-.78.803.111.137.178.313.178.503 0 .41-.306.748-.703.798l-.1.006H5.26c-.428 0-.781-.32-.833-.735l-.007-.105V5.822L5.726 3.01V1.301c0-.166.135-.3.302-.3z"
    />
  </svg>
);

export const NotFillRecommend = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path
      fill="#97A0A8"
      fillRule="evenodd"
      d="M3.63 5.662V10.3H1.301c-.142 0-.262-.098-.293-.231L1 9.999V5.963c0-.143.1-.262.232-.293l.069-.008H3.63zM6.028 1c.838 0 1.526.642 1.6 1.46l.006.147v1.658h2.562c.444 0 .804.36.804.803 0 .444-.36.804-.804.804.444 0 .804.36.804.803 0 .405-.299.738-.687.795.115.138.184.317.184.511 0 .436-.347.79-.78.803.111.137.178.313.178.503 0 .41-.306.748-.703.798l-.1.006H5.26c-.428 0-.781-.32-.833-.735l-.007-.105V5.822L5.726 3.01V1.301c0-.166.135-.3.302-.3z"
    />
  </svg>
);

export const RightArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <path fillRule="evenodd" d="M5 0.944L5.761 0 25 15 5.774 30 5 29.024 22.968 15z" />
  </svg>
);

export const LeftArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <path fillRule="evenodd" d="M25 0.944L7.032 15 25 29.024 24.226 30 5 15 24.239 0z" />
  </svg>
);

export const WhiteRecommendIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="#FFF" fillRule="evenodd">
      <path
        d="M12.875 6.82c.621 0 1.125-.503 1.125-1.125 0-.621-.504-1.124-1.125-1.124H9.288V2.25C9.288 1.008 8.281 0 7.04 0c-.234 0-.423.19-.423.421v2.392L4.788 6.75v4.8c0 .65.527 1.176 1.176 1.176h5.364c.621 0 1.125-.504 1.125-1.125 0-.266-.093-.513-.249-.704.606-.019 1.092-.514 1.092-1.125 0-.271-.096-.522-.257-.715.543-.08.961-.546.961-1.113 0-.622-.504-1.124-1.125-1.124M0 6.949v5.65c0 .234.189.422.421.422h3.261V6.527H.422c-.233 0-.422.188-.422.422"
        transform="translate(1 1)"
      />
    </g>
  </svg>
);

export const GrayRecommendIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path
      fill="#97A0A8"
      fillRule="evenodd"
      d="M4.682 7.527v6.494h-3.26c-.207 0-.38-.149-.415-.346L1 13.6V7.95c0-.208.15-.38.346-.415l.075-.007h3.261zM8.04 1c1.19 0 2.164.926 2.243 2.096l.005.154V5.57h3.587c.621 0 1.125.503 1.125 1.124 0 .622-.504 1.126-1.125 1.126.621 0 1.125.502 1.125 1.124 0 .567-.418 1.033-.961 1.113.16.193.257.444.257.715 0 .61-.486 1.106-1.092 1.125.156.191.25.438.25.704 0 .58-.44 1.058-1.004 1.119l-.122.006H6.964c-.606 0-1.105-.458-1.169-1.047l-.007-.129v-4.8l1.829-3.938V1.42c0-.232.189-.42.423-.42z"
    />
  </svg>
);

export const RightArrowGrayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="#495057"
      fillRule="evenodd"
      d="M3.846 8.013L16.015 8.013 16.015 10.016 3.846 10.016 9.924 16.095 8.507 17.511 0.014 9.018 8.507 0.518 9.924 1.935z"
      opacity=".8"
      transform="matrix(-1 0 0 1 20.5 3)"
    />
  </svg>
);

export const RightArrowBlueIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <path fill="#4285F4" fillRule="evenodd" d="M6.574 3L12.5 8.996 6.574 14.992 5.4 13.805 10.158 8.991 5.405 4.182z" />
  </svg>
);

export const ChevronTop = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <path fill="#868E96" fillRule="evenodd" d="M14.999 11.5L13.999 12.5 9 7.501 4.001 12.5 3.001 11.5 9.001 5.5z" />
  </svg>
);

export const ChevronTopBig = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="chevronTop">
    <g fill="none" fillRule="evenodd">
      <g fill="#868E96">
        <g>
          <g>
            <path
              d="M4.001 15.5L5.335 16.834 12 10.168 18.665 16.834 19.999 15.5 12.001 7.5z"
              transform="translate(-1196 -1363) translate(292 1347) translate(904 16)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ChevronBottom = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <path fill="#868E96" fillRule="evenodd" d="M3.001 7L4.001 6 9 10.999 13.999 6 14.999 7 8.999 13z" />
  </svg>
);

export const ChevronBottomBig = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="chevronBottom">
    <g fill="none" fillRule="evenodd">
      <g fill="#868E96">
        <g>
          <g>
            <path
              d="M4.001 8.834L5.335 7.5 12 14.166 18.665 7.5 19.999 8.834 12.001 16.834z"
              transform="translate(-1196 -1363) translate(292 1347) translate(904 16)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const BtnPhotoGridView = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="#FFF">
      <path
        d="M1.282 0h11.436c.446 0 .607.046.77.134.163.087.291.215.378.378.088.163.134.324.134.77v11.436c0 .446-.046.607-.134.77-.087.163-.215.291-.378.378-.163.088-.324.134-.77.134H1.282c-.446 0-.607-.046-.77-.134-.163-.087-.291-.215-.378-.378-.088-.163-.134-.324-.134-.77V1.282C0 .836.046.675.134.512.22.349.349.22.512.134.675.046.836 0 1.282 0zM17.282 0h11.436c.446 0 .607.046.77.134.163.087.291.215.378.378.088.163.134.324.134.77v11.436c0 .446-.046.607-.134.77-.087.163-.215.291-.378.378-.163.088-.324.134-.77.134H17.282c-.446 0-.607-.046-.77-.134-.163-.087-.291-.215-.378-.378-.088-.163-.134-.324-.134-.77V1.282c0-.446.046-.607.134-.77.087-.163.215-.291.378-.378.163-.088.324-.134.77-.134zM1.282 16h11.436c.446 0 .607.046.77.134.163.087.291.215.378.378.088.163.134.324.134.77v11.436c0 .446-.046.607-.134.77-.087.163-.215.291-.378.378-.163.088-.324.134-.77.134H1.282c-.446 0-.607-.046-.77-.134-.163-.087-.291-.215-.378-.378-.088-.163-.134-.324-.134-.77V17.282c0-.446.046-.607.134-.77.087-.163.215-.291.378-.378.163-.088.324-.134.77-.134zM17.282 16h11.436c.446 0 .607.046.77.134.163.087.291.215.378.378.088.163.134.324.134.77v11.436c0 .446-.046.607-.134.77-.087.163-.215.291-.378.378-.163.088-.324.134-.77.134H17.282c-.446 0-.607-.046-.77-.134-.163-.087-.291-.215-.378-.378-.088-.163-.134-.324-.134-.77V17.282c0-.446.046-.607.134-.77.087-.163.215-.291.378-.378.163-.088.324-.134.77-.134z"
        transform="translate(1 1)"
      />
    </g>
  </svg>
);

export const ChevronLeftGray = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <defs>
      <filter id="prefix__a" width="148.1%" height="127.7%" x="-24.1%" y="-13.9%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter1" />
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5" />
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
      <path id="prefix__left" d="M24.686 8L26.99 10.465 17.618 20.489 27 30.524 24.686 33 13 20.5z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__left" />
      <use fill="#FFF" fillOpacity=".9" xlinkHref="#prefix__left" />
    </g>
  </svg>
);

export const ChevronRightGray = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    key="gray-right-svg"
  >
    <defs>
      <filter id="prefix__a" width="148.1%" height="127.7%" x="-24.1%" y="-13.9%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter1" />
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5" />
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
      <path id="prefix__right" d="M15.314 8L27 20.5 15.314 33 13 30.524 22.382 20.489 13.01 10.465z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__right" />
      <use fill="#FFF" fillOpacity=".9" xlinkHref="#prefix__right" />
    </g>
  </svg>
);

export const ArrowUpDownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12">
    <path
      fill="#495057"
      fillRule="evenodd"
      d="M11 12h-1L7 8h1l2 3V0h1v11l2-3h1l-3 4zM4 1v11H3V1L1 4H0l3-4h1l3 4H6L4 1z"
    />
  </svg>
);

export const GrayRightArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8">
    <path fill="#495057" fillRule="evenodd" d="M5 4L0 0v1l4 3-4 3v1l5-4z" />
  </svg>
);

export const ClaimReceiptIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M10.222 18.7h7.39v2.1h-7.39v-2.1zm0-4.2h10.556v2.1H10.222v-2.1zm0-4.2h10.556v2.1H10.222v-2.1zM22.89 6.1h-4.412C18.033 4.882 16.872 4 15.5 4c-1.372 0-2.533.882-2.977 2.1H8.111c-.148 0-.285.01-.422.042-.412.084-.781.294-1.066.577-.19.19-.349.42-.454.672C6.063 7.633 6 7.906 6 8.2v14.7c0 .284.063.567.169.819.105.252.264.473.454.672.285.284.654.494 1.066.578.137.02.274.031.422.031H22.89C24.05 25 25 24.055 25 22.9V8.2c0-1.155-.95-2.1-2.111-2.1zM15.5 5.838c.433 0 .792.356.792.787 0 .43-.36.787-.792.787-.433 0-.792-.356-.792-.787 0-.43.36-.787.792-.787zM22.889 22.9H8.11V8.2H22.89v14.7z"
                transform="translate(-332 -567) translate(309 558) translate(14) translate(9 9)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ClaimPickUpIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M15 4c-4.41 0-8 3.512-8 7.826 0 5.448 6.96 13.05 7.26 13.372L15 26l.74-.802c.3-.323 7.26-7.924 7.26-13.372C23 7.512 19.41 4 15 4zm0 19.046c-2.18-2.553-6-7.757-6-11.22 0-3.238 2.69-5.87 6-5.87s6 2.632 6 5.87c0 3.746-4.25 9.156-6 11.22zm-1.53-9.098l-1.76-1.731-1.42 1.389 3.18 3.11 6.01-5.878-1.41-1.39-4.6 4.5z"
                transform="translate(-427 -567) translate(309 558) translate(109) translate(9 9)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ClaimShippingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M23 11h-3V7H6c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5l1.96 2.5H20v-2.5h2.5zM9 21c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1-.89 0-1.67.39-2.22 1H6V9h12v9h-6.78zM21 21c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
                transform="translate(-521 -567) translate(309 558) translate(203) translate(9 9)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ClaimFinIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M14.99 5C9.47 5 5 9.48 5 15s4.47 10 9.99 10C20.52 25 25 20.52 25 15S20.52 5 14.99 5zM15 23c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H9.89c.8 2.04 2.78 3.5 5.11 3.5z"
                transform="translate(-616 -567) translate(309 558) translate(298) translate(9 9)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ClaimAcceptIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M22.122 8.573l1.284 1.272-11.678 11.582-5.134-5.09 1.284-1.273 3.85 3.818 10.394-10.31m0-2.572L11.727 16.31l-3.85-3.82L4 16.337 11.727 24 26 9.845 22.122 6z"
                transform="translate(-458 -567) translate(293 558) translate(156) translate(9 9)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ClaimRightGrayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd">
      <g fill="#C7CDD4">
        <g>
          <g>
            <path
              d="M14.999 11.499L13.999 12.499 9 7.5 4.001 12.499 3.001 11.499 9.001 5.499z"
              transform="translate(-386 -573) translate(309 558) translate(77 15) rotate(90 9 9)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const LockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g fill="#C7CDD4" fillRule="nonzero">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M12.5 5.35h-.75v-1.5C11.75 1.78 10.07.1 8 .1 5.93.1 4.25 1.78 4.25 3.85v1.5H3.5c-.825 0-1.5.675-1.5 1.5v7.5c0 .825.675 1.5 1.5 1.5h9c.825 0 1.5-.675 1.5-1.5v-7.5c0-.825-.675-1.5-1.5-1.5zM8 12.1c-.825 0-1.5-.675-1.5-1.5S7.175 9.1 8 9.1s1.5.675 1.5 1.5-.675 1.5-1.5 1.5zm2.325-6.75h-4.65v-1.5c0-1.283 1.043-2.325 2.325-2.325 1.283 0 2.325 1.042 2.325 2.325v1.5z"
                    transform="translate(-308 -1503) translate(292 1327) translate(0 145) translate(0 28) translate(16) translate(0 3)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const UncheckedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-1095 -1273) translate(1095 1271) translate(0 2)">
            <rect
              width="18.75"
              height="18.75"
              x=".625"
              y=".625"
              fill="#DEE2E6"
              stroke="#DEE2E6"
              strokeWidth="1.25"
              rx="2.5"
            />
            <path fill="#FFF" d="M16.255 5L17.5 6.205 8.236 15 2.5 9.591 3.745 8.386 8.236 12.62z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const CheckedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <g fill="#4285F4">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M16 0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zM7 14L2 9.192l1.4-1.346L7 11.308 14.6 4 16 5.346 7 14z"
                      transform="translate(-271 -404) translate(0 81) translate(16 20) translate(0 300) translate(255) translate(0 3) translate(1 1)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const BtnBack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill="#495057">
        <g>
          <g>
            <g>
              <path
                d="M3.846 8.013L16.015 8.013 16.015 10.016 3.846 10.016 9.924 16.095 8.507 17.511 0.014 9.018 8.507 0.518 9.924 1.935z"
                transform="translate(-18 -40) translate(0 24) translate(18 16) translate(3.5 3)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const CloseQna = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill="#495057">
        <g>
          <g>
            <path
              d="M12 11.293L20.879 2.414 21.586 3.121 12.707 12 21.586 20.879 20.879 21.586 12 12.707 3.121 21.586 2.414 20.879 11.293 12 2.414 3.121 3.121 2.414z"
              transform="translate(-619 -80) translate(73 58) translate(546 22)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const BadgeNew = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-643 -448) translate(292 229) translate(0 101) translate(269 115) translate(82 3) translate(1 1)">
                  <circle cx="7" cy="7" r="7" fill="#F23538" />
                  <text fill="#FFF" fontFamily="Lato-Black, Lato" fontSize="10" fontWeight="700">
                    <tspan x="3.22" y="10.5">
                      N
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const PopupLeftArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="27" viewBox="0 0 14 27">
    <g fill="none" fillRule="evenodd" opacity=".9">
      <g fill="#DEE2E6">
        <g>
          <path
            d="M11.686 1L13.99 3.465 4.618 13.489 14 23.524 11.686 26 0 13.5z"
            transform="translate(-502 -549) translate(502 549)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const PopupRightArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="27" viewBox="0 0 14 27">
    <g fill="none" fillRule="evenodd" opacity=".9">
      <g fill="#DEE2E6">
        <g>
          <path
            d="M2.314 1L14 13.5 2.314 26 0 23.524 9.382 13.489 0.01 3.465z"
            transform="translate(-924 -549) translate(924 549)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const FilterBlackIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <g fill="#212529" fillRule="nonzero">
        <g>
          <path
            d="M9.556 22.667h2.222c.613 0 1.11-.498 1.11-1.111 0-.614-.497-1.112-1.11-1.112H9.556c-.614 0-1.112.498-1.112 1.112 0 .613.498 1.11 1.112 1.11zM.666 10.444c0 .614.498 1.112 1.112 1.112h17.778c.613 0 1.11-.498 1.11-1.112 0-.613-.497-1.11-1.11-1.11H1.778c-.614 0-1.111.497-1.111 1.11zm4.445 6.667h11.111c.614 0 1.111-.497 1.111-1.111s-.497-1.111-1.11-1.111H5.11C4.497 14.889 4 15.386 4 16s.497 1.111 1.111 1.111z"
            transform="translate(-290 -390) translate(290 390)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const FilterColorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g fill="#F23538" fillRule="nonzero">
            <path
              d="M9.556 13.333h2.222c.613 0 1.11-.497 1.11-1.11 0-.614-.497-1.112-1.11-1.112H9.556c-.614 0-1.112.498-1.112 1.111 0 .614.498 1.111 1.112 1.111zM.666 1.111c0 .614.498 1.111 1.112 1.111h17.778c.613 0 1.11-.497 1.11-1.11C20.667.496 20.17 0 19.557 0H1.778C1.164 0 .667.497.667 1.111zm4.445 6.667h11.111c.614 0 1.111-.498 1.111-1.111 0-.614-.497-1.111-1.11-1.111H5.11C4.497 5.556 4 6.053 4 6.666c0 .614.497 1.112 1.111 1.112z"
              transform="translate(-290 -390) translate(290 390) translate(0 9.333)"
            />
          </g>
          <g transform="translate(-290 -390) translate(290 390) translate(16)">
            <circle cx="8" cy="8" r="8" fill="#F23538" />
            <g fill="#FFF">
              <path d="M0 5.137L1 4.176 3.571 6.648 9 1.429 10 2.39 3.571 8.571z" transform="translate(3 3)" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const IconChevronBottomGray = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd">
      <g fill="#868E96">
        <g>
          <g>
            <g>
              <path
                d="M3.001 7L4.001 6 9 10.999 13.999 6 14.999 7 8.999 13z"
                transform="translate(-326 -278) translate(0 248) translate(16 16) translate(310 14)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const IconChevronTopButtonGray = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd">
      <g fill="#868E96">
        <g>
          <g>
            <g>
              <path
                d="M14.999 11.5L13.999 12.5 9 7.501 4.001 12.5 3.001 11.5 9.001 5.5z"
                transform="translate(-326 -278) translate(0 248) translate(16 16) translate(310 14)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const WhiteHelpButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <g>
            <path
              d="M20 11c-5.282 0-10 3.458-10 8.188 0 1.677.615 3.324 1.706 4.602.046 1.497-.853 3.646-1.661 5.21 2.168-.385 5.25-1.234 6.648-2.075C24.39 28.764 30 24.14 30 19.188 30 14.432 25.25 11 20 11zm0 12.273c-.47 0-.853-.376-.853-.839 0-.462.382-.838.853-.838.472 0 .853.376.853.838 0 .463-.381.839-.853.839zm1.338-3.975c-.616.654-.646 1.015-.638 1.46h-1.37c-.004-.988.014-1.425.978-2.325.391-.365.7-.654.657-1.221-.04-.54-.5-.822-.93-.822-.485 0-1.052.354-1.052 1.35h-1.371c0-1.61.962-2.65 2.45-2.65.708 0 1.327.228 1.742.642.39.387.591.92.584 1.54-.011.928-.587 1.537-1.05 2.026z"
              transform="translate(-220 -84) translate(0 80) translate(220 4)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const BlueHelpButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd">
      <g fill="#4285F4" fillRule="nonzero">
        <g>
          <g>
            <path
              d="M20 11c-5.282 0-10 3.458-10 8.188 0 1.677.615 3.324 1.706 4.602.046 1.497-.853 3.646-1.661 5.21 2.168-.385 5.25-1.234 6.648-2.075C24.39 28.764 30 24.14 30 19.188 30 14.432 25.25 11 20 11zm0 12.273c-.47 0-.853-.376-.853-.839 0-.462.382-.838.853-.838.472 0 .853.376.853.838 0 .463-.381.839-.853.839zm1.338-3.975c-.616.654-.646 1.015-.638 1.46h-1.37c-.004-.988.014-1.425.978-2.325.391-.365.7-.654.657-1.221-.04-.54-.5-.822-.93-.822-.485 0-1.052.354-1.052 1.35h-1.371c0-1.61.962-2.65 2.45-2.65.708 0 1.327.228 1.742.642.39.387.591.92.584 1.54-.011.928-.587 1.537-1.05 2.026z"
              transform="translate(-220 -84) translate(0 80) translate(220 4)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const BlueSearchButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill="#4285F4" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M15.95 6.05c2.493 2.494 2.712 6.4.657 9.143l4.292 4.292-1.414 1.414-4.292-4.292c-2.742 2.055-6.65 1.836-9.143-.657-2.733-2.734-2.733-7.166 0-9.9 2.734-2.733 7.166-2.733 9.9 0zM7.606 7.606c-1.875 1.874-1.875 4.914 0 6.788 1.694 1.694 4.371 1.874 6.267.452l.556-.417.417-.556c1.422-1.896 1.242-4.573-.452-6.267-1.874-1.875-4.914-1.875-6.788 0z"
                transform="translate(-1200 -213) translate(292 205) translate(868 8) translate(40)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const GrayXmarkButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill="#B0B8BF">
        <g>
          <g>
            <path
              d="M13 4l-.001 6.999L20 11v2l-7.001-.001L13 20h-2l-.001-7.001L4 13v-2l6.999-.001L11 4h2z"
              transform="translate(-1160 -213) translate(292 205) translate(868 8) rotate(45 12 12)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const BoldXmarkButton = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <filter id="zq47x5juea" width="166.7%" height="166.7%" x="-33.3%" y="-29.2%" filterUnits="objectBoundingBox">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2.5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
      </filter>
      <path
        id="z6nish04lb"
        d="M21.818 0L24 2.182 14.181 12 24 21.818 21.818 24 12 14.181 2.182 24 0 21.818 9.818 12 0 2.182 2.182 0 12 9.818 21.818 0z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-915 -253) translate(485 237) translate(430 16)">
            <use fill="#000" filter="url(#zq47x5juea)" xlinkHref="#z6nish04lb" />
            <use fill="#495057" xlinkHref="#z6nish04lb" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const LightningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g fill="#FFEA00" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M7.274 14.49v-4.199H4.518c-.384 0-.643-.405-.456-.74l3.813-7.272c.239-.477.974-.305.974.233v4.25h2.632c.384 0 .633.395.467.73l-3.69 7.221c-.248.487-.984.315-.984-.223z"
                transform="translate(-1173 -398) translate(292 281) translate(735 105) translate(28) translate(118 12)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const RateStar = ({ isSelect }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="40" viewBox="0 0 48 40">
    <g fill="none" fillRule="evenodd">
      <g fill={isSelect ? "#F2616B" : "#DEE2E6"}>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M20 30.733L8.244 37.514 11.06 24.238 0.979 15.153 14.475 13.729 20 1.333 25.525 13.729 39.021 15.153 28.94 24.238 31.756 37.514z"
                      transform="translate(-1020 -2378) translate(760 1965) translate(22 88) translate(0 277) translate(0 48) translate(70) translate(168)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const PhotoAddIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g fill="#C7CDD4" fillRule="nonzero">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71-2.75 3.54zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z"
                      transform="translate(-802 -1553) translate(760 816) translate(22 88) translate(0 277) translate(0 308) translate(0 28) translate(20 36)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const FillCircleXmark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <defs>
      <circle id="uqffg1b3sa" cx="10.667" cy="10.667" r="10.667" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g transform="translate(-914 -1517) translate(760 816) translate(22 88) translate(0 277) translate(0 308) translate(0 28) translate(132) translate(5.333 5.333)">
                      <mask id="1n2vkhcszb" fill="#fff">
                        <use xlinkHref="#uqffg1b3sa" />
                      </mask>
                      <use fill="#4285F4" xlinkHref="#uqffg1b3sa" />
                      <path
                        fill="#FFF"
                        d="M14.844 5.97L15.731 6.857 11.737 10.85 15.731 14.844 14.844 15.731 10.85 11.737 6.857 15.731 5.97 14.844 9.963 10.85 5.97 6.857 6.857 5.97 10.85 9.963z"
                        mask="url(#1n2vkhcszb)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const FillRateStarV2 = () => {
  return (
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.78282 0.451041C9.07642 -0.142999 9.92358 -0.142999 10.2172 0.451041L12.2449 4.55358C12.3616 4.78983 12.5873 4.95342 12.8481 4.99095L17.3898 5.64435C18.0472 5.73892 18.3097 6.54697 17.8335 7.00985L14.5524 10.199C14.3632 10.383 14.2768 10.6485 14.3217 10.9086L15.097 15.4057C15.2096 16.0585 14.5252 16.5575 13.9381 16.2506L9.87057 14.1247C9.63842 14.0033 9.36158 14.0033 9.12943 14.1247L5.06192 16.2506C4.47478 16.5575 3.79042 16.0585 3.90299 15.4057L4.67834 10.9086C4.72319 10.6485 4.63684 10.383 4.44757 10.199L1.16652 7.00985C0.690298 6.54697 0.952847 5.73892 1.61019 5.64435L6.15186 4.99095C6.41272 4.95342 6.63835 4.78983 6.75512 4.55358L8.78282 0.451041Z"
        fill="#F7445D"
      />
    </svg>
  );
};

export const InfoCircle = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.5" cy="8.5" r="8" fill="white" stroke="#4285F4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.52014 13.0889L7.48014 13.0889L7.48014 7.47887L9.52014 7.47887L9.52014 13.0889ZM9.52014 6.28961L7.48014 6.28961L7.48014 4.24961L9.52014 4.24961L9.52014 6.28961Z"
        fill="#4285F4"
      />
    </svg>
  );
};

export const LookpinLogo = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5156 0C39.875 0 44 4.125 44 10.4844V33.5156C44 39.875 39.875 44 33.5156 44H10.4844C4.125 44 0 39.875 0 33.5156V10.4844C0 4.125 4.125 0 10.4844 0H33.5156Z"
        fill="#040F3B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.7896 16.3943C28.3 16.3092 28.7091 15.8202 28.7031 15.302L28.636 11.4615C28.6274 10.6846 27.9907 10.0547 27.2138 10.0547H16.7853C16.0092 10.0547 15.3724 10.6846 15.363 11.4615L15.296 15.302C15.2899 15.8202 15.699 16.3092 16.2095 16.3943L17.294 16.5756C17.4255 16.598 17.5329 16.7252 17.5329 16.8584V20.4987C17.5329 20.6319 17.4255 20.7599 17.294 20.7814L16.2095 20.9627C15.699 21.0478 15.2899 21.5368 15.296 22.055L15.363 25.8019C15.3716 26.5787 16.0067 27.2302 16.7844 27.2551L20.8278 27.3934C20.8802 27.3952 20.9223 27.4381 20.9223 27.4905L21.4242 37.0648C21.4345 37.2152 21.5591 37.3321 21.7103 37.3321H22.2904C22.4408 37.3321 22.5663 37.2152 22.5766 37.0648L23.0776 27.4905C23.0776 27.439 23.1206 27.3952 23.1721 27.3934L27.2146 27.2551C27.9907 27.2293 28.6274 26.5787 28.636 25.8019L28.7031 22.055C28.7091 21.5368 28.3 21.0478 27.7896 20.9627L26.705 20.7814C26.5735 20.7591 26.4661 20.6319 26.4661 20.4987V16.8584C26.4661 16.7252 26.5735 16.598 26.705 16.5756L27.7896 16.3943Z"
        fill="white"
      />
    </svg>
  );
};
