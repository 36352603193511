import { fetcher } from "utils";
import { ColorKey, IJSendSuccessResponse, ShippingKey, SizeKey } from "typings";

interface IReviewPostRequest {
  order_product_order_num: number | string;
  param: {
    satisfaction: number;
    size?: SizeKey | null;
    color?: ColorKey | null;
    shipping?: ShippingKey | null;
    content?: string | null;
    photo_urls?: Array<String>;
    userable_info: {
      height: number | string;
      weight: number | string;
    };
  };
}
type ReviewPostFetcher = ({ order_product_order_num, param }: IReviewPostRequest) => Promise<IJSendSuccessResponse<{}>>;

export const api__post_order_proudcts_reviews: ReviewPostFetcher = ({ order_product_order_num, param }) =>
  fetcher.post(`/api/v1/members/order_products/${order_product_order_num}/order_product_reviews`, param);
