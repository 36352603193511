import { IJSendSuccessResponse, ProductQuestionType } from "typings";
import { fetcher } from "utils";

type PostProductQuestions = (payload: {
  productId: number;
  typeof: ProductQuestionType;
  contents: string;
  hidden: boolean;
}) => Promise<IJSendSuccessResponse<{}>>;
export const api__v1_member_product_questions_post: PostProductQuestions = payload => {
  return fetcher.post("/api/v1/members/product_questions", payload);
};
