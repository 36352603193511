import { useCallback, useState } from "react";

export const useHover = () => {
  const [isHover, setIsHover] = useState(false);

  const hoverOn = useCallback(() => setIsHover(true), []);
  const hoverOff = useCallback(() => setIsHover(false), []);
  const handleHover = useCallback(() => {
    if (isHover) setIsHover(false);
    else setIsHover(true);
  }, [isHover]);

  return { isHover, hoverOn, hoverOff, handleHover };
};
