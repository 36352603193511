import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { modalOff, modalOn, userLogin } from "actions/actionCreators";
import { formChecker, useInputs } from "utils";
import { UI__FORM_FAILURE_EMAIL_INVALID, UI__FORM_FAILURE_PASSWORD_INVALID, UI__MODAL_FIND_PASSWORD } from "constant";

import classNames from "classnames/bind";
import styles from "../../ModalWrapper.module.scss";

const cx = classNames.bind(styles);

export function AlreadyAccountLogin() {
  const dispatch = useDispatch();
  const history = useHistory();
  const injectedEmail = useSelector(({ authentication: { email } }) => email) || "";

  const [state, onChange] = useInputs({
    email: injectedEmail,
    password: ""
  });
  const [formFailure, setFormFailure] = useState({});
  const checker = formChecker(setFormFailure);
  const { email, password } = state;

  const handleSubmit = async event => {
    event.preventDefault();

    if (checker(state)) {
      try {
        await dispatch(userLogin(email, password));
        history.replace("/member/accounts");
      } catch (e) {
        console.error(e);
      }
    }
  };

  const closeModal = () => {
    dispatch(modalOff());
    history.replace("/");
  };

  return (
    <div className={cx("container")}>
      <div className={cx("header")}>
        로그인
        <button className={cx("close")} onClick={closeModal} type="button">
          {" "}
        </button>
      </div>
      <div className={cx("contents")}>
        <form onSubmit={handleSubmit}>
          <p>
            <span style={{ color: "#f23538" }}>이미 가입된 이메일 계정</span>
            입니다.
            <br />
            해당 ID로 로그인 하신 후 소셜 계정 연동을 진행해주세요.
          </p>
          <input name="email" onChange={onChange} placeholder="이메일 주소" type="email" value={email} />
          {formFailure.UI__FORM_FAILURE_EMAIL_INVALID && (
            <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_EMAIL_INVALID}</div>
          )}

          <input
            autoComplete="on"
            name="password"
            onChange={onChange}
            placeholder="비밀번호"
            type="password"
            value={password}
          />
          {formFailure.UI__FORM_FAILURE_PASSWORD_INVALID && (
            <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_PASSWORD_INVALID}</div>
          )}

          <div className={cx("btnWrapper")}>
            <button className={cx("login")} type="submit">
              이메일 로그인
            </button>
          </div>
          <p className={cx("find-pwd-desc")}>비밀번호가 기억나지 않으신가요?</p>
          <button
            className={cx("find-pwd")}
            type="button"
            onClick={() => dispatch(modalOn(UI__MODAL_FIND_PASSWORD))}
            data-position="FindPassword"
          >
            비밀번호 찾기
          </button>
        </form>
      </div>
    </div>
  );
}
