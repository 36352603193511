import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { camelizeKeys } from "humps";
import { debounce } from "debounce";

import { get__stores__brands } from "api";

import { StoreSerializedCamel, IStore } from "typings";

export const useBrands = () => {
  const [brands, setBrands] = useState<StoreSerializedCamel[]>([]);
  const [page, setPage] = useState(1);
  const [done, setDone] = useState(false);

  const genderId = useSelector<IStore, IStore["ui"]["genderId"]>(({ ui: { genderId } }) => genderId);

  useEffect(() => {
    (async () => {
      if (done) return;
      const response = await get__stores__brands({ page, genderId });

      if (response.length) {
        if (page === 1) {
          setBrands(camelizeKeys(response) as StoreSerializedCamel[]);
        } else {
          setBrands(brands.concat(camelizeKeys(response) as any));
        }
      }

      if (response.length < 25) setDone(true);
    })();
  }, [done, page, genderId]); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    setDone(false);
    setPage(1);
  }, [genderId]);

  useEffect(() => {
    const handler = debounce(() => {
      const { scrollTop, clientHeight, offsetHeight } = document.documentElement;
      const bottomY = scrollTop + clientHeight;

      if (bottomY + 800 >= offsetHeight) {
        setPage(page => page + 1);
      }
    }, 100);

    window.addEventListener("scroll", handler);

    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  return { brands };
};
