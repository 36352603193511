import React, { FC, useMemo } from "react";
import { IMemeberOrderViewModelReNewal, IOrderProductNextStatusCamel, OrderProductCardType } from "typings";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import { RightArrowBlueIcon } from "assets/icon";
import { OrderAction, CallButton, ContactButton, BankInfo } from "components";
import { statusClaimTest } from "utils";
import { useOrderAction } from "./useOrderAction";
import styles from "./OrderProductCardMobileView.module.scss";
import { ReviewWriteAction } from "./ReviewWriteAction";
import { BuyConfirmButton } from "./BuyConfirmButton";

interface IProp {
  order: IMemeberOrderViewModelReNewal;
  disabledLink?: boolean;
  actionClickEvent: (payload: IOrderProductNextStatusCamel) => void;
  reviewSubmitCallback?: () => void;
  type?: OrderProductCardType;
}

const cx = classNames.bind(styles);

export const OrderProductCardMobileView: FC<IProp> = ({
  order,
  actionClickEvent,
  disabledLink,
  reviewSubmitCallback,
  type
}) => {
  const { historyPushOrderDetail, createOptionString } = useOrderAction();
  const {
    productOrderNum,
    productName,
    prevPrice,
    paidPrice,
    productImage,
    currentStatusText,
    productOptions,
    productSelectedCount,
    store,
    orderNum,
    nextStatus,
    beforeStatus,
    shippingInfoFromPartner,
    productId,
    bankInfo,
    review
  } = order;

  const productOptionText = useMemo(() => createOptionString(productOptions, productSelectedCount), [
    productOptions,
    productSelectedCount,
    createOptionString
  ]);

  const statusClass = useMemo(() => (statusClaimTest(beforeStatus) ? "claim" : beforeStatus), [beforeStatus]);

  return (
    <div className={cx("card-wrapper")}>
      {disabledLink ? (
        <div className={cx("card-head")}>
          <div className={cx("title")}>상품 주문번호&nbsp;{productOrderNum}</div>
        </div>
      ) : (
        <div className={cx("card-head")} onClick={historyPushOrderDetail(orderNum, productOrderNum)}>
          <div>
            <div className={cx("title")}>상품 주문번호&nbsp;{productOrderNum}</div>
          </div>
          <div className={cx("more")}>
            <span className={cx("blue-text", "order-detail-text")}>주문상세</span> <RightArrowBlueIcon />
          </div>
        </div>
      )}
      <section className={cx("card-body")}>
        {type === "order" && (
          <div className={cx("order-status")}>
            <span className={cx("status-text", statusClass, "bold")}>{currentStatusText}</span>
            {shippingInfoFromPartner.trackingUrl && (
              <a
                className={cx("ship-tracking")}
                href={shippingInfoFromPartner.trackingUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                배송조회
              </a>
            )}
          </div>
        )}
        {bankInfo && <BankInfo bankData={bankInfo} />}
        <div className={cx("product-section")}>
          <Link to={`/products/${productId}`} className={cx("product")}>
            <div>
              <img className={cx("image")} src={productImage} alt={productName} />
            </div>
            <div className={cx("product-description-wrapper")}>
              <div className={cx("product-text-wrapper")}>
                <span className={cx("product-name")}>{productName}</span>
                <span className={cx("product-pay")}>
                  <span className={cx("gray", "cancle-line", "pay-text")}>{prevPrice.toLocaleString()}원</span>
                  <span className={cx("semi-bold", "text-margin", "pay-text")}>{paidPrice.toLocaleString()}원</span>
                </span>
              </div>
              <div className={cx("order-options")}>{productOptionText} </div>
            </div>
          </Link>
        </div>
        {type === "review" && beforeStatus === "confirm" && (
          <div className={cx("review-action-wrapper")}>
            <ReviewWriteAction
              review={review}
              product={{ productId, productTitle: productName, productImage, storeName: store.name }}
              orderProductOrderNum={productOrderNum}
              reviewSubmitCallback={reviewSubmitCallback}
            />
          </div>
        )}
        {type === "order" && (
          <div className={cx("action-wrapper")}>
            <OrderAction nextStatus={nextStatus} onClick={actionClickEvent} />
          </div>
        )}
        {type === "review" && beforeStatus === "ship_ing" && (
          <div className={cx("action-wrapper")}>
            <BuyConfirmButton onClick={() => actionClickEvent(nextStatus[2])} />
          </div>
        )}
      </section>
      <section className={cx("card-footer")}>
        <div className={cx("store-name")}>판매처: {store.name}</div>
        <div className={cx("button-wrapper")}>
          <CallButton cellNumber={store.cellNumber} />
          <ContactButton productOrderNum={productOrderNum} orderNum={orderNum} />
        </div>
      </section>
    </div>
  );
};
