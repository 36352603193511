import React, { FC } from "react";
import { useSelector } from "react-redux";
import { IStore } from "typings";
import { DetailPhotoReviewPC } from "./DetailPhotoReviewPC";
import { DetailPhotoReviewMobile } from "./DetailPhotoReviewMobile";

export const DetailPhotoReview: FC = () => {
  const { isMobile } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);
  return isMobile ? <DetailPhotoReviewMobile /> : <DetailPhotoReviewPC />;
};
