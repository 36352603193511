import { fetcher } from "utils";

import { IJSendSuccessResponse, Gender } from "typings";

export const get__api__v1__search__keywords = (
  genderId: Gender
): Promise<IJSendSuccessResponse<{
  payload: {
    keyword_type: "product";
    gender_id: Gender;
    keywords: string[];
  };
}>> =>
  fetcher.get(`/api/v1/search/keywords`, {
    keywordType: "product",
    genderId
  });
