import React, { useCallback } from "react";
import { historyContext } from "contexts";

import "styles/Common/Footer.scss";
import {
  LOOKPIN_KAKAO_LINK,
  LOOKPIN_STORE,
  LINK_TO_TERMS,
  LINK_TO_PRIVACY,
  LINK_TO_USE_OF_PRIVACY,
  LINK_TO_MARKETING
} from "constant";
import { Link } from "react-router-dom";

const Footer = () => {
  if( window.location.pathname === '/2021chuseok') return null;

  const handleFTCinfoModal = useCallback(() => {
    window.open(
      "http://www.ftc.go.kr/info/bizinfo/communicationViewPopup.jsp?wrkr_no=1618700607",
      "kftcInfo",
      "modal,top=10,left=10,width=500,height=450"
    );
  }, []);
  const withoutFooter = ["/cart", "/order", "/coupons", "/order/coupon"].includes(historyContext.location.pathname);
  if (withoutFooter) return null;

  return (
    <footer className="footer">
      <div className="footer__infoTab">
        <span>
          <a href={LINK_TO_TERMS} target="_blank" rel="noopener noreferrer">
            이용약관
          </a>
        </span>
        <span>
          <a href={LINK_TO_PRIVACY} target="_blank" rel="noopener noreferrer">
            개인정보처리방침
          </a>
        </span>
        <span>
          <a href={LOOKPIN_STORE} target="_blank" rel="noopener noreferrer">
            입점안내
          </a>
        </span>
        <span>
          <a href={LINK_TO_USE_OF_PRIVACY} target="_blank" rel="noopener noreferrer">
            개인정보 제공 내용
          </a>
        </span>

        <span>
          <a href={LINK_TO_MARKETING} target="_blank" rel="noopener noreferrer">
            이벤트 및 프로모션 문자(SMS) 수신 및 활용 방침
          </a>
        </span>
      </div>
      <div className="footer__block">
        <div className="footer__title">(주)레벨13</div>
        <div className="footer__content__wrapper">
          <span className="footer__content">
            사업자 등록 번호: 161-87-00607&nbsp;
            <span
              role="button"
              tabIndex={0}
              className="ftcInfo"
              onClick={handleFTCinfoModal}
              onKeyDown={handleFTCinfoModal}
            >
              [사업자 정보확인]
            </span>
            &nbsp; | 통신판매업: 제 2020-서울강남-01989호 | 주소: 서울특별시 강남구 테헤란로34길 6, 3층 1,2호(역삼동, 태광타워) |
            전화번호: 070-5165-7198
          </span>
          <span className="footer__content">대표이사: 김종현, 권순영 | 개인정보담당자: 오경식(privacy@lookpin.co.kr)</span>
          <span className="footer__content">
            (주)레벨13은 통신판매중개자이며 통신판매의 당사자가 아닙니다. 따라서 (주)레벨13은 상품거래정보 및 거래에
            대한 책임을 지지 않습니다.
          </span>
        </div>
      </div>
      <div className="footer__block">
        <div className="footer__title">고객센터</div>
        <div className="footer__content__wrapper">
          <a className="footer__linker" href={LOOKPIN_KAKAO_LINK} target="_blank" rel="noopener noreferrer">
            카카오톡
          </a>
          <Link className="footer__linker" to="/notice">
            공지사항
          </Link>
          <Link className="footer__linker" to="/FAQ">
            자주묻는질문
          </Link>
        </div>
      </div>

      <span className="footer__content" />
    </footer>
  );
};

export default Footer;
