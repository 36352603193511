import { fetcher } from "utils";

import { OrderSerialized, IPage, IPaginationWithHeader } from "typings";

export function get__members__orders(params: { page?: IPage }): Promise<IPaginationWithHeader<OrderSerialized[]>> {
  return fetcher.get("/members/orders", params);
}

type StringHTMLType = string;

export function get__members__orders__views_v3(url: string): Promise<StringHTMLType> {
  return fetcher.get(url);
}
