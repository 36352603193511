/*
 * 서버의 시리얼라이저를 통해 생성되는 베이스 타입들은 이곳에 모아둠
 * 서버에서 주는 객체 프로퍼티가 바뀌지 않는 이상 건드리지 말기
 * 즉, API로부터 받는 데이터에 대한 타입을 서버의 시리얼라이저와 맞춰버렸음.
 * 따라서 레거시가 될 확률이 크므로, 추후 ~serialized타입을 모두 지우고 api 문서에 따라서만 타입을 맞추도록 수정하면
 * ~serialized 네이밍에 대한 규칙을 제거할 수 있겠음...
 */

import {
  GenderPartner,
  ProductStatus,
  IAddress,
  StoreGroupId,
  IPartner,
  PayMethodKo,
  FieldStatus,
  StatusText,
  INextStatus,
  GenderMember,
  Provider,
  IOrderProductReviews,
  ICremaProductReviews,
  IPolicyPhotoUrls,
  Gender,
  ProductQuestionType
} from "typings";
import { ShippingInfoFromPartner } from "./viewModel";

export interface ProductSerialized {
  discount_price: null | number;
  discount_rate: null | string;
  gender_id: GenderPartner;
  id: number;
  is_lookpin_pay: boolean;
  name: string;
  photo_url: string;
  prev_price: number;
  price: number;
  shipping_speed: "fast" | "slow" | null;
  sold_out: boolean;
  status: ProductStatus;
  store: StoreSerialized;
}

export interface NewProductSerialized {
  id: number;
  name: string;
  discount_price: null | number;
  gender_id: GenderPartner;
  status: ProductStatus;
  price: number;
  prev_price: number;
  discount_rate: null | string;
  is_lookpin_pay: boolean;
  shipping_speed: "fast" | "slow" | null;
  sold_out: boolean;
  photo_url: string;
  store: StoreSerialized;
  info: PartnersProductInfoSerialized | null;
}

export interface MatchingProductSerialized {
  id: number;
  name: string;
  discount_price: number;
  gender_id: Gender;
  status: ProductStatus;
  price: number;
  prev_price: number;
  discount_rate: string;
  is_lookpin_pay: boolean;
  shipping_speed: "fast" | "slow" | null;
  sold_out: boolean;
  photo_url: string;
  store: {
    id: number;
    name: string;
    photo_url: string;
    rank: number;
    prev_rank: number;
    group: "default" | "brand";
    message: string | null;
    gender_id: Gender;
    refund_info: {
      address: string;
      zipcode: string;
      phone_number: string;
      collect_delivery_cost: string;
      prepaid_delivery_cost: string;
    };
    pick_up_info: {
      cusCde: string;
      comDivCde: string;
      post_userId: string;
      delivery_company_code: string;
    };
    use_auto_pick_up: boolean;
    extra_info: {
      ceo: string;
      email: string;
      company_name: string;
      business_hours: string;
      mail_order_number: string;
      company_registration_number: string;
    };
    coordi_limit: number;
    tags: string[];
    serialized_tags: string;
    is_lookpin_pay: boolean;
    crema_brand_id: string;
    is_new: boolean;
  };
  url: string | null;
  store_id: number;
}

export interface ShippingInfoSerialized {
  id: number;
  name: string;
  phone_number: string;
  address: IAddress;
  message: null | string;
}

enum TagSerializedCategory {
  type_tags = 1,
  age_tags,
  style_tags,
  eng_name_tag
}

interface TagSerialized {
  id: number;
  name: string;
  category: TagSerializedCategory;
}

export interface StoreSerialized {
  id: number;
  name: string;
  photo_url: string; // null일 수 있지만 img 태그와의 호환성을 고려하여 string 처리
  rank: null | number;
  prev_rank: number;
  group: StoreGroupId;
  message: null | string;
  coordi_limit?: number;
  refund_info: null | {
    address: string;
    zipcode: string;
    phone_number: string;
    collect_delivery_cost: string;
    prepaid_delivery_cost: string;
  };
  pick_up_info: null | {
    cusCde: string;
    comDivCde: string;
    post_userId: string;
    delivery_company_code: string;
  };
  use_auto_pick_up: boolean;
  extra_info: {
    ceo: string;
    email: string;
    company_name: string;
    business_hours: string;
    mail_order_number: string;
    company_registration_number: string;
  };
  gender_id: GenderPartner;
  tags?: TagSerialized[];
  serialized_tags?: string;
  is_lookpin_pay?: boolean;
  crema_brand_id?: null | string;
  is_new?: boolean;
}

export interface StoreDetailSerialized extends StoreSerialized {
  url: string;
  users_liked_cnt: number;
  categories: ["아우터", "상의", "하의", "신발", "가방/ACC"];
  recently_updated_at: null | string;
  partner: {
    id: IPartner;
    extra_info: IPartner["extra_info"];
  };
}

export interface UsersStoreSerialized extends StoreSerialized {
  new_products_cnt: number;
  is_liked: boolean;
}

export interface CoordinationUniqueTagSerialized {
  id: number;
  title: string;
  background_color: string;
}

export interface CoordinationSerialized {
  id: number;
  title: string;
  photo_url: string;
  description: null | string;
  gender_id: GenderPartner;
  store_id: number;
  is_liked: boolean; // deprecated
  like_counts: number;
  tags: CoordinationUniqueTagSerialized[];
}

export interface CoordinationDetailSerialized extends CoordinationSerialized {
  products: ProductSerialized[];
}

export interface NewCoordinationDetailSerialized extends CoordinationSerialized {
  products: NewProductSerialized[];
}

export interface PartnerSerialized {
  id: number;
  extra_info: { info?: [string, string][] };
  is_auto_thumbnail: boolean;
  coordi_limit: number;
  store: StoreSerialized;
}

export interface ProductDetailSerialized extends ProductSerialized {
  url: string;
  store_id: number;
}

export interface ProductCategorySerialized {
  id: number;
  division1: string;
  division2: string;
  gender_id: string;
  ordering: number;
  image_url: null | string;
}

export interface PartnersProductInfoSerialized {
  id: number;
  extra_info: {
    info: [string, string][];
    notice: string;
  };
  images: {
    detail: [];
    main: string[];
  };
  category: null | ProductCategorySerialized;
  html: string;
  options: {
    type: "single" | "multiple";
    names: string[];
    contents: string[][];
    data: Array<{
      id: number;
      positions: string[];
      data: {
        stock_count: number;
        option_price: number;
      };
    }>;
  };
}

export interface PartnersProductSerialized extends ProductSerialized {
  provider_note: string;
  info: PartnersProductInfoSerialized; // 예전 크롤링 상품이어야만 info가 null로 올 수 있음
  is_on_event_sale?: boolean;
}

export interface DiscountCouponSerialized {
  id: number;
  name: string;
  description: string;
  image_url: string;
  discount_type: "rate" | "flat";
  discount_value: number;
  discount_price_limit_lte: number;
  usable_product_options_count_type: "single" | "multiple";
  usable_price_limit_gte: number;
  usable_hours_from_issue: number;
  issue_limit_per_member: number;
  issue_total_limit: number;
  issuable_time_gte: string;
  issuable_time_lte: string;
}

export interface IssuedDiscountCouponSerialized {
  id: number;
  usable_at_limit_lte: string;
  created_at: string;
  old_discount_coupon: DiscountCouponSerialized;
  is_used: boolean;
}

export interface EventBannerSerialized {
  id: number;
  gender_id: string;
  store_group_id: string | null;
  is_show: boolean;
  ordering: number;
  image: string;
  redirect_url: string;
}

export interface ProductOptionSerialized {
  id: number;
  positions: string[];
  data: {
    is_show: boolean;
    stock_count: number;
    option_price: number;
  };
  selected_count: number;
}

export interface CartSerialized {
  id: number;
  option: ProductOptionSerialized;
  product: PartnersProductSerialized;
}

export interface OrderProductContactSerialized {
  id: number;
  contents: string;
  created_at: string;
  order_product: {
    order_num: string;
    product: {
      id: ProductSerialized["id"];
      name: ProductSerialized["name"];
      photo_url: ProductSerialized["photo_url"];
    };
  };
  response?: {
    id: number;
    contents: string;
    created_at: string;
    store: {
      id: StoreSerialized["id"];
      name: StoreSerialized["name"];
    };
  };
}

export interface OrderSerialized {
  created_at: string;
  pay_method_text: PayMethodKo;
  order_num: string;
  order_products: OrderProductSerialized[];
  shipping_info: ShippingInfoSerialized;
}

export interface OrderDetailSerialized extends OrderSerialized {
  order_products: OrderProductDetailSerialized[];
}
export interface OrderStatusSerialized {
  id: number;
  created_at: string;
  status: FieldStatus;
  text: StatusText;
  partner_text: null | string;
}

export interface OrderStatusDetailSerialized extends OrderStatusSerialized {
  extra_info: { reason?: string; message?: string; action_by?: "partner" | "pay" };
  next_statuses: INextStatus[];
}

export interface OrderProductSerialized {
  shipping_info_from_partner: ShippingInfoFromPartner;
  order_num: string;
  paid_price: number;
  product: ApiV1ProductSerialized;
  option: ProductOptionSerialized;
  current_status: OrderStatusDetailSerialized;
}

export interface OrderProductDetailSerialized extends OrderProductSerialized {
  refund_price_message: null | {
    price: number;
    order_point: number;
  };
  total_price: number;
  total_prev_price: number;
}
export interface MemberSerialized {
  id: number;
  email: string;
  gender_id: GenderMember;
  token: string;
  shipping_infos: ShippingInfoSerialized[];
  connected_social_accounts: Provider[];
  has_password: boolean;
  phone_number: null | string;
  confirm_marketing_sms_at: string | null;
}

export interface IBasePhotoReviews {
  type: string;
  data: IOrderProductReviews | ICremaProductReviews;
}

export interface IOrderProductNextStatus {
  text: string;
  action_type: string;
  btn_text: string;
  color: string;
  extra_info: {
    message: string;
  };
  status: string;
}

export interface IOrderProductHistoryNextStatus {
  text: string;
  partner_text: string;
  action_type: string;
  btn_text: string;
  color: string;
  extra_info: {
    title: string;
    reasons: string[];
    policy_photo_urls: IPolicyPhotoUrls;
    refund_account: {
      banks: string[];
    };
  };
  status: string;
}
export interface IOrderProductCurrentStatus {
  id: number;
  created_at: string;
  status: string;
  partner_text: string | null;
  text?: string;
  extra_info: {
    action_by: string;
  };
  next_statuses: IOrderProductNextStatus[];
}

export interface IOrderBank {
  /* 가상계좌 정보 */
  vbank_info: {
    name: string;
    num: string;
    amount: number;
    holder: string;
    date: string | null;
  };
  order_num: string;
}

export interface IVbankInfo {
  name: string;
  amount: number;
  /** v1 imp 이용 시 */
  date: string;
  /** v3 tosspayments 이용 시 */
  data?: string;
  holder: string;
  num: string;
}
export interface IOrderProductDetailOrder {
  total_prev_price: number;
  total_price: number;
  consumed_point: number;
  discount_coupon_price: number;
  paid_price: number;
  products: {
    id: number;
    name: string;
    gender_id: string;
    status: string;
    price: number;
    prev_price: number;
    extra_info: {
      info: Array<[string, string]>;
      notice: string;
    };
    images: {
      main: Array<string>;
      detail: Array<string>;
    };
  };
  vbank_info: IVbankInfo | null;
  order_num: string;
  created_at: string;
  paid_at: string;
  pay_status: string;
  pay_method_detail: string;
  order_products_count: number; // 주문 내 총 주문상품 갯수
}

export interface OrderHistoryProductInterface {
  id: number;
  name: string;
  gender_id: GenderPartner;
  status: string;
  price: number;
  prev_price: number;
  store: StoreSerialized;
  info: {
    id: number;
    extra_info: {
      info: [string, string][];
      notice: string;
    };
    images: {
      detail: [];
      main: string[];
    };
  };
}
export interface IOrderProductHistorySerialized {
  total_prev_price: number; // 원가
  total_price: number;
  consumed_point: number;
  discount_coupon_price: number;
  is_review_creatable: boolean;
  paid_price: number; // 결제금액
  product: {
    id: number;
    name: string;
    store: {
      id: number;
      name: string;
      extra_info: {
        ceo: string;
        email: string;
        company_name: string;
        company_registration_number: string;
        mail_order_number: string;
      };
      refund_info: {
        address: string;
        collect_delivery_cost: string;
        phone_number: string;
        zipcode: string;
      };
    };
    images: {
      main: string[];
    };
  }; //
  option: ProductOptionSerialized;
  order_num: string;
  current_status: IOrderProductCurrentStatus;
  process_group: string;
  process?: string;
  shipping_info_from_partner?: ShippingInfoFromPartner; // 배송 추적
  shipping_info: ShippingInfoSerialized;
  order: IOrderProductDetailOrder;
  exist_reviews: {
    simple: boolean;
    content: boolean;
    photo: boolean;
  };
  review: null | {
    id: number;
    type: {
      simple: boolean;
      content: boolean;
      photo: boolean;
    };
  };
}

export interface IOrderProductHistoryDetailSerialized {
  total_prev_price: number; // 원가
  total_price: number;
  consumed_point: number;
  discount_coupon_price: number;
  is_review_creatable: boolean;
  paid_price: number; // 결제금액
  product: {
    id: number;
    name: string;
    store: {
      id: number;
      name: string;
      extra_info: {
        ceo: string;
        email: string;
        company_name: string;
        company_registration_number: string;
        mail_order_number: string;
      };
      refund_info: {
        address: string;
        collect_delivery_cost: string;
        phone_number: string;
        zipcode: string;
      };
    };
    images: {
      main: string[];
    };
  };
  option: ProductOptionSerialized;
  order_num: string;
  current_status: IOrderProductCurrentStatus;
  process_group: string;
  process?: string;
  shipping_info_from_partner?: ShippingInfoFromPartner; // 배송 추적
  shipping_info: ShippingInfoSerialized;
  order: IOrderProductDetailOrder;
  exist_reviews: {
    simple: boolean;
    content: boolean;
    photo: boolean;
  };
  pick_up_address: {
    main: string;
    detail: string;
    zipcode: string;
  } | null;
  auto_pick_up?: boolean;
  review: null | {
    id: number;
    type: {
      simple: boolean;
      content: boolean;
      photo: boolean;
    };
  };
}

export interface IPageMeta {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
}
export interface IOrderProductSummary {
  pay: number; // 입금/결제
  claim: number; // 취소/교환/환불
  ship_wait: number; // 배송준비중
  ship_ing: number; // 배송중
  ship_done: number; // 배송완료
  latest_filter_group: "claim" | "pay" | "ship_wait" | "ship_ing" | "ship_done"; // 하이라이트 해야 할 부분
}

export interface IOrderProductDetail extends IOrderProductHistorySerialized {
  consumed_point: number;
  discount_coupon_price: number;
  order: IOrderProductDetailOrder;
}

export interface IMemberOrderProductsContacts {
  id: number;
  contents: string;
  created_at: string;
  order_product: {
    order_num: string;
    option: {
      id: number;
      positions: Array<string>;
      data: {
        stock_count: number;
        option_price: number;
      };
      selected_count: number;
    };
    product: {
      id: number;
      name: string;
      photo_url: string;
    };
  };
  response: {
    id: number;
    contents: string;
    created_at: string;
    store: {
      id: number;
      name: string;
    };
  };
}

export interface IMemberOrderProductsContactsMeta {
  current_page: number | null;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number | null;
  total_count: number | null;
}

export interface IProductQuestions {
  id: number;
  typeof: ProductQuestionType;
  created_at: string;
  my_unread: boolean;
  contents: string;
  hidden: boolean;
  is_mine: boolean;
  member: {
    id: number;
    level: number;
    email: string;
  };
  store: {
    id: number;
    name: string;
  };
  answer: {
    id: number;
    contents: string;
    updated_at: string;
  } | null;
  product?: {
    id: number;
    name: string;
    photo_url: string | null;
  };
}

export interface EmailSignUpSerialized {
  id: number;
  email: string;
  gender_id: Gender;
  phone_number: null | string;
  confirm_marketing_sms_at: null | string;
  token: string;
  shipping_infos: [];
  has_password: true;
  connected_social_accounts: [];
}

export interface NoticeSerialized {
  id: number;
  title: string;
  html_url: string;
  is_popup: boolean;
  is_show: boolean;
  is_top_fix: boolean;
  created_at: string;
}

export interface FAQSerialized {
  id: number;
  title: string;
  html_url: string;
  is_show: boolean;
  category: string;
  platform: "app" | "web" | "all";
  created_at: string;
}

export interface ApiV1ProductSerialized extends ProductSerialized {
  id: number;
  name: string;
  gender_id: GenderPartner;
  provider_note: null | string;
  event_price: null | string;
  created_at: string;
  updated_at: string;
  is_on_event_sale: boolean;
  status: ProductStatus;
  price: number;
  prev_price: number;
  discount_rate: null | string;
  is_lookpin_pay: boolean;
  shipping_speed: "fast" | "slow" | null;
  status_text: "판매 대기" | "판매" | "판매 중지" | "품절";
  store: StoreSerialized;
  info: null | PartnersProductInfoSerialized;
}

export interface ApiV1CoordiSearchSerialized {
  id: number;
  title: string;
  photo_url: string;
  description: string | null;
  gender_id: GenderPartner;
  store_id: number;
  like_counts: number;
  tags: CoordinationUniqueTagSerialized[];
}
