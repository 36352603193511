import React, { FC } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames/bind";
import qs from "qs";

import { imageResizeHelper } from "utils";

import { IconThumb } from "assets/icon";

import { ICoordiViewModel } from "typings";

import styles from "./CoordiCard.module.scss";

const cx = classnames.bind(styles);

interface IProps {
  id: ICoordiViewModel["id"];
  photoUrl: ICoordiViewModel["photoUrl"];
  title: ICoordiViewModel["title"];
  tags: ICoordiViewModel["tags"];

  likeCounts: ICoordiViewModel["likeCounts"];
  isLiked: boolean;
  toggleLike: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const CoordiCard: FC<IProps> = ({ id, photoUrl, title, tags, toggleLike, isLiked, likeCounts }) => {
  return (
    <div className={cx("card")}>
      <div className={cx("thumbnail")}>
        <Link to={`/coordi/${id}`}>
          <picture className={cx("picture")}>
            <img src={imageResizeHelper(photoUrl, 270)} className={cx("img")} alt="coordi" />
          </picture>
        </Link>

        <button onClick={toggleLike} data-id={id} data-title={title} className={cx("like", { isLiked })} type="button">
          <IconThumb />
          &nbsp;<span className={cx("like-counts")}>{likeCounts + Number(isLiked)}</span>
        </button>
      </div>

      <Link to={`/coordi/${id}`}>
        <div className={cx("title")}>{title}</div>
      </Link>

      <div className={cx("tags")}>
        {tags.map(tag => (
          <Link
            to={`/coordi${qs.stringify({ searchKeywords: [tag] }, { addQueryPrefix: true })}`}
            className={cx("tag")}
            key={tag}
          >
            {tag}
          </Link>
        ))}
      </div>
    </div>
  );
};
