import isEmail from "validator/lib/isEmail";

import { curry, isThereOnlyFalsyValueInObject } from "utils";

import { isProperPassword } from "utils/isProperPassword";

export const formChecker = curry((setFormFailure, { email, password, passwordConfirm, passwordCurrent }) => {
  const formFailure = {};

  if (email != null && !isEmail(email)) formFailure.UI__FORM_FAILURE_EMAIL_INVALID = true;

  if (password != null && !isProperPassword(password)) formFailure.UI__FORM_FAILURE_PASSWORD_INVALID = true;

  if (passwordConfirm != null) {
    if (!isProperPassword(passwordConfirm)) {
      formFailure.UI__FORM_FAILURE_PASSWORD_CONFIRM_INVALID = true;
    } else if (password !== passwordConfirm) {
      formFailure.UI__FORM_FAILURE_PASSWORD_NOT_EQUAL = true;
    }
  }

  if (passwordCurrent === "") {
    formFailure.UI__FORM_FAILURE_PASSWORD_CURRENT_NOT_CORRECT = true;
  } else if (passwordCurrent != null && password === passwordCurrent && isThereOnlyFalsyValueInObject(formFailure)) {
    formFailure.UI__FORM_FAILURE_PASSWORD_NOT_CHANGE = true;
  }

  setFormFailure(formFailure);

  return isThereOnlyFalsyValueInObject(formFailure);
});
