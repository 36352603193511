import React, { FC, useMemo } from "react";
import { ClaimAcceptIcon, ClaimFinIcon, ClaimPickUpIcon, ClaimReceiptIcon, ClaimShippingIcon } from "assets/icon";
import classNames from "classnames/bind";
import styles from "./ClaimIcon.module.scss";

interface IProp {
  keyData: string;
  isHighLight: boolean;
  text: string;
}
const cx = classNames.bind(styles);
export const ClaimIcon: FC<IProp> = ({ keyData, isHighLight, text }) => {
  const iconKey = useMemo(() => {
    if (/soon/.test(keyData)) return "soon";
    if (/wait/.test(keyData)) return "wait";
    if (/done/.test(keyData)) return "done";
    if (/pickup/.test(keyData)) return "pickup";
    return keyData;
  }, [keyData]);

  const iconMap = useMemo(
    () => ({
      soon: ClaimAcceptIcon,
      wait: ClaimReceiptIcon,
      done: ClaimFinIcon,
      pickup: ClaimPickUpIcon,
      re_ship: ClaimShippingIcon
    }),
    []
  );

  const Icon = iconMap[iconKey];
  return (
    <div className={cx("wrapper")}>
      <div className={cx("icon-wrapper", { highLight: isHighLight })}>
        <Icon />
      </div>
      <div className={cx("title", { highLight: isHighLight })}>{text}</div>
    </div>
  );
};
