import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames/bind";

import { PreviousPageMobile } from "components";

import { sync, modalOff } from "actions/actionCreators";

import callPostcode from "services/daum";

import { IStore } from "typings";

import stylesWrapper from "../../ModalWrapper.module.scss";
import styles from "./PickUpAddress.module.scss";

const cxWrapper = classNames.bind(stylesWrapper);
const cx = classNames.bind(styles);

export const PickUpAddress = () => {
  const dispatch = useDispatch();
  const address = useSelector<IStore, IStore["pickUpAddress"]>(({ pickUpAddress }) => pickUpAddress);

  const [main, setMain] = useState(address?.main);
  const [detail, setDetail] = useState(address?.detail);
  const [zipcode, setZipcode] = useState(address?.zipcode);

  const setAddress = useCallback((zipcode, main) => {
    setZipcode(zipcode);
    setMain(main);
  }, []);
  const handleClick = () => callPostcode(setAddress);
  const handleDetail = event => setDetail(event.target.value);
  const handleCancel = () => dispatch(modalOff());
  const handleSubmit = () => {
    const trimDetail = detail.trim();

    dispatch(sync({ pickUpAddress: { main, detail: trimDetail, zipcode } }));
    dispatch(modalOff());
  };

  return (
    <div className={cxWrapper("container")}>
      <header className={cxWrapper("header")}>
        <div className={cx("title")}>
          <PreviousPageMobile />
          배송지 변경
        </div>
      </header>
      <ul className={cx("pick-up-address")}>
        <li className={cx("zipcode")}>
          <input value={zipcode} type="text" disabled />

          <button onClick={handleClick} className={cx("find")} type="button">
            주소검색
          </button>
        </li>

        <li className={cx("address")}>
          <input value={main} type="text" disabled />
        </li>

        <li className={cx("detail")}>
          <input value={detail} onChange={handleDetail} type="text" />
        </li>
      </ul>

      <div className={cx("actions")}>
        <button onClick={handleCancel} type="button" className={cx("cancel")}>
          취소
        </button>

        <button onClick={handleSubmit} type="button" className={cx("submit")}>
          저장
        </button>
      </div>
    </div>
  );
};
