import * as api from "api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { EXOIRATION_TIME, NOTICE } from "constant";
import { numberCheck } from "utils";
import { AgreeMentChangeKeys, NumberAuthCallBackFunction, SignUpAgreeMent } from "typings";

export const useNumberAuth = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>("010");
  const [authNumber, setAuthNumber] = useState<string>("");
  const [isSendNumber, setIsSendNumber] = useState<boolean>(false);
  const [isExpire, setIsExpire] = useState<boolean>(false);
  const [timeCount, setTimeCount] = useState(EXOIRATION_TIME);
  const [fixPhoneNumber, setFixPhoneNumber] = useState<string>("");
  const [agreeMent, setAgreeMent] = useState<SignUpAgreeMent>({
    upperFourteen: false,
    serviceAgree: false,
    privacyAgree: false,
    usePrivacyAgree: false,
    marketingAgree: false
  });
  const necessaryAgreeMent = useMemo(() => {
    const { upperFourteen, serviceAgree, privacyAgree, usePrivacyAgree } = agreeMent;
    return upperFourteen && serviceAgree && privacyAgree && usePrivacyAgree;
  }, [agreeMent]);

  const [isTermAgree, setTermAgree] = useState(false);
  const [isAuthDone, setIsAuthDone] = useState(false);
  const sendNumberDisabled = useMemo(() => phoneNumber.length !== 11, [phoneNumber]);

  const timeCountRef = useRef<number>(EXOIRATION_TIME);
  const intervalTime = useRef<NodeJS.Timeout>();

  const clearIntervalTime = useCallback(() => {
    intervalTime.current && clearInterval(intervalTime.current);
  }, []);

  useEffect(() => {
    return () => {
      clearIntervalTime();
    };
  }, [clearIntervalTime]);

  const phoneNumberChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event;

    const isOnlyNum = numberCheck(value);
    !isOnlyNum && alert("숫자만 입력 가능합니다");
    if (isOnlyNum && value.length <= 11) {
      setPhoneNumber(value);
    }
  }, []);

  const authNumberChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event;

    const isOnlyNum = numberCheck(value);

    if (isOnlyNum && value.length <= 4) {
      setAuthNumber(value);
    }
  }, []);

  const timeCountDown = useCallback(() => {
    timeCountRef.current--;
    if (timeCountRef.current < 0) {
      clearIntervalTime();
      setIsExpire(true);
      alert(NOTICE.NUMBER_AUTH_EXPIRE);
    } else {
      setTimeCount(timeCountRef.current);
    }
  }, [clearIntervalTime]);

  const sendAuthNumber = useCallback(async () => {
    if (phoneNumber.length < 11) {
      alert(NOTICE.PHONE_NUMBER_CHECK);
      return;
    }

    timeCountRef.current = EXOIRATION_TIME;
    clearIntervalTime();
    intervalTime.current = setInterval(timeCountDown, 1000);

    await api.postSMSAuth({ phone_number: phoneNumber });
    alert(NOTICE.SENDING_AUTH_NUMBER);

    setFixPhoneNumber(phoneNumber);
    setIsSendNumber(true);
    setIsExpire(false);
    setIsAuthDone(false);
    setAuthNumber("");
  }, [timeCountDown, clearIntervalTime, phoneNumber]);

  const submitFormEvent = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (authNumber.length === 4) {
        await api.postSMSAuthVerify({ phone_number: fixPhoneNumber, verification_code: authNumber });
        alert(NOTICE.AUTH_SUCCESS);
        setIsAuthDone(true);
        clearIntervalTime();
      } else {
        alert("인증번호 4자리를 입력해주세요");
      }
    },
    [clearIntervalTime, fixPhoneNumber, authNumber]
  );

  const doneClickEvent = useCallback(
    async (callbackFunction?: NumberAuthCallBackFunction) => {
      if (!necessaryAgreeMent) {
        alert(NOTICE.MOST_TERMS_OF_USE_AGREE);
        return;
      }
      callbackFunction && callbackFunction({ phoneNumber: fixPhoneNumber, marketingAgree: agreeMent.marketingAgree });
    },
    [fixPhoneNumber, necessaryAgreeMent, agreeMent]
  );

  const termAgreeCheck = useCallback((agreeKey: AgreeMentChangeKeys) => {
    switch (agreeKey) {
      case "allAgree":
        setAgreeMent({
          upperFourteen: true,
          serviceAgree: true,
          privacyAgree: true,
          usePrivacyAgree: true,
          marketingAgree: true
        });
        break;
      case "allDisAgree":
        setAgreeMent({
          upperFourteen: false,
          serviceAgree: false,
          privacyAgree: false,
          usePrivacyAgree: false,
          marketingAgree: false
        });
        break;
      default:
        setAgreeMent(prevAgreeMent => ({
          ...prevAgreeMent,
          [agreeKey]: !prevAgreeMent[agreeKey]
        }));
        break;
    }
  }, []);

  return {
    phoneNumber,
    phoneNumberChange,
    sendAuthNumber,
    isSendNumber,
    authNumber,
    authNumberChange,
    submitFormEvent,
    timeCount,
    isExpire,
    setTermAgree,
    isAuthDone,
    doneClickEvent,
    sendNumberDisabled,
    isTermAgree,
    fixPhoneNumber,
    timeCountDown,
    necessaryAgreeMent,
    termAgreeCheck,
    agreeMent,
    setAuthNumber
  };
};
