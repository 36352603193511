import React, { FC } from "react";

import "./PointModal.scss";

export const PointModal: FC<{ price: number }> = ({ price }) => (
  <div className="modal__points">
    <ul>
      <li className="modal__points--header">
        <span className="modal__points--level">레벨</span>
        <span className="modal__points--percent">적립률</span>
        <span>적립금</span>
      </li>
      <li>
        <span id="lv1" className="modal__points--level lv">
          Lv.1 - 4
        </span>
        <span className="modal__points--percent">1%</span>
        <span>{Math.floor(price * 0.01)}원</span>
      </li>
      <li>
        <span id="lv5" className="modal__points--level lv">
          Lv.5 - 7
        </span>
        <span className="modal__points--percent">1.5%</span>
        <span>{Math.floor(price * 0.015)}원</span>
      </li>
      <li>
        <span id="lv8" className="modal__points--level lv">
          Lv.8 - 10
        </span>
        <span className="modal__points--percent">2%</span>
        <span>{Math.floor(price * 0.02)}원</span>
      </li>
      <li>
        <span id="lv11" className="modal__points--level lv">
          Lv.11 - 12
        </span>
        <span className="modal__points--percent">2.5%</span>
        <span>{Math.floor(price * 0.025)}원</span>
      </li>
      <li>
        <span id="lv13" className="modal__points--level lv">
          Lv.13이상
        </span>
        <span className="modal__points--percent">3%</span>
        <span>{Math.floor(price * 0.03)}원</span>
      </li>
    </ul>
  </div>
);
