import React from "react";
import classNames from "classnames/bind";
import styles from "./ReviewWriteFooter.module.scss";

const cx = classNames.bind(styles);
export const ReviewWriteFooter = () => {
  return (
    <ul className={cx("wrapper")}>
      <li>작성한 리뷰는 리뷰작성일로부터 2개월 이내에 수정할 수 있습니다.</li>
      <li>상품이 판매중지 된 경우 리뷰 작성이 불가할 수 있습니다.</li>
      <li>단순 문자 및 기호 나열/반복된 리뷰는 통보 없이 삭제될 수 있습니다.</li>
      <li>
        포토리뷰는 직접 촬영한 사진이어야 하며, 옷의 형태를 알아보기 어려운 사진(저화질 및 흔들린 사진, 상품이 절반 이상
        보이지 않는 경우 등)은 통보 없이 삭제될 수 있습니다. (불량 및 제품불만 제외)
      </li>
      <li>상품과 무관한 사진으로 포토리뷰를 작성했을 경우 작성된 리뷰는 통보 없이 삭제될 수 있습니다.</li>
      <li>
        리뷰가 삭제되는 경우 적립된 리뷰 작성 적립금도 회수됩니다. 리뷰가 삭제된 상품의 리뷰를 다시 작성해도 적립금이
        다시 지급되지 않습니다.
      </li>
      <li>작성된 리뷰는 룩핀 홍보 컨텐츠로 사용될 수 있습니다.</li>
    </ul>
  );
};
