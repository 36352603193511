import React, { FC } from "react";
import classNames from "classnames/bind";
import { getTrackBackground, Range } from "react-range";
import { IRangeParam } from "typings";
import styles from "./RangeInput.module.scss";

const STEP = 0.01;
const MIN = 0;
const MAX = 1;
interface IProp {
  range: IRangeParam;
  setRange: (props: IRangeParam) => void;
}

const cx = classNames.bind(styles);
export const RangeInput: FC<IProp> = ({ range, setRange }) => {
  return (
    <div>
      <Range
        values={[range.gte, range.lte]}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={([gte, lte]) => {
          setRange({ lte, gte });
        }}
        renderTrack={({ props, children }) => (
          <div
            role="button"
            tabIndex={0}
            onTouchStart={props.onTouchStart}
            onMouseDown={props.onMouseDown}
            className={cx("track-wrapper")}
          >
            <div
              ref={props.ref}
              className={cx("track")}
              style={{
                background: getTrackBackground({
                  values: [range.gte, range.lte],
                  colors: ["#ccc", "#548BF4", "#ccc"],
                  min: MIN,
                  max: MAX
                })
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => <div {...props} style={props.style} className={cx("oval")} />}
      />
    </div>
  );
};
