import { fetcher } from "utils";

type GetProductsOrderPointsByLevel = (params: {
  productIds: Array<{
    productId: number | string;
    optionId?: number | string;
  }>;
  discountPrice?: number;
}) => Promise<{
  point: number;
  level: number;
}>;

export const get__products__order_points__by_level: GetProductsOrderPointsByLevel = params =>
  fetcher.get("/products/order_points/by_level", params);
