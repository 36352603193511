import { getDomain } from "./domain";

export const getCookie = (key: string) => {
  let cookieValue: string | null = null;
  if (document.cookie) {
    const array = document.cookie.split(`${escape(key)}=`);
    if (array.length >= 2) {
      const arraySub = array[1].split(";");
      cookieValue = unescape(arraySub[0]);
    }
  }
  return cookieValue;
};

export const setCookie = ({
  key,
  value,
  expireSeconds,
  path,
  domain,
  secure
}: {
  key: string;
  value: string;
  expireSeconds?: number;
  path?: string;
  domain?: string | null;
  secure?: boolean;
}) => {
  let cookieText = `${escape(key)}=${escape(value)}`;
  let expire: null | Date = null;
  if (expireSeconds) {
    const date = new Date();
    date.setTime(date.getTime() + expireSeconds * 1000);
    expire = date;
  }
  cookieText += expire ? `; EXPIRES=${expire.toUTCString()}` : "";
  cookieText += path ? `; PATH=${path}` : "";
  cookieText += domain ? `; DOMAIN=${domain}` : "";
  cookieText += secure ? "; SECURE" : "";
  document.cookie = cookieText;
};

export const deleteCookie = (cookieName, isDomainSetting = true) => {
  const temp = getCookie(cookieName);
  const domain = isDomainSetting ? getDomain(process.env.REACT_APP_ENV) : null;
  if (temp) {
    setCookie({ key: cookieName, value: temp, expireSeconds: 0.1, domain });
  }
};
