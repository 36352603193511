import { fetcher } from "utils";

import { IJSendSuccessResponse, Gender } from "typings";

type IGetStoresStoreIdCategoriesResponse = IJSendSuccessResponse<{
  categories: {
    name: string;
    count: number;
  }[];
}>;

export const get__stores__store_id__categories = ({
  id,
  genderId
}: {
  id: string | number;
  genderId: Gender;
}): Promise<IGetStoresStoreIdCategoriesResponse> => fetcher.get(`/stores/${id}/categories`, { genderId });
