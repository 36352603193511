import React from "react";
import { useSelector } from "react-redux";
import { IStore } from "typings";
import { levelInfoDesktop } from "assets/img";
import classNames from "classnames/bind";
import styles from "./Level.module.scss";

const cx = classNames.bind(styles);

export function Level() {
  const { color, level, pointsToNextLevel, currentLevelInfo } = useSelector<IStore, IStore["orderPoints"]>(
    ({ orderPoints }) => orderPoints
  );

  return (
    <main className={cx("level-tab")}>
      <header className={cx(["bold"], "title")}>레벨</header>

      <section className={cx("current")}>
        <div className={cx("title")}>
          현재 레벨 <span className={cx("graylight")}>(다음 레벨까지 {pointsToNextLevel}점 남음)</span>
        </div>
        <div className={cx(["bold"], "level")} style={{ color }}>
          Lv.{level}
        </div>
        <div className={cx("info")}>
          <ul>
            {currentLevelInfo.map(([key, value]) => (
              <li key={key}>
                <span className={cx("name")}>{key}</span>
                <span className={cx("desc")}>{value}</span>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className={cx("formula")}>
        <div className={cx("title")}>
          레벨 점수 공식 <span className={cx("graylight")}>(2년간 누적 구매 금액)/10 + 현재 보유 적립금</span>
        </div>
        <div className={cx("desc")}>
          - 적립금 지급 및 구매금액 합산 시점: 구매확정 시
          <br />- 적립금 유효기간: 적립 후 1년
        </div>

        <div className={cx("title", "second")}>추가 적립금</div>
        <div className={cx("desc")}>
          <span className={cx("text-indent")}>
            - 상품 구매확정 후 리뷰 남길 시 <span className={cx("bold")}>최대 1,000원의 추가 적립금</span>을 드립니다.
            (5초 리뷰 300원, 일반 리뷰 300원, 포토 리뷰 400원)
          </span>
        </div>
        <img className={cx("level-info")} src={levelInfoDesktop} alt="레벨 점수 공식" />
        <div className={cx("btm-desc")}>* 룩핀 정책에 따라 레벨 등급 기준은 예고없이 변경될 수 있습니다.</div>
      </section>
    </main>
  );
}
