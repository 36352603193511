import React, { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames/bind";
import qs from "qs";

import { TRENDING } from "constant";

import styles from "./Sidebar.module.scss";

const cx = classNames.bind(styles);

interface IProps {
  main: string;
  sub?: string;
}

export const SidebarItem: FC<IProps> = ({ main, sub }) => {
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const name = sub ? `${main} ${sub}` : main;

  const search = qs.stringify({
    params: {
      name,
      order: TRENDING,
      page: { number: 1, size: 50 }
    },
    main,
    ...(sub && { category: sub })
  });

  const selected = main === query.main && sub === query.category;

  return (
    <li className={cx(!sub ? "main" : "sub")}>
      <NavLink className={cx("category", { selected })} to={{ pathname: "/search/category", search }}>
        {sub || main}
      </NavLink>
    </li>
  );
};
