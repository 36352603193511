import { produce } from "utils";
import { IPhotoReviewState } from "typings";
import {
  PHOTO_REVIEW_PHOTO_MODAL_SET,
  PHOTO_REVIEW_FETCH_REQUEST,
  PHOTO_REVIEW_FETCH_SUCCESS,
  PHOTO_REVIEW_FETCH_FAILURE,
  PHOTO_REVIEW_SET__PHOTO_REVIEW_TOTAL_COUNT,
  PHOTO_REVIEW_INIT_REQUSET,
  PHOTO_REVIEW_DETAIL_CHANGE_BY_ARROW,
  PHOTO_REVIEW_OPEN_DETAIL_MODAL,
  PHOTO_REVIEW_CLOSE_DETAIL_MODAL,
  PHOTO_REVIEW_ONLY_CLOSE_DETAIL_MODAL
} from "constant";

const initialState: IPhotoReviewState = {
  isLoading: false,
  isMorePhotoReview: true,
  nowProductId: 0,
  photoReviewPage: 1,
  photoReviewsTotalCount: 0,
  selectedPhotoNumber: 0,
  photoReviews: [],
  photoModalDetail: null
};

export default function photoReview(state: IPhotoReviewState = initialState, { type, payload }): IPhotoReviewState {
  return produce(state, draft => {
    switch (type) {
      case PHOTO_REVIEW_ONLY_CLOSE_DETAIL_MODAL:
        draft.photoModalDetail = null;
        draft.selectedPhotoNumber = 0;
        break;

      case PHOTO_REVIEW_OPEN_DETAIL_MODAL:
        draft.selectedPhotoNumber = payload.select;
        draft.photoModalDetail = payload.reviewDetail;
        break;
      case PHOTO_REVIEW_CLOSE_DETAIL_MODAL:
        draft.photoModalDetail = null;
        draft.selectedPhotoNumber = 0;
        break;

      case PHOTO_REVIEW_PHOTO_MODAL_SET:
        draft.selectedPhotoNumber = payload.select;
        if (payload.reviewDetail) {
          draft.photoModalDetail = payload.reviewDetail;
        }
        break;

      case PHOTO_REVIEW_FETCH_REQUEST:
        draft.isLoading = true;
        break;
      case PHOTO_REVIEW_FETCH_SUCCESS:
        if (payload.length === 0) {
          draft.isMorePhotoReview = false;
        }
        draft.photoReviewPage += 1;
        draft.isLoading = false;
        draft.photoReviews = [...draft.photoReviews, ...payload];
        break;
      case PHOTO_REVIEW_FETCH_FAILURE:
        draft.isLoading = false;
        break;
      case PHOTO_REVIEW_SET__PHOTO_REVIEW_TOTAL_COUNT:
        draft.photoReviewsTotalCount = payload;
        break;
      case PHOTO_REVIEW_DETAIL_CHANGE_BY_ARROW: {
        const nextSelectNumber = draft.selectedPhotoNumber + payload;
        if (draft.photoReviews[nextSelectNumber - 1]) {
          draft.selectedPhotoNumber = nextSelectNumber;
          draft.photoModalDetail = draft.photoReviews[nextSelectNumber - 1];
        }
        break;
      }
      case PHOTO_REVIEW_INIT_REQUSET:
        return {
          ...initialState,
          isLoading: true,
          nowProductId: payload
        };
      default:
        break;
    }
  }) as any;
}
