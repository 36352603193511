export const getDomain = (env?: string) => {
  switch (env) {
    case "production":
      return ".lookpin.co.kr";
    case "development":
      return ".lookpin.co.kr";
    default:
      return null;
  }
};

export const getPreCookieKeyString = (key: string, env?: string) => {
  switch (env) {
    case "production":
      return key;
    case "development":
      return `dev_${key}`;
    default:
      return `local_${key}`;
  }
};
