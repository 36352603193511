import React, { useEffect } from "react";
import { func } from "prop-types";
import { lookpinLogoRound } from "assets/img";
import { IconThinClose } from "assets/icon";
import classNames from "classnames/bind";
import styles from "./SuggestMobileApp.module.scss";
import { Airbridge, Tracking } from "utils";

const cx = classNames.bind(styles);

export const SuggestMobileApp = ({ closeSuggestMobileApp }) => {
  if (window.location.pathname === "/2021chuseok") return null;

  useEffect(() => {
    Airbridge.setDownloads("app_download", () => Tracking.suggestMobileApp());
  }, []);

  return (
    <>
      <div className={cx("container")}>
        <div className={cx("close")}>
          <button type="button" onClick={closeSuggestMobileApp}>
            <IconThinClose />
          </button>
        </div>
        <div className={cx("box")}>
          <img className={cx("logo")} src={lookpinLogoRound} alt="lookpin-logo" />
          <div className={cx("txt")}>
            <p className="bold">앱을 설치하면 최적의 환경에서 룩핀을 이용할 수 있습니다.</p>
            <p>
              쇼핑몰, 브랜드 상품의 모든 리뷰를 확인하고
              <br />
              모든 상품을 무료배송으로 받아보세요.
            </p>
          </div>
        </div>
        <button id="app_download" type="button" className={cx("open-app-btn")}>
          앱 사용하기
        </button>
      </div>
    </>
  );
};

SuggestMobileApp.propTypes = {
  closeSuggestMobileApp: func.isRequired
};
