import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames/bind";

import styles from "./SearchBar.module.scss";

const cx = classnames.bind(styles);

interface IProps {
  keyword: string;
  changeKeyword: (event: any) => void;
  handleKeyword: () => void;
  handleEnter: ({ key, target: { name } }: any) => void;
}

export const SearchBar: FC<IProps> = ({ keyword, changeKeyword, handleKeyword, handleEnter }) => {
  const { pathname } = useLocation();

  return (
    <div className={cx("search")}>
      <input
        value={keyword || ""}
        onChange={e => {
          if (e.target.value.length <= 50) changeKeyword(e);
        }}
        onKeyPress={handleEnter}
        className={cx("input")}
        placeholder={`${pathname.includes("store") ? "스토어" : "상품"} 검색`}
        type="search"
        name="keyword"
      />

      <button onClick={handleKeyword} className={cx("submit")} type="button">
        검색
      </button>
    </div>
  );
};
