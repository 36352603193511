import React, { FC } from "react";
import classNames from "classnames/bind";
import { IOrderProductNextStatusCamel } from "typings";
import styles from "./OrderActionButton.module.scss";

interface IProp {
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  nextState: IOrderProductNextStatusCamel;
  highlight?: boolean;
}

const cx = classNames.bind(styles);

export const OrderActionButton: FC<IProp> = ({ nextState: { btnText }, onClick, highlight }) => {
  return (
    <button type="button" onClick={onClick} className={cx("wrapper", { highlight })}>
      {btnText}
    </button>
  );
};
