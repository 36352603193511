import { fetcher } from "utils";
import { IJSendSuccessResponse, IJSendFailResponse } from "typings";

type PostUserableLikeOrderProuctReviews = (param: {
  id: number;
}) => Promise<
  | IJSendSuccessResponse<null>
  | IJSendFailResponse<{
      userable_like_order_product_reviews: {};
    }>
>;
export const post__userable_like__order_product_reviews: PostUserableLikeOrderProuctReviews = param =>
  fetcher.post("/userable_like/order_product_reviews", param);
