import { camelizeKeys } from "humps";
import { newProductStatusParser, productStatusParser } from "view-model-creator";

import { INewPinViewModel, IPinViewModel, ApiV1ProductSerialized } from "typings";

export const pinsViewModelCreator = (products: ApiV1ProductSerialized[]): IPinViewModel[] =>
  products.map(({ id, sold_out, status, name, price, shipping_speed, store, info }) => ({
    id,
    status: productStatusParser(status, sold_out),
    name,
    heroImage: info?.images.main[0] || "",
    price,
    priceDesc: `${price.toLocaleString()}원`,
    shippingSpeed: shipping_speed,
    store: camelizeKeys(store) as any
  }));

export const newPinsViewModelCreator = (products: ApiV1ProductSerialized[]): INewPinViewModel[] =>
  products.map(({ id, status, name, price, shipping_speed, store, info }) => ({
    id,
    status: newProductStatusParser(status),
    name,
    info,
    price,
    priceDesc: `${price.toLocaleString()}원`,
    shippingSpeed: shipping_speed,
    store: camelizeKeys(store) as any
  }));

export const pinsMetaViewModelCreator = ({
  total_count,
  total_price
}: {
  total_price: number;
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
}) => ({
  totalCount: total_count,
  totalPriceDesc: `(총 ${total_price.toLocaleString()}원)`
});
