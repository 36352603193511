import { camelizeKeys } from "humps";
import {
  FieldStatus,
  GetMembersOrdersOrderNumDetailResponse,
  IMemberOrderDetailViewModel,
  IMembersOrdersOrderNumDetailV3,
  IMembersOrdersViewModel,
  IMemeberOrderViewModelReNewal,
  INextStatus,
  IOrderDetailViewModel,
  IOrderProductHistoryDetailSerialized,
  IOrderProductHistorySerialized,
  IOrderResultViewModel,
  IOrderResultViewModelV3,
  IPageMeta,
  IVbankInfo,
  OrderDetailSerialized,
  OrderProductSerialized,
  OrderProductSerializedCamel,
  OrderSerialized
} from "typings";

import { formatDateTime } from "utils";

function isCamel(
  products: OrderProductSerialized[] | OrderProductSerializedCamel[]
): products is OrderProductSerializedCamel[] {
  return (products as OrderProductSerializedCamel[])[0].currentStatus != null;
}

function refineVbankInfo(vBankInfo: IVbankInfo | null): IVbankInfo | null {
  if (!vBankInfo) {
    return null;
  }

  const { data, date, ...others } = vBankInfo;

  return {
    ...others,
    date: date || data || ""
  };
}

export function orderProductStatusChecker(
  products: OrderProductSerialized[],
  fieldStatus: FieldStatus
): OrderProductSerialized[];
export function orderProductStatusChecker(
  products: OrderProductSerializedCamel[],
  fieldStatus: FieldStatus
): OrderProductSerializedCamel[];
export function orderProductStatusChecker(
  products: OrderProductSerialized[] | OrderProductSerializedCamel[],
  fieldStatus: FieldStatus
): OrderProductSerialized[] | OrderProductSerializedCamel[] {
  if (isCamel(products)) {
    return products.filter(({ currentStatus: { status } }) => {
      return fieldStatus === status || (fieldStatus === "claim" && status.match(/claim|refund|change|cancel/));
    });
  }

  return products.filter(({ current_status: { status } }) => {
    return fieldStatus === status || (fieldStatus === "claim" && status.match(/claim|refund|change|cancel/));
  });
}

export const orderProductStatusValue = (products: OrderProductSerializedCamel[], fieldStatus: FieldStatus) =>
  orderProductStatusChecker(products, fieldStatus).length > 0
    ? `${orderProductStatusChecker(products, fieldStatus).length}건`
    : "-";

export const createOrderProductStatusGroup = (
  products: OrderProductSerializedCamel[]
): Map<string, OrderProductSerializedCamel[]> =>
  products.reduce((acc, product) => {
    const {
      currentStatus: { text, status }
    } = product;

    const key = `${text}-${status}`;

    if (acc.has(key)) acc.get(key).push(product);
    else acc.set(key, [product]);

    return acc;
  }, new Map());

export const ordersViewModelCreator = (orders: OrderSerialized[]): IMembersOrdersViewModel[] =>
  orders.map(({ created_at, order_num, order_products, pay_method_text }) => ({
    payMethodText: pay_method_text,
    orderNum: order_num,
    orderProduct: camelizeKeys(order_products) as any,
    createdAtDesc: formatDateTime(created_at),
    mainOrderProductName:
      order_products.length > 1
        ? `${order_products[0].product.name} 외 ${order_products.length - 1}건`
        : order_products[0].product.name,
    mainOrderProductPhotoUrl: order_products[0].product.info?.images.main[0] || "",
    totalPaidPriceDesc: `${order_products.reduce((acc, { paid_price }) => acc + paid_price, 0).toLocaleString()}원`
  }));

export const ordersViewModelCreatorRenewal = (
  orders: IOrderProductHistorySerialized[]
): IMemeberOrderViewModelReNewal[] =>
  orders.map(
    ({
      order,
      order_num,
      current_status: { text, next_statuses, created_at, status },
      total_prev_price,
      paid_price,
      product: {
        id,
        name,
        images: { main },
        store
      },
      option: { positions, selected_count },
      shipping_info_from_partner,
      order: { vbank_info },
      review
    }) => ({
      orderNum: order.order_num,
      productOrderNum: order_num,
      currentStatusText: text,
      nextStatus: camelizeKeys(next_statuses) as any,
      beforeStatus: status as INextStatus["status"],
      prevPrice: total_prev_price,
      paidPrice: paid_price,
      productId: id,
      productName: name,
      productImage: main[0],
      productOptions: positions,
      productSelectedCount: selected_count,
      createdAt: formatDateTime(created_at),
      bankInfo: refineVbankInfo(vbank_info),
      store: {
        id: store.id,
        name: store.name,
        ceo: store.extra_info.ceo,
        cellNumber: store?.refund_info?.phone_number,
        email: store.extra_info.email
      },
      shippingInfoFromPartner: {
        deliveryCompany: shipping_info_from_partner?.delivery_company,
        deliveryNum: shipping_info_from_partner?.delivery_num,
        trackingUrl: shipping_info_from_partner?.tracking_url
      },
      review
    })
  );

export const orderProductDetailViewModelCreator = ({
  order,
  order_num,
  current_status: { text, next_statuses, created_at, status },
  total_prev_price,
  paid_price,
  product: {
    id,
    name,
    images: { main },
    store
  },
  option: { positions, selected_count },
  shipping_info_from_partner,
  order: { vbank_info },
  shipping_info,
  process,
  process_group,
  auto_pick_up,
  discount_coupon_price,
  consumed_point,
  total_price,
  pick_up_address,
  review
}: IOrderProductHistoryDetailSerialized): IMemberOrderDetailViewModel => ({
  orderNum: order.order_num,
  productOrderNum: order_num,
  currentStatusText: text,
  nextStatus: camelizeKeys(next_statuses) as any,
  beforeStatus: status as INextStatus["status"],
  prevPrice: total_prev_price,
  paidPrice: paid_price,
  productId: id,
  productName: name,
  productImage: main[0],
  productOptions: positions,
  productSelectedCount: selected_count,
  createdAt: formatDateTime(created_at),
  bankInfo: refineVbankInfo(vbank_info),
  store: {
    id: store.id,
    name: store.name,
    ceo: store.extra_info.ceo,
    cellNumber: store?.refund_info?.phone_number,
    email: store.extra_info.email,
    refundAddress: store.refund_info.address
  },
  shippingInfoFromPartner: {
    deliveryCompany: shipping_info_from_partner?.delivery_company,
    deliveryNum: shipping_info_from_partner?.delivery_num,
    trackingUrl: shipping_info_from_partner?.tracking_url
  },
  shippingInfo: {
    main: shipping_info.address.main,
    detail: shipping_info.address.detail,
    zipcode: shipping_info.address.zipcode,
    message: shipping_info.message,
    name: shipping_info.name,
    phoneNumber: shipping_info.phone_number
  },
  payInfo: {
    payStatus: order.pay_status,
    payMethodDetail: order.pay_method_detail,
    paidAt: order.paid_at,
    consumedPoint: order.consumed_point,
    discountCouponPrice: order.discount_coupon_price,
    paidPrice: order.paid_price,
    orderProductsCount: order.order_products_count
  },
  pickUpAddress: pick_up_address,
  process,
  processGroup: process_group,
  autoPickUp: auto_pick_up,
  currentStatusAt: formatDateTime(created_at, "yearDateTimeStr"),
  discountCouponPrice: discount_coupon_price,
  totalPrice: order.total_price,
  consumedPoint: consumed_point,
  targetProductPrice: total_price,
  review
});

export const ordersPageViewModelCreator = ({ total_count }: IPageMeta) => total_count;

type OrderDetailViewModelCreator = (
  orderInfo: OrderDetailSerialized,
  payInfo: GetMembersOrdersOrderNumDetailResponse
) => IOrderDetailViewModel;

export const orderDetailViewModelCreator: OrderDetailViewModelCreator = (
  { created_at, order_num, order_products },
  { data: { payment_info, total_price, price_info, user_info } }
) => ({
  createdAtDesc: formatDateTime(created_at),
  orderNum: order_num,
  orderProducts: camelizeKeys(order_products) as any,
  paymentInfo: payment_info,
  totalPrice: total_price,
  priceInfo: price_info,
  userInfo: user_info
});

type OrderResultViewModelCreator = (payInfo: GetMembersOrdersOrderNumDetailResponse) => IOrderResultViewModel;

export const orderResultViewModelCreator: OrderResultViewModelCreator = ({
  data: { payment_info, original_price, title, total_price, user_info }
}) => {
  const target = payment_info.find(([key]) => key === "상품금액");
  if (target) target.push(original_price);

  return {
    title,
    paymentInfo: payment_info,
    totalPriceKV: total_price,
    userInfo: user_info
  };
};

type OrderResultViewModelCreatorV3 = (payload: IMembersOrdersOrderNumDetailV3) => IOrderResultViewModelV3;

export const orderResultViewModelCreatorV3: OrderResultViewModelCreatorV3 = ({
  title,
  original_price,
  payment_info,
  total_price,
  user_info,
  is_proceeding,
  is_success
}) => {
  const target = payment_info.find(([key]) => key === "상품금액");
  if (target) {
    if (original_price) {
      target.push(original_price);
    }
  }

  return {
    title,
    isProceeding: is_proceeding,
    isSuccess: is_success,
    paymentInfo: payment_info ?? [["", ""]],
    totalPriceKV: total_price ?? ["총 결제금액", "-원"],
    userInfo: user_info ?? [["구매자", ""]]
  };
};
