import React, { FC, useCallback } from "react";
import { BtnPhotoGridView } from "assets/icon";
import classNames from "classnames/bind";
import { useDispatch } from "react-redux";
import { modalOff } from "actions/actionCreators";
import styles from "./PhotoHeadNav.module.scss";

const cx = classNames.bind(styles);

interface IProp {
  fullPageSize: number;
  nowPage: number;
  hasGridButton?: boolean;
}

export const PhotoHeadNav: FC<IProp> = ({ fullPageSize, nowPage, hasGridButton }) => {
  const dispatch = useDispatch();
  const gridOpen = useCallback(() => {
    dispatch(modalOff());
  }, [dispatch]);
  return (
    <nav className={cx("navWrapper")}>
      <div />
      <div className={cx("pageNavIcon")}>
        {nowPage} / {fullPageSize}
      </div>
      <div className={cx("gridViewWrapper")}>
        {hasGridButton && (
          <button type="button" onClick={gridOpen}>
            <BtnPhotoGridView />
          </button>
        )}
      </div>
    </nav>
  );
};
