import { omit } from "ramda";
import { camelizeKeys } from "humps";

import { newProductViewModelCreator } from "view-model-creator";

import { NewCoordinationDetailSerialized, INewCoordiDetailViewModel } from "typings";

type CoordiDetailViewModelCreator = (coordi: NewCoordinationDetailSerialized) => INewCoordiDetailViewModel;

export const newCoordiDetailViewModelCreator: CoordiDetailViewModelCreator = coordi => {
  const data = camelizeKeys(omit(["is_liked", "products"], coordi)) as any;

  return {
    ...data,
    description: data.description || "",
    tags: data.tags.map(({ title }) => title.replace("#", "")),
    products: newProductViewModelCreator(coordi.products)
  };
};
