import React, { FC, useCallback, useState } from "react";
import classNames from "classnames/bind";
import { Checkbox } from "components/presentation/checkbox/Checkbox";
import { IconThinClose } from "assets/icon";
import { useSelector } from "react-redux";
import { IStore } from "typings";
import styles from "./PinFilterContent.module.scss";

interface IProp {
  categories: string[];
  selectCategories: string[];
  onFilterSubmit: (props: { categories: string[]; viewOnlySale: boolean }) => void;
  isViewSoldOut: boolean;
  closeFilter: () => void;
}

const cx = classNames.bind(styles);
export const PinFilterContent: FC<IProp> = ({
  categories,
  onFilterSubmit,
  isViewSoldOut,
  closeFilter,
  selectCategories
}) => {
  const { isMobile } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);
  const [tmpViewSoldOut, setTmpViewSoldOut] = useState(isViewSoldOut);
  const [tmpSelectCategories, setTmpSelectCategories] = useState<string[]>(selectCategories);

  const getIsTmpSelectCategory = useCallback(
    category => {
      return tmpSelectCategories.findIndex(tmpCategory => tmpCategory === category) !== -1;
    },
    [tmpSelectCategories]
  );

  const setIsTmpSelectCategory = useCallback((category, isSelect) => {
    if (isSelect) {
      setTmpSelectCategories(prevList => prevList.filter(prev => prev !== category));
    } else {
      setTmpSelectCategories(prevList => [...prevList, category]);
    }
  }, []);

  const submitFilter = useCallback(() => {
    onFilterSubmit({ categories: tmpSelectCategories, viewOnlySale: tmpViewSoldOut });
    closeFilter();
  }, [onFilterSubmit, tmpSelectCategories, tmpViewSoldOut, closeFilter]);

  const filterClean = useCallback(() => {
    onFilterSubmit({ categories: [], viewOnlySale: false });
    closeFilter();
  }, [onFilterSubmit, closeFilter]);

  return (
    <div className={cx("filter-background")}>
      <div
        role="button"
        tabIndex={-1}
        className={cx("filter-wrapper")}
        onClick={e => e.stopPropagation()}
        onKeyDown={() => {}}
      >
        <div className={cx("filter-content-wrapper")}>
          <div className={cx("filter-header")}>
            <span className={cx("header-text")}>필터</span>
            <button type="button" className={cx("close-button")} onClick={closeFilter}>
              <IconThinClose />
            </button>
          </div>
          <button
            type="button"
            className={cx("filter-sale")}
            onClick={e => {
              e.preventDefault();
              setTmpViewSoldOut(prev => !prev);
            }}
          >
            <Checkbox checked={tmpViewSoldOut} handleClick={() => {}} id="check-box" />
            <span className={cx("filter-sale-font")}>품절(판매중지) 상품 포함</span>
          </button>

          <div>
            <div className={cx("header-text", "category-header")}>카테고리</div>

            {categories.map(category =>
              isMobile ? (
                <button
                  key={`category-${category}`}
                  type="button"
                  className={cx("category-button-mobile", { select: getIsTmpSelectCategory(category) })}
                  onClick={() => setIsTmpSelectCategory(category, getIsTmpSelectCategory(category))}
                >
                  {category}
                </button>
              ) : (
                <button
                  key={`category-${category}`}
                  type="button"
                  className={cx("category-button", { select: getIsTmpSelectCategory(category) })}
                  onClick={e => {
                    e.preventDefault();
                    setIsTmpSelectCategory(category, getIsTmpSelectCategory(category));
                  }}
                >
                  <Checkbox id={category} handleClick={() => {}} checked={getIsTmpSelectCategory(category)} />
                  <span className={cx("filter-category-text")}>{category}</span>
                </button>
              )
            )}
          </div>
        </div>
        <div className={cx("bottom-wrapper")}>
          <button className={cx("nav-button")} type="button" onClick={filterClean}>
            초기화
          </button>
          <button className={cx("nav-button", "submit")} type="button" onClick={submitFilter}>
            적용
          </button>
        </div>
      </div>
    </div>
  );
};
