import { useReviewWrite, useSets } from "hooks";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IReviewWrite, IStore } from "typings";
import classNames from "classnames/bind";
import { modalOff } from "modules/ui";
import { CloseQna, IconChevronBottomGray } from "assets/icon";
import {
  ReviewWritePhoto,
  AddPhotoInput,
  UserInput,
  SimpleProductRow,
  ReviewWriteFooter,
  SatisfactionInput,
  Evaluation
} from "components";

import nomalReviewWriteStyle from "./NormalPhotoReview.module.scss";
import simpleReviewWriteStyle from "./SimpleReview.module.scss";

const ns = classNames.bind(nomalReviewWriteStyle);
const ss = classNames.bind(simpleReviewWriteStyle);
interface IProp {
  callbackFunction?: (arg: any) => void;
}
export const ReviewReplace = ({ callbackFunction }: IProp) => {
  const dispatch = useDispatch();
  const { id: reviewId, product, orderProductOrderNum } = useSelector<IStore, IStore["reviewWrite"]>(
    ({ reviewWrite }) => reviewWrite
  );
  const [normalReviewWriteMode, setNormalReviewWriteMode] = useState(false);
  const [photoReviewWriteMode, setPhotoReviewWriteMode] = useState(false);

  const { getTargetMyWriteReview } = useSets();

  const targetReview = useMemo<IReviewWrite | undefined>(() => {
    if (reviewId !== undefined && reviewId !== null) {
      return getTargetMyWriteReview(reviewId);
    }
    return undefined;
  }, [getTargetMyWriteReview, reviewId]);

  const {
    userableHeightChange,
    userableWeightChange,
    userableInfo,
    satisfaction,
    setSatisfaction,
    content,
    setContent,
    addPhoto,
    photoDatas,
    deletePhoto,
    submitReview,
    size,
    setSize,
    color,
    setColor,
    shipping,
    setShipping
  } = useReviewWrite(orderProductOrderNum, product?.productId, targetReview, callbackFunction);
  return (
    <div className={ns("wrapper")}>
      <header className={ns("header")}>
        <span className={ns("title")}>리뷰 수정</span>
        <button type="button" className={ns("button")} onClick={() => dispatch(modalOff({ isClear: true }))}>
          <CloseQna />
        </button>
      </header>
      <section className={ns("product-wrapper")}>{product && <SimpleProductRow product={product} />}</section>
      <section className={ns("user-wrapper")}>
        <div className={ns("sub-title")}>내정보</div>
        <div className={ns("user-info-input-wrapper")}>
          <UserInput value={userableInfo.height} onChange={userableHeightChange} title="키" unit="cm" />
          <UserInput value={userableInfo.weight} onChange={userableWeightChange} title="몸무게" unit="kg" />
        </div>
        <div className={ns("description")}>
          입력하신 정보(키, 몸무게 등)는 다른 구매 고객들에게 참고용으로 <span>상품 상세페이지 &gt; 구매후기</span> 에
          노출될 수 있습니다.
        </div>
      </section>
      <section>
        <div className={ns("sub-title", "normal-review-margin")}>일반리뷰</div>
        <div className={ns("satisfaction-wrapper")}>
          <div className={ns("input-title")}>만족도</div>
          <SatisfactionInput satisfaction={satisfaction} setSatisfaction={setSatisfaction} />
        </div>
        <section>
          <div className={ss("evaluation-wrapper")}>
            <div className={ss("evaluation-title")}>사이즈</div>
            <Evaluation part="size" select={size} onChange={setSize} />
          </div>
          <div className={ss("evaluation-wrapper")}>
            <div className={ss("evaluation-title")}>색상</div>
            <Evaluation part="color" select={color} onChange={setColor} />
          </div>
          <div className={ss("evaluation-wrapper")}>
            <div className={ss("evaluation-title")}>배송</div>
            <Evaluation part="shipping" select={shipping} onChange={setShipping} />
          </div>
        </section>
      </section>
      <section>
        <div className={ss("sub-title", "normal-review-margin")}>
          일반리뷰 <span>(적립금 +300원)</span>
        </div>
        <div className={ns("input-wrapper")}>
          {content || normalReviewWriteMode ? (
            <textarea
              className={ns("content-input")}
              value={content}
              onChange={e => setContent(e.target.value)}
              placeholder="상품에 대한 구매후기를 작성해주세요. (10자 이상)"
            />
          ) : (
            <button className={ns("review-write-button")} onClick={() => setNormalReviewWriteMode(true)}>
              일반리뷰 작성하기{" "}
              <i>
                <IconChevronBottomGray />
              </i>
            </button>
          )}
        </div>
      </section>

      <section>
        <div className={ns("sub-title", "normal-review-margin")}>
          <div className={ns("photo-review-title")}>
            포토리뷰 <span>(적립금 +400원)</span>
          </div>
        </div>
        <div className={ns("photo-input-wrapper", "input-wrapper")}>
          {photoDatas.length > 0 || photoReviewWriteMode ? (
            <>
              <AddPhotoInput onFileUpload={addPhoto} />
              {photoDatas.map(({ url }, index) => (
                <ReviewWritePhoto key={url} url={url} onClick={() => deletePhoto(index)} />
              ))}
            </>
          ) : (
            <button className={ns("review-write-button")} onClick={() => setPhotoReviewWriteMode(true)}>
              포토리뷰 작성하기{" "}
              <i>
                <IconChevronBottomGray />
              </i>
            </button>
          )}
        </div>
      </section>
      <section className={ss("submit-wrapper")}>
        <button className={ss("submit-button")} type="button" onClick={submitReview}>
          완료
        </button>
      </section>
      <section>
        <ReviewWriteFooter />
      </section>
    </div>
  );
};
