import React from "react";
import animationData from "assets/animation/ani_page_loading.json";
import Lottie from "react-lottie";

export const LoadingIcon = () => {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }}
      width={60}
      height={60}
    />
  );
};
