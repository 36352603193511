import React, { Fragment, useEffect, useState } from "react";
import * as api from "api";
import { contactsViewModelCreator } from "view-model-creator";
import { imageResizeHelper } from "utils";
import { Loading } from "components";
import { IContactsViewModel } from "typings";
import classNames from "classnames/bind";
import styles from "./Contacts.module.scss";

const cx = classNames.bind(styles);

export function Contacts() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contacts, setContacts] = useState<IContactsViewModel[]>([]);

  const handleContactResponse = ({ currentTarget }) => {
    const {
      classList,
      dataset: { id }
    } = currentTarget;

    setContacts(
      contacts.map(contact => {
        let { flip } = contact.response;

        if (contact.contactId === Number(id)) {
          flip = !contact.response.flip;
          classList.toggle(styles.on);
        }
        return { ...contact, response: { ...contact.response, flip } };
      })
    );
  };

  useEffect(() => {
    api.get__members__order_product_contacts().then(list => {
      setContacts(contactsViewModelCreator(list));
      setIsLoading(false);
    });
  }, []);

  return (
    <main className={cx("contacts-tab")}>
      <header className={cx(["bold"], "title")}>문의내역</header>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <section className={cx("table")}>
            <div className={cx("row", "header")}>
              <div className={cx("cell")}>문의일시</div>
              <div className={cx("cell")}>문의상품</div>
              <div className={cx("cell")}>문의내용</div>
              <div className={cx("cell")}>답변여부</div>
            </div>

            {contacts.length > 0 ? (
              contacts.map(
                ({
                  contactId,
                  contents,
                  createdAt,
                  thumbnail,
                  productName,
                  response: { responseCreatedAt, responseContents, storeName, flip }
                }) => (
                  <Fragment key={contactId}>
                    <div
                      role="button"
                      tabIndex={0}
                      className={cx("row", "list")}
                      key={contactId}
                      data-id={contactId}
                      onClick={handleContactResponse}
                      onKeyDown={handleContactResponse}
                    >
                      <div className={cx("cell", "date")}>{createdAt}</div>

                      <div className={cx("cell", "no-wrap", "left")}>
                        <div className={cx("product")}>
                          <div
                            className={cx("product-image")}
                            style={{
                              backgroundImage: `url(${imageResizeHelper(thumbnail, 40)})`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat"
                            }}
                          />
                          <div className={cx("ellipsis")}>{productName}</div>
                        </div>
                      </div>

                      <div className={cx("cell", "left")}>{contents}</div>

                      {responseCreatedAt ? (
                        <div className={cx("cell", "answer-done")}>답변완료</div>
                      ) : (
                        <div className={cx("cell")}>답변이 없습니다.</div>
                      )}
                    </div>

                    <div className={cx("row", [flip ? "flip" : "no-flip"])}>
                      <div className={cx("cell", "date")}>{responseCreatedAt}</div>
                      <div className={cx("cell", "response")}>
                        <div className={cx("store")}>{storeName}</div>
                        <div>
                          <pre>{responseContents}</pre>
                        </div>
                      </div>
                      <div className={cx("cell")} />
                      <div className={cx("cell")} />
                    </div>
                  </Fragment>
                )
              )
            ) : (
              <div className={cx("row", "no-list")}>문의내역이&nbsp;없습니다.</div>
            )}
          </section>

          <section className={cx("card-container")}>
            {contacts.length > 0 ? (
              contacts.map(
                ({
                  contactId,
                  contents,
                  createdAt,
                  thumbnail,
                  productName,
                  response: { responseCreatedAt, responseContents, storeName, flip }
                }) => {
                  const isFlip = flip ? "flip" : "no-flip";

                  return (
                    <div className={cx("card")} key={contactId}>
                      <ul className={cx("content", isFlip)}>
                        <li className={cx("info")}>
                          <img alt="상품이미지" src={imageResizeHelper(thumbnail, 40)} />
                          <span className={cx("name")}>{productName}</span>
                          {responseCreatedAt ? (
                            <span className={cx("answer-done")}>답변완료</span>
                          ) : (
                            <span className={cx("is-answer")}>미답변</span>
                          )}
                        </li>
                        <li className={cx("desc")}>
                          <pre>{contents}</pre>
                        </li>
                        <li className={cx("date")}>{createdAt}</li>
                      </ul>

                      <ul className={cx("response", isFlip)}>
                        <li className={cx("store")}>{storeName}</li>
                        <li className={cx("desc")}>
                          <pre>{responseContents}</pre>
                        </li>
                        <li className={cx("date")}>{responseCreatedAt}</li>
                      </ul>

                      {responseCreatedAt ? (
                        <button
                          type="button"
                          className={cx("flip-button")}
                          data-id={contactId}
                          data-flip="답변 펼치기"
                          data-unflip="답변 접기"
                          onClick={handleContactResponse}
                        >
                          {" "}
                        </button>
                      ) : (
                        <button type="button">{responseContents}</button>
                      )}
                    </div>
                  );
                }
              )
            ) : (
              <div className={cx("no-list")}>문의내역이&nbsp;없습니다.</div>
            )}
          </section>
        </>
      )}
    </main>
  );
}
