import qs from "qs";
import { path, pipe, identity } from "ramda";
import omitEmpty from "omit-empty";
import { produce } from "utils";

import { historyContext, storageContext } from "contexts";

import { TRENDING } from "constant";

type GetConditions = (arg: { category?: string; keyword?: string; gte?: number; lte?: number }) => string[];

export const getConditions: GetConditions = ({ category, keyword, gte, lte }) => {
  const result: string[] = [];

  if (category) result.push(category);

  if (keyword) result.push(keyword);

  if (gte && lte) result.push(`${gte.toLocaleString()} ~ ${lte.toLocaleString()}원`);
  else if (gte) result.push(`${gte.toLocaleString()}원 ~ `);
  else if (lte) result.push(`~ ${lte.toLocaleString()}원`);

  return result;
};

export const setQuery = pipe(
  query => omitEmpty(query, { omitZero: true }),
  qs.stringify,
  search => ({ search, pathname: historyContext.location.pathname }),
  historyContext.push
);

export const handlePageHelper = query => (page: number) => {
  setQuery(
    produce(query, draft => {
      draft.params.page.number = page;
    })
  );

  window.scrollTo(0, 0);
};

export const handlePriceHelper = query => (gte: number, lte: number) => {
  setQuery(
    produce(query, draft => {
      draft.params.price = {
        ...(gte && { gte }),
        ...(lte && { lte })
      };

      draft.params.page.number = 1;
    })
  );
};

export const handlePriceResetHelper = query => () => {
  setQuery(
    produce(query, draft => {
      draft.params.price = {
        gte: 0,
        lte: 0
      };

      draft.params.page.number = 1;
    })
  );
};

export const updateStateByValueFromInputTag = (setState: React.Dispatch<React.SetStateAction<string>>) =>
  pipe(path(["target", "value"]), String, setState);

export const changeName = (draft, { category, keyword, main }) => {
  const name = [main, category, keyword]
    .filter(identity)
    .join(" ")
    .trim();

  draft.category = category;
  draft.keyword = keyword;
  draft.main = main;

  draft.params.name = name;

  draft.params.page.number = 1;
};

const fillQuery = (draft, base) => ({
  params: {
    order: draft?.params?.order ?? base?.params?.order ?? TRENDING,

    page: {
      number: Number(draft?.params?.page?.number ?? base?.params?.page?.number ?? 1),
      size: Number(draft?.params?.page?.size ?? base?.params?.page?.size ?? 50)
    },

    genderId: draft?.params?.genderId ?? storageContext.genderId,

    price: {
      gte: Number(draft?.params?.price?.gte ?? base?.params?.price?.gte ?? 0),
      lte: Number(draft?.params?.price?.lte ?? base?.params?.price?.lte ?? 0)
    },

    period: draft?.params?.period ?? "",

    storeGroupId: draft?.params?.storeGroupId ?? "",

    onlyLikeStores: (draft?.params?.onlyLikeStores ?? "false") === "true",

    name: [draft?.main ?? "", draft?.category ?? "", draft?.keyword ?? ""].filter(identity).join(" ")
  },

  keyword: draft?.keyword ?? "",

  category: draft?.category ?? "",

  main: draft?.main ?? ""
});

export const processingQuery = (search: string, base) =>
  !search
    ? base
    : pipe(
        (search: string) => qs.parse(search, { ignoreQueryPrefix: true }) as any,
        query => produce(query, draft => fillQuery(draft, base)),
        query => omitEmpty(query, { omitZero: true })
      )(search);
