import React, { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  newPinsViewModelCreator,
  pinsMetaViewModelCreator,
  reviewSummariesViewModelCreatorV3
} from "view-model-creator";
import { times, pipeWith, then, path } from "ramda";

import { createSetFromListId, Tracking, produce } from "utils";
import { Checkbox, PageTitle, Pagination, Loading, ProductCardWithoutPin, PinFilter } from "components";
import * as api from "api";
import { usePin } from "hooks";
import { IStore, INewPinViewModel, IReviewsSummariesViewModelV3 } from "typings";

import classnames from "classnames/bind";
import qs from "qs";

import styles from "./Pins.module.scss";
import { HaveNotPin } from "./HaveNotPin";

const cx = classnames.bind(styles);

const PER_PAGE = 50;

export function Pins() {
  const mainRef = useRef<HTMLElement>(null);
  const history = useHistory();
  const isMobile = useSelector<IStore, IStore["ui"]["isMobile"]>(({ ui: { isMobile } }) => isMobile);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pins, setPins] = useState<INewPinViewModel[]>([]);
  const [reviews, setReviews] = useState<IReviewsSummariesViewModelV3[]>([]);
  const [pinsMeta, setPinsMeta] = useState({
    totalCount: 0,
    totalPriceDesc: ""
  });

  const [categories, setCategories] = useState<string[]>([]);
  const [selectCategories, setSelectCategories] = useState<string[]>([]);
  const [isViewSoldOut, setIsVeiwSoldOut] = useState(false);
  const isFilting = useMemo(() => selectCategories.length > 0 || isViewSoldOut === true, [
    isViewSoldOut,
    selectCategories.length
  ]);

  const { deletePin } = usePin();

  const [checkIds, setCheckIds] = useState<Set<number>>(new Set());

  const column = useMemo(() => (isMobile ? 3 : 5), [isMobile]);

  const hiddenFlexBoxes = useMemo(() => times(v => v + 1, pins.length % column ? column - (pins.length % column) : 0), [
    column,
    pins.length
  ]);

  const qsPageNumber = useMemo(() => {
    const { page } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    return page || 1;
  }, [history.location.search]);

  const handlePage = useCallback(
    number => {
      const search = qs.stringify({ page: number });
      history.push({ search });

      window.scrollTo(0, 0);
    },
    [history]
  );

  const getPins = useCallback(() => {
    const param = {
      page: { number: pageNumber, size: PER_PAGE },
      name: selectCategories.join(" "),
      status: isViewSoldOut ? undefined : "sale"
    };
    setIsLoading(true);

    api.get__api__v1__userables__products(param).then(({ data: { payload: pins, meta: pinsMeta } }) => {
      setPins(newPinsViewModelCreator(pins));

      setPinsMeta(pinsMetaViewModelCreator(pinsMeta));

      setIsLoading(false);

      if (mainRef.current) mainRef.current.style.minHeight = "auto";
    });
  }, [isViewSoldOut, pageNumber, selectCategories]);

  const handleDeletePin = useCallback(async () => {
    if (checkIds.size === 0) return;
    await deletePin(...checkIds.values());
    api.api__v1_get_userables_products_only_categories().then(res => {
      setCategories(res.data.payload);
    });
    setCheckIds(new Set());
    getPins();
  }, [checkIds, deletePin, getPins]);

  const togglePin = useCallback(
    event => {
      event.preventDefault();

      const id = Number(event.target.dataset.id);

      setCheckIds(
        produce(checkIds, draft => {
          if (checkIds.has(id)) draft.delete(id);
          else draft.add(id);
        })
      );
    },
    [checkIds]
  );

  const selectAll = useCallback(
    event => {
      event.preventDefault();

      setCheckIds(checkIds.size === pins.length ? new Set() : new Set(createSetFromListId(pins)));
    },
    [pins, checkIds]
  );

  useEffect(() => {
    setPageNumber(Number(qsPageNumber));
  }, [qsPageNumber, history.location.search]);

  useEffect(getPins, [pageNumber, selectCategories, isViewSoldOut]);

  useEffect(() => {
    Tracking.viewWishlist();
  }, []);

  useEffect(() => {
    api.api__v1_get_userables_products_only_categories().then(res => {
      setCategories(res.data.payload);
    });
  }, []);

  useEffect(() => {
    const pinsIds = pins.map(pin => pin.id);

    if (!pinsIds.length) return;
    pipeWith(then)([
      api.get__products__id__reviews_summaries_v3,
      path(["data", "payload"]),
      reviewSummariesViewModelCreatorV3,
      setReviews
    ])(pinsIds);
  }, [pins]);

  const onFilterSubmit = useCallback(
    ({ categories, viewOnlySale }: { categories: string[]; viewOnlySale: boolean }) => {
      setSelectCategories(categories);
      setIsVeiwSoldOut(viewOnlySale);
      const search = qs.stringify({ page: 1 });
      history.push({ search });
    },
    [history]
  );

  return (
    <main className={cx("pins-tab")} ref={mainRef}>
      <PageTitle title="핀 한 상품" />

      <div className={cx("info")}>
        {pinsMeta.totalCount}개의 상품이 있습니다.
        <span className={cx("price")}>{pinsMeta.totalPriceDesc}</span>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={cx("nav-wrapper")}>
            <div className={cx("remove")}>
              <Checkbox checked={!!pins.length && checkIds.size === pins.length} id="all" handleClick={selectAll} />

              <button type="button" className={cx("button")} onClick={handleDeletePin}>
                삭제
              </button>

              {checkIds.size > 0 && <div className={cx("checked")}>{checkIds.size}개 선택됨</div>}
            </div>
            <div className={cx("filter-wrapper")}>
              <PinFilter
                isFilting={isFilting}
                categories={categories}
                onFilterSubmit={onFilterSubmit}
                selectCategories={selectCategories}
                isViewSoldOut={isViewSoldOut}
              />
            </div>
          </div>
          {pins.length === 0 && <HaveNotPin />}
          <div className={cx("list")}>
            {pins.map((pin, i) => (
              <div className={cx("card")} key={pin.id}>
                <Checkbox checked={checkIds.has(pin.id)} handleClick={togglePin} id={pin.id} />

                <ProductCardWithoutPin product={pin} review={reviews[i]} isSelect={checkIds.has(pin.id)} />
              </div>
            ))}

            {hiddenFlexBoxes.map(v => (
              <div key={`hidden_pin_${v}`} className={cx("hidden")} />
            ))}
          </div>
        </>
      )}

      <Pagination totalCount={pinsMeta.totalCount} handler={handlePage} number={pageNumber} size={PER_PAGE} />
    </main>
  );
}
