import React, { useCallback, useState, useRef, useMemo, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import qs from "qs";

import { useBookmark } from "hooks";
import { Checkbox, Pagination, Loading } from "components";
import { get__api__v1__userables__stores } from "api";
import { bookmarksViewModelCreator, bookmarksMetaViewModelCreator } from "view-model-creator";
import { IBookmarkViewModel } from "typings";

import classnames from "classnames/bind";
import { produce } from "utils";

import styles from "./Bookmarks.module.scss";

const cx = classnames.bind(styles);

const PER_PAGE = 50;

export function Bookmarks() {
  const history = useHistory();
  const mainRef = useRef<HTMLElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [bookmarks, setBookmarks] = useState<IBookmarkViewModel[]>([]);
  const [bookmarksMeta, setBookmarksMeta] = useState({ totalCount: 0 });

  const [checkIds, setCheckIds] = useState<Set<number>>(new Set());

  const { deleteBookmark } = useBookmark();

  const qsPageNumber = useMemo(() => {
    const { page } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    return page || 1;
  }, [history.location.search]);

  const handlePage = useCallback(
    number => {
      const search = qs.stringify({ page: number });
      history.push({ search });

      window.scrollTo(0, 0);
    },
    [history]
  );

  const getBookmarks = useCallback(() => {
    const param = { page: { number: pageNumber, size: PER_PAGE } };

    setIsLoading(true);

    get__api__v1__userables__stores(param).then(({ data: { payload: bookmarks, meta: bookmarksMeta } }) => {
      setBookmarks(bookmarksViewModelCreator(bookmarks));

      setBookmarksMeta(bookmarksMetaViewModelCreator(bookmarksMeta));

      setIsLoading(false);

      if (mainRef.current) mainRef.current.style.minHeight = "auto";
    });
  }, [pageNumber]);

  const toggleBookmark = useCallback(
    event => {
      event.preventDefault();

      const id = Number(event.target.dataset.id);

      setCheckIds(
        produce(checkIds, draft => {
          if (checkIds.has(id)) draft.delete(id);
          else draft.add(id);
        })
      );
    },
    [checkIds]
  );
  const selectAll = useCallback(
    event => {
      event.preventDefault();

      setCheckIds(checkIds.size === bookmarks.length ? new Set() : new Set(bookmarks.map(({ id }) => id)));
    },
    [bookmarks, checkIds]
  );
  const handleDeleteBookmark = useCallback(async () => {
    if (checkIds.size === 0) return;
    await deleteBookmark(...checkIds.values());

    setCheckIds(new Set());
    getBookmarks();
  }, [deleteBookmark, checkIds, getBookmarks]);

  useEffect(() => {
    setPageNumber(Number(qsPageNumber));
  }, [qsPageNumber, history.location.search]);

  useEffect(getBookmarks, [pageNumber]);

  return (
    <main className={cx("bookmarks-tab")} ref={mainRef}>
      <div className={cx("title")}>즐겨찾기</div>

      <div className={cx("size")}>{bookmarksMeta.totalCount}개의 쇼핑몰이 있습니다.</div>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={cx("remove")}>
            <Checkbox
              checked={!!checkIds.size && checkIds.size === bookmarks.length}
              id="all"
              handleClick={selectAll}
            />

            <button type="button" className={cx("button")} onClick={handleDeleteBookmark}>
              삭제
            </button>

            {checkIds.size > 0 && <div className={cx("checked")}>{checkIds.size}개 선택됨</div>}
          </div>

          <div className={cx("stores")}>
            {bookmarks.map(({ id, photoUrl, name, message, newProductsCnt, serializedTags }) => (
              <div key={id} className={cx("store")}>
                <Checkbox checked={checkIds.has(id)} handleClick={toggleBookmark} id={id} />

                <Link className={cx("link")} to={{ pathname: `/stores/${id}` }}>
                  <picture className={cx("picture")}>
                    <img className={cx("img")} src={photoUrl} alt="상점 이미지" />
                  </picture>

                  <div className={cx("right")}>
                    <div className={cx("top")}>
                      <div className={cx("left")}>
                        <span className={cx("name")}>{name}</span>
                        <span className={cx("message")}>{message}</span>
                      </div>

                      <div className={cx("new-products-cnt", { hide: !newProductsCnt })}>신상품 +{newProductsCnt}</div>
                    </div>

                    <div className={cx("tags")}>{serializedTags}</div>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          <Pagination totalCount={bookmarksMeta.totalCount} handler={handlePage} number={pageNumber} size={PER_PAGE} />
        </>
      )}
    </main>
  );
}
