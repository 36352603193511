import React, { useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { photoDetailChangeByArrow } from "actions/actionCreators";
import classNames from "classnames/bind";
import { IconThinClose, FillRecommend, NotFillRecommend } from "assets/icon";
import { LevelIcon, CountReviewSummary, ReviewStars, PhotoHeadNav, PhotoArrowNav } from "components";

import { REVIEWTYPE, LOOKPIN_REVIEW } from "constant";
import { useReviewLikers } from "hooks/useReviewLikers";
import styles from "./DetailPhotoReviewMobile.module.scss";
import { usePhotoReviews } from "./usePhotoReviews";

const cx = classNames.bind(styles);
export const DetailPhotoReviewMobile = () => {
  const dispatch = useDispatch();
  const {
    photoModalGridStatus,
    photoModalDetail,
    imageIndex,
    setImageIndex,
    viewTotalCount,
    viewSelectNumber
  } = usePhotoReviews();

  const { getIsReviewLiked, toggleReviewLikes } = useReviewLikers();

  const [likeCountState, setLikeCountState] = useState(photoModalDetail?.likeCount);

  const isLiked = useMemo(() => {
    return photoModalDetail && getIsReviewLiked(photoModalDetail.id);
  }, [photoModalDetail, getIsReviewLiked]);

  const isLookpinReview = useCallback((reviewType: string) => reviewType === LOOKPIN_REVIEW, []);

  const arrowButtonClick = useCallback(
    (diff: number) => {
      if (photoModalGridStatus) dispatch(photoDetailChangeByArrow(diff));
      else setImageIndex(imageIndex + diff);
    },
    [imageIndex, photoModalGridStatus, dispatch, setImageIndex]
  );

  const closeButtonClick = useCallback(() => {
    window.history.back();
  }, []);

  const toggleRecommend = useCallback(
    (id: number, reviewType: string) => {
      if (isLookpinReview(reviewType)) {
        const diff = isLiked ? -1 : 1;
        if (likeCountState) toggleReviewLikes(id, () => setLikeCountState(likeCountState + diff));
      }
    },
    [isLiked, likeCountState, toggleReviewLikes, isLookpinReview]
  );

  const stopBubbleEvent = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  }, []);

  const {
    id,
    satisfaction,
    options,
    images,
    type,
    contents,
    createdAt,
    author,
    authorLevel,
    height,
    weight,
    color,
    size,
    shipping
  } = photoModalDetail!;

  return (
    photoModalDetail && (
      <div className={cx("wrapper")} onClick={stopBubbleEvent}>
        <header className={cx("header")}>
          <span>포토리뷰</span>
          <button type="button" onClick={closeButtonClick}>
            <IconThinClose />
          </button>
        </header>
        <div className={cx("photoWrapper")}>
          <div className={cx("photoNavHeadPositionWrapper")}>
            <PhotoHeadNav
              fullPageSize={viewTotalCount}
              nowPage={viewSelectNumber}
              hasGridButton={photoModalGridStatus}
            />
          </div>
          <div className={cx("photoNavBodyPositionWrapper")}>
            <PhotoArrowNav
              changeDiffEvent={arrowButtonClick}
              fullPageSize={viewTotalCount}
              nowPage={viewSelectNumber}
            />
          </div>
          <img className={cx("photoContent")} src={images[imageIndex]} alt="" />
        </div>
        <div className={cx("reviewWrapper")}>
          <section className={cx("authorWrapper")}>
            {!isLookpinReview(type) && <ReviewStars reviewId={id} starRating={satisfaction} isSmall />}
            {authorLevel && <LevelIcon level={authorLevel} />}
            <span className={cx("author-name")}>{author}</span>
            {height && weight && (
              <span className={cx("author-spec")}>
                {height}cm, {weight}Kg
              </span>
            )}
          </section>
          <section className={cx("reviewSummaryWrapper")}>
            {isLookpinReview(type) && (
              <CountReviewSummary id={id} satisfaction={satisfaction} color={color} size={size} shipping={shipping} />
            )}
          </section>
          {options && <section className={cx("optionWrapper")}>구매옵션 : {options}</section>}

          <pre className={cx("commentContentsWrapper")}>{contents}</pre>
        </div>
        <footer className={cx("reviewFooter")}>
          <span className={cx("createdContents")}>
            {createdAt}&nbsp;
            {REVIEWTYPE[type]}
          </span>
          <button
            type="button"
            className={cx("likeContents", { isLiked })}
            onClick={() => toggleRecommend(photoModalDetail.id, type)}
          >
            {isLookpinReview(type) && (isLiked ? <FillRecommend /> : <NotFillRecommend />)}
            추천 {likeCountState}
          </button>
        </footer>
      </div>
    )
  );
};
