import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga";

import store from "store";

import { App, Inspection } from "components";

import { GOOGLE_ANALYTICS_ID } from "constant";

import { historyContext } from "contexts";

import { Airbridge, Tracking } from "utils";
import { v4 as uuidV4 } from "uuid";
import { version } from "../package.json";

import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: "https://5d169668cac84e73bf0114bb9c787b52@sentry.io/1388703",
    release: version
  });

  ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
    gaOptions: { allowLinker: true }
  });

  ReactGA.plugin.require("linker", {
    domains: "https://event.lookpin.co.kr"
  });

  if (window.fbq != null) {
    window.fbq("init", "2006761072925839");
    window.fbq("track", "PageView", { event_id: uuidV4() });
  }

  window.criteo_q.push(
    { event: "setAccount", account: 65921 }, // You should never update this line
    { event: "setEmail", email: "" }, // Can be an empty string
    { event: "setSiteType", type: window.deviceType },
    { event: "viewHome" }
  );
  Tracking.init();

  Airbridge.init();
} else {
  const EX_GOOGLE_ANALYTICS_ID = "UA-75362862-6";

  ReactGA.initialize(EX_GOOGLE_ANALYTICS_ID, {
    gaOptions: { allowLinker: true }
  });

  ReactGA.plugin.require("linker", {
    domains: "https://develop--lp-event.netlify.app"
  });

  Airbridge.init();
}

const init = async () => {
  const url =
    process.env.REACT_APP_ENV === "production"
      ? "https://api.lookpin.co.kr/config.json"
      : "https://api.dev.lookpin.co.kr/config.json";

  let isMaintenance = false;
  let description = "";

  try {
    const res = await fetch(url);
    const data = await res.json();

    isMaintenance = data.server.is_maintenance;
  } catch (error) {
    isMaintenance = true;
    description = "서버에서 설정 정보를 가져오지 못하였습니다.\n점검중이오니 잠시만 기다려 주십시요.";
  }

  if (isMaintenance) {
    ReactDOM.render(<Inspection message={description} />, document.getElementById("root"));
  } else {
    ReactDOM.render(
      <Provider store={store}>
        <Router history={historyContext}>
          <Route path="/" component={App} />
        </Router>
      </Provider>,
      document.getElementById("root")
    );
  }
};

init();

serviceWorker.unregister();
