import React, { FC } from "react";
import { map, times, identity } from "ramda";

import { EmptyRateStarInReview, EmptyRateStar, FillRateStarInReview, FillRateStar } from "assets/icon";

interface IProp {
  starRating: number;
  reviewId: number;
  isSmall: boolean;
}

interface ISmallProp {
  isSmall: boolean;
}

interface IDrawProp extends ISmallProp {
  isFill: boolean;
}
export const DrawEmptyStar: FC<ISmallProp> = ({ isSmall }) => (isSmall ? <EmptyRateStarInReview /> : <EmptyRateStar />);

export const DrawFillStar: FC<ISmallProp> = ({ isSmall }) => (isSmall ? <FillRateStarInReview /> : <FillRateStar />);

export const DrawReviewStar: FC<IDrawProp> = ({ isSmall, isFill }) =>
  isFill ? <DrawFillStar isSmall={isSmall} /> : <DrawEmptyStar isSmall={isSmall} />;

export const ReviewStars: FC<IProp> = ({ starRating, reviewId, isSmall }) => {
  return (
    <div>
      {map(
        (count: number) => (
          <DrawReviewStar key={`${reviewId}${count}-star`} isSmall={isSmall} isFill={starRating >= count + 1} />
        ),
        times(identity, 5)
      )}
    </div>
  );
};
