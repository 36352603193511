import React, { ElementType } from "react";
import { Route, Redirect } from "react-router-dom";
import { IGNORE_PRODUCT_IDS } from "constant";

interface IProp {
  component: ElementType;
  path: string;
  exact?: true;
}

export default ({ component: Component, ...options }: IProp) => {
  return (
    <Route
      {...options}
      render={routerProps => {
        const productId = Number(routerProps.match.params.productId);
        if (Number.isNaN(productId)) {
          alert("잘못된 상품 번호입니다.");
          return <Redirect to="/" />;
        }
        if (IGNORE_PRODUCT_IDS.findIndex(id => id === productId) !== -1) {
          alert("웹에서는 구매할 수 없는 상품입니다.");
          return <Redirect to="/" />;
        }
        return <Component {...routerProps} />;
      }}
    />
  );
};
