import { fetcher } from "utils";
import { IOrderProductReviews } from "typings";

type MemberOrderProductReviewsFetch = () => Promise<IOrderProductReviews[]>;

export const api__get_members_order_product_reviews: MemberOrderProductReviewsFetch = async () => {
  const fetchReview = async (pageNum = 1, size = 100) => {
    const {
      data: { meta, order_product_reviews }
    } = await fetcher.get("/members/order_product_reviews", {
      page: { number: pageNum, size }
    });
    if (meta.next_page) {
      const nextData = await fetchReview(pageNum + 1);
      return [...nextData, ...order_product_reviews];
    }
    return order_product_reviews;
  };
  const reviewList = await fetchReview();
  return reviewList;
};
