import React, { useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames/bind";
import isEmail from "validator/lib/isEmail";

import { modalOff, modalOn, userLogin } from "actions/actionCreators";

import { isThereOnlyFalsyValueInObject } from "utils";

import { NaverLoginButton, KakaoLoginButton } from "components";

import {
  UI__MODAL_FIND_PASSWORD,
  UI__MODAL_EMAIL_SIGNUP,
  UI__FORM_FAILURE_EMAIL_INVALID,
  UI__FORM_FAILURE_PASSWORD_BLANK,
  UI__FORM_FAILURE_PASSWORD_INVALID
} from "constant";

import styles from "../../ModalWrapper.module.scss";

const cx = classNames.bind(styles);

export function LoginForm() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formFailure, setFormFailure] = useState({});

  const formSetter = ({ target: { name, value } }) => {
    if (name === "email") setEmail(value);
    else if (name === "password") setPassword(value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    const failure = {};

    if (!isEmail(email)) failure.UI__FORM_FAILURE_EMAIL_INVALID = true;
    if (!password) failure.UI__FORM_FAILURE_PASSWORD_BLANK = true;

    if (isThereOnlyFalsyValueInObject(failure)) dispatch(userLogin(email, password));
    else setFormFailure(failure);
  };

  return (
    <div className={cx("container")}>
      <div className={cx("header")}>
        로그인
        <button className={cx("close")} onClick={() => dispatch(modalOff())} type="button">
          {" "}
        </button>
      </div>

      <div className={cx("contents")}>
        <form onSubmit={handleSubmit}>
          <input name="email" onChange={formSetter} placeholder="이메일 주소" type="email" value={email} />
          {formFailure.UI__FORM_FAILURE_EMAIL_INVALID && (
            <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_EMAIL_INVALID}</div>
          )}

          <input
            autoComplete="on"
            name="password"
            onChange={formSetter}
            placeholder="비밀번호"
            type="password"
            value={password}
          />
          {formFailure.UI__FORM_FAILURE_PASSWORD_BLANK && (
            <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_PASSWORD_BLANK}</div>
          )}
          {formFailure.UI__FORM_FAILURE_PASSWORD_INVALID && (
            <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_PASSWORD_INVALID}</div>
          )}

          <div className={cx("btnWrapper")}>
            <button className={cx("login")} type="submit">
              이메일 로그인
            </button>
          </div>

          <div className={cx("login__modalFooter")}>
            <button
              className={cx("link--pwreset")}
              onClick={() => dispatch(modalOn(UI__MODAL_FIND_PASSWORD))}
              type="button"
            >
              <span>비밀번호 찾기</span>
            </button>

            <button
              className={cx("link--signup")}
              onClick={() => dispatch(modalOn(UI__MODAL_EMAIL_SIGNUP))}
              type="button"
            >
              <span>이메일 간편 회원가입</span>
            </button>

            <div className={cx("login__socialWrapper")}>
              <div>
                <KakaoLoginButton />
              </div>
              <div>
                <NaverLoginButton />
              </div>
              <div className={cx("auth")}><span>휴대폰인증을 완료하셨을 경우</span><span>모바일 APP에서 이메일 ID 찾기가 가능합니다</span></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
