import * as api from "api";
import { BUY_CONFIRM } from "constant";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IMemberOrderDetailViewModel, IMemeberOrderViewModelReNewal, IOrderProductNextStatusCamel } from "typings";
import { orderProductDetailViewModelCreator, ordersViewModelCreatorRenewal } from "view-model-creator";

export const useOrderDetail = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { orderNum, productOrderNum } = useParams() as { orderNum: string; productOrderNum: string };
  const [order, setOrder] = useState<IMemberOrderDetailViewModel>();
  const [etcOrders, setEtcOrders] = useState<Array<IMemeberOrderViewModelReNewal>>([]);
  const isClaim = useMemo(() => order && order.processGroup !== "base", [order]);
  const pending = useRef(false);

  const fetchOrderDetail = useCallback(() => {
    return Promise.all([
      api.get__api_v1_members_orderproducts({ order_order_num: orderNum, page: { number: 1, size: 100 } }),
      api.get__api__v1__members__order_products__order_num(productOrderNum)
    ]);
  }, [productOrderNum, orderNum]);

  const orderDetailSet = useCallback(async () => {
    const [fetchOrderListModel, fetchOrderModel] = await fetchOrderDetail();
    const targetOrder = orderProductDetailViewModelCreator(fetchOrderModel.data.payload);
    const orderListViewModel = ordersViewModelCreatorRenewal(fetchOrderListModel.data.payload);
    setEtcOrders(orderListViewModel.filter(order => order.productOrderNum !== targetOrder.productOrderNum));
    setOrder(targetOrder);
    setIsLoading(false);
  }, [fetchOrderDetail]);

  useEffect(() => {
    setIsLoading(true);
    orderDetailSet();
  }, [orderDetailSet]);

  const confirmMessage = useCallback(
    (status, otherMessage) => window.confirm(status === "confirm" ? BUY_CONFIRM : otherMessage),
    []
  );

  const memberStatusSetFetch = useCallback(
    async ({ productOrderNum, beforeStatus, status }) => {
      setIsLoading(true);
      await api.put__members__order_products__order_num(productOrderNum as string, {
        beforeStatus,
        status
      });
      setTimeout(() => {
        const fiterGroup = status === "confirm" ? "ship_done" : "claim";
        history.push(`/member/orders?filterGroup=${fiterGroup}&page=1&sort=updated_at`);
        setIsLoading(false);
        window.scroll(0, 0);
      }, 1000);
    },
    [history]
  );

  const actionClickEvent = useCallback(
    ({ orderNum, productOrderNum, beforeStatus }) => async ({
      actionType,
      status,
      extraInfo
    }: IOrderProductNextStatusCamel) => {
      if (!pending.current) {
        if (actionType === "dialog") {
          pending.current = true;
          if (confirmMessage(status, extraInfo.message))
            await memberStatusSetFetch({ productOrderNum, beforeStatus, status, extraInfo });

          pending.current = false;
        } else {
          history.push({
            pathname: `/member/orders/${orderNum}/${productOrderNum}/claim/${status}`
          });
        }
      }
    },
    [history, memberStatusSetFetch, confirmMessage]
  );
  return { order, actionClickEvent, isClaim, etcOrders, isLoading, orderDetailSet };
};
