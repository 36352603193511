import React, { memo, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames/bind";
import Loader from "react-loader-spinner";

import { formChecker, useInputs } from "utils";

import { modalOff, userSignUp } from "actions/actionCreators";

import {
  UI__FORM_FAILURE_EMAIL_INVALID,
  UI__FORM_FAILURE_PASSWORD_INVALID,
  UI__FORM_FAILURE_PASSWORD_CONFIRM_INVALID,
  UI__FORM_FAILURE_PASSWORD_NOT_EQUAL
} from "constant";

import styles from "../../ModalWrapper.module.scss";
import { ConditionComponent, NumberAuth } from "components";

const cx = classNames.bind(styles);

export const EmailSignup = memo(() => {
  const dispatch = useDispatch();

  const [state, onChange] = useInputs({
    email: "",
    password: "",
    passwordConfirm: ""
  });
  const [formFailure, setFormFailure] = useState({});
  const checker = formChecker(setFormFailure);
  const { email, password, passwordConfirm } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [isNumberAuth, setIsNumberAuth] = useState(false);
  const [isMarketingAgree, setIsMarketingAgree] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const numberAuthCallbackFunction = useCallback(({ phoneNumber, marketingAgree }) => {
    setPhoneNumber(phoneNumber);
    setIsMarketingAgree(marketingAgree);
    setIsNumberAuth(true);
  }, []);

  const handleSubmit = async event => {
    event.preventDefault();

    if (checker(state)) {
      setIsLoading(true);
      await dispatch(userSignUp(email, password, phoneNumber, isMarketingAgree));
      setIsLoading(false);
    }
  };

  return (
    <ConditionComponent
      condition={isNumberAuth}
      FallBackComponent={() => <NumberAuth callbackFunction={numberAuthCallbackFunction} />}
    >
      <div className={cx("container")}>
        <div className={cx("header")}>
          회원가입
          <button
            className={cx("close")}
            onClick={() => {
              setIsNumberAuth(false);
              dispatch(modalOff());
            }}
            type="button"
          >
            {" "}
          </button>
        </div>

        <div className={cx("contents")}>
          <form onSubmit={handleSubmit}>
            <div className={cx("input-title")}>{email && "이메일 주소"}</div>
            <input type="email" placeholder="이메일 주소" name="email" value={email} onChange={onChange} />
            {formFailure.UI__FORM_FAILURE_EMAIL_INVALID && (
              <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_EMAIL_INVALID}</div>
            )}
            <div className={cx("input-title")}>{password && "비밀번호 (8~20자 영문, 숫자 조합)"}</div>
            <input
              type="password"
              placeholder="비밀번호 (8~20자 영문, 숫자 조합)"
              name="password"
              value={password}
              onChange={onChange}
            />
            {formFailure.UI__FORM_FAILURE_PASSWORD_INVALID && (
              <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_PASSWORD_INVALID}</div>
            )}
            <div className={cx("input-title")}>{passwordConfirm && "비밀번호 확인"}</div>
            <input
              type="password"
              placeholder="비밀번호 확인"
              name="passwordConfirm"
              value={passwordConfirm}
              onChange={onChange}
            />
            {formFailure.UI__FORM_FAILURE_PASSWORD_CONFIRM_INVALID && (
              <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_PASSWORD_CONFIRM_INVALID}</div>
            )}
            {formFailure.UI__FORM_FAILURE_PASSWORD_NOT_EQUAL && (
              <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_PASSWORD_NOT_EQUAL}</div>
            )}

            <div className={cx("btnWrapper")}>
              <button className={cx("login", { "btn-loading": isLoading })} type="submit">
                {isLoading ? (
                  <Loader type="Oval" color="#ffffff" height={20} width={20} visible style={{ margin: "0 auto" }} />
                ) : (
                  "가입하기"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </ConditionComponent>
  );
});
