import { EventPosition } from "typings";

export const AIRBRIDGE_DEV_APP_ID = "lookpindev";
export const AIRBRIDGE_DEV_WEB_TOKEN = "0f4be104d3ad49e485b7862d023d336c";
export const AIRBRIDGE_PROD_APP_ID = "lookpin";
export const AIRBRIDGE_PROD_WEB_TOKEN = "157ef02ec9b247f0b7316f5743258dc9";

export const GUEST_ID = "LOOKPIN_WEB_GUEST";
export const GUEST_TOKEN = "1da4a3b96c5ce56389568db936c9f38b";
export const GOOGLE_ANALYTICS_ID = "UA-75362862-5";
export const GOOGLE_TAG_MANAGER_ID = "GTM-5LLQZHJ";

export const WOMEN = "women";
export const MEN = "men";

export const PRODUCT = "PRODUCT";
export const STORE = "STORE";

export const KAKAO = "kakao" as const;
export const KAKAO_CLIENT_ID = "8eccd61991fd07fa6bf35451c43eda4e";

export const NAVER = "naver" as const;
export const NAVER_CLIENT_ID = "LWdrNlrBuhBSYvNj3X2S";
export const NAVER_CALLBACK_URL = "https://lookpin.co.kr/naver";
export const LOOKPIN_KAKAO_LINK = "https://pf.kakao.com/_svdpd";
export const LINK_TO_TERMS = "https://cs.lookpin.co.kr/terms/service";
export const LINK_TO_PRIVACY = "https://cs.lookpin.co.kr/terms/privacy";
export const LINK_TO_MARKETING = "https://cs.lookpin.co.kr/terms/marketing";
export const LINK_TO_USE_OF_PRIVACY = "https://cs.lookpin.co.kr/terms/use_of_privacy";
export const LOOKPIN_STORE = "https://business.lookpin.co.kr";
export const KAKAO_AD_TRACK_ID = "3166427678322373116";

export const PERSONAL_INFO_URL = "https://drive.google.com/file/d/0Bysj1QOXXATueTBWN19NVXRNcjA/view?usp=sharing";
export const PRIVACY_URL =
  "https://docs.google.com/document/d/1zeufxki2AxmgUbnipjpteD4fEVbkprsFjWhAlU6tp7Y/edit?usp=sharing";
export const TERMS_URL =
  "https://docs.google.com/document/d/1_KR7nRvkrRjV6H0KBGa8ScG7vdIrNFS1di-qd4bJXXc/edit?usp=sharing";

export const UI__IS_LOADING = "UI__IS_LOADING";

export const UI__MODAL_ALREADY_ACCOUNT_LOGIN = "UI__MODAL_ALREADY_ACCOUNT_LOGIN";
export const UI__MODAL_DISCONNECT_PROVIDER = "UI__MODAL_DISCONNECT_PROVIDER";
export const UI__MODAL_EMAIL_SIGNUP = "UI__MODAL_EMAIL_SIGNUP";
export const UI__MODAL_FILL_EMAIL = "UI__MODAL_FILL_EMAIL";
export const UI__MODAL_FIND_PASSWORD = "UI__MODAL_FIND_PASSWORD";
export const UI__MODAL_LOGIN = "UI__MODAL_LOGIN";
export const UI__MODAL_UPDATE_PASSWORD = "UI__MODAL_UPDATE_PASSWORD";
export const UI__MODAL_ISSUABLE_COUPON = "UI__MODAL_ISSUABLE_COUPON";
export const UI__MODAL_APPLY_COUPON = "UI__MODAL_APPLY_COUPON";
export const UI__MODAL_COUPON_REGISTER = "UI__MODAL_COUPON_REGISTER";
export const UI__MODAL_PICKUP_ADDRESS = "UI__MODAL_PICKUP_ADDRESS";
export const UI__MODAL_PRODUCT_QNA = "UI__MODAL_PRODUCT_QNA";
export const UI__MODAL_NUMBER_AUTH = "UI__MODAL_NUMBER_AUTH";
export const UI__MODAL_SIMPLE_REVIEW = "UI__MODAL_SIMPLE_REVIEW";
export const UI__MODAL_NORMAL_PHOTO_REVIEW = "UI__MODAL_NORMAL_PHOTO_REVIEW";
export const UI__MODAL_DETAIL_PHOTO_REVIEW = "UI__MODAL_DETAIL_PHOTO_REVIEW";
export const UI__MODAL_GRID_PHOTO_REVIEW = "UI__MODAL_GRID_PHOTO_REVIEW";
export const UI__MODAL_REVIEW_REPLACE = "UI__MODAL_REVIEW_REPLACE";
export const UI__SET_MOBILE = "UI__SET_MOBILE";
export const UI__SET_GENDER = "UI__SET_GENDER";
export const UI__MODAL_ON = "UI__MODAL_ON";
export const UI__MODAL_OFF = "UI__MODAL_OFF";
export const UI__MODAL_CALLBACK_SET = "UI__MODAL_CALLBACK_SET";
export const APPLY__COUPON_DATA_SET = "APPLY__COUPON_DATA_SET";
export const PHOTO_REVIEW_PHOTO_MODAL_SET = "PHOTO_REVIEW_PHOTO_MODAL_SET";
export const PHOTO_REVIEW_OPEN_DETAIL_MODAL = "PHOTO_REVIEW_OPEN_DETAIL_MODAL";
export const PHOTO_REVIEW_CLOSE_DETAIL_MODAL = "PHOTO_REVIEW_CLOSE_DETAIL_MODAL";
export const PHOTO_REVIEW_ONLY_CLOSE_DETAIL_MODAL = "PHOTO_REVIEW_ONLY_CLOSE_DETAIL_MODAL";
export const PHOTO_REVIEW_FETCH_REQUEST = "PHOTO_REVIEW_FETCH_REQUEST";
export const PHOTO_REVIEW_FETCH_SUCCESS = "PHOTO_REVIEW_FETCH_SUCCESS";
export const PHOTO_REVIEW_FETCH_FAILURE = "PHOTO_REVIEW_FETCH_FAILURE";
export const PHOTO_REVIEW_SET__PHOTO_REVIEW_TOTAL_COUNT = "PHOTO_REVIEW_SET__PHOTO_REVIEW_TOTAL_COUNT";
export const PHOTO_REVIEW_INIT_REQUSET = "PHOTO_REVIEW_INIT_REQUSET";
export const PHOTO_REVIEW_DETAIL_CHANGE_BY_ARROW = "PHOTO_REVIEW_DETAIL_CHANGE_BY_ARROW";
export const PHOTO_REVIEW_CLOSE_GRID_MODAL = "PHOTO_REVIEW_CLOSE_GRID_MODAL";
export const REVIEW_WRITE_DATA_SET = "REVIEW_WRITE_DATA_SET";

export const ALERT_MESSAGE_AT_NOT_CHROME =
  "현재 룩핀웹 버전은 크롬 브라우저에 최적화 되어있습니다.\n익스플로러는 안정화 작업 중에 있으며, 화면 깨짐 현상 및 버그가 발생할 수 있으므로 가급적이면 크롬에서 사용하길 권장드립니다.\n*화면에 컨텐츠가 표시되지 않을 경우 브라우저 히스토리를 정리해주세요.";

export const UI__FORM_FAILURE_EMAIL_INVALID = "올바른 이메일을 입력해주세요.";
export const UI__FORM_FAILURE_PASSWORD_BLANK = "비밀번호를 입력해주세요.";
export const UI__FORM_FAILURE_PASSWORD_INVALID = "8 ~ 20자 영문, 숫자 조합으로 입력해주세요.";
export const UI__FORM_FAILURE_PASSWORD_CONFIRM_INVALID = "8 ~ 20자 영문, 숫자 조합으로 입력해주세요.";
export const UI__FORM_FAILURE_PASSWORD_NOT_EQUAL = "비밀번호와 비밀번호 확인이 일치하지 않습니다.";
export const UI__FORM_FAILURE_PASSWORD_NOT_CHANGE =
  "현재 비밀번호와 새로운 비밀번호가 같습니다, 다른 비밀번호를 설정해주세요.";
export const UI__FORM_FAILURE_PASSWORD_CURRENT_NOT_CORRECT = "현재 비밀번호가 맞지 않습니다.";

export const INITIALIZE = "INITIALIZE";
export const INITIALIZE_SETS = "INITIALIZE_SETS";
export const LOGOUT = "LOGOUT";
export const SYNC = "SYNC";
export const ADD_SETS_ITEM = "ADD_SETS_ITEM";
export const REMOVE_SETS_ITEM = "REMOVE_SETS_ITEM";
export const CHANGE_SETS_ITEM = "CHANGE_SETS_ITEM";

export const SALE = "SALE";
export const SOLDOUT_OPTION = "SOLDOUT_OPTION";
export const SOLDOUT_PRODUCT = "SOLDOUT_PRODUCT";
export const SALE_STOP = "SALE_STOP";

export const AUTO = "auto";
export const SELF = "self";

export const NOTICE = {
  ORDER_SELECT: "주문하실 아이템을 선택해주세요.",
  COUPON_SELECT: "보유하신 쿠폰이 없습니다.",
  DELETE: "정말 삭제하시겠습니까?",
  NEED_SELECT_OPTION: "옵션을 선택해주세요!",
  FOR_ORDER: (length: number) => `품절 상품이 ${length}개 포함되어 있습니다.\n품절 상품을 제외하고 주문하시겠습니까?`,
  LACK_ORDER_POINT: "보유적립금이 1000원 미만이어서 사용할 수 없습니다.",
  UNUSABLE_ORDER_POINT: "10,000원 이하의 주문 금액은 적립금 사용이 불가합니다.",
  UNEXIST_APPLIABLE_PRODUCT: "적용 가능한 쿠폰이 없습니다.",
  RELEASABLE_PRODUCT: (optionNames: string) =>
    `기존 쿠폰이 조건을 충족하지 못해, 다음 상품의 쿠폰이 해제됩니다.\n${optionNames}\n계속 하시겠습니까?`,
  RELEASABLE_COUPON: (couponName: string) => `'${couponName}' 쿠폰이 해제됩니다.\n계속하시겠습니까?`,
  BRING_COUPON: `쿠폰이 적용되어있던 다른 상품에서 쿠폰을 해제하고 선택한 상품에 적용하시겠습니까?`,
  CHECK_CONDITION: `쿠폰 사용 조건을 확인해주세요!`,
  MAXIMUM_STOCK_COUNT: `구매할 수 있는 수량을 초과했습니다.`,
  AFTER_AUTH_EXIRE: "입력하신 정보가 30분이 초과 되었습니다.\n휴대폰번호인증 절차를 다시 진행해주세요",
  NUMBER_AUTH_EXPIRE: "유효시간 초과\n인증번호를 다시 요청해주세요",
  AUTH_SUCCESS: "인증되었습니다",
  MOST_TERMS_OF_USE_AGREE: "필수 약관을 동의해주세요",
  SENDING_AUTH_NUMBER: "인증번호가 발송되었습니다.",
  PHONE_NUMBER_CHECK: "휴대폰번호 11자리를 입력해주세요"
};

export const TRENDING = "trending" as const;
export const NEWEST = "newest" as const;
export const EXPENSIVE = "expensive" as const;
export const CHEAP = "cheap" as const;
export const RISING = "rising" as const;
export const RECOMMENDING = "recommending" as const;

export const ORDERS = {
  [TRENDING]: "인기순",
  [NEWEST]: "신상품순",
  [EXPENSIVE]: "높은가격순",
  [CHEAP]: "낮은가격순",
  [RISING]: "급상승"
};

export const BOOKMARK = "bookmark";

export const COORDI_VIEW_SIZE = 3 * 8;
export const PHOTO_GRID_PAGE_SIZE = 7 * 7;
export const PHOTO_EXAMPLE_SIZE = 10;

export const CART = "cart";
export const PRODUCT_DETAIL = "product_detail";

export enum SATISFACTION {
  좋아요 = "good",
  크네요 = "soso",
  작네요 = "bad",
  빠르네요 = "good",
  괜찮네요 = "soso",
  느리네요 = "bad",
  똑같아요 = "good",
  비슷해요 = "soso",
  다르네요 = "bad"
}
export const SATISFACTION_VALUE = {
  size: {
    good: "좋아요",
    soso: "크네요",
    bad: "작네요"
  },
  color: {
    good: "똑같아요",
    soso: "비슷해요",
    bad: "다르네요"
  },
  shipping: {
    good: "빠르네요",
    soso: "괜찮네요",
    bad: "느리네요"
  }
};

export const SATISFACTION_KEY = {
  size: {
    good: "fine",
    soso: "big",
    bad: "small"
  },
  color: {
    good: "same",
    soso: "fine",
    bad: "different"
  },
  shipping: {
    good: "fast",
    soso: "fine",
    bad: "slow"
  }
};

export enum REVIEWTYPE {
  lookpinReview = "룩핀 리뷰",
  cremaReview = "쇼핑몰 리뷰"
}

export const ORDER_PRODUCT_REVIEW = "order_product_review";
export const CREMA_PRODUCT_REVIEW = "crema_product_review";

export const LOOKPIN_REVIEW = "lookpinReview";
export const CREMA_REVIEW = "cremaReview";

export const ESC_KEYCODE = 27;
export const RIGHT_KEYCODE = 39;
export const LEFT_KEYCODE = 37;

export const BUY_CONFIRM = "구매확정 이후에는 반품/교환이 불가합니다. 구매확정 하시겠습니까?";

export const ORDER_PRODUCT_STATUS = {
  pay: "입금/결제",
  ship_wait: "배송준비중",
  ship_ing: "배송중",
  ship_done: "배송완료",
  claim: "취소 / 교환 / 환불"
};

export const ORDER_PRODUCT_LINK_URL = {
  pay: "/member/orders?filterGroup=pay&page=1&sort=updated_at",
  ship_wait: "/member/orders?filterGroup=ship_wait&page=1&sort=updated_at",
  ship_ing: "/member/orders?filterGroup=ship_ing&page=1&sort=updated_at",
  ship_done: "/member/orders?filterGroup=ship_done&page=1&sort=updated_at",
  claim: "/member/orders?filterGroup=claim&page=1&sort=updated_at"
};

export const ORDER_PRODUCT_SORT = {
  created_at: "주문일자순",
  updated_at: "업데이트순"
};

export enum ORDER_PRODUCT_QUERY_KEYS {
  SORT = "sort",
  FILTER_GROUP = "filterGroup",
  MONTH_DIFF = "monthDiff",
  PAGE = "page"
}

export enum EMPTY_ORDER_PRODUCT_TEXT {
  pay = "입금/결제한 주문이 없습니다.",
  ship_wait = "배송준비중인 주문이 없습니다.",
  ship_ing = "배송중인 주문이 없습니다.",
  ship_done = "배송완료한 주문이 없습니다.",
  claim = "취소/교환/환불한 주문이 없습니다."
}

export const NOTICE_ABOUT_PRODUCT_BOLD_KEYS = ["AS전화번호", "반송지", "교환/반품택배비", "입금계좌"];

/**
 * product ignore 시킬 아이디 수동으로 넣어주기
 */
export const IGNORE_PRODUCT_IDS = [];

export const CLAIM_TEXT = {
  cancel: "취소",
  refund: "환불",
  change: "교환"
};

export const CLAIM_DESCRIPTION = {
  change: `교환상품과 교환택배비(구매자 사유의 경우)가 확인되면 판매자가 재배송처리를 진행합니다.\n재배송이 시작되면 주문내역에서 운송장번호를 확인할 수 있습니다.\n\nMY룩핀 > 주문내역의 [문의하기] 로 교환 원하는 상품의 옵션을 판매자에게 전달해주세요.`,
  cancel: `구매하신 상품의 판매자가 취소승인을 하면 주문취소가 완료됩니다.\n결제취소 및 입금은 취소완료일로부터 평균 3일 (영업일 기준)정도 소요됩니다.\n\n가상계좌/휴대폰결제의 경우 꼭 환불계좌를 입력해주세요.`,
  refund: `환불금액 입금 또는 카드취소는 환불완료일로부터 평균 3일(영업일 기준)정도 소요됩니다.\n반품상품과 반품택배비(구매자 사유의 경우)가 확인되면 판매자가 환불승인 처리합니다.\n\n가상계좌/휴대폰결제의 경우 꼭 환불계좌를 입력해주세요.`
};

export const CLAIM_PROCESS = {
  cancel: [
    { text: "취소접수", key: "cancel_wait" },
    { text: "취소승인", key: "cancel_soon" },
    { text: "취소완료", key: "cancel_done" }
  ],
  refund: [
    { text: "환불접수", key: "refund_wait" },
    { text: "수거완료", key: "refund_pickup" },
    { text: "환불승인", key: "refund_soon" },
    { text: "환불완료", key: "refund_done" }
  ],
  change: [
    { text: "교환접수", key: "change_wait" },
    { text: "수거완료", key: "change_pickup" },
    { text: "재배송처리", key: "re_ship" },
    { text: "배송완료", key: "change_done" }
  ]
};

export const EXOIRATION_TIME = 3 * 60;

export const SIGNUP_DESCRIPTION = `- 14세 미만 회원은 회원가입이 불가합니다.\n- 법인폰 사용자는 휴대폰 인증이 불가합니다.\n- 인증이 안 될 경우, 문의하기 버튼을 이용해 주세요.`;
export const EVENT_POSITIONS: EventPosition[] = [
  "today_top",
  "today_bottom",
  "rank_bottom",
  "sale_middle",
  "launch_popup",
  "home_main_top"
];

export const categoryKorToEng = {
  주문: "order",
  상품: "product",
  배송: "delivery",
  "취소 / 환불": "cancel_or_refund",
  교환: "exchange",
  계정: "account",
  쿠폰: "coupon",
  "룩핀 이용방법": "lookpin_guide",
  기타: "etc"
};

export const ReviewColorMap = {
  똑같아요: "same",
  비슷해요: "fine",
  다르네요: "different"
};

export const ReviewSizeMap = {
  좋아요: "fine",
  크네요: "big",
  작네요: "small"
};

export const ReviewShiipingMap = {
  빠르네요: "fast",
  괜찮네요: "fine",
  느리네요: "slow"
};
