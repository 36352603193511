import React from "react";
import { useLocation } from "react-router-dom";

import "./LogoutBtn.scss";

interface IProps {
  logout: () => void;
}

export default function LogoutBtn({ logout }: IProps) {
  if( window.location.pathname === '/2021chuseok') return null;

  const location = useLocation();

  return ["/cart", "/order", "/coupons", "/order/coupon"].includes(location.pathname) ? null : (
    <div className="logout" style={location.pathname.includes("/products") ? { marginBottom: "70px" } : {}}>
      <button type="button" className="logout__btn" onClick={logout}>
        로그아웃
      </button>
    </div>
  );
}
