import { fetcher } from "utils";

import { Context, IJSendSuccessResponse } from "typings";

interface IMembersOrdersPrepareRequest {
  optionIds: number[];
  context: Context;
}

// prepare 발동조건
// 사용타입 === multiple
// 사용세부전략 충족
// 최소사용제한금액 충족
// 주문하기에서 적용가능한 쿠폰이 오직 한 가지일 때

export const post__members__orders__prepare = (
  params: IMembersOrdersPrepareRequest
): Promise<IJSendSuccessResponse<{
  payload: {
    issued_discount_coupon_id: number;
    option_ids: number[];
  }[];
  meta: {
    notice?: string;
  };
}>> => fetcher.post("/members/orders/prepare", params);
