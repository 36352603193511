import React, { FC, useCallback } from "react";

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import classNames from "classnames/bind";
import { modalOn } from "actions/actionCreators";

import { IStore } from "typings";

import { UI__MODAL_LOGIN, UI__MODAL_ISSUABLE_COUPON } from "constant";

import { IconDownload } from "assets/icon";

import styles from "./CouponIssuableButton.module.scss";

const cx = classNames.bind(styles);

interface ICouponIssuableButton {
  isIssuable: boolean;
}

export const CouponIssuableButton: FC<ICouponIssuableButton> = ({ isIssuable }) => {
  const dispatch = useDispatch();
  const token = useSelector<IStore, IStore["member"]["token"]>(({ member: { token } }) => token, shallowEqual);

  const handleClick = useCallback(() => {
    dispatch(modalOn(token ? UI__MODAL_ISSUABLE_COUPON : UI__MODAL_LOGIN));
  }, [dispatch, token]);

  return (
    <div className={cx("coupon-container")} hidden={!isIssuable}>
      <span className={cx("method-txt")}>쿠폰</span>
      <button type="button" className={cx("usable-coupon", ["bold"])} onClick={handleClick}>
        발급 가능 쿠폰
        <IconDownload />
      </button>
    </div>
  );
};
