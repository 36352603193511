import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./SimpleProduct.module.scss";

interface IProp {
  product: {
    productTitle: string;
    productImage?: string;
    storeName: string;
  };
}
const cx = classNames.bind(styles);

export const SimpleProductRow: FC<IProp> = ({ product: { productImage, productTitle, storeName } }) => {
  return (
    <div className={cx("wrapper")}>
      <div className={cx("image-wrapper")}>
        <img className={cx("image")} src={productImage} alt={productTitle} />
      </div>
      <div className={cx("name-wrapper")}>
        <div className={cx("store-name")}>판매처: {storeName}</div>
        <div className={cx("product-title")}>{productTitle}</div>
      </div>
    </div>
  );
};
