import { OrderProductContactSerialized, IContactsViewModel } from "typings";

export const contactsViewModelCreator = (contacts: OrderProductContactSerialized[]): IContactsViewModel[] =>
  contacts.map(
    ({
      id,
      contents,
      created_at,
      order_product: {
        product: { photo_url, name }
      },
      response
    }): IContactsViewModel => ({
      contactId: id,
      contents,
      createdAt: created_at,
      thumbnail: photo_url,
      productName: name,
      response: response
        ? {
            responseCreatedAt: response.created_at,
            responseContents: response.contents,
            storeName: `${response.store.name} 답변`,
            flip: false
          }
        : {
            responseCreatedAt: null,
            responseContents: "답변이 아직 달리지 않았습니다.",
            storeName: null,
            flip: false
          }
    })
  );
