/* eslint-disable no-underscore-dangle */
import { GOOGLE_TAG_MANAGER_ID } from "constant";
import { kakaoPixelContext, storageContext } from "contexts";
import sha256 from "crypto-js/sha256";
import TagManager from "react-gtm-module";
import * as api from "api";
import { v4 as uuidV4 } from "uuid";
import { getCookie } from "./cookie";

class Tracking {
  private ttq: any;

  private productionMode = process.env.REACT_APP_ENV === "production";

  private fbc = getCookie("_fbc") || undefined;

  private fbp = getCookie("_fbp") || undefined;

  constructor() {
    if (window.ttq) {
      this.ttq = window.ttq;
    }
  }

  init() {
    if (!this.productionMode) return;

    TagManager.initialize({
      gtmId: GOOGLE_TAG_MANAGER_ID
    });

    TagManager.dataLayer({
      dataLayer: {
        event: "Visit",
        uuid: storageContext.uuid
      }
    });
    if (this.ttq) this.ttq.track("Browse");
  }

  memeberSetting({ email, phoneNumber }) {
    if (!this.productionMode) return;

    if (this.ttq) {
      this.ttq.identify({
        sha256_email: sha256(email).toString(),
        sha256_phone_number: sha256(phoneNumber).toString()
      });
    }
  }

  login(token) {
    if (!this.productionMode) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "Login",
        token
      }
    });
  }

  search(keyword) {
    if (!this.productionMode) return;

    if (kakaoPixelContext) kakaoPixelContext.search({ keyword });
    if (this.ttq) this.ttq.track("Search", { query: keyword });
    TagManager.dataLayer({
      dataLayer: {
        event: "Search",
        keyword
      }
    });
  }

  viewContent(param) {
    if (!this.productionMode) return;

    const event_name = "ViewContent";
    const event_id = uuidV4();
    api.post__api__v1__pixel__action({
      event_name,
      facebook: {
        fbp: this.fbp,
        fbc: this.fbc,
        event_cost: param.value
      },
      contents: [
        {
          id: parseInt(param.content_ids[0], 10),
          item_price: param.value,
          quantity: 1
        }
      ],
      content_type: "product",
      event_source_url: window.location.href,
      event_id
    });
    TagManager.dataLayer({
      dataLayer: {
        event: event_name
      }
    });
    if (this.ttq) this.ttq.track(event_name);
    if (kakaoPixelContext) kakaoPixelContext.viewContent({ id: param.content_ids[0] });
    if (window.fbq) window.fbq("track", event_name, { ...param }, { eventID: event_id });
  }

  viewCart() {
    if (!this.productionMode) return;

    if (kakaoPixelContext) kakaoPixelContext.viewCart();
    TagManager.dataLayer({
      dataLayer: {
        event: "ViewCart"
      }
    });
  }

  viewCoordiLike() {
    if (!this.productionMode) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "ViewCoordiLike"
      }
    });
  }

  viewWishlist() {
    if (!this.productionMode) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "ViewWishlist"
      }
    });
  }

  purchaseResult(totalPrice) {
    if (!this.productionMode) return;
    if (!window.wcs) return;

    const totalNumber = totalPrice
      .split("")
      .filter(s => s !== "원" && s !== ",")
      .join("");

    window._nasa.cnv = window.wcs.cnv("1", totalNumber);
    window.wcs_do(window._nasa);
  }

  refineCost(value) {
    if (typeof value === 'string') {
      return Number(value.replace(/,|원/ig, ''));
    }
    return value;
  }

  purchase(actionField, products) {
    if (!this.productionMode) return;

    const event_name = "Purchase";
    const currency = "KRW";
    const event_id = actionField.id.toString();
    const contents = products.map(({ product_id, price, quantity }) => ({
      id: product_id,
      item_price: price,
      quantity
    }));
    api.post__api__v1__pixel__action({
      event_name,
      facebook: {
        fbp: this.fbp,
        fbc: this.fbc,
        event_cost: this.refineCost(actionField.revenue)
      },
      contents,
      event_source_url: window.location.href,
      event_id,
      content_type: "product"
    });
    if (window.fbq) {
      window.fbq(
        "track",
        event_name,
        {
          contents: contents.map(content => ({ ...content, id: content.id.toString() })),
          content_type: "product",
          value: actionField.revenue,
          currency
        },
        { eventID: event_id }
      );
    }
    if (window.wcs) {
      const totalPrice = products.reduce((acc, { price }) => acc + price, 0);
      window._nasa.cnv = window.wcs.cnv("1", totalPrice.toString());
      window.wcs_do(window._nasa);
    }
    if (this.ttq) {
      this.ttq.track(event_name, {
        content: products.map(({ name, id, price, quantity }) => ({
          content_id: id,
          content_name: name,
          quantity,
          price,
          currency
        }))
      });
    }
    const products_info = products.map(({ name, id, price, brand, variant, quantity }) => ({
      name,
      id,
      price,
      brand,
      variant,
      quantity
    }));
    TagManager.dataLayer({
      dataLayer: {
        event: event_name,
        ecommerce: {
          purchase: {
            actionField
          },
          products_info
        }
      },
      gtmId: "GTM-5LLQZHJ"
    });
  }

  completeRegistration() {
    if (!this.productionMode) return;

    const event_name = "CompleteRegistration";
    const event_id = uuidV4();
    api.post__api__v1__pixel__action({
      event_name,
      event_source_url: window.location.href,
      facebook: {
        fbp: this.fbp,
        fbc: this.fbc,
        event_cost: 1
      },
      content_type: "registration",
      event_id
    });
    if (window.wcs) {
      window._nasa.cnv = window.wcs.cnv("2", 1);
      window.wcs_do(window._nasa);
    }
    if (window.fbq)
      window.fbq(
        "track",
        event_name,
        {
          content_name: "Signup",
          status: true
        },
        { eventID: event_id }
      );
    if (this.ttq) this.ttq.track("Registration");
    if (kakaoPixelContext) kakaoPixelContext.completeRegistration();
    TagManager.dataLayer({
      dataLayer: {
        event: event_name
      }
    });
  }

  suggestMobileApp() {
    if (!this.productionMode) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "SuggestMobileApp"
      }
    });
  }

  addToCart(param) {
    if (!this.productionMode) return;

    const event_name = "AddToCart";
    const currency = "KRW";
    const content_type = "product";
    const event_id = uuidV4();
    const contents = [
      {
        id: parseInt(param.content_ids[0], 10),
        item_price: param.value,
        quantity: 1
      }
    ];
    api.post__api__v1__pixel__action({
      event_name,
      facebook: {
        fbp: this.fbp,
        fbc: this.fbc,
        event_cost: param.value
      },
      contents,
      event_source_url: window.location.href,
      event_id,
      content_type
    });
    if (window.wcs) {
      window._nasa.cnv = window.wcs.cnv("3", 1000);
      window.wcs_do(window._nasa);
    }
    if (window.fbq) {
      window.fbq(
        "track",
        event_name,
        {
          content_name: param.content_name,
          contents: contents.map(content => ({ ...content, id: content.id.toString() })),
          content_type,
          value: param.value,
          currency
        },
        { eventID: event_id }
      );
    }
    if (this.ttq) {
      this.ttq.track(event_name, {
        content_id: param.content_ids[0],
        content_type,
        content_name: param.content_name,
        quantity: 1,
        value: param.value,
        price: param.value,
        currency
      });
    }
    TagManager.dataLayer({
      dataLayer: {
        event: event_name
      }
    });
  }

  addToCoordi() {
    if (!this.productionMode) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "AddToCoordi"
      }
    });
  }

  addToWishList(params) {
    if (!this.productionMode) return;

    const event_name = "AddToWishlist";
    const event_id = uuidV4();
    api.post__api__v1__pixel__action({
      event_name,
      facebook: {
        fbp: this.fbp,
        fbc: this.fbc,
        event_cost: params.value
      },
      contents: [
        {
          id: parseInt(params.content_ids[0], 10),
          item_price: params.value,
          quantity: 1
        }
      ],
      event_source_url: window.location.href,
      event_id,
      content_type: "product"
    });
    if (window.fbq) {
      window.fbq("track", event_name, params, { eventID: event_id });
    }
    TagManager.dataLayer({
      dataLayer: {
        event: event_name
      }
    });
  }

  pageChange() {
    if (!this.productionMode) return;

    if (window.wcs) {
      window._nasa = {};
      window.wcs_do(window._nasa);
    }
  }
}

export default new Tracking();
