import React from "react";
import { useSelector } from "react-redux";

import {
  AlreadyAccountLogin,
  CouponApply,
  DisconnectProvider,
  CouponIssuable,
  EmailSignup,
  FillEmail,
  FindPassword,
  LoginForm,
  UpdatePassword,
  ModalWrapper,
  CouponRegister,
  PickUpAddress,
  NumberAuth,
  SimpleReview,
  NormalPhotoReview,
  DetailPhotoReview,
  ProductQnA,
  GridPhotoReview
} from "components";

import {
  UI__MODAL_ALREADY_ACCOUNT_LOGIN,
  UI__MODAL_DISCONNECT_PROVIDER,
  UI__MODAL_EMAIL_SIGNUP,
  UI__MODAL_FILL_EMAIL,
  UI__MODAL_FIND_PASSWORD,
  UI__MODAL_LOGIN,
  UI__MODAL_UPDATE_PASSWORD,
  UI__MODAL_ISSUABLE_COUPON,
  UI__MODAL_APPLY_COUPON,
  UI__MODAL_COUPON_REGISTER,
  UI__MODAL_PICKUP_ADDRESS,
  UI__MODAL_PRODUCT_QNA,
  UI__MODAL_NUMBER_AUTH,
  UI__MODAL_NORMAL_PHOTO_REVIEW,
  UI__MODAL_SIMPLE_REVIEW,
  UI__MODAL_DETAIL_PHOTO_REVIEW,
  UI__MODAL_GRID_PHOTO_REVIEW,
  UI__MODAL_REVIEW_REPLACE
} from "constant";

import { IStore } from "typings";
import { ReviewReplace } from "./modal-content/review-wrtie/ReviewReplace";

export const ModalContainer = () => {
  const modalStatus = useSelector<IStore, IStore["ui"]["modalStatus"]>(({ ui: { modalStatus } }) => modalStatus);
  const { modalCallbackFunction } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);
  return (
    <ModalWrapper>
      {(() => {
        switch (modalStatus) {
          case UI__MODAL_ALREADY_ACCOUNT_LOGIN:
            return <AlreadyAccountLogin />;
          case UI__MODAL_DISCONNECT_PROVIDER:
            return <DisconnectProvider />;
          case UI__MODAL_EMAIL_SIGNUP:
            return <EmailSignup />;
          case UI__MODAL_FILL_EMAIL:
            return <FillEmail />;
          case UI__MODAL_FIND_PASSWORD:
            return <FindPassword />;
          case UI__MODAL_LOGIN:
            return <LoginForm />;
          case UI__MODAL_UPDATE_PASSWORD:
            return <UpdatePassword />;
          case UI__MODAL_ISSUABLE_COUPON:
            return <CouponIssuable />;
          case UI__MODAL_APPLY_COUPON:
            return <CouponApply />;
          case UI__MODAL_COUPON_REGISTER:
            return <CouponRegister />;
          case UI__MODAL_PICKUP_ADDRESS:
            return <PickUpAddress />;
          case UI__MODAL_PRODUCT_QNA:
            return <ProductQnA />;
          case UI__MODAL_NUMBER_AUTH:
            return <NumberAuth callbackFunction={modalCallbackFunction} />;
          case UI__MODAL_NORMAL_PHOTO_REVIEW:
            return <NormalPhotoReview callbackFunction={modalCallbackFunction} />;
          case UI__MODAL_SIMPLE_REVIEW:
            return <SimpleReview callbackFunction={modalCallbackFunction} />;
          case UI__MODAL_DETAIL_PHOTO_REVIEW:
            return <DetailPhotoReview />;
          case UI__MODAL_GRID_PHOTO_REVIEW:
            return <GridPhotoReview />;
          case UI__MODAL_REVIEW_REPLACE:
            return <ReviewReplace callbackFunction={modalCallbackFunction} />;
          default:
            return <div />;
        }
      })()}
    </ModalWrapper>
  );
};
