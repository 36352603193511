import React, { useEffect, useState, FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { path, pipeWith, then, map, prop, slice } from "ramda";
import qs from "qs";
import * as api from "api";
import { useNavbar } from "hooks";
import { Recents } from "components";
import { modalOn } from "actions/actionCreators";
import { PRODUCT, STORE, UI__MODAL_LOGIN } from "constant";
import { IStore } from "typings";
import { lookpinLogoText, cartIcon, cartActiveIcon, myLookpinIcon, myLookpinActiveIcon, silverCoin } from "assets/img";
import { BlueHelpButton, IconPin, WhiteHelpButton } from "assets/icon";
import classnames from "classnames/bind";
import styles from "./NavbarMobile.module.scss";

const cx = classnames.bind(styles);

export const NavbarMobile: FC = () => {
  if (window.location.pathname === "/2021chuseok") return null;
  const dispatch = useDispatch();
  const genderId = useSelector<IStore, IStore["ui"]["genderId"]>(({ ui: { genderId } }) => genderId);

  const {
    pathname,
    isShowRecents,
    isShowCategories,
    target,
    keyword,
    token,
    cart,
    recent,
    level,
    point,
    nextLevelPercent,
    color,
    toggleRecents,
    toggleCategories,
    handleLogin,
    targetOnProduct,
    targetOnStore,
    handleKeyword,
    handleFind,
    handleEnter,
    placeholder
  } = useNavbar();

  const [categories, setCategories] = useState<string[]>([]);
  const [hideNavMobile, setHideNavMobile] = useState<boolean>(false);

  const handleNav = useCallback(() => {
    if (window.pageYOffset < 200) setHideNavMobile(false);
    else setHideNavMobile(true);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleNav);
    return () => {
      window.removeEventListener("scroll", handleNav);
    };
  }, [handleNav]);

  useEffect(() => {
    pipeWith(then)([
      api.get__products_main_categories,
      path(["data", "product_main_categories"]),
      slice(1, Infinity),
      map(prop("name")),
      setCategories
    ])(genderId);
  }, [genderId]);

  return (
    <nav className={cx("nav", { hide: hideNavMobile })}>
      <header className={cx("header")}>
        <section className={cx("icons")}>
          <Link to="/" className={cx("logo-wrapper")}>
            <img src={lookpinLogoText} alt="logo" />
          </Link>

          <section className={cx("icon-wrapper")}>
            <Link to="/help">{pathname === "/help" ? <BlueHelpButton /> : <WhiteHelpButton />}</Link>
            {token ? (
              <>
                <Link to="/member/cart" className={cx("cart-wrapper")}>
                  <img alt="cart" src={pathname === "/member/cart" ? cartActiveIcon : cartIcon} />
                  {cart.totalCount > 0 && <div className={cx("cart-badge")}>{cart.totalCount}</div>}
                </Link>

                <Link to="/member">
                  <img alt="member" src={pathname === "/member" ? myLookpinActiveIcon : myLookpinIcon} />
                </Link>
              </>
            ) : (
              <>
                <button onClick={() => dispatch(modalOn(UI__MODAL_LOGIN))} className={cx("cart-wrapper")} type="button">
                  <img alt="cart" src={pathname === "/member/cart" ? cartActiveIcon : cartIcon} />
                  {cart.totalCount > 0 && <div className={cx("cart-badge")}>{cart.totalCount}</div>}
                </button>

                <button onClick={() => dispatch(modalOn(UI__MODAL_LOGIN))} type="button">
                  <img alt="member" src={pathname === "/member" ? myLookpinActiveIcon : myLookpinIcon} />
                </button>
              </>
            )}
          </section>
        </section>

        <section className={cx("routes")}>
          {map(
            ([to, ko, exact]: [string, string, boolean]) => (
              <NavLink to={to} key={to} className={cx("route")} activeClassName={cx("active")} exact={exact}>
                {ko}
              </NavLink>
            ),
            [
              ["/search/ranking", "상품랭킹", false],
              ["/search/newest", "신상품", false],
              ["/coordi", "코디", true],
              ["/stores", "스토어", true],
              ["/banners", "이벤트", true]
            ]
          )}

          <button
            onClick={toggleCategories}
            className={cx("route", "button", { active: pathname === "/search/category" })}
            type="button"
          >
            카테고리
          </button>
        </section>
      </header>

      <section className={cx("sub-header")}>
        <section className={cx("status")}>
          {token ? (
            <div className={cx("member")}>
              <i className={cx("level-bg")} style={{ backgroundColor: color }}>
                <IconPin />
              </i>

              <div className={cx("level-wrapper")}>
                <div className={cx("border-base")}>
                  <span
                    className={cx("border-percent")}
                    style={{
                      backgroundColor: color,
                      width: `${nextLevelPercent || 1}%`
                    }}
                  />
                  <span className={cx("level")}>Lv.{level}</span>
                  <span className={cx("percent")}>{nextLevelPercent}%</span>
                </div>
              </div>

              <div className={cx("point-wrapper")}>
                <img className={cx("point-icon")} src={silverCoin} alt="point" />
                <div className={cx("point-text-wrapper")}>
                  <span className={cx("point-text")}>{point.toLocaleString()}</span>원
                </div>
              </div>
            </div>
          ) : (
            <div className={cx("user")}>
              지금 룩핀에&nbsp;
              <button className={cx("login")} type="button" onClick={handleLogin}>
                로그인
              </button>
              해보세요!
            </div>
          )}

          <div className={cx("division")} />

          <button type="button" onClick={toggleRecents} className={cx("recents-wrapper")}>
            <div className={cx("thumbnail-wrapper")}>
              {recent?.photoUrl ? (
                <img className={cx("thumbnail")} src={recent.photoUrl} alt="최근 본 상품" />
              ) : (
                <IconPin />
              )}
            </div>

            <span className={cx("recent-text")}>최근 본 상품</span>
          </button>
        </section>

        <section className={cx("search")}>
          <div className={cx("condition-wrapper")}>
            <button onClick={targetOnProduct} className={cx("product", { active: target === PRODUCT })} type="button">
              상품
            </button>
            <button onClick={targetOnStore} className={cx("store", { active: target === STORE })} type="button">
              스토어
            </button>
          </div>

          <div className={cx("keyword-wrapper")}>
            <input
              onChange={handleKeyword}
              onKeyPress={handleEnter}
              value={keyword}
              className={cx("keyword")}
              placeholder={placeholder}
              type="search"
            />
          </div>

          <div className={cx("find-wrapper")}>
            <button onClick={handleFind} className={cx("find")} type="button">
              검색
            </button>
          </div>
        </section>

        {isShowCategories && (
          <ul className={cx("categories")}>
            {map(
              category => (
                <li key={category} className={cx("category")}>
                  <Link
                    to={{ pathname: `/search/category`, search: qs.stringify({ main: category }) }}
                    className={cx("category-link")}
                  >
                    {category}
                  </Link>
                </li>
              ),
              categories
            )}
          </ul>
        )}

        {isShowRecents && <Recents />}
      </section>
    </nav>
  );
};
