import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./ClaimStatus.module.scss";
import { CLAIM_DESCRIPTION, CLAIM_PROCESS, CLAIM_TEXT } from "constant";
import { ClaimIcon } from "./ClaimIcon";
import { ClaimRightGrayIcon } from "assets/icon";
import { ConditionComponent } from "components";

interface IProp {
  claimGroup: string;
  status?: string;
}
const cx = classNames.bind(styles);
export const ClaimStatus: FC<IProp> = ({ claimGroup, status }) => {
  const lastIndex = CLAIM_PROCESS[claimGroup].length - 1;
  return (
    <div className={cx("wrapper")}>
      <div className={cx("head")}>{CLAIM_TEXT[claimGroup]} 상태</div>
      <div className={cx("process")}>
        <div className={cx("icon-wrapper")}>
          {CLAIM_PROCESS[claimGroup].map(({ key, text }, index) => (
            <ConditionComponent
              key={key}
              condition={index < lastIndex}
              FallBackComponent={() => (
                <ClaimIcon key={key + index} keyData={key} isHighLight={status === key} text={text} />
              )}
            >
              <ClaimIcon key={key} keyData={key} isHighLight={status === key} text={text} />
              <div className={cx("arrow-wrapper")} key={`${key}arrow${index}`}>
                <ClaimRightGrayIcon key={`${key}-arrow=${index}`} />
              </div>
            </ConditionComponent>
          ))}
        </div>
      </div>
      <pre className={cx("description")}>{CLAIM_DESCRIPTION[claimGroup]}</pre>
    </div>
  );
};
