/* eslint-disable react/no-danger */
import classNames from "classnames/bind";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getInitPhotoReviewRequest } from "actions/actionCreators";
import { CheckedIcon, FillRateStar, IconChevronDown, IconPin, UncheckedIcon } from "assets/icon";
import {
  ChoiceOptions,
  CouponIssuableButton,
  DynamicMeta,
  ExpectedOrderPoint,
  Loading,
  NoticeAboutLookpin,
  NoticeAboutProduct,
  Pagination,
  ProductQnaCard,
  SelectedOptions,
  ShippingSpeedOptions,
  SingleCounter,
  StoreRowWithBookmark
} from "components";
import { NOTICE, NOTICE_ABOUT_PRODUCT_BOLD_KEYS, UI__MODAL_LOGIN, UI__MODAL_PRODUCT_QNA } from "constant";
import { useQnARead } from "hooks";
import { modalOn } from "modules/ui";
import { IStore } from "typings";
import { AirbridgeCustomEvent, criteoEventPush, imageLazyLoading, imageResizeHelper } from "utils";
import copyToClipboard from "utils/copyToClipboard";

import { useProductDetail } from "./useProductDetail";

import styles from "./ProductDetailMobile.module.scss";
import { useReviews } from "./useReviews";

const cx = classNames.bind(styles);

type InfoTab = "상품정보" | "배송/교환/반품" | "판매자 정보" | "Q&A";

export function ProductDetailMobile() {
  const dispatch = useDispatch();
  const productDetailRef = useRef<HTMLDivElement>(null);
  const [infoTab, setInfoTab] = useState<InfoTab>("상품정보");
  const [actionTab, setActionTab] = useState<null | "cart" | "purchase">(null);
  const [more, setMore] = useState(false);
  const { email } = useSelector<IStore, IStore["member"]>(({ member }) => member);
  const { isMobile } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);

  const {
    product,
    store,
    hero,
    setHero,
    point,
    isIssuable,
    singleSelectedCount,
    selectedOptions,
    isPin,
    togglePin,
    handleCart,
    optionChecker,
    optionStack,
    optionStackSetter,
    setSingleSelectedCount,
    setSelectedOptions,
    purchase,
    isLoading,
    totalPrice,
    cartId
  } = useProductDetail();

  const { reviewSummary } = useReviews();

  const {
    qnaList,
    getProductQnaList,
    qnaPage,
    setQnaHiddenVisible,
    qnaHiddenVisible,
    qnaMeta,
    setQnaPageAndScrollPage
  } = useQnARead();

  const handleProductCopyToClipboard = useCallback(() => {
    copyToClipboard("상품");
    AirbridgeCustomEvent.share("product", { channel: "링크" });
  }, []);

  useEffect(() => {
    if (product?.id) {
      getProductQnaList({
        page: qnaPage,
        visible: qnaHiddenVisible,
        productId: product.id
      });
    }
  }, [qnaPage, qnaHiddenVisible, getProductQnaList, product]);

  const { token } = useSelector<IStore, IStore["member"]>(({ member }) => member, shallowEqual);

  const optionViewClass = cx("optionView");

  const handleOptionView = useCallback(
    tab => {
      const type = product?.info?.options?.type ?? "multiple";

      if (!token) dispatch(modalOn(UI__MODAL_LOGIN));
      else if (!actionTab) setActionTab(tab);
      else if (type === "multiple") {
        if (selectedOptions.length) handleCart();
        else alert(NOTICE.NEED_SELECT_OPTION);
      } else if (type === "single") handleCart();
    },
    [product, actionTab, token, dispatch, selectedOptions.length, handleCart]
  );

  const handlePurchase = useCallback(() => {
    if (actionTab === "purchase") purchase();
    else handleOptionView("purchase");
  }, [actionTab, purchase, handleOptionView]);

  useEffect(() => {
    if (product?.id) dispatch(getInitPhotoReviewRequest(product.id));
  }, [dispatch, product]);

  const handleOptionViewHeight = useCallback(() => {
    const optionView = document.querySelector(`.${optionViewClass}`) as HTMLElement;

    if (optionView) optionView.style.bottom = `${optionView.offsetHeight}px`;
  }, [optionViewClass]);

  useEffect(handleOptionViewHeight, [actionTab, selectedOptions]);

  useEffect(() => {
    if (product) criteoEventPush({ email, event: "viewItem", item: product.id });
  }, [product, email]);
  const mobileCartId = useMemo(() => (actionTab ? cartId : undefined), [cartId, actionTab]);
  useEffect(() => {
    if (productDetailRef.current) imageLazyLoading(productDetailRef.current);
  }, [product, store, infoTab]);
  if (isLoading || !product || !store) return <Loading />;
  return (
    <>
      <DynamicMeta
        description={`${product.price}원`}
        title={product.name}
        image={product.photoUrl ? imageResizeHelper(product.photoUrl, 300) : undefined}
        url={window.location.href}
      />
      <main className={cx("itemDetail")}>
        <div className={cx("view")}>
          <div className={cx("imageTab")}>
            <img src={hero} className={cx("image")} alt="상품 이미지" />

            <div className={cx("subImageArea")}>
              {product.info.images.main.map(image => (
                <div
                  role="button"
                  tabIndex={0}
                  className={cx("subImageWrapper")}
                  onClick={() => setHero(image)}
                  onKeyDown={() => setHero(image)}
                  key={image}
                >
                  <img key={image} src={image} className={cx("subImage")} alt="이미지 하단 뷰" />
                </div>
              ))}
            </div>
          </div>

          <div className={cx("textTab")}>
            <section className={cx("leftWrapper")}>
              <div className={cx("discount-txt")}>
                {product.discountRate && <span>{product.discountRate}할인&nbsp;</span>}
                <span className={cx("free")}>무료배송</span>
              </div>

              <div className={cx("title")}>{product.name}</div>

              <div className={cx("priceTab", ["bold"])}>
                <span className={cx("price")}>{product.price.toLocaleString()}원</span>
                {` `}
                {product.prevPrice !== product.price && (
                  <span className={cx("prevPrice")}>{product.prevPrice.toLocaleString()}원</span>
                )}
              </div>
            </section>
            <section className={cx("rightWrapper")}>
              <div
                role="button"
                tabIndex={0}
                className={cx("copy")}
                onClick={handleProductCopyToClipboard}
                onKeyDown={handleProductCopyToClipboard}
              >
                URL&nbsp;복사
              </div>
              {/* TODO: 해당 수정 */}
              {reviewSummary && reviewSummary.totalCount > 0 && (
                <div className={cx("rating")}>
                  <div className={cx("starCount")}>
                    <FillRateStar />
                    <span>{reviewSummary.averageSatisfaction}</span>
                  </div>
                  <span className={cx("rateCountText")}>{reviewSummary.totalCount.toLocaleString()}명 평가</span>
                </div>
              )}
            </section>
          </div>

          <div className={cx("methods-descripton")}>
            {product.shippingSpeed && <ShippingSpeedOptions shippingSpeed={product.shippingSpeed} />}
            <ExpectedOrderPoint price={product.price} point={point} />

            <CouponIssuableButton isIssuable={isIssuable} />
          </div>

          <hr className={cx("hr")} />

          <StoreRowWithBookmark
            id={store.id}
            name={store.name}
            group={store.group}
            photoUrl={store.photoUrl}
            serializedTags={store.serializedTags}
            genderId={store.genderId}
          />
          <hr className={cx("hr")} />
          <div className={cx("info-wrapper")}>
            <div
              role="button"
              tabIndex={0}
              className={cx({ selectedTab: infoTab === "상품정보" })}
              onClick={_ => setInfoTab("상품정보")}
              onKeyDown={() => {}}
            >
              <span>상품정보</span>
            </div>
            <div
              role="button"
              tabIndex={0}
              className={cx({ selectedTab: infoTab === "Q&A" })}
              onClick={_ => setInfoTab("Q&A")}
              onKeyDown={() => {}}
            >
              <span>Q&A {qnaMeta && `(${qnaMeta.totalCount.toLocaleString()})`}</span>
            </div>
          </div>
          {infoTab === "상품정보" && (
            <div
              className={cx("details", { more })}
              ref={productDetailRef}
              dangerouslySetInnerHTML={{ __html: product.info.html }}
            />
          )}

          {!more && infoTab === "상품정보" && (
            <div className={cx("more-wrapper")}>
              <div
                role="button"
                tabIndex={0}
                className={cx("more")}
                onClick={() => setMore(true)}
                onKeyDown={() => setMore(true)}
              >
                상품정보 더보기
                <IconChevronDown />
              </div>
              <NoticeAboutProduct product={product} boldKeys={NOTICE_ABOUT_PRODUCT_BOLD_KEYS} />
              <NoticeAboutLookpin />
            </div>
          )}

          <div className={cx("mobileBtns-wrapper", { isOptionOpen: actionTab })}>
            {actionTab ? (
              <div
                role="button"
                tabIndex={0}
                className={cx("cancel")}
                onClick={() => setActionTab(null)}
                onKeyDown={() => setActionTab(null)}
              >
                <span>취소</span>
              </div>
            ) : (
              <div
                role="button"
                tabIndex={0}
                className={cx("pin", { isPin })}
                onClick={togglePin}
                onKeyDown={togglePin}
              >
                <IconPin />
                핀하기
              </div>
            )}

            {actionTab !== "purchase" && (
              <div
                role="button"
                tabIndex={0}
                className={cx("cart")}
                onClick={() => handleOptionView("cart")}
                onKeyDown={() => handleOptionView("cart")}
                id={mobileCartId}
              >
                <span>장바구니</span>
              </div>
            )}

            {actionTab !== "cart" && (
              <div
                role="button"
                tabIndex={0}
                className={cx("purchase")}
                onClick={handlePurchase}
                onKeyDown={handlePurchase}
              >
                <span>구매하기</span>
              </div>
            )}

            {actionTab && (
              <div className={optionViewClass}>
                <div className={cx("head")}>
                  <span>옵션선택</span>
                  <span className={cx("head-totalPrice")}>
                    총&nbsp;상품금액
                    <span>{totalPrice.toLocaleString()}원</span>
                  </span>
                </div>

                {product.info.options.type === "single" ? (
                  <SingleCounter
                    price={product.price}
                    stockCount={product.info.options.data[0].data.stockCount}
                    singleSelectedCount={singleSelectedCount}
                    setSingleSelectedCount={setSingleSelectedCount}
                    isMobile={isMobile}
                  />
                ) : (
                  <>
                    <ChoiceOptions
                      options={product.info.options}
                      optionChecker={optionChecker}
                      optionStack={optionStack}
                      optionStackSetter={optionStackSetter}
                    />

                    <SelectedOptions
                      product={product}
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                      optionChecker={optionChecker}
                      isMobile={isMobile}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <div className={cx("infoTab", { isSelected: infoTab === "Q&A" })} />
        {infoTab === "Q&A" && (
          <div className={cx("mobile-qna-wrapper")}>
            <section className={cx("qna-button-wrapper")}>
              <button
                className={cx("qna-button")}
                type="button"
                onClick={() =>
                  dispatch(
                    modalOn(UI__MODAL_PRODUCT_QNA, {
                      productId: product.id,
                      productTitle: product.name,
                      productImage: hero,
                      storeName: store.name
                    })
                  )
                }
              >
                상품 문의하기
              </button>
            </section>
            <section id="qna-wrapper" className={cx("qna-body")}>
              <label className={cx("qna-hidden-label")} onClick={() => setQnaHiddenVisible(qnaHidden => !qnaHidden)}>
                <div>{qnaHiddenVisible ? <CheckedIcon /> : <UncheckedIcon />}</div>
                <span className={cx("qna-checkbox-title")}>비공개 문의 제외</span>
              </label>
              {qnaList.length > 0 ? (
                <>
                  <div className={cx("qna-list-wrapper")}>
                    {qnaList.map(qna => (
                      <ProductQnaCard key={qna.id} {...qna} />
                    ))}
                  </div>
                  <div className={cx("pagination-wrapper")}>
                    {qnaMeta && (
                      <Pagination
                        totalCount={qnaMeta.totalCount}
                        number={qnaPage.number}
                        size={qnaPage.size}
                        handler={setQnaPageAndScrollPage}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className={cx("qna-list-no-exist")}>문의내역이 없습니다.</div>
              )}
            </section>
          </div>
        )}
      </main>
    </>
  );
}
