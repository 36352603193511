import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./PercentageRodGraph.module.scss";
import { SATISFACTION } from "constant";
import { useSelector } from "react-redux";
import { IStore } from "typings";

const cx = classNames.bind(styles);

interface IProp {
  percentage: number;
  isBig: boolean;
  text: string;
}

export const PercentageRodGraph: FC<IProp> = ({ percentage, isBig, text }) => {
  const { isMobile } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);
  return (
    <div className={cx("graphWrapper")}>
      {isMobile && (
        <div className={cx("graph-text", { isBig }, SATISFACTION[text])}>
          <span>{text}</span>
        </div>
      )}
      <div className={cx("rod-wrapper", { isBig })}>
        <div
          className={cx("rod-fill", SATISFACTION[text])}
          style={{
            width: `${percentage}%`
          }}
        ></div>
      </div>
      {isMobile ? (
        <div className={cx("percent-text", { isBig }, SATISFACTION[text])}>
          <span>{percentage}%</span>
        </div>
      ) : (
        <div className={cx("graph-text", { isBig }, SATISFACTION[text])}>
          <span>
            {text} {percentage}%
          </span>
        </div>
      )}
    </div>
  );
};
