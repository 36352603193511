import React, { useCallback, useEffect } from "react";

import { init } from "services/naver";
/* eslint-disable no-undef */
export default function NaverLoginButton() {
  const naverInit = useCallback(() => {
    if (window.naver) init();
  }, []);

  useEffect(naverInit, []);

  return window.naver ? <div id="naverIdLogin" /> : <div>네이버로그인을 하시려면 새로고침 해주세요</div>;
}
