import { fetcher } from "utils";

import { IJSendSuccessResponse, PayMethod, PayMethodV3 } from "typings";

export const get__api__v1__pay__methods = (
  isMobile?: boolean
): Promise<IJSendSuccessResponse<{ payload: PayMethod[] }>> =>
  fetcher.get("/api/v1/pay/methods", isMobile ? {} : { platform: "pcweb" });

export const get__api__v3__pay__methods = (): Promise<IJSendSuccessResponse<{ payload: PayMethodV3[] }>> =>
  fetcher.get("/api/v3/members/orders/pay-methods");
