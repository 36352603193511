import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./OrderProductSummary.module.scss";
import { MoreBox } from "./MoreBox";
import { OrderIcon } from "./OrderIcon";
import { IOrderProductSummary } from "typings";
interface IProp {
  orderSummary: IOrderProductSummary;
}

const cx = classNames.bind(styles);

export const OrderProductSummary: FC<IProp> = ({ orderSummary }) => {
  return (
    <div>
      <div className={cx("header")}>
        <div className={cx("title")}>
          주문내역 <span className={cx("range")}>(최근 1개월)</span>
        </div>
        <div>
          <MoreBox to="/member/orders" />
        </div>
      </div>
      <div className={cx("icon-wrapper")}>
        <OrderIcon type="pay" count={orderSummary.pay} highLighting={orderSummary.latest_filter_group === "pay"} />
        <OrderIcon
          type="ship_wait"
          count={orderSummary.ship_wait}
          highLighting={orderSummary.latest_filter_group === "ship_wait"}
        />
        <OrderIcon
          type="ship_ing"
          count={orderSummary.ship_ing}
          highLighting={orderSummary.latest_filter_group === "ship_ing"}
        />
        <OrderIcon
          type="ship_done"
          count={orderSummary.ship_done}
          highLighting={orderSummary.latest_filter_group === "ship_done"}
        />
        <OrderIcon
          type="claim"
          count={orderSummary.claim}
          highLighting={orderSummary.latest_filter_group === "claim"}
        />
      </div>
    </div>
  );
};
