import airbridge from "airbridge-web-sdk-loader";
import { EventOption, AirbridgeAPI } from "airbridge-web-sdk-loader/build/airbridge_api";
import qs from "qs";
import AirbridgeBaseAPI from "./AirbridgeBaseAPI";

const AIRBRIDGE_EVENT_DIC = {
  signup: "airbridge.user.signup",
  signin: "airbridge.user.signin",
  signout: "airbridge.user.signout",

  homeViewed: "airbridge.ecommerce.home.viewed",
  productDetailViewed: "airbridge.ecommerce.product.viewed",
  productListViewed: "airbridge.ecommerce.productList.viewed",
  searchResultsViewed: "airbridge.ecommerce.searchResults.viewed",
  productAddedToCart: "airbridge.ecommerce.product.addedToCart",

  orderCompleted: "airbridge.ecommerce.order.completed",
  orderCanceled: "airbridge.ecommerce.order.canceled"
};

interface SignAction {
  type: "email" | "social_naver" | "social_kakao" | "social_apple";
}

class AirbridgeEvent extends AirbridgeBaseAPI {
  constructor(protected airbridge: AirbridgeAPI) {
    super(airbridge);
  }

  signin({ type }: SignAction) {
    this.sendEvents(AIRBRIDGE_EVENT_DIC.signin, {
      action: type
    });
  }

  signup({ type }: SignAction) {
    this.sendEvents(AIRBRIDGE_EVENT_DIC.signup, {
      action: type
    });
  }

  signout() {
    this.sendEvents(AIRBRIDGE_EVENT_DIC.signout).clearUser();
  }

  homeViewed() {
    this.sendEvents(AIRBRIDGE_EVENT_DIC.homeViewed);
  }

  productDetailViewed(options: EventOption) {
    this.sendEvents(AIRBRIDGE_EVENT_DIC.productDetailViewed, options);
  }

  searchResultsViewed(options: EventOption) {
    const searchQs = Object.keys(qs.parse(window.location.search));
    const isNavSearch = searchQs.length === 1 && searchQs.some(key => key === "?keyword");

    if (isNavSearch && !localStorage.getItem("searched")) {
      this.sendEvents(AIRBRIDGE_EVENT_DIC.searchResultsViewed, options);
      localStorage.setItem("searched", "1");
    }
  }

  productAddedToCart(options: EventOption) {
    this.sendEvents(AIRBRIDGE_EVENT_DIC.productAddedToCart, options);
  }

  orderCompleted(options: EventOption) {
    this.sendEvents(AIRBRIDGE_EVENT_DIC.orderCompleted, options);
  }

  orderCanceled(options: EventOption) {
    this.sendEvents(AIRBRIDGE_EVENT_DIC.orderCanceled, options);
  }
}

export default new AirbridgeEvent(airbridge);
