import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./DescriptionTotalPrice.module.scss";

const cx = classNames.bind(styles);
interface IProp {
  title: string;
  totalCount?: number;
  paidPrice: number;
  titleColor?: string;
}
export const DescriptionTotalPrice: FC<IProp> = ({ title, paidPrice, totalCount, titleColor }) => {
  return (
    <div className={cx("row")}>
      <div className={cx("row-key", titleColor)}>{title}</div>
      <div className={cx("row-value")}>
        <span className={cx("price")}>{`${paidPrice.toLocaleString()}원 `}</span>
        {totalCount && totalCount > 1 && <span className={cx("total-count")}>{`(총 ${totalCount}개 상품)`}</span>}
      </div>
    </div>
  );
};
