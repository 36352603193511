import React, { useMemo } from "react";
import classNames from "classnames/bind";

import {
  PreviousPageMobile,
  OrderProductCard,
  OrderProductCardMobileView,
  DescriptionRow,
  ClaimStatus,
  ClaimInfo,
  DescriptionTotalPrice,
  MiddleBoder,
  LoadingWrapper
} from "components";

import { shallowEqual, useSelector } from "react-redux";
import { IStore } from "typings";
import { formatDateTime, toDiscountLocaleString } from "utils";
import styles from "./OrderDetail.module.scss";
import { useOrderDetail } from "./useOrderDetail";

const cx = classNames.bind(styles);

export function OrderDetail() {
  const { order, actionClickEvent, isClaim, etcOrders, isLoading, orderDetailSet } = useOrderDetail();
  const isMobile = useSelector<IStore, IStore["ui"]["isMobile"]>(({ ui: { isMobile } }) => isMobile, shallowEqual);
  const CardView = useMemo(() => (isMobile ? OrderProductCardMobileView : OrderProductCard), [isMobile]);

  return (
    <LoadingWrapper isLoading={isLoading}>
      <main className={cx("order-detail-tab")}>
        <div className={cx("orderDetail__tab")}>
          <div className={cx("orderDetail__txt--head")}>
            {isMobile && <PreviousPageMobile />}

            <header className={cx("title")}>주문상세</header>
          </div>
        </div>
        <div className={cx("card-wrapper")}>
          {order && (
            <CardView
              order={order}
              actionClickEvent={actionClickEvent({
                orderNum: order.orderNum,
                productOrderNum: order.productOrderNum,
                beforeStatus: order.beforeStatus
              })}
              disabledLink
              reviewSubmitCallback={orderDetailSet}
            />
          )}
        </div>
        {order && isClaim && (
          <div className={cx("claimWrapper")}>
            <ClaimStatus claimGroup={order.processGroup} status={order.process} />
            <ClaimInfo
              claimGroup={order.processGroup!}
              status={{
                text: order.currentStatusText!,
                createdAt: order.currentStatusAt!,
                payStatus: order.payInfo.payStatus,
                orderNum: order.orderNum,
                payMethodDetail: order.payInfo.payMethodDetail
              }}
              payInfo={{
                paidPrice: order.paidPrice,
                totalPrice: order.targetProductPrice,
                discountCoupon: order.discountCouponPrice,
                consumedPoint: order.consumedPoint
              }}
              pickupInfo={{
                autoPickUp: order.autoPickUp,
                storeName: order.store.name,
                storeAddress: order.store.refundAddress,
                userName: order.shippingInfo.name,
                userAddress: order.pickUpAddress ? order.pickUpAddress?.main + order.pickUpAddress?.detail : undefined,
                userPhone: order.shippingInfo.phoneNumber
              }}
            />
          </div>
        )}

        <div className={cx("orderDetail__tab", "summary")}>
          <div className={cx("m-full-box")}>
            <div className={cx(["bold"], "head")}>배송지 정보</div>
            <DescriptionRow
              keyData="구매자"
              value={order && `${order.shippingInfo.name} / ${order.shippingInfo.phoneNumber}`}
            />
            <DescriptionRow
              keyData="주소지"
              value={order && `${order?.shippingInfo.main} ${order?.shippingInfo.detail}`}
            />
            <DescriptionRow keyData="배송 요청사항" value={order?.shippingInfo.message} />
          </div>

          <div className={cx("m-flex")}>
            <div className={cx("m-box", "left", "right-border")}>
              <div className={cx(["bold"], "head")}>결제 정보</div>
              <DescriptionRow keyData="주문번호" value={order?.orderNum} />
              <DescriptionRow keyData="결제방법" value={order?.payInfo.payMethodDetail} />
              <DescriptionRow
                keyData="결제시각"
                value={order && order.payInfo?.paidAt && formatDateTime(order.payInfo.paidAt, "yearDateTimeStr")}
              />
              <DescriptionRow keyData="결제상태" value={order?.payInfo.payStatus} />
            </div>

            <div className={cx("m-box", "right")}>
              <div className={cx(["bold"], "head")}>결제금액</div>
              <DescriptionRow
                keyData="상품 금액"
                value={order && `${order.totalPrice.toLocaleString()}원`}
                spaceBetween={!isMobile}
              />
              <DescriptionRow
                keyData="적립금 사용"
                value={order && toDiscountLocaleString(order.consumedPoint)}
                spaceBetween={!isMobile}
              />
              <DescriptionRow
                keyData="쿠폰 사용"
                value={order && toDiscountLocaleString(order.discountCouponPrice)}
                spaceBetween={!isMobile}
              />
              <MiddleBoder />
              {order && (
                <DescriptionTotalPrice
                  title="총 결제 금액"
                  totalCount={order.payInfo.orderProductsCount}
                  paidPrice={order.payInfo.paidPrice}
                />
              )}
            </div>
          </div>
        </div>
        <div className={cx("card-wrapper")}>
          {etcOrders.map(etcOrder => (
            <CardView
              key={etcOrder.productOrderNum}
              order={etcOrder}
              actionClickEvent={actionClickEvent({
                orderNum: etcOrder.orderNum,
                productOrderNum: etcOrder.productOrderNum,
                beforeStatus: etcOrder.beforeStatus
              })}
              reviewSubmitCallback={orderDetailSet}
            />
          ))}
        </div>
      </main>
    </LoadingWrapper>
  );
}
