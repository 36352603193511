import React, { FC, useCallback } from "react";
import classNames from "classnames/bind";
import styles from "./DateSelect.module.scss";
import { IOptionObject } from "typings";

interface IProp {
  onChange?: (value: string) => void;
  options: Array<IOptionObject>;
  select: string;
}
const cx = classNames.bind(styles);

export const DateSelect: FC<IProp> = ({ onChange, options, select }) => {
  const changeEvent = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onChange && onChange(event.target.value);
    },
    [onChange]
  );
  return (
    <select className={cx("wrapper")} onChange={changeEvent} defaultValue={select}>
      {options.map(({ key, value }) => (
        <option key={key} value={key}>
          {value}
        </option>
      ))}
    </select>
  );
};
