import produce, { enableAllPlugins, enableES5, enableMapSet, enablePatches } from "immer";

const immerUpdate = (object, draft) => {
  enableAllPlugins();
  enableES5();
  enableMapSet();
  enablePatches();
  return produce(object, draft);
};

export default immerUpdate;
