import React, { FC } from "react";

import { emptyPayOrderIcon, emptyShipWaitIcon, emptyShippingIcon, emptyShipDoneIcon, emptyClaimIcon } from "assets/img";
interface IProp {
  filterGroup: string;
}

export const EmptyProductOrderIcon: FC<IProp> = ({ filterGroup }) => {
  switch (filterGroup) {
    case "pay":
      // return <img src={emptyPayOrderIcon} />;
      return <img src={emptyPayOrderIcon} alt="입금결제아이콘" />;
    case "ship_wait":
      return <img src={emptyShipWaitIcon} alt="배송대기아이콘" />;
    case "ship_ing":
      return <img src={emptyShippingIcon} alt="배송중아이콘" />;
    case "ship_done":
      return <img src={emptyShipDoneIcon} alt="배송완료아이콘" />;
    case "claim":
      return <img src={emptyClaimIcon} alt="클레임아이콘" />;
    default:
      return <div></div>;
  }
};
