import { fetcher } from "utils";

import { IAddress, INextStatus, IRefundAccount } from "typings";

interface IPutMembersOrderProductsOrderNumRequest {
  status: string;
  reason?: string;
  message?: string;
  beforeStatus: INextStatus["status"];
  refundAccount?: IRefundAccount;
  pickUpAddress?: IAddress;
}

export const put__members__order_products__order_num = (
  orderNum: number | string,
  params: IPutMembersOrderProductsOrderNumRequest
): Promise<" "> => fetcher.put(`/members/order_products/${orderNum}`, params);
