import { fetcher } from "utils";
import { IJSendSuccessResponse, GenderPartner, IPage, ReviewSort, IBasePhotoReviews } from "typings";

type GetBasePhotoReviewsSearch = (params: {
  sort: ReviewSort;
  page: IPage;
  genderId: GenderPartner;
  name?: string;
  productId?: string | number;
}) => Promise<IJSendSuccessResponse<{ base_photo_reviews: IBasePhotoReviews[] }>>;

export const get__base_photo_reviews__search: GetBasePhotoReviewsSearch = params =>
  fetcher.get("/base_photo_reviews/search", params);
