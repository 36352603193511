import React, { FC } from "react";
import classNames from "classnames/bind";
import { FillCircleXmark } from "assets/icon";
import styles from "./ReviewWritePhoto.module.scss";

interface IProp {
  onClick?: () => void;
  url: string;
}
const cx = classNames.bind(styles);

export const ReviewWritePhoto: FC<IProp> = ({ url, onClick }) => {
  return (
    <figure className={cx("image-wrapper")}>
      <img className={cx("image")} src={url} alt="" />
      <button type="button" onClick={onClick} className={cx("xmark-wrapper")}>
        <FillCircleXmark />
      </button>
    </figure>
  );
};
