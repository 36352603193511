import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./BankInfo.module.scss";
import { IVbankInfo } from "typings";
import { formatDateTime } from "utils";

interface IProp {
  bankData: IVbankInfo;
}

const cx = classNames.bind(styles);

export const BankInfo: FC<IProp> = ({ bankData: { name, amount, date, holder, num } }) => {
  return (
    <div className={cx("wrapper")}>
      {name} / {num} / {amount.toLocaleString()}원 ({formatDateTime(date, "dateStr")}까지)
    </div>
  );
};
