import React, { FC } from "react";
import classnames from "classnames/bind";

import styles from "./PageTitle.module.scss";

const cx = classnames.bind(styles);

interface IProps {
  title: string;
}

export const PageTitle: FC<IProps> = ({ title }) => <div className={cx("page-title")}>{title}</div>;
