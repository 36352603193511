import { scrollBtnBottom, scrollBtnTop } from "assets/img";
import classNames from "classnames/bind";
import React, { FC } from "react";
import styles from "./ScrollButton.module.scss";

const cx = classNames.bind(styles);

export const ScrollButton: FC = () => {
  const setScrollTop = () => window.scrollTo(0, 0);
  const setScrollBottom = () => window.scrollTo(0, document.body.scrollHeight - 1000);

  return (
    <div className={cx("button-wrapper")}>
      <button className={cx("button")} type="button" onClick={setScrollTop}>
        <img src={scrollBtnTop} alt="맨위" />
      </button>

      <button className={cx("button")} type="button" onClick={setScrollBottom}>
        <img src={scrollBtnBottom} alt="맨아래" />
      </button>
    </div>
  );
};
