import { mainBanner, mobileBanner } from "assets/img";
import { IconChevronLeft, IconChevronRight } from "assets/icon";
import classNames from "classnames/bind";
import React, { useState, FC } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Slider from "react-slick";
import { Loading } from "components";
import { IStore, IEventBannerViewModel } from "typings";
import qs from "qs";

import { imageResizeHelper } from "utils";

import styles from "./EventBanner.module.scss";

const cx = classNames.bind(styles);

type CustomPageButton = { currentSlide?: any; slideCount?: any };
interface IProps {
  eventBanners?: IEventBannerViewModel[];
  eventClickListner?: (url: string) => void;
}

/* eslint-disable react/jsx-props-no-spreading */
export const EventBanner: FC<IProps> = ({ eventBanners, eventClickListner }) => {
  const { isMobile, genderId } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui, shallowEqual);

  const [carouselIndex, setCarouselIndex] = useState(0);

  const PrevArrowButton = (
    { currentSlide, slideCount, ...props }: CustomPageButton /* eslint-disable-line no-unused-vars */
  ) => (
    <i className={cx("slick-arrow slick-prev")} {...props}>
      <IconChevronLeft />
    </i>
  );

  const NextArrowButton = (
    { currentSlide, slideCount, ...props }: CustomPageButton /* eslint-disable-line no-unused-vars */
  ) => (
    <i className={cx("slick-arrow slick-next")} {...props}>
      <IconChevronRight />
    </i>
  );
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrowButton />,
    nextArrow: <NextArrowButton />,
    afterChange: curIdx => setCarouselIndex(curIdx)
  };

  if (!eventBanners)
    return (
      <div className={cx("banner-tab")}>
        <Loading />
      </div>
    );

  return (
    <div className={cx("banner-tab")}>
      {eventBanners.length > 0 ? (
        <>
          <Slider {...settings}>
            {eventBanners.map(({ imageUrl, eventUrl }) => {
              return (
                <a
                  href={`${eventUrl}?${qs.stringify({
                    platform: "web",
                    gender_id: genderId,
                    store_group_id: "default"
                  })}`}
                  onClick={() => eventClickListner && eventClickListner(eventUrl)}
                  key={eventUrl}
                  style={{ display: "block" }}
                >
                  <span className={cx("banner-image-wrap")}>
                    <img className={cx("banner-image")} alt="룩핀 이벤트 배너" src={imageResizeHelper(imageUrl)} />
                  </span>
                </a>
              );
            })}
          </Slider>

          <div className={cx("slide-box")}>
            <div className={cx("slide-count")}>
              <span>{carouselIndex + 1}</span>
              <span>&nbsp;/&nbsp;{eventBanners.length}</span>
            </div>
          </div>
        </>
      ) : (
        <button type="button">
          {isMobile ? (
            <img src={mobileBanner} alt="룩핀 이벤트 배너" />
          ) : (
            <img src={mainBanner} alt="룩핀 이벤트 배너" />
          )}
        </button>
      )}
    </div>
  );
};
