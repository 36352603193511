export const API_PRO_URL = "https://api.lookpin.co.kr";
export const API_DEV_URL = "https://api.dev.lookpin.co.kr";

export const PRODUCTS_ORDER_POINTS_RANGE = "/products/order_points/range";
export const PRODUCTS_ORDER_POINTS_BY_LEVEL = "/products/order_points/by_level";

// Mall & Brand

export const STORES = "/stores";
export const STORES_BRANDS = "/stores/brands";
export const STORES_PRODUCTS = id => `/stores/${id}/products`;

export const COORDI_TAGS_TRENDS = "/coordi/tags/trends";
export const COORDI_LIST = "/coordi";
export const COORDI_SEARCH = "/coordi/search";

export const SEARCH_KEYWORDS = "/search/keywords";

export const SALE_LIST = "/coordi";

// Member

export const MEMBERS_AUTH_SIGN_UP = "/members/auth";

export const MEMBERS_ORDERS = "/members/orders";

export const MEMBERS_ORDER_PRODUCT_CONTACTS_NEW = "/members/order_product_contacts/new";
export const MEMBERS_ORDER_PRODUCT_CONTACTS = "/members/order_product_contacts";
export const MEMBERS_ORDER_PRODUCTS = "/members/order_products";

// Account Validation

export const PAY_VALIDATE_BANK_ACCOUNT = "/pay/validate_bank_account";

// Campaign

export const CAMPAIGN = "/campaign";