import React, { FC } from "react";
import classNames from "classnames/bind";
import { IReviewsSummariesViewModelV3 } from "typings";
import { FillRateStarV2, InfoCircle } from "assets/icon";
import styles from "./ReviewTotal.module.scss";

interface IProp {
  reviewSummary: IReviewsSummariesViewModelV3;
}

const cx = classNames.bind(styles);
export const ReviewTotal: FC<IProp> = ({ reviewSummary }) => {
  const { totalCount, averageSatisfaction } = reviewSummary;
  return (
    <div className={cx("review-thumbnail")}>
      <div className={cx("total-summary")}>
        <div className={cx("total-summary-count")}>전체 구매 후기</div>
        <div className={cx("divider")} />
        <div className={cx("satisfaction-wrapper")}>
          <FillRateStarV2 />
          <div className={cx("satisfaction-count")}>{averageSatisfaction}</div>
          <div className={cx("satisfaction-user")}>
            (총&nbsp;
            {totalCount.toLocaleString()}명 평가)
          </div>
        </div>
      </div>

      <div className={cx("review-description-wrapper")}>
        <InfoCircle />
        <div className={cx("review-description-text")}>이 상품의 상세 리뷰는 룩핀 앱에서 확인하실 수 있습니다.</div>
      </div>
    </div>
  );
};
