import { fetcher } from "utils";

import { IPostSocialAccountsFail, IPostV1SocialAccountsSuccess, Provider } from "typings";

export const post__api_v1_social_accounts = (
  provider: Provider,
  providerToken: string,
  email?: string,
  phoneNumber?: string,
  confirmMarketingSms?: boolean
): Promise<IPostV1SocialAccountsSuccess | IPostSocialAccountsFail> =>
  fetcher.post(
    "/api/v1/social_accounts",
    {
      provider,
      providerToken,
      phone_number: phoneNumber,
      confirm_marketing_sms: confirmMarketingSms,
      ...(email && { email })
    },
    true
  );
