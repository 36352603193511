/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IconChevronTopButtonGray, IconChevronBottomGray } from "assets/icon";

import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./NoticeCardSummary.module.scss";

interface IProp {
  title: string;
  subTitle: string;
  onClick: () => void;
  isShow: boolean;
}

const cx = classNames.bind(styles);

export const NoticeCardSummary: FC<IProp> = ({ title, subTitle, onClick, isShow }) => {
  return (
    <div className={cx("wrapper")} onClick={onClick}>
      <div>
        <div className={cx("sub-title-text")}>{subTitle}</div>
        <div className={cx("title-text")}>{title}</div>
      </div>
      <div>{isShow ? <IconChevronTopButtonGray /> : <IconChevronBottomGray />}</div>
    </div>
  );
};
