import { NAVER_CALLBACK_URL, NAVER_CLIENT_ID } from "constant";

/* eslint-disable no-undef */

export const init = (): void => {
  // https://developers.naver.com/docs/login/web/#2-2-2--네이버-아이디로-로그인-버튼-생성
  const naverLogin = new naver.LoginWithNaverId({
    clientId: NAVER_CLIENT_ID,
    callbackUrl: NAVER_CALLBACK_URL,
    isPopup: false,
    callbackHandle: true,
    loginButton: {
      color: "green",
      type: 3,
      height: 48
    }
  });

  localStorage.setItem("lp_naver_redirectPath", window.location.pathname);

  naverLogin.init();
};

// https://developers.naver.com/docs/login/web/#2-3--인증-시작하기
export default function getAccessToken(): Promise<string> {
  const naverLogin = new naver.LoginWithNaverId({
    clientId: NAVER_CLIENT_ID,
    callbackUrl: NAVER_CALLBACK_URL,
    isPopup: false,
    callbackHandle: true
  });

  naverLogin.init();

  return new Promise((resolve, reject) => {
    naverLogin.getLoginStatus(status => {
      if (!status) reject(new Error(`error: 네이버 인증 과정에서 문제 발생`));
      else {
        const { loginStatus } = naverLogin;

        if (loginStatus.naverUser.email == null) {
          alert("이메일은 필수정보입니다. 정보제공을 동의해주세요.");
          naverLogin.reprompt();
        }

        resolve(loginStatus.accessToken.accessToken);
      }
    });
  });
}
