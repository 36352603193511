import { addCart, initialize, modalOn, sync } from "actions/actionCreators";
import * as api from "api";
import { camelizeKeys } from "humps";
import { NOTICE, UI__MODAL_LOGIN, PRODUCT_DETAIL } from "constant";
import { storageContext } from "contexts";

import { dissoc, map, path, pipe, prop, reduce, add } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  IStore,
  IPartnerOptionViewModel,
  IProductDetailViewModel,
  ISelectedOption,
  StoreSerializedCamel,
  GetDiscountCouponsResponse,
  GetUserablesProductsRecentResponse
} from "typings";
import { createPartnerOption, flattenProductOptions, productDetailViewModelCreator } from "view-model-creator";
import { usePin, useBookmark } from "hooks";
import { Tracking, produce, AirbridgeEvent } from "utils";

interface ISelectedOptionValue {
  stockCount: number;
  optionId: number;
  optionPrice: number;
}

export const useProductDetail = () => {
  const history = useHistory();
  const { pathname } = history.location;
  const { productId: pid } = useParams() as { productId: string };
  const productId = useMemo(() => Number(pid), [pid]);

  const dispatch = useDispatch();

  const { getIsBookmark, toggleBookmark } = useBookmark();

  const { token } = storageContext;
  const { isMobile, isLoading } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui, shallowEqual);

  const [product, setProduct] = useState<IProductDetailViewModel>();
  const [store, setStore] = useState<StoreSerializedCamel>();
  const [isIssuable, setIsIssuable] = useState(false);
  const [point, setPoint] = useState<{
    point: number;
    minPoint: number;
    maxPoint: number;
  }>({
    point: 0,
    minPoint: 0,
    maxPoint: 0
  });
  const [hero, setHero] = useState("");
  const [singleSelectedCount, setSingleSelectedCount] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState<ISelectedOption[]>([]);
  const [optionStack, setOptionStack] = useState<string[]>([]);

  const { getIsPin, handlePin } = usePin();
  const togglePin = useCallback(() => {
    if (product) handlePin(product.id, product.name, product.price);
  }, [handlePin, product]);
  const totalPrice = useMemo<number>(() => {
    if (!product) return 0;
    return (
      (product.info.options.type === "single"
        ? product.price * singleSelectedCount
        : pipe(
            map(({ optionPrice, selectedCount }) => (optionPrice + product.price) * selectedCount),
            reduce<number, number>(add, 0)
          )(selectedOptions)) || 0
    );
  }, [product, singleSelectedCount, selectedOptions]);

  const isPin = useMemo(() => (product ? getIsPin(product.id) : false), [getIsPin, product]);

  const optionChecker = useMemo(() => {
    const options = path<IProductDetailViewModel["info"]["options"]>(["info", "options"], product);

    return options ? flattenProductOptions(options) : new Map();
  }, [product]);

  const optionStackSetter = useCallback(
    event => {
      if (product && optionChecker) {
        const {
          value,
          dataset: { index: i }
        } = event.target;
        const index = Number(i);

        const stack = produce(optionStack, draft => {
          draft.splice(index, 1, value);
        }) as any;

        if (stack.length === product.info.options.names.length) {
          const optionText = stack.join(", ");
          const { optionId, optionPrice, stockCount } = optionChecker.get(optionText) as ISelectedOptionValue;
          const targetIndex = selectedOptions.findIndex(opt => opt.optionText === optionText);

          if (targetIndex >= 0) {
            if (selectedOptions[targetIndex].selectedCount === stockCount) {
              alert(NOTICE.MAXIMUM_STOCK_COUNT);
              setOptionStack([]);
            } else {
              setSelectedOptions(
                produce(selectedOptions, draft => {
                  draft[targetIndex].selectedCount += 1;
                }) as any
              );
            }
          } else {
            setSelectedOptions(
              produce(selectedOptions, draft => {
                draft.push({
                  optionText,
                  selectedCount: 1,
                  optionId,
                  optionPrice
                });
              }) as any
            );
          }

          setOptionStack([]);
        } else {
          setOptionStack(stack);
        }
      }
    },
    [product, optionChecker, optionStack, selectedOptions]
  );

  // TODO: userable gender_id
  // TODO: ui/genderId
  useEffect(() => {
    const param = { productIds: [{ productId }] };

    api.get__products__id(productId).then(res => {
      const product = res.data.payload;
      const productViewModel = productDetailViewModelCreator(product);
      const storeViewModel = camelizeKeys(product.store) as StoreSerializedCamel;
      Tracking.viewContent({
        content_type: "product",
        content_ids: [pid],
        content_name: product.name,
        value: product.price,
        currency: "KRW"
      });
      AirbridgeEvent.productDetailViewed({
        semanticAttributes: {
          products: [
            {
              productID: product.id,
              name: product.name,
              price: product.price,
              currency: "KRW"
            }
          ]
        }
      });
      setProduct(productViewModel);
      setHero(productViewModel.info.images.main[0]);
      setStore(storeViewModel);

      const promises: [
        Promise<GetDiscountCouponsResponse>,
        Promise<{ point: number; level: number } | { min_point: number; max_point: number }>,
        Promise<GetUserablesProductsRecentResponse>
      ] = [
        api.get__discount_coupons({
          productId,
          page: { number: 1, size: 1 }
        }),
        token ? api.get__products__order_points__by_level(param) : api.get__products__order_points__range(param),
        api.get__userables__products__recent()
      ];

      Promise.all(promises).then(
        ([
          {
            data: { payload: discountCoupons }
          },
          point,
          recents
        ]) => {
          pipe(() => discountCoupons, prop("length"), Boolean, setIsIssuable)();

          pipe(() => point, dissoc("level"), camelizeKeys as any, setPoint)();

          dispatch(
            initialize({
              ...(token && { recents })
            })
          );

          if (!token) dispatch(sync({ recents: storageContext.recentsUpdater(product) }));
        }
      );
    });
  }, [productId, pid, dispatch, token]);

  const partnerProductBase = useMemo(() => {
    if (product && store) {
      const {
        id,
        name,
        price,
        prevPrice,
        info: { images }
      } = product as IProductDetailViewModel;

      return {
        productId: id,
        productName: name,
        photoUrl: images.main[0],
        price,
        prevPrice,
        storeName: store.name
      };
    }

    return {
      productId: 0,
      productName: "",
      photoUrl: "",
      price: 0,
      prevPrice: 0,
      storeName: ""
    };
  }, [product, store]);

  const createPartnerProductBase = useMemo(() => createPartnerOption(partnerProductBase), [partnerProductBase]);

  const getPartnerProducts = useMemo<IPartnerOptionViewModel[]>(() => {
    if (!product) return [];
    const { type } = product.info.options;
    const id = product.info.options.data[0]?.id;

    return type === "single"
      ? [createPartnerProductBase({ optionId: id, selectedCount: singleSelectedCount })]
      : map(createPartnerProductBase, selectedOptions);
  }, [product, selectedOptions, singleSelectedCount, createPartnerProductBase]);

  const cartId = useMemo(() => {
    if (product && token) {
      const { type } = product.info.options;
      if (type === "single" || selectedOptions.length) return "add_to_cart";
    }
  }, [product, selectedOptions, token]);

  const handleCart = useCallback(async () => {
    if (!product) return;
    const { type } = product.info.options;
    const id = product.info.options.data[0]?.id;

    if (!token) dispatch(modalOn(UI__MODAL_LOGIN));
    else if (type === "single") {
      await dispatch(addCart(Array(singleSelectedCount).fill(id)));
      setSingleSelectedCount(1);
    } else if (selectedOptions.length) {
      await dispatch(
        addCart(getPartnerProducts.flatMap(({ optionId, selectedCount }) => Array(selectedCount).fill(optionId)))
      );
      setSelectedOptions([]);
    } else {
      alert(NOTICE.NEED_SELECT_OPTION);
    }
    Tracking.addToCart({
      content_name: product.name,
      content_ids: [String(product.id)],
      content_type: "product",
      value: product.price,
      currency: "KRW"
    });
  }, [product, token, dispatch, selectedOptions, singleSelectedCount, getPartnerProducts]);

  const readyToOrder = useCallback(() => {
    history.push("/order", { partnerOptions: getPartnerProducts, context: PRODUCT_DETAIL });
  }, [getPartnerProducts, history]);

  const purchase = useCallback(() => {
    if (!token) dispatch(modalOn(UI__MODAL_LOGIN));
    else if (product!.info.options.type === "multiple" && selectedOptions.length) readyToOrder();
    else if (product!.info.options.type === "single") readyToOrder();
    else alert(NOTICE.NEED_SELECT_OPTION);
  }, [token, dispatch, product, selectedOptions.length, readyToOrder]);

  // TODO: 각각에 대한 타이핑해줄 것
  // TODO: 공통로직이나 분리할 로직 더 없는지 꼼꼼히 살펴보기

  return {
    product,
    pathname,
    store,
    hero,
    setHero,
    getIsBookmark,
    toggleBookmark,
    point,
    isIssuable,
    singleSelectedCount,
    selectedOptions,
    isPin,
    togglePin,
    handleCart,
    readyToOrder,
    isMobile,
    optionChecker,
    optionStackSetter,
    optionStack,
    setSingleSelectedCount,
    setSelectedOptions,
    purchase,
    isLoading,
    totalPrice,
    cartId
  };
};
