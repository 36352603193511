import { EventOption, AirbridgeAPI } from "airbridge-web-sdk-loader/build/airbridge_api";

class AirbridgeBaseAPI {
  sendEvents: (category: string, eventOption?: EventOption) => AirbridgeAPI;

  constructor(protected airbridge: AirbridgeAPI) {
    this.sendEvents = (category: string, eventOption?: EventOption): AirbridgeAPI => {
      airbridge.events.send(category, eventOption);

      return airbridge;
    };
  }
}

export default AirbridgeBaseAPI;
