import { fetcher } from "utils";

import { MemberSerialized, Provider, IJSendSuccessResponse, IJSendFailResponse } from "typings";

export interface IPost {
  member: MemberSerialized;
  message?: "이미 연동되어있는 소셜계정입니다.";
}

export function post__members__social_accounts(
  provider: Provider,
  providerToken: string
): Promise<IJSendSuccessResponse<IPost> | IJSendFailResponse<IPost>> {
  return fetcher.post("/members/social_accounts", { provider, providerToken });
}
