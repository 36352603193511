import { camelizeKeys } from "humps";

import { IMembersOrderPointsDetail, IMembersOrderPointsSimple, IOrderPointsViewModel } from "typings";

type OrderPointsViewModelCreator = (arg: {
  simple: IMembersOrderPointsSimple;
  detail: IMembersOrderPointsDetail;
}) => IOrderPointsViewModel;

export const orderPointsViewModelCreator: OrderPointsViewModelCreator = ({
  simple,
  detail: { points_to_next_level, last_2_years_price, level_score, points_to_will_expire }
}) =>
  camelizeKeys({
    ...simple,
    points_to_next_level,
    last_2_years_price,
    points_to_will_expire,
    level_score,
    limit: level_score + points_to_next_level - 1,
    currentLevelInfo: [
      ["레벨 혜택", `${simple.level_point_rate}% 적립`],
      ["레벨 점수", `${level_score.toLocaleString()}점`],
      ["적립금", `${simple.point.toLocaleString()}원`],
      ["2년 누적 구매금액", `${last_2_years_price.toLocaleString()}원`]
    ]
  }) as any;
