import React, { FC, useMemo, useCallback } from "react";
import styles from "./OrderNavBar.module.scss";
import classNames from "classnames/bind";

interface IProp {
  onClick?: (filter: string) => void;
  count: number;
  title: string;
  value: string;
  isSelected?: boolean;
  isLast?: boolean;
}

const cx = classNames.bind(styles);

export const OrderNavBar: FC<IProp> = ({ onClick, count, title, isSelected, value, isLast }) => {
  const viewCount = useMemo(() => (count > 99 ? "99+" : count), [count]);
  const clickEvent = useCallback(() => {
    onClick && onClick(value);
  }, [onClick, value]);
  return (
    <div className={cx("wrapper", { last: isLast })} onClick={clickEvent}>
      <div className={cx("title", { isSelected })}>{title}</div>
      <div className={cx("count", { isSelected })}>{viewCount}건</div>
    </div>
  );
};
