import React, { FC, useCallback } from "react";

import { IOrderProductNextStatusCamel } from "typings";

import { OrderActionButton } from "./OrderActionButton";

interface IProp {
  nextStatus: IOrderProductNextStatusCamel[];
  onClick: (target: IOrderProductNextStatusCamel) => void;
}

export const OrderAction: FC<IProp> = ({ nextStatus, onClick }) => {
  const actionClickEvent = useCallback(
    (index: number) => () => {
      onClick(nextStatus[index]);
    },
    [nextStatus, onClick]
  );
  return (
    <>
      {nextStatus.map((nextState, index) => (
        <OrderActionButton
          key={`${nextState.actionType}-${Math.random()}`}
          nextState={nextState}
          onClick={actionClickEvent(index)}
          highlight={nextState.status === "confirm"}
        />
      ))}
    </>
  );
};
