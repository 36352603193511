import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import {
  UI__MODAL_DISCONNECT_PROVIDER,
  UI__MODAL_ISSUABLE_COUPON,
  UI__MODAL_APPLY_COUPON,
  UI__MODAL_COUPON_REGISTER
} from "constant";
import { IStore } from "typings";

import styles from "./ModalWrapper.module.scss";

const cx = classNames.bind(styles);

// const isiOS = /ipad|iphone|ipod/.test(window.navigator.userAgent.toLowerCase()) && !window.MSStream;

export const ModalWrapper: FC = ({ children }) => {
  const { isMobile, modalStatus } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);

  useEffect(() => {
    const androidInputEvent = () => {
      if (document.activeElement && document.activeElement.tagName === "INPUT") document.activeElement.scrollIntoView();
    };

    if (window.document) {
      const $nav = window.document.querySelector("nav");

      if ($nav) $nav.classList.remove("navSlideUp");
      window.document.body.style.overflow = "hidden";
    }

    if (/Android/.test(navigator.appVersion)) window.addEventListener("resize", androidInputEvent);

    return () => {
      window.document.body.style.overflow = "";
      window.document.body.style.position = "";

      if (/Android/.test(navigator.appVersion)) window.removeEventListener("resize", androidInputEvent);
    };
  }, []);

  const isNotFullScreenModalAtMobile = isMobile && modalStatus === UI__MODAL_DISCONNECT_PROVIDER;

  const isCoverPopup = [UI__MODAL_APPLY_COUPON, UI__MODAL_ISSUABLE_COUPON, UI__MODAL_COUPON_REGISTER].includes(
    modalStatus || ""
  );

  return (
    <div className={cx("background", { isCoverPopup })}>
      <div className={cx("center", { isNotFullScreenModalAtMobile })}>
        <div className={cx("wrapper")}>{children}</div>
      </div>
    </div>
  );
};
