import React, { FC } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames/bind";

import { StoreRow } from "components";

import { redNew } from "assets/img";

import { StoreSerializedCamel } from "typings";

import styles from "./StoreRowWithRank.module.scss";

const cx = classnames.bind(styles);

interface IProps {
  id: StoreSerializedCamel["id"];
  name: StoreSerializedCamel["name"];
  photoUrl: StoreSerializedCamel["photoUrl"];
  group: StoreSerializedCamel["group"];
  rank: StoreSerializedCamel["rank"];
  message: StoreSerializedCamel["message"];
  serializedTags: StoreSerializedCamel["serializedTags"];
  isNew: StoreSerializedCamel["isNew"];
  isStar: boolean;
  genderId: StoreSerializedCamel["genderId"];
}

export const StoreRowWithRank: FC<IProps> = ({
  id,
  name,
  photoUrl,
  group,
  rank,
  message,
  serializedTags,
  isNew,
  isStar
}) => (
  <Link to={{ pathname: `/stores/${id}` }} className={cx("store")} key={id}>
    <div className={cx("rank-and-new")}>
      <div className={cx("rank")}>{rank}</div>
      {isNew && <img className={cx("new")} alt="new" src={redNew} />}
    </div>

    <StoreRow
      key={id}
      id={id}
      name={name}
      photoUrl={photoUrl}
      group={group}
      message={message}
      serializedTags={serializedTags}
      isStar={isStar}
    />
  </Link>
);
