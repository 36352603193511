import React, { Suspense, lazy, useRef, useEffect } from "react";
import { chuseok1, chuseok2, chuseok3, chuseok4, chuseok5 } from "assets/img";
import styles from "./Campaign.module.scss";
import classnames from "classnames/bind";
import Slider from "react-slick";
const Marketing = lazy(() => import("./Marketing"));
const Chuseok = lazy(() => import("./Chuseok"));
const cx = classnames.bind(styles);

export function Campaign() {
  const settings = {
    speed: 1,
    slidesToScroll: 1,
    slidesToShow: 1,
    swipe: false,
    infinite: false,
    afterChange: i => {
      vid?.current?.load();
      vid?.current?.pause();
      if (i === 4) vid?.current?.play();
    }
  };
  const chuseokImage = [chuseok1, chuseok2, chuseok3, chuseok4];

  const slider = useRef<any>();
  const vid = useRef<any>();

  useEffect(() => {
    const prevBg = document.body.style.background;
    document.body.style.background = "black";
    return () => {
      document.body.style.background = prevBg;
    };
  }, []);

  const handleNext = () => {
    slider?.current?.slickNext();
  };
  const handlePrev = () => {
    slider?.current?.slickPrev();
  };
  const chuseokVid = () => {
    return (
      <video
        ref={vid}
        src="https://d3ixabm91cqpbb.cloudfront.net/2021_chuseok/chuseok_1.mp4"
        controls
        loop
        autoPlay
        playsInline
      />
    );
  };
  const handleDeeplink = () => {
    vid?.current?.pause();
  };

  return (
    <>
      <div className={cx("wrapper")}>
        <Slider ref={slider} {...settings}>
          {chuseokImage.map(img => {
            return (
              <Suspense fallback={<div />}>
                <Chuseok key={img} prev={handlePrev} next={handleNext} img={img} />;
              </Suspense>
            );
          })}
          <Suspense fallback={<div />}>
            <Marketing
              prev={handlePrev}
              next={handleNext}
              vid={chuseokVid}
              img={chuseok5}
              onDeeplink={handleDeeplink}
            />
          </Suspense>
        </Slider>
      </div>
    </>
  );
}
