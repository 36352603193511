import { camelizeKeys } from "humps";
import { format } from "date-fns";

import {
  GetDiscountCouponsResponse,
  GetMembersIssuedDiscountCouponsResponse,
  IDiscountCouponViewModel,
  IIssuedDiscountCouponViewModel,
  GetDiscountCouponsCheckIssuableResponse,
  IPossibleCouponViewModel,
  GetMembersIssuedDiscountCouponsPossibleCouponsResponse
} from "typings";
import { formatDateTime } from "utils";

export const discountCouponViewModelCreator = ({
  data: { payload: discountCoupons }
}: GetDiscountCouponsResponse): IDiscountCouponViewModel[] =>
  discountCoupons.map(
    ({
      id,
      name,
      discount_type,
      discount_value,
      usable_price_limit_gte,
      discount_price_limit_lte,
      issuable_time_lte,
      usable_product_options_count_type
    }): IDiscountCouponViewModel => ({
      couponId: id,
      name,
      usableProductOptionsCountType: usable_product_options_count_type,
      usablePriceLimitGte: usable_price_limit_gte,
      formatDiscountValue:
        discount_type === "rate"
          ? `${Math.round(discount_value * 100)}%`
          : `${Math.round(discount_value).toLocaleString()}원`,
      formatUsablePriceLimitGte: `${
        usable_product_options_count_type === "single" ? "개별상품" : "합산"
      } ${usable_price_limit_gte.toLocaleString()}원 이상 구매시 사용가능`,
      formatDiscountPriceLimitLte: `최대 ${discount_price_limit_lte.toLocaleString()}원 할인`,
      issuableDateLte: `발급 가능 일자: ~${formatDateTime(issuable_time_lte)}`
    })
  );

export const issuedDiscountCouponsViewModelCreator = ({
  data: { payload }
}: GetMembersIssuedDiscountCouponsResponse): IIssuedDiscountCouponViewModel[] =>
  payload.map(
    ({ id, old_discount_coupon, usable_at_limit_lte }): IIssuedDiscountCouponViewModel => {
      const usableAtLimitText = `~${format(new Date(usable_at_limit_lte), "yyyy.MM.dd HH시")}`;

      const conditionDesc = (() => {
        const result: string[] = [];

        if (old_discount_coupon.usable_price_limit_gte && old_discount_coupon.usable_product_options_count_type) {
          result.push(old_discount_coupon.usable_product_options_count_type === "single" ? "개별상품" : "합산");
          result.push(`${old_discount_coupon.usable_price_limit_gte.toLocaleString()}원 이상 구매시 사용가능.`);
        }

        if (old_discount_coupon.discount_price_limit_lte) {
          result.push(`최대 ${old_discount_coupon.discount_price_limit_lte.toLocaleString()}원 할인`);
        }

        return result.join(" ");
      })();

      return {
        couponId: id,
        coupon: camelizeKeys(old_discount_coupon) as any,
        conditionDesc,
        usableAtLimitText,
        usableAtLimitDesc: `사용 가능 기간: ${usableAtLimitText}`
      };
    }
  );

export const couponStatusViewModelCreator = ({ data: { payload } }: GetDiscountCouponsCheckIssuableResponse) => payload;

export const possibleCouponsViewModelCreator = ({
  data: { payload }
}: GetMembersIssuedDiscountCouponsPossibleCouponsResponse): IPossibleCouponViewModel[] =>
  payload.map(({ product_option_id, issued_discount_coupon_ids }) => ({
    productOptionId: product_option_id,
    issuedDiscountCouponIds: issued_discount_coupon_ids
  }));
