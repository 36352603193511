import { useCallback, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { modalOn, removeSetsIteAction, addSetsItemAction } from "actions/actionCreators";

import { post__users__coordi, delete__users__coordi } from "api";

import { UI__MODAL_LOGIN } from "constant";
import { IStore } from "typings";
import { Tracking, AirbridgeCustomEvent } from "utils";
import { useSets } from "./useSets";

export const useCoordi = () => {
  const dispatch = useDispatch();

  const pending = useRef(false);

  const token = useSelector<IStore, IStore["member"]["token"]>(({ member: { token } }) => token, shallowEqual);
  const { coordi } = useSets();

  const getIsCoordiLike = useCallback((id: number) => coordi.has(id), [coordi]);

  const deleteCoordiLike = useCallback(
    async (...ids: number[]) => {
      await Promise.all(ids.map(id => delete__users__coordi(id)));
      ids.forEach(id => {
        dispatch(removeSetsIteAction({ key: "coordi", value: id }));
      });
    },
    [dispatch]
  );
  const toggleLike = useCallback(
    async event => {
      if (!pending.current) {
        pending.current = true;
        const { title: name, id: _id } = event.currentTarget.dataset;
        const id = Number(_id);

        if (!token) dispatch(modalOn(UI__MODAL_LOGIN));
        else if (getIsCoordiLike(id)) await deleteCoordiLike(id);
        else {
          await post__users__coordi({ coordinationId: id });
          dispatch(addSetsItemAction({ key: "coordi", value: id }));
          Tracking.addToCoordi();
          AirbridgeCustomEvent.like("coordi", {
            id,
            name
          });
        }
        pending.current = false;
      }
    },
    [token, dispatch, deleteCoordiLike, getIsCoordiLike]
  );

  return { coordi, getIsCoordiLike, toggleLike, deleteCoordiLike };
};
