import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { func } from "prop-types";
import { Route } from "react-router-dom";

import { logPageView, isSuggestMobileApp, Tracking } from "utils";

import * as actions from "actions/actionCreators";

import qs from "qs";

import { locationTypes, matchTypes, historyTypes, uiTypes, memberTypes } from "utils/routerPropTypes";

import {
  Accounts,
  Base,
  Bookmarks,
  Cart,
  CoordiDetail,
  Coordi,
  CoordiLike,
  Claim,
  Contact,
  Contacts,
  Coupons,
  CouponSelect,
  DynamicMeta,
  Footer,
  Home,
  Kakao,
  Level,
  LogoutBtn,
  Member,
  ModalContainer,
  NavbarDesktop,
  NavbarMobile,
  Naver,
  Order,
  OrderDetail,
  OrderResult,
  Orders,
  Pins,
  Points,
  ProductDetailDesktop,
  ProductDetailMobile,
  ScrollButton,
  StoresDesktop,
  StoresMobile,
  CategorySearch,
  NewestSearch,
  ProductSearch,
  RankingSearch,
  Sidebar,
  StoreDetail,
  StoreSearch,
  SuggestMobileApp,
  Logout,
  Banners,
  PopUpEventModal,
  ProductReviewMore,
  Qna,
  Notice,
  Help,
  Faq,
  MemberReview,
  Campaign
} from "components";

import { UI__MODAL_LOGIN } from "constant";

import { modalContext, kakaoPixelContext, storageContext } from "contexts";
import { ProductRoute, PrivateRoute } from "routes";

import "styles/App.scss";
import { PopUpNoticeModal } from "./role/PopUpNoticeModal/PopUpNoticeModal";
import LookpinBanner from "./base/LookpinBanner";

// TODO: Footer로 감싸주는 HOC 템플릿과 LogoutBtn로 감싸주는 HOC 템플릿 만들기

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showSuggestMobileApp: true,
      exceedSuggestMobileApp: true,
      popUpEventModalClose: storageContext.isPopUpClose,
      isBannerVisible: true
    };
    this.handleCloseBanner = this.handleCloseBanner.bind(this);
    this.closeSuggestMobileApp = this.closeSuggestMobileApp.bind(this);
    this.closePopUpEventModal = this.closePopUpEventModal.bind(this);
    this.todayNoSeePopUpModal = this.todayNoSeePopUpModal.bind(this);
  }

  componentDidMount() {
    const { history, modalOn, modalOff, setMobile } = this.props;
    const { showSuggestMobileApp } = this.state;

    const loginParam = qs.parse(history.location.search, { ignoreQueryPrefix: true }).login;
    const redirectURL = qs.parse(history.location.search, { ignoreQueryPrefix: true }).redirect;

    Tracking.pageChange();
    if (loginParam) {
      if (!storageContext.token) modalOn(UI__MODAL_LOGIN);
      history.replace({ search: "" }, { redirect: redirectURL });
    }

    window.matchMedia("(max-width: 450px)").addListener(({ matches }) => setMobile(matches));

    history.listen((location, action) => {
      if (action === "PUSH" && !location.search) window.scrollTo(0, 0);

      if (modalContext.stack.length) {
        if (action === "PUSH" && modalContext.url && modalContext.url !== location.pathname) {
          modalOff({ isClear: true });
        } else if (action === "POP") {
          modalOff({
            isClear: !location.state
          });
        }
      }

      if (showSuggestMobileApp) this.setState({ showSuggestMobileApp: false });
    });

    // 에어브릿지 - 최초 1번의 검색 이벤트 적용을 위해 초기화.
    if (localStorage.getItem("searched")) {
      localStorage.removeItem("searched");
    }

    if (!isSuggestMobileApp(storageContext.suggestMobileAppLimit)) this.setState({ exceedSuggestMobileApp: false });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.location !== nextProps.location) {
      logPageView();
      if (kakaoPixelContext) kakaoPixelContext.pageView();
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      history: {
        location: { pathname }
      }
    } = this.props;

    if (prevProps.location.pathname !== pathname) Tracking.pageChange();
  }

  handleCloseBanner() {
    this.setState(prevState => ({ ...prevState, isBannerVisible: false }));
  }

  closeSuggestMobileApp() {
    this.setState({ showSuggestMobileApp: false });
  }

  closePopUpEventModal() {
    this.setState(prevState => ({ ...prevState, popUpEventModalClose: "close" }));
    storageContext.sessionPopUpClose();
  }

  todayNoSeePopUpModal() {
    this.setState(prevState => ({ prevState, popUpEventModalClose: "close" }));
    storageContext.todayPopUpNoSee();
  }

  render() {
    const {
      ui: { modalStatus, isMobile },
      member: { token },
      logout,
      location
    } = this.props;

    const {
      showSuggestMobileApp,
      isLoading,
      exceedSuggestMobileApp,
      popUpEventModalClose,
      isBannerVisible
    } = this.state;

    const redirectURL = location.state?.redirect;

    return (
      <>
        {!["/products"].includes(window.location.pathname) && <DynamicMeta url={window.location.href} />}

        {/* 모바일 앱 딥링크 상단 배너 */}
        {isMobile && <LookpinBanner isBannerVisible={isBannerVisible} onCloseBanner={this.handleCloseBanner} />}

        <Base />
        {!popUpEventModalClose && !redirectURL && (
          <PopUpEventModal onClose={this.closePopUpEventModal} onTodayNoSee={this.todayNoSeePopUpModal} />
        )}
        {!redirectURL && <PopUpNoticeModal />}
        <div className={`App ${isBannerVisible ? "isBanner" : ""}`}>
          <ScrollButton />

          {isMobile ? <NavbarMobile /> : <NavbarDesktop />}

          {isMobile && showSuggestMobileApp && !storageContext.token && exceedSuggestMobileApp && (
            <SuggestMobileApp closeSuggestMobileApp={this.closeSuggestMobileApp} />
          )}

          {modalStatus && <ModalContainer />}

          {!isMobile && location.pathname !== "/banners" && <Sidebar />}

          <Route exact path="/" component={Home} />

          <Route exact path="/coordi" component={Coordi} />
          <Route path="/coordi/:coordiId" component={CoordiDetail} />

          <Route path="/banners" component={Banners} />
          <Route exact path={["/category", "/search/category"]} component={CategorySearch} />
          <Route exact path="/search/product" component={ProductSearch} />
          <Route exact path={["/ranking", "/search/ranking"]} component={RankingSearch} />
          <Route exact path={["/new", "/search/newest"]} component={NewestSearch} />
          <Route exact path="/search/store" component={StoreSearch} />

          <ProductRoute
            exact
            path="/products/:productId"
            component={isMobile ? ProductDetailMobile : ProductDetailDesktop}
          />

          <Route exact path="/order" component={Order} />
          <Route exact path="/order/coupon" component={CouponSelect} />
          <Route exact path="/order/result/:orderNum" component={OrderResult} />

          <Route exact path="/stores" component={isMobile ? StoresMobile : StoresDesktop} />
          <Route path="/stores/:storeId(\d+)" component={StoreDetail} />

          <Route path="/kakao" component={Kakao} />
          <Route path="/naver" component={Naver} />
          <Route path={["/notice", "/member/notice"]} component={Notice} />
          <Route path={["/faq", "/member/faq"]} component={Faq} />
          <Route path="/help" component={Help} />
          <PrivateRoute exact path="/member" component={Member} />
          <PrivateRoute exact path="/member/orders" component={Orders} />
          <PrivateRoute exact path="/member/orders/:orderNum/:productOrderNum" component={OrderDetail} />
          <PrivateRoute path="/member/accounts" component={Accounts} />
          <PrivateRoute path="/member/cart" component={Cart} />
          <PrivateRoute path="/member/level" component={Level} />
          <PrivateRoute path="/member/coupons" component={Coupons} />
          <PrivateRoute path="/member/contacts" component={Contacts} />
          <PrivateRoute path="/member/point" component={Points} />
          <PrivateRoute path="/member/bookmarks" component={Bookmarks} />
          <PrivateRoute path="/member/pins" component={Pins} />
          <PrivateRoute path="/member/coordi" component={CoordiLike} />
          <PrivateRoute path="/member/qna" component={Qna} />
          <PrivateRoute path="/member/orders/:orderNum/:orderProductOrderNum/contact" component={Contact} />
          <PrivateRoute path="/member/orders/:orderNum/:orderProductOrderNum/claim/:status" component={Claim} />
          <PrivateRoute path="/member/review" component={MemberReview} />

          <Route path="/logout" component={Logout} />

          {!isLoading && <Footer />}
          {!isLoading && isMobile && token && <LogoutBtn logout={logout} />}
        </div>
      </>
    );
  }
}

App.propTypes = {
  match: matchTypes.isRequired,
  location: locationTypes.isRequired,
  history: historyTypes.isRequired,
  ui: uiTypes.isRequired,
  member: memberTypes.isRequired,
  modalOff: func.isRequired,
  modalOn: func.isRequired,
  setMobile: func.isRequired,
  setGender: func.isRequired,
  logout: func.isRequired
};

export default hot(
  connect(
    state => state,
    dispatch => ({ dispatch, ...bindActionCreators(actions, dispatch) })
  )(App)
);
