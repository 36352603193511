import React, { FC, useCallback } from "react";
import classNames from "classnames/bind";
import styles from "./UserInput.module.scss";

interface IProp {
  value: number | string;
  onChange: (str: number | string) => void;
  title: string;
  unit: string;
}
const cx = classNames.bind(styles);
export const UserInput: FC<IProp> = ({ value, onChange, title, unit }) => {
  const valueChangeLitsner = useCallback(
    e => {
      const number = Number(e.target.value);
      if (Number.isNaN(number)) return;
      if (number === 0) onChange("");
      else onChange(e.target.value);
    },
    [onChange]
  );
  return (
    <div className={cx("wrapper")}>
      <div className={cx("title")}>{value.toString().length !== 0 ? title : ""}</div>
      <div className={cx("input-wrapper")}>
        <input className={cx("input")} type="tel" value={value} onChange={valueChangeLitsner} placeholder={title} />
        <span className={cx("unit")}>{unit}</span>
      </div>
    </div>
  );
};
