import { IJSendSuccessResponse, IPageMeta, IWithMeta, NoticeSerialized } from "typings";
import { fetcher } from "utils";

export type NoticeListGetRequest = (params?: {
  isPopup: boolean;
}) => Promise<IJSendSuccessResponse<IWithMeta<NoticeSerialized[], IPageMeta>>>;

export const api__v1_get_notice_for_userables: NoticeListGetRequest = params =>
  fetcher.get("/api/v1/notice_for_userables", {
    page: {
      number: 1,
      size: 100
    },
    is_popup: params?.isPopup
  });
