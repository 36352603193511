import React, { FC } from "react";
import classnames from "classnames/bind";

import { joinByComponent } from "utils";

import { ResetIconBlack } from "assets/icon";

import styles from "./DisplayCondition.module.scss";

const cx = classnames.bind(styles);

export const DisplayCondition: FC<{
  conditions: string[];
  resetCondition: () => void;
}> = ({ conditions, resetCondition }) => (
  <div className={cx("condition")}>
    <div className={cx("condition-display", { visible: conditions.length })}>
      <span className={cx("condition-text")}>
        {joinByComponent(<span className={cx("seperator")}>&nbsp;|&nbsp;</span>, conditions)}
      </span>
      에&nbsp;대한&nbsp;검색결과입니다.&nbsp;
      <button type="button" className={cx("condition-reset")} onClick={resetCondition}>
        <ResetIconBlack />
        초기화
      </button>
    </div>
  </div>
);
