import { productStatusParser } from "view-model-creator";

import { IRecentViewModel, GetUserablesProductsRecentResponse, ApiV1ProductSerialized } from "typings";

export const recentsViewModelCreator = ({
  data: { products }
}: GetUserablesProductsRecentResponse): IRecentViewModel[] =>
  products.map(({ id, sold_out, status, name, photo_url, price }) => ({
    productId: id,
    status: productStatusParser(status, sold_out),
    name,
    photoUrl: photo_url,
    price
  }));

export const recentViewModelCreator = ({
  id,
  sold_out,
  status,
  name,
  info,
  price
}: ApiV1ProductSerialized): IRecentViewModel => ({
  productId: id,
  status: productStatusParser(status, sold_out),
  name,
  photoUrl: info?.images.main[0],
  price
});
