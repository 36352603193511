import { INITIALIZE, LOGOUT, SYNC } from "constant";

const initialState = {
  totalCount: 0
};

export default function cart(state = initialState, { type, payload }) {
  switch (type) {
    case INITIALIZE:
      if (!payload || !payload.cart) return state;
      return { ...state, totalCount: payload.cart.totalCount };

    case SYNC:
      if (!payload || !payload.cart) return state;
      return payload.cart;

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}
