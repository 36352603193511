import React, { FC } from "react";
import classNames from "classnames/bind";

import styles from "./Checkbox.module.scss";

const cx = classNames.bind(styles);

interface ICheckbox {
  checked: boolean;
  id: number | string;
  handleClick: (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
}

export const Checkbox: FC<ICheckbox> = ({ checked, id, handleClick }) => (
  <label htmlFor={String(id)} data-id={String(id)} className={cx("checkbox", { checked })} onClick={handleClick}>
    <input type="checkbox" className={cx("check-none")} id={String(id)} />
  </label>
);
