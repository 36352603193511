import { REVIEW_WRITE_DATA_SET, UI__MODAL_OFF } from "constant";
import { produce } from "utils";
import { IReviewWriteData } from "typings";

const initialState: IReviewWriteData = {};

export default function reviewWrite(state = initialState, { type, payload }) {
  return produce(state, _ => {
    switch (type) {
      case REVIEW_WRITE_DATA_SET:
        return payload;
      case UI__MODAL_OFF:
        return initialState;
      default:
        break;
    }
  });
}
