import { modalOff, updateCoupons } from "actions/actionCreators";
import * as api from "api";
import classNames from "classnames/bind";
import { IssuableCoupon } from "components";
import { applyTo, forEach, map, pipe, prop, reduce, then } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IDiscountCouponViewModel, IssaubleStatus, IIssuedDiscountCouponViewModel, IStore } from "typings";
import { discountCouponViewModelCreator, couponStatusViewModelCreator } from "view-model-creator";

import stylesWrapper from "../../ModalWrapper.module.scss";
import styles from "./CouponIssuable.module.scss";

const cxWrapper = classNames.bind(stylesWrapper);
const cx = classNames.bind(styles);

export const CouponIssuable = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [productId] = location.pathname.match(/\d+/) || [undefined];
  const issuedDiscountCoupons = useSelector<IStore, IStore["coupons"]>(({ coupons }) => coupons);

  const [discountCoupons, setDiscountCoupons] = useState<IDiscountCouponViewModel[]>([]);
  const [discountCouponStatusTable, setDiscountCouponStatusTable] = useState<{
    [key: string]: { status: IssaubleStatus };
  }>({});

  const issuedDiscountCouponTable = useMemo(
    pipe(
      () => issuedDiscountCoupons,
      reduce<IIssuedDiscountCouponViewModel, { [key: number]: IIssuedDiscountCouponViewModel }>((acc, coupon) => {
        acc[coupon.coupon.id] = coupon;
        return acc;
      }, {})
    ),
    [issuedDiscountCoupons]
  );

  const getDiscountCouponStatusTable = useCallback((coupons: IDiscountCouponViewModel[]) => {
    forEach(applyTo(coupons), [
      pipe(
        map(prop("couponId")),
        api.get__discount_coupons__check_issuable,
        then(couponStatusViewModelCreator),
        then(setDiscountCouponStatusTable)
      )
    ]);
  }, []);

  const getDiscountCoupons = useCallback(async () => {
    const coupons = await pipe(
      api.get__discount_coupons,
      then(discountCouponViewModelCreator)
    )({
      productId,
      page: { number: Math.ceil(discountCoupons.length / 100) + 1, size: 100 }
    });
    setDiscountCoupons(coupons);
    getDiscountCouponStatusTable(coupons);
  }, [productId, discountCoupons.length, getDiscountCouponStatusTable]);

  const getIssuedDiscountCoupons = useCallback(() => dispatch(updateCoupons()), [dispatch]);

  useEffect(() => {
    getDiscountCoupons();
    getIssuedDiscountCoupons();
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  const issueCoupon = useCallback(
    pipe(
      ({ target }) => ({ id: target.dataset.couponId }),
      api.post__discount_coupons__issue,
      then(getIssuedDiscountCoupons),
      then(() => {
        getDiscountCouponStatusTable(discountCoupons);
      })
    ),
    [getIssuedDiscountCoupons, discountCoupons]
  );

  if (!discountCoupons.length || !Object.keys(discountCouponStatusTable).length) return null;

  return (
    <div className={cxWrapper("cover-container")}>
      <div className={cx("issuable-coupons")}>
        <div className={cxWrapper("header")}>
          발급 가능 쿠폰
          <button className={cxWrapper("close")} onClick={() => dispatch(modalOff())} type="button">
            {" "}
          </button>
        </div>

        <div className={cx("contents")}>
          <ul>
            {discountCoupons.map(discountCoupon => (
              <li key={discountCoupon.couponId}>
                <IssuableCoupon
                  discountCoupon={discountCoupon}
                  issuableStatus={discountCouponStatusTable[discountCoupon.couponId].status}
                  issuedDiscountCoupon={issuedDiscountCouponTable[discountCoupon.couponId]}
                  issueCoupon={issueCoupon}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
