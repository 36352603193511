import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { NOTICE } from "constant";
import { always, equals, filter, identity, invoker, join, map, path, pipe, prop, sum, uniq, values } from "ramda";
import callPostcode from "services/daum";
import {
  Context,
  IMembersShippingInfosFail,
  IPartnerOptionViewModel,
  IPossibleCouponViewModel,
  IStore,
  PayMethodEn,
  PayMethodKo
} from "typings";
import { memberViewModelCreator, possibleCouponsViewModelCreator } from "view-model-creator";

import { initialize } from "actions/actionCreators";
import * as api from "api";
import { IPostMembersOrdersEntity, get__members__orders__views_v3 } from "api";
import { AirbridgeCustomEvent, produce } from "utils";
import { useScript } from "./useScript";
import { storageContext } from "contexts";

function createPaymentObject(): IPostMembersOrdersEntity["payload"]["paymentObject"] {
  return {
    pg: "",
    identificationCode: "",
    payMethod: null,
    merchantUid: 0,
    name: "",
    amount: 0,
    buyerEmail: "",
    buyerName: "",
    buyerTel: "",
    buyerAddr: "",
    appScheme: "",
    redirectUrl: "",
    noticeUrl: "",
    digital: null,
    naverpay_info: null
  };
}

const TOSS_PAYMENTS_SCRIPT_SRC = "https://js.tosspayments.com/v1/payment";

export const useOrder = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const scriptStatus = useScript(TOSS_PAYMENTS_SCRIPT_SRC);

  const { email } = useSelector<IStore, IStore["member"]>(({ member }) => member);
  const [isLoading, setIsLoading] = useState(false);
  const [partnerOptions, setPartnerOptions] = useState<IPartnerOptionViewModel[]>(
    path(["location", "state", "partnerOptions"], history) || []
  );

  useEffect(() => {
    setPartnerOptions(path(["location", "state", "partnerOptions"], history) || []);
  }, [history.location.search]); /* eslint-disable-line react-hooks/exhaustive-deps */

  const postcodeEl = useRef<HTMLButtonElement>(null);
  const zipcodeEl = useRef<HTMLInputElement>(null);
  const mainEl = useRef<HTMLInputElement>(null);
  const detailEl = useRef<HTMLInputElement>(null);

  const point = useSelector<IStore, IStore["orderPoints"]["point"]>(
    ({ orderPoints: { point } }) => point,
    shallowEqual
  );
  const context: Context = useMemo(() => {
    return path(["location", "state", "context"], history) as Context;
  }, [history.location.state]); /* eslint-disable-line react-hooks/exhaustive-deps */

  // 상품
  const optionIds: number[] = useMemo(
    () => partnerOptions.flatMap(({ optionId, selectedCount }) => Array(selectedCount).fill(optionId)),
    [partnerOptions]
  );
  const sumPrevPrice: number = useMemo(
    pipe(
      always(partnerOptions),
      map(({ prevPrice }) => prevPrice),
      sum
    ),
    [partnerOptions]
  );
  const sumProductPrice: number = useMemo(
    pipe(
      always(partnerOptions),
      map(({ priceToBuy }) => priceToBuy),
      sum
    ),
    [partnerOptions]
  );
  const sumStoreDiscountPrice: number = useMemo(() => sumPrevPrice - sumProductPrice, [sumPrevPrice, sumProductPrice]);
  const sumCouponDiscountPrice: number = useMemo(
    pipe(
      always(partnerOptions),
      map(({ couponDiscountPrice }) => couponDiscountPrice || 0),
      sum
    ),
    [partnerOptions]
  );

  // 쿠폰
  const coupons = useSelector<IStore, IStore["coupons"]>(({ coupons }) => coupons);
  const readyToSelectCoupon = useCallback(() => history.push("/order/coupon", { partnerOptions }), [
    history,
    partnerOptions
  ]);
  const [possibles, setPossibles] = useState<IPossibleCouponViewModel[]>([]);
  const couponUnusable = useMemo(
    () =>
      !coupons.length ||
      !possibles.reduce<number[]>((acc, { issuedDiscountCouponIds }) => acc.concat(issuedDiscountCouponIds), []).length,
    [coupons.length, possibles]
  );
  const hasCouponOptions = useMemo(() => partnerOptions.filter(({ couponId }) => couponId), [partnerOptions]);
  const issuedDiscountCoupons = useMemo(
    () =>
      Array.from<[number, number[]]>(
        hasCouponOptions
          .reduce((acc, option) => {
            if (acc.has(option.couponId as number)) {
              acc.set(
                option.couponId as number,
                acc.get(option.couponId as number).concat(Array(option.selectedCount).fill(option.optionId))
              );
            } else acc.set(option.couponId as number, Array(option.selectedCount).fill(option.optionId));
            return acc;
          }, new Map())
          .entries()
      ).map(([id, optionIds]) => ({ id, optionIds })),
    [hasCouponOptions]
  );
  const appliedCouponSize = useMemo(
    pipe(
      always(partnerOptions as any),
      filter(({ couponId }) => !!couponId),
      map(({ couponId }) => couponId),
      uniq,
      prop("length")
    ),
    []
  );
  const handleCoupon = useCallback(() => {
    if (!coupons.length) alert(NOTICE.COUPON_SELECT);
    else if (couponUnusable) alert(NOTICE.UNEXIST_APPLIABLE_PRODUCT);
    else readyToSelectCoupon();
  }, [coupons.length, readyToSelectCoupon, couponUnusable]);

  // 적립금
  const [inputPoint, setInputedPoint] = useState<number | "">("");
  const [consumedPoint, setConsumedPoint] = useState(0);
  const isUnusableOrderPoint: boolean = useMemo(() => point < 1000 || sumProductPrice < 10000, [
    point,
    sumProductPrice
  ]);
  const handleInputOrderPoint = useCallback(
    ({ target }) => setInputedPoint(target.value ? Number(target.value) : target.value),
    []
  );
  const handleConsumedOrderPoint = useCallback(() => {
    if (inputPoint) {
      (async () => {
        const { status, message, consumed_point } = await api.get__members__order_points__valid({
          optionIds,
          consumedPoint: inputPoint,
          discountPrice: sumCouponDiscountPrice
        });
        const consumedPoint = consumed_point || 0;

        if (status === "success") setConsumedPoint(consumedPoint);
        else alert(message);

        setInputedPoint(consumedPoint);
      })();
    } else {
      setInputedPoint(0);
      setConsumedPoint(0);
    }
  }, [optionIds, inputPoint, sumCouponDiscountPrice]);
  const setMaxPoint = useCallback(() => {
    (async () => {
      const { consumed_point, is_max_point } = await api.get__members__order_points__valid({
        optionIds,
        consumedPoint: point,
        discountPrice: sumCouponDiscountPrice
      });

      if (!is_max_point) return;
      const consumedPoint = consumed_point || 0;

      setInputedPoint(consumedPoint);
      setConsumedPoint(consumedPoint);
    })();
  }, [optionIds, point, sumCouponDiscountPrice]);

  // 주문자 정보
  const [shippingInfo, setShippingInfo] = useState({
    id: 0,
    name: "",
    phoneNumber: "",
    address: {
      zipcode: "",
      main: "",
      detail: ""
    },
    message: ""
  });
  const {
    name,
    phoneNumber,
    address: { main, detail, zipcode },
    message
  } = shippingInfo;
  const updateShippingInfo = useCallback(
    ({ target: { name, value } }) =>
      setShippingInfo(
        produce(shippingInfo, draft => {
          draft[name] = name === "phoneNumber" ? value.replace(/-/g, "") : value;
        })
      ),
    [shippingInfo]
  );
  const updateDetail = useCallback(
    ({ target: { value } }) =>
      setShippingInfo(
        produce(shippingInfo, draft => {
          draft.address.detail = value;
        })
      ),
    [shippingInfo]
  );
  const trimDetail = useCallback(() => {
    setShippingInfo(
      produce(shippingInfo, draft => {
        draft.address.detail = draft.address.detail.trim();
      })
    );
  }, [shippingInfo]);
  const setZipcodeAndMain = useCallback(() => {
    if (zipcodeEl.current && mainEl.current && detailEl.current) {
      callPostcode(
        (zipcode, main) =>
          setShippingInfo(
            produce(shippingInfo, draft => {
              draft.address = { zipcode, main, detail: "" };
            })
          ),
        () => (detailEl.current as HTMLInputElement).focus()
      );
    }
  }, [zipcodeEl, mainEl, detailEl, setShippingInfo, shippingInfo]);

  // 결제수단
  const [payMethods, setPayMethods] = useState<[PayMethodKo, PayMethodEn][]>([]);
  const [payMethod, setPayMethod] = useState<PayMethodEn | null>(null);
  const handlePayMethod = useCallback(event => setPayMethod(event.target.dataset.method), [setPayMethod]);

  // 결제금액
  const [expectedOrderPoint, setPredictOrderPoint] = useState(0);
  const callSetPredictOrderPoint = useCallback(async () => {
    if (!partnerOptions.length) return;
    const { point } = await api.get__products__order_points__by_level({
      productIds: partnerOptions.flatMap(({ optionId, productId, selectedCount }) =>
        Array(selectedCount).fill({ productId, optionId })
      ),
      discountPrice: consumedPoint + sumCouponDiscountPrice
    });

    setPredictOrderPoint(point);
  }, [partnerOptions, consumedPoint, sumCouponDiscountPrice]);
  const paidAmount = useMemo(() => sumProductPrice - sumCouponDiscountPrice - consumedPoint, [
    sumProductPrice,
    sumCouponDiscountPrice,
    consumedPoint
  ]);

  // 결제
  const canPay: boolean = useMemo(() => {
    const target = [optionIds.length, name, phoneNumber, zipcode, main, payMethod];
    return equals(target, target.filter(identity));
  }, [optionIds.length, payMethod, name, phoneNumber, zipcode, main]);
  const generateInvalidMessage = useCallback(() => {
    const checker: string[] = [];

    if (!optionIds.length) checker.push("구매 상품");
    if (!name) checker.push("이름");
    if (!phoneNumber) checker.push("연락처");
    if (!zipcode) checker.push("우편번호");
    if (!main) checker.push("주소");
    if (!detail) checker.push("상세 주소");
    if (!payMethod) checker.push("결제 방법");

    return join(", ", checker);
  }, [optionIds.length, name, phoneNumber, zipcode, main, detail, payMethod]);
  const requestPay = useCallback(() => {
    if (!canPay) {
      alert(`필수항목이 누락되었습니다.\n(${generateInvalidMessage()})`);
    } else {
      setIsLoading(true);

      // 결제 시작
      AirbridgeCustomEvent.orderStart({
        totalValue: paidAmount,
        totalQuantity: partnerOptions.reduce((acc, { selectedCount }) => {
          return (acc += selectedCount);
        }, 0),
        currency: "KRW",
        products: partnerOptions.map(({ productId, productName, priceToBuy, selectedCount }) => ({
          productID: productId,
          name: productName,
          price: priceToBuy,
          quantity: selectedCount,
          currency: "KRW"
        }))
      });

      (async () => {
        const { status, message, consumed_point } = await api.get__members__order_points__valid({
          optionIds,
          consumedPoint: inputPoint as number,
          discountPrice: sumCouponDiscountPrice
        });

        const consumedPoint = consumed_point || 0;
        setInputedPoint(consumedPoint);

        if (status === "error") {
          alert(message);
          setIsLoading(false);
        } else {
          setConsumedPoint(consumedPoint);

          const { status, data } = await api.post__members__shipping_infos({
            ...shippingInfo,
            name: shippingInfo.name.trim(),
            phoneNumber: shippingInfo.phoneNumber.trim()
          });

          if (status === "fail") {
            const message = pipe(
              always(data as IMembersShippingInfosFail),
              prop("shipping_info"),
              values,
              invoker(0, "flat"),
              join("\n")
            )();

            if (message) alert(message);

            setIsLoading(false);
          } else if (status === "success") {
            try {
              const {
                data: { payload }
              } = await api.post__members__orders__v3({
                consumePoint: consumedPoint,
                isPcWeb: true,
                options: partnerOptions.map(option => ({
                  count: option.selectedCount,
                  optionId: option.optionId,
                  issuedDiscountCouponId: option.couponId,
                  trackingData: null
                })),
                payMethod: payMethod as PayMethodEn,
                shippingInfo: {
                  shippingInfoId: shippingInfo.id === 0 ? null : shippingInfo.id,
                  address: shippingInfo.address,
                  message: shippingInfo.message,
                  name: shippingInfo.name,
                  phoneNumber: shippingInfo.phoneNumber,
                  infoAlias: null,
                  pccc: null
                },
                totalPaidPrice: paidAmount
              });

              if (scriptStatus === "idle" || scriptStatus === "error") {
                alert("결제사 연동이 올바르게 되지 않았습니다. 새로고침하여 다시 시도해 주세요.");
                setIsLoading(false);
                return;
              }

              const view = await get__members__orders__views_v3(payload.url);
              const parseredView = new DOMParser().parseFromString(view, "text/html");
              const script = parseredView.querySelector("body > script") as HTMLElement;

              if (script?.innerText) {
                // eslint-disable-next-line no-new-func
                const loadTossPayment = new Function(
                  `${script.innerText}.catch(error => {
                    if (error.code === 'USER_CANCEL') {
                      this?.();
                   }
                 })`
                );

                storageContext.setOrderOptions(partnerOptions);
                loadTossPayment.call(() => {
                  setIsLoading(false);
                  storageContext.removeOrderOptions();
                });
              }
            } catch (err) {
              alert("에러가 발생했습니다.");
              setIsLoading(false);
            }
          }
        }
      })();
    }
  }, [
    scriptStatus,
    canPay,
    paidAmount,
    generateInvalidMessage,
    hasCouponOptions.length,
    history,
    inputPoint,
    issuedDiscountCoupons,
    optionIds,
    partnerOptions,
    payMethod,
    shippingInfo,
    sumCouponDiscountPrice,
    email
  ]);

  useEffect(() => {
    if (!partnerOptions.length) alert("상품이 없습니다.");
    else {
      (async () => {
        const [
          member,
          {
            data: { payload: payMethods }
          },
          coupons,
          possibles,
          simple,
          detail
        ] = await Promise.all([
          api.get__members(),
          api.get__api__v3__pay__methods(),
          api.get__members__issued_discount_coupons(),
          api.get__members__issued_discount_coupons__possible_coupons({
            productOptionIds: partnerOptions
              .map(({ optionId, selectedCount }) => Array(selectedCount).fill(optionId))
              .flat()
          }),
          api.get__members__order_points__simple(),
          api.get__members__order_points__detail()
        ]);
        const { shippingInfo } = memberViewModelCreator(member);

        shippingInfo.phoneNumber = member.phone_number ? member.phone_number : shippingInfo.phoneNumber;
        setShippingInfo(shippingInfo);
        setPayMethods(payMethods.filter(({ code }) => code !== "tosspay").map(({ text, code }) => [text, code]));
        setPossibles(possibleCouponsViewModelCreator(possibles));
        dispatch(initialize({ coupons, orderPoints: { simple, detail } }));

        if (context) {
          api
            .post__members__orders__prepare({
              optionIds: partnerOptions
                .map(({ optionId, selectedCount }) => Array(selectedCount).fill(optionId))
                .flat(),
              context
            })
            .then(({ data: { payload: prepare } }) => {
              Promise.all<any>(
                [
                  api.get__members__issued_discount_coupons(),
                  api.get__members__issued_discount_coupons__possible_coupons({
                    productOptionIds: partnerOptions
                      .map(({ optionId, selectedCount }) => Array(selectedCount).fill(optionId))
                      .flat()
                  }),
                  prepare.map(({ issued_discount_coupon_id, option_ids }) =>
                    api.get__members__issued_discount_coupons__id__calculate_discount_price(issued_discount_coupon_id, {
                      productOptionIds: option_ids
                    })
                  )
                ].flat() as any
              ).then(([coupons, possibles, ...discounts]) => {
                setPossibles(possibleCouponsViewModelCreator(possibles as any));

                setPartnerOptions(
                  produce(partnerOptions, draft => {
                    discounts.forEach(({ data: { issued_discount_coupon_id, payload } }: any) => {
                      payload.forEach(({ product_option_id, value }) => {
                        const option = draft.find(({ optionId }) => optionId === product_option_id);
                        const coupon = coupons.data.payload.find(({ id }) => id === issued_discount_coupon_id);

                        if (option && coupon) {
                          option.couponId = issued_discount_coupon_id;
                          option.couponDesc = coupon.old_discount_coupon.name;
                          option.couponDiscountPrice = value;
                        }
                      });
                    });
                  })
                );

                dispatch(initialize({ coupons }));
              });
            });
        }
      })();
    }
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    callSetPredictOrderPoint();
  }, [partnerOptions, consumedPoint, callSetPredictOrderPoint]);

  return {
    partnerOptions,
    appliedCouponSize,
    coupons,
    couponUnusable,
    handleCoupon,
    point,
    isUnusableOrderPoint,
    inputPoint,
    handleInputOrderPoint,
    handleConsumedOrderPoint,
    setMaxPoint,
    sumPrevPrice,
    sumStoreDiscountPrice,
    sumCouponDiscountPrice,
    consumedPoint,
    paidAmount,
    expectedOrderPoint,
    name,
    updateShippingInfo,
    phoneNumber,
    zipcode,
    zipcodeEl,
    setZipcodeAndMain,
    postcodeEl,
    main,
    mainEl,
    detail,
    detailEl,
    updateDetail,
    trimDetail,
    message,
    payMethods,
    handlePayMethod,
    payMethod,
    canPay,
    requestPay,
    isLoading,
    generateInvalidMessage,
    sumProductPrice
  };
};
