import React, { FC, useMemo } from "react";
import classNames from "classnames/bind";
import { EMPTY_ORDER_PRODUCT_TEXT } from "constant";

import { EmptyProductOrderIcon } from "./EmptyProductOrderIcon";
import styles from "./EmptyProductOrder.module.scss";

interface IProp {
  filterGroup: string;
}
const cx = classNames.bind(styles);

export const EmptyProductOrder: FC<IProp> = ({ filterGroup }) => {
  const message = useMemo(() => EMPTY_ORDER_PRODUCT_TEXT[filterGroup] || "주문 내역이 없습니다.", [filterGroup]);

  return (
    <div className={cx("wrapper")}>
      <EmptyProductOrderIcon filterGroup={filterGroup} />
      <div className={cx("text")}>{message}</div>
    </div>
  );
};
