import React, { Dispatch, SetStateAction, FC, useCallback } from "react";
import classNames from "classnames/bind";

import { IOptionValue, IProductDetailViewModel, ISelectedOption } from "typings";
import { IconMinus, IconPlus } from "assets/icon";
import { NOTICE } from "constant";
import { produce } from "utils";
import styles from "./SelectedOptions.module.scss";

const cx = classNames.bind(styles);

interface ISelectedOptions {
  product: IProductDetailViewModel;
  setSelectedOptions: Dispatch<SetStateAction<ISelectedOption[]>>;
  selectedOptions: ISelectedOption[];
  optionChecker: Map<string, IOptionValue>;
  isMobile?: boolean;
}

export const SelectedOptions: FC<ISelectedOptions> = ({
  product,
  selectedOptions,
  setSelectedOptions,
  optionChecker,
  isMobile
}) => {
  const minusSelectedCount = useCallback(
    event =>
      setSelectedOptions(
        produce(selectedOptions, draft => {
          const target = draft[event.target.dataset.index];
          if (target.selectedCount === 1) return draft;
          target.selectedCount -= 1;
        })
      ),
    [selectedOptions, setSelectedOptions]
  );
  const plusSelectedCount = useCallback(
    event =>
      setSelectedOptions(
        produce(selectedOptions, draft => {
          const target = draft[event.target.dataset.index];
          if (target.selectedCount === optionChecker.get(target.optionText)!.stockCount) {
            alert(NOTICE.MAXIMUM_STOCK_COUNT);
            return draft;
          }

          target.selectedCount += 1;
        })
      ),
    [selectedOptions, setSelectedOptions, optionChecker]
  );
  const deleteSelectedOptions = useCallback(
    event => {
      const { index } = event.target.dataset;

      setSelectedOptions(
        produce(selectedOptions, draft => {
          draft.splice(index, 1);
        })
      );
    },
    [selectedOptions, setSelectedOptions]
  );

  return (
    <>
      {selectedOptions.map((option, i) => (
        <div
          key={`${option.optionId}_${option.optionPrice}_${option.selectedCount}`}
          className={cx("count-with-option")}
        >
          {isMobile ? (
            <>
              <span className={cx("icon-cancel")}>
                <span
                  role="button"
                  tabIndex={0}
                  className={cx("cancel")}
                  data-index={i}
                  onClick={deleteSelectedOptions}
                  onKeyDown={deleteSelectedOptions}
                >
                  +
                </span>
              </span>

              <div className={cx("option-container")}>
                <div className={cx("selected")}>{option.optionText}</div>

                <div className={cx("counter")}>
                  <button type="button" className={cx("count-btn")} data-index={i} onClick={minusSelectedCount}>
                    <IconMinus />
                  </button>

                  <span className={cx("number")}>{option.selectedCount}</span>

                  <button type="button" className={cx("count-btn")} data-index={i} onClick={plusSelectedCount}>
                    <IconPlus />
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={cx("option-container")}>
                <div className={cx("selected")}>{option.optionText}</div>

                <span className={cx("icon-cancel")}>
                  <span
                    role="button"
                    tabIndex={0}
                    className={cx("cancel")}
                    data-index={i}
                    onClick={deleteSelectedOptions}
                    onKeyDown={deleteSelectedOptions}
                  >
                    +
                  </span>
                </span>
              </div>

              <hr className={cx("option-hr")} />

              <div className={cx("option-container")}>
                <span className={cx("item-price")}>
                  {((product.price + option.optionPrice) * option.selectedCount).toLocaleString()}원
                </span>

                <div className={cx("counter")}>
                  <button type="button" className={cx("count-btn")} data-index={i} onClick={minusSelectedCount}>
                    <IconMinus />
                  </button>

                  <span className={cx("number")}>{option.selectedCount}</span>

                  <button type="button" className={cx("count-btn")} data-index={i} onClick={plusSelectedCount}>
                    <IconPlus />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </>
  );
};
