import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./StoreAnswer.module.scss";
import { formatDateTime } from "utils";

interface IProp {
  answer: {
    id: number;
    contents: string;
    updatedAt: string;
  };
  store: {
    id: number;
    name: string;
  };
  visible?: boolean;
}

const cx = classNames.bind(styles);
export const StoreAnswer: FC<IProp> = ({ answer, store, visible }) => {
  return (
    <div className={cx("wrapper", { inVisible: !visible })}>
      <div className={cx("store")}>판매처: {store.name}</div>
      <pre className={cx("contents")}>{answer.contents}</pre>
      <div className={cx("updated")}>
        {formatDateTime(answer.updatedAt, "date")} {formatDateTime(answer.updatedAt, "timeLocalStr")}
      </div>
    </div>
  );
};
