import React, { FC, useMemo } from "react";
import classNames from "classnames/bind";
import styles from "./Term.module.scss";
import { LINK_TO_MARKETING, LINK_TO_PRIVACY, LINK_TO_TERMS, LINK_TO_USE_OF_PRIVACY } from "constant";
import { AgreeMentChangeKeys, SignUpAgreeMent } from "typings";

interface IProp {
  onChange: (payload: AgreeMentChangeKeys) => void;
  agreeMent: SignUpAgreeMent;
}
const cx = classNames.bind(styles);

export const Term: FC<IProp> = ({ onChange, agreeMent }) => {
  const isAllAgree = useMemo(() => {
    const agreeKeys = Object.keys(agreeMent);
    return agreeKeys.reduce((acc, agreeKey) => acc && agreeMent[agreeKey], true);
  }, [agreeMent]);
  return (
    <label className={cx("term-container")} htmlFor="term">
      <label className={cx("label", "first")}>
        <label className={cx("input-label", "all", { checked: isAllAgree })}>
          <input
            checked={isAllAgree}
            type="checkbox"
            className={cx("checkbox", "all")}
            onChange={() => (isAllAgree ? onChange("allDisAgree") : onChange("allAgree"))}
          />
        </label>
        <span className={cx("text", "all")}>이용약관에 모두 동의합니다.</span>
      </label>
      <label className={cx("label")}>
        <label className={cx("input-label", { checked: agreeMent.upperFourteen })}>
          <input
            className={cx("checkbox", "age")}
            checked={agreeMent.upperFourteen}
            type="checkbox"
            onChange={() => onChange("upperFourteen")}
          />
        </label>
        <span className={cx("text")}>[필수] 만 14세 이상</span>
      </label>
      <label className={cx("label")}>
        <label className={cx("input-label", { checked: agreeMent.serviceAgree })}>
          <input
            className={cx("checkbox", "service")}
            checked={agreeMent.serviceAgree}
            type="checkbox"
            onChange={() => onChange("serviceAgree")}
          />
        </label>
        <span className={cx("text")}>
          [필수]&nbsp;
          <a className={cx("link")} href={LINK_TO_TERMS} target="_blank" rel="noopener noreferrer">
            서비스 이용약관
          </a>
        </span>
      </label>
      <label className={cx("label")}>
        <label className={cx("input-label", { checked: agreeMent.privacyAgree })}>
          <input
            className={cx("checkbox", "privacy")}
            checked={agreeMent.privacyAgree}
            type="checkbox"
            onChange={() => onChange("privacyAgree")}
          />
        </label>
        <span className={cx("text")}>
          [필수]&nbsp;
          <a className={cx("link")} href={LINK_TO_PRIVACY} target="_blank" rel="noopener noreferrer">
            개인 정보 처리방침
          </a>
        </span>
      </label>
      <label className={cx("label")}>
        <label className={cx("input-label", { checked: agreeMent.usePrivacyAgree })}>
          <input
            className={cx("checkbox", "use-privacy")}
            checked={agreeMent.usePrivacyAgree}
            type="checkbox"
            onChange={() => onChange("usePrivacyAgree")}
          />
        </label>
        <span className={cx("text")}>
          [필수]&nbsp;
          <a className={cx("link")} href={LINK_TO_USE_OF_PRIVACY} target="_blank" rel="noopener noreferrer">
            개인정보 제공 내용
          </a>
        </span>
      </label>
      <label className={cx("label")}>
        <label className={cx("input-label", { checked: agreeMent.marketingAgree })}>
          <input
            className={cx("checkbox", "marketing")}
            checked={agreeMent.marketingAgree}
            type="checkbox"
            onChange={() => onChange("marketingAgree")}
          />
        </label>
        <span className={cx("text")}>
          [선택]&nbsp;
          <a className={cx("link")} href={LINK_TO_MARKETING} target="_blank" rel="noopener noreferrer">
            이벤트,프로모션 문자(SMS) 수신 및 활용
          </a>
        </span>
      </label>
    </label>
  );
};
