import React, { FC, useCallback, useEffect, useState } from "react";
import * as api from "api";
import { FAQForm, FAQCategories, NoticeCard } from "components";
import classNames from "classnames/bind";
import { INoticeCard } from "typings";
import { categoryKorToEng } from "constant";
import styles from "./Faq.module.scss";

const cx = classNames.bind(styles);
interface IProp {
  other?: boolean;
}

export const Faq: FC<IProp> = ({ other }) => {
  const faqCategories = ["전체", ...Object.keys(categoryKorToEng)];
  const [faqlList, setFaqList] = useState<INoticeCard[]>([]);
  const [search, setSearch] = useState("");
  const [selectCategory, setSelectCategory] = useState("전체");

  const getFAQList = useCallback(async (params = { page: { number: 1, size: 100 }, platform: "web" }) => {
    const response = await api.api__v1_get_faq_for_userables(params);
    const nextStatus = response.data.payload.map(({ title, html_url, category }) => ({
      title,
      htmlUrl: html_url,
      subTitle: category
    }));

    setFaqList(nextStatus);
  }, []);

  const setCategory = useCallback(
    category => {
      setSelectCategory(category);
      getFAQList({
        page: { number: 1, size: 100 },
        platform: "web",
        category: categoryKorToEng[category]
      });
    },
    [getFAQList]
  );

  useEffect(() => {
    getFAQList();
  }, [getFAQList]);

  const submitEventListner = useCallback(
    (searchString: string) => {
      getFAQList({
        page: { number: 1, size: 100 },
        platform: "web",
        title_query: searchString
      });
    },
    [getFAQList]
  );

  const onClear = useCallback(() => {
    setSearch("");
    getFAQList({ page: { number: 1, size: 100 }, platform: "web" });
  }, [getFAQList]);

  return (
    <main className={cx("wrapper", { other })}>
      {!other && <div className={cx("title")}>자주 묻는 질문</div>}
      <FAQForm search={search} setSearch={setSearch} onSubmit={submitEventListner} onClear={onClear} />
      {search === "" && (
        <FAQCategories categories={faqCategories} selectCategory={selectCategory} setCategory={setCategory} />
      )}
      {search !== "" && faqlList.length === 0 ? (
        <div className={cx("not-search-wrapper")}>
          <div className={cx("not-search-title")}>검색결과가 없습니다.</div>
          <div className={cx("not-search-description-wrapper")}>
            <div className={cx("not-search-description")}>다른 검색어를 입력하시거나,</div>
            <div className={cx("not-search-description")}>철자 및 띄어쓰기를 확인해주세요.</div>
          </div>
        </div>
      ) : (
        <div>
          {faqlList.map(({ title, subTitle, htmlUrl }) => (
            <NoticeCard key={`${title}-${htmlUrl}`} title={title} subTitle={subTitle} htmlUrl={htmlUrl} />
          ))}
        </div>
      )}
    </main>
  );
};
