import { fetcher } from "utils";

import { IJSendSuccessResponse, IJSendErrorResponse, DiscountCouponSerialized } from "typings";

type PostDiscountCouponsIdIssue = (
  data: { id: number | string } | { code: number | string }
) => Promise<
  | IJSendSuccessResponse<{
      id: number;
      old_discount_coupon: DiscountCouponSerialized;
      usable_at_limit_lte: string;
      created_at: string;
      is_used: boolean;
    }>
  | IJSendErrorResponse<string>
>;

export const post__discount_coupons__issue: PostDiscountCouponsIdIssue = data =>
  fetcher.post(`/discount_coupons/issue`, data);
