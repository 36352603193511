import { APPLY__COUPON_DATA_SET, UI__MODAL_OFF } from "constant";
import { produce } from "utils";
import { ICouponApplyData } from "typings";

const initialState: ICouponApplyData = {
  trigger: 0,
  target: null,
  options: null,
  coupon: null,
  findAppliedOptions: null,
  findCoupon: null,
  findOption: null
};

export default function applyCouponData(state: ICouponApplyData = initialState, { type, payload }) {
  return produce(state, _ => {
    switch (type) {
      case APPLY__COUPON_DATA_SET:
        return payload;
      case UI__MODAL_OFF:
        return initialState;
      default:
        break;
    }
  });
}
