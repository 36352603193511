import { ICoordiViewModel, ApiV1CoordiSearchSerialized } from "typings";

export const coordiViewModelCreator = (coordi: ApiV1CoordiSearchSerialized[]): ICoordiViewModel[] =>
  coordi.map(({ id, title, description, photo_url, like_counts, tags }) => ({
    id,
    title,
    description: description || "",
    photoUrl: photo_url,
    likeCounts: like_counts,
    tags: tags.map(({ title }) => title.replace("#", ""))
  }));

export const coordiMetaViewModelCreator = ({
  total_count
}: {
  current_page?: number;
  next_page?: number | null;
  prev_page?: number | null;
  total_pages?: number;
  total_count?: number;
}) => ({
  totalCount: total_count ?? 0
});
