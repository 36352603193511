import React, { useState, useEffect, Fragment } from "react";
import { useSelector, shallowEqual } from "react-redux";
import * as api from "api";
import { membersOrderPointsViewModelCreator, IMembersOrderPointsViewModel } from "view-model-creator";
import { IStore } from "typings";
import classNames from "classnames/bind";
import styles from "./Points.module.scss";

const cx = classNames.bind(styles);

export function Points() {
  const { color, point, pointsToWillExpire } = useSelector<IStore, IStore["orderPoints"]>(
    ({ orderPoints }) => orderPoints,
    shallowEqual
  );
  const [points, setPoints] = useState<IMembersOrderPointsViewModel[]>([]);

  useEffect(() => {
    api.get__members__order_points().then(data => setPoints(membersOrderPointsViewModelCreator(data)));
  }, []);

  return (
    <>
      <main className={cx("points-tab")}>
        <header className={cx(["bold"], "title")}>적립금</header>

        <section className={cx("my-points")}>
          <div className={cx("title-box")}>
            <span className={cx(["middle"], "head")}>보유&nbsp;적립금</span>
            <span className={cx("gray07")}>&nbsp;(1주일&nbsp;내&nbsp;소멸&nbsp;예정&nbsp;적립금&nbsp;</span>
            <span className={cx("blue")}>{pointsToWillExpire.toLocaleString()}원</span>
            <span className={cx("gray07")}>)</span>
          </div>
          <div className={cx(["bold"], "own")} style={{ color }}>
            {point.toLocaleString()}원
          </div>
        </section>

        <div className={cx(["middle"], "points-title")}>적립내역</div>
        <section className={cx("table")}>
          <div className={cx("row", "header")}>
            <div className={cx("cell")}>적립일</div>
            <div className={cx("cell")}>적립명</div>
            <div className={cx("cell")}>구매금액</div>
            <div className={cx("cell")}>적립금</div>
          </div>

          {points.length > 0 ? (
            points.map(({ pointsId, createdAtDesc, message, productPrice, pointValueDesc }) => (
              <Fragment key={pointsId}>
                <div className={cx("row", "list")}>
                  <div className={cx("cell")}>{createdAtDesc}</div>
                  <div className={cx("cell", "left")}>{message}</div>
                  <div className={cx(["middle"], "cell", "right")}>{productPrice}</div>
                  <div className={cx(["middle"], "cell", "right", !pointValueDesc.includes("+") ? "minus" : undefined)}>
                    {pointValueDesc}
                  </div>
                </div>
              </Fragment>
            ))
          ) : (
            <div className={cx("row", "no-list")}>적립내역이&nbsp;없습니다.</div>
          )}
        </section>
      </main>
    </>
  );
}
