import { fetcher } from "utils";

import { IJSendSuccessResponse, MemberSerialized } from "typings";

interface IPassword {
  password: string;
  password_confirmation: string;
  current_password?: string;
}

export function patch__members__change_password(
  params: IPassword
): Promise<
  IJSendSuccessResponse<{
    member: MemberSerialized;
  }>
> {
  return fetcher.patch("/members/change_password", params);
}
