import { useCallback } from "react";
import { initializeSet, setsItemChangeAction } from "actions/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { ColorKey, IInitializeSetCase, IStore, ShippingKey, SizeKey } from "typings";
import { ReviewColorMap, ReviewShiipingMap, ReviewSizeMap } from "constant";

export const useSets = () => {
  const { myReviewWrite, bookmarks, pins, coordi, reviewLikes } = useSelector<IStore, IStore["sets"]>(
    ({ sets }) => sets
  );
  const dispatch = useDispatch();

  const initSets = useCallback(
    (params: IInitializeSetCase) => {
      dispatch(initializeSet(params));
    },
    [dispatch]
  );

  const setsItemChange = useCallback(
    (payload: Parameters<typeof setsItemChangeAction>[0]) => {
      dispatch(setsItemChangeAction(payload));
    },
    [dispatch]
  );

  const getTargetMyWriteReview = useCallback(
    (reviewId: number) => {
      const review = myReviewWrite.get(reviewId);

      if (review) {
        return {
          id: review.id,
          satisfaction: review.satisfaction,
          size: ReviewSizeMap[review.size] as SizeKey,
          color: ReviewColorMap[review.color] as ColorKey,
          shipping: ReviewShiipingMap[review.shipping] as ShippingKey,
          content: review.content,
          photoURLs: review.photoUrls,
          userableInfo: {
            height: review.userableInfo.height,
            weight: review.userableInfo.weight
          }
        };
      }
    },
    [myReviewWrite]
  );

  return { myReviewWrite, bookmarks, pins, coordi, reviewLikes, initSets, setsItemChange, getTargetMyWriteReview };
};
