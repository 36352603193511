import { combineReducers } from "redux";

import authentication from "modules/authentication";
import cart from "modules/cart";
import coupons from "modules/coupons";
import recents from "modules/recents";
import member from "modules/member";
import orderPoints from "modules/orderPoints";
import pickUpAddress from "modules/pickUpAddress";
import sets from "modules/sets";
import ui from "modules/ui";
import photoReview from "modules/photoReview";
import applyCouponData from "modules/applyCouponData";
import reviewWrite from "modules/reviewWrite";

export default combineReducers({
  authentication,
  cart,
  coupons,
  ui,
  member,
  orderPoints,
  pickUpAddress,
  recents,
  sets,
  photoReview,
  applyCouponData,
  reviewWrite
});
