import React, { FC, useCallback } from "react";
import classNames from "classnames/bind";
import styles from "./SortSelectButton.module.scss";
import { ArrowUpDownIcon } from "assets/icon";
import { OrderDateAtType } from "typings";

interface IProp {
  onClick?: (value: string) => void;
  value: OrderDateAtType;
  title: string;
}

const cx = classNames.bind(styles);
export const SortSelectButton: FC<IProp> = ({ onClick, value, title }) => {
  const clickEvent = useCallback(() => {
    onClick && onClick(value === "updated_at" ? "created_at" : "updated_at");
  }, [onClick, value]);
  return (
    <div className={cx("wrapper")} onClick={clickEvent}>
      <ArrowUpDownIcon /> <span className={cx("text")}>{title}</span>
    </div>
  );
};
