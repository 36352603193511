import { historyContext } from "contexts";

import { KAKAO_CLIENT_ID } from "constant";

/* global Kakao */
Kakao.init(KAKAO_CLIENT_ID);

export const init = () => {
  Kakao.Auth.createLoginButton({
    container: "#kakaoIdLogin",

    success({ access_token: accessToken }) {
      historyContext.replace("/kakao", { accessToken, redirectPath: window.location.pathname });
    },

    fail(err) {
      alert(JSON.stringify(err));
    }
  });
};
