import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./DescriptionRow.module.scss";
interface IProp {
  keyData: string;
  value?: string | null;
  bold?: boolean;
  keyBold?: boolean;
  spaceBetween?: boolean;
}
const cx = classNames.bind(styles);

export const DescriptionRow: FC<IProp> = ({ keyData, value, bold, spaceBetween, keyBold }) => {
  return (
    <div className={cx("row", { spaceBetween })}>
      <span className={cx("row-key", { bold }, { keyBold })}>{keyData}</span>
      <span className={cx("row-value", { bold }, { spaceBetween })}>{value}</span>
    </div>
  );
};
