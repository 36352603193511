import { fetcher } from "utils";
import { IJSendSuccessResponse, IReviewsSummariesV3 } from "typings";

type GetProductsIdReviewSummariesResponseV3 = (
  productIds: number[]
) => Promise<IJSendSuccessResponse<{ payload: IReviewsSummariesV3[] }>>;

export const get__products__id__reviews_summaries_v3: GetProductsIdReviewSummariesResponseV3 = productIds => {
  return fetcher.post(`api/v3/products/review-summaries`, { product_id_list: productIds });
};
