import React, { useState, useEffect, useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { get__products_main_categories } from "api";
import { path, pipeWith, then, map, prop, times, take } from "ramda";
import { useProductSearch } from "hooks";
import {
  SelectCategory,
  Pagination,
  RangePrice,
  SearchKeyword,
  DisplayCondition,
  ProductCard,
  SelectGroup,
  ProductsNotExist,
  SelectGroupWithBookmark,
  Loading
} from "components";
import { TRENDING } from "constant";
import { IStore } from "typings";
import classnames from "classnames/bind";
import styles from "components/pages/search/SearchShare.module.scss";
import { AirbridgeCustomEvent, criteoEventPush } from "utils";

const cx = classnames.bind(styles);

export function RankingSearch() {
  const token = useSelector<IStore, IStore["member"]["token"]>(({ member }) => member.token);
  const { isMobile, genderId } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui, shallowEqual);
  const { email } = useSelector<IStore, IStore["member"]>(({ member }) => member);

  const base = useMemo(
    () => ({
      params: {
        order: TRENDING,
        page: { number: 1, size: isMobile ? 33 : 50 },
        genderId
      }
    }),
    [genderId, isMobile]
  );

  const {
    category,
    handleCategory,
    handlePrice,
    handlePriceReset,
    gte,
    lte,
    keyword,
    handleKeyword,
    changeKeyword,
    conditions,
    resetCondition,
    isLoading,
    products,
    reviews,
    totalCount,
    handlePage,
    size,
    number,
    maxPrice,
    group,
    handleGroup,
    mainRef
  } = useProductSearch(base);

  const [categories, setCategories] = useState([]);

  const COLUMN = useMemo(() => (isMobile ? 3 : 5), [isMobile]);

  const hiddenFlexBoxes = useMemo(
    () => times(v => v + 1, products.length % COLUMN ? COLUMN - (products.length % COLUMN) : 0),
    [COLUMN, products.length]
  );

  useEffect(() => {
    pipeWith(then)([
      get__products_main_categories,
      path(["data", "product_main_categories"]),
      map(prop("name")),
      setCategories
    ])(genderId);
  }, [genderId]);

  useEffect(() => {
    if (products.length > 0) {
      const productsItems = take(3, products).map(product => product.id);
      criteoEventPush({ email, event: "viewList", item: productsItems });
    }
  }, [products, email]);

  useEffect(() => {
    AirbridgeCustomEvent.mainScreen("home_ranking");
  }, []);

  return (
    <main className={cx("main")} ref={mainRef}>
      <h2 className={cx("title")}>상품랭킹</h2>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={cx("filter-container")}>
            <ul className={cx("filter-list")}>
              <li className={cx("filter-item")}>
                <SelectCategory categories={categories} category={category} handleCategory={handleCategory} />
              </li>

              <li className={cx("filter-item")}>
                {token ? (
                  <SelectGroupWithBookmark handleGroup={handleGroup} group={group} />
                ) : (
                  <SelectGroup handleGroup={handleGroup} group={group} />
                )}
              </li>

              <li className={cx("filter-item")}>
                <RangePrice
                  handlePrice={handlePrice}
                  handlePriceReset={handlePriceReset}
                  gte={gte}
                  lte={lte}
                  maxPrice={maxPrice}
                />
              </li>
            </ul>

            <SearchKeyword keyword={keyword} handleKeyword={handleKeyword} changeKeyword={changeKeyword} />
          </div>

          <DisplayCondition conditions={conditions} resetCondition={resetCondition} />

          <section className={cx("products")}>
            {products.length ? (
              <>
                {products.map((product, index) => (
                  <ProductCard
                    product={product}
                    review={reviews[index]}
                    rank={products.length <= size ? size * (number - 1) + index + 1 : index + 1}
                    key={product.id}
                  />
                ))}

                {map(
                  v => (
                    <div key={totalCount + v} className={cx("hidden-card")} />
                  ),
                  hiddenFlexBoxes
                )}
              </>
            ) : (
              <ProductsNotExist />
            )}
          </section>
        </>
      )}

      <Pagination totalCount={totalCount} handler={handlePage} number={number} size={size} />
    </main>
  );
}
