import * as CryptoJS from "crypto-js";
import { pickBy } from "ramda";

export const criteoEventPush = ({
  email,
  event,
  item,
  id
}: {
  email?: string;
  event: string;
  item: any;
  id?: string;
}) => {
  if (process.env.REACT_APP_ENV !== "production") return;
  window.criteo_q.push(
    { event: "setAccount", account: 65921 },
    { event: "setEmail", email: email ? CryptoJS.MD5(email).toString() : "" }, // Can be an empty string
    { event: "setSiteType", type: window.deviceType },
    pickBy(val => val !== undefined, { event, item, id })
  );
};
