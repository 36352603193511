import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { camelizeKeys } from "humps";

import {
  newCoordiDetailViewModelCreator,
  coordiViewModelCreator,
  reviewSummariesViewModelCreatorV3
} from "view-model-creator";

import { pipeWith, then, path } from "ramda";
import * as api from "api";

import { COORDI_VIEW_SIZE } from "constant";

import {
  INewCoordiDetailViewModel,
  StoreDetailSerializedCamel,
  ICoordiViewModel,
  IStore,
  IReviewsSummariesViewModelV3
} from "typings";

export const useCoordiDetail = (coordiId: number) => {
  const [coordi, setCoordi] = useState<INewCoordiDetailViewModel>();
  const [store, setStore] = useState<StoreDetailSerializedCamel>();
  const [trending, setTrending] = useState<ICoordiViewModel[]>([]);
  const [reviews, setReviews] = useState<IReviewsSummariesViewModelV3[]>([]);

  const genderId = useSelector<IStore, IStore["ui"]["genderId"]>(({ ui: { genderId } }) => genderId);

  useEffect(() => {
    (async () => {
      if (!coordiId) return;
      const newCoordi = await pipeWith(then)([api.get__v1__coordi__id, path(["data", "payload"])])(coordiId);
      const newStore = await api.get__stores__id(newCoordi.store_id);
      const productsIds = newCoordi.products.map(product => product.id);

      if (productsIds.length > 0) {
        await pipeWith(then)([
          api.get__products__id__reviews_summaries_v3,
          path(["data", "payload"]),
          reviewSummariesViewModelCreatorV3,
          setReviews
        ])(productsIds);
      }

      setCoordi(newCoordiDetailViewModelCreator(newCoordi));
      setStore(camelizeKeys(newStore) as never);
    })();
  }, [coordiId]);

  useEffect(() => {
    (async () => {
      const coordi = await api.get__coordi({ order: "trending", page: 1, per: COORDI_VIEW_SIZE, genderId });
      setTrending(coordiViewModelCreator(coordi));
    })();
  }, [genderId]);

  return { coordi, store, trending, reviews };
};
