import React from "react";
import { useParams, Link } from "react-router-dom";
import classnames from "classnames/bind";
import qs from "qs";
import { useCoordi } from "hooks";
import copyToClipboard from "utils/copyToClipboard";
import { AirbridgeCustomEvent, imageResizeHelper } from "utils";
import {
  Loading,
  PageTitle,
  ProductCard,
  CoordiCard,
  StoreRowWithBookmark,
  CoordiLikeButton,
  DynamicMeta
} from "components";
import share from "components/presentation/card/product/ProductCardShare.module.scss";
import { useCoordiDetail } from "./useCoordiDetail";
import styles from "./CoordiDetail.module.scss";

const cx = classnames.bind(styles);
const ss = classnames.bind(share);

export function CoordiDetail() {
  const { coordiId: cId } = useParams<{ coordiId: string }>();
  const coordiId = Number(cId);

  const { getIsCoordiLike, toggleLike } = useCoordi();

  const { coordi, store, trending, reviews } = useCoordiDetail(coordiId);

  if (!coordi || !store || !trending) return <Loading />;

  const hiddenFlexBoxes = new Array(coordi.products.length % 5 ? 5 - (coordi.products.length % 5) : 0).fill(0);

  const handleCoordiCopyToClipboard = () => {
    copyToClipboard("코디");
    AirbridgeCustomEvent.share("product", {
      channel: "링크"
    });
  };

  return (
    <main className={cx("main")}>
      <DynamicMeta
        url={window.location.href}
        description={coordi.tags.map(tag => `#${tag}`).join(", ")}
        title={coordi.title}
        image={coordi.photoUrl ? imageResizeHelper(coordi.photoUrl, 300) : undefined}
      />
      <section className={cx("top")}>
        <picture className={cx("picture")}>
          <img src={imageResizeHelper(coordi.photoUrl, 440)} className={cx("img")} alt="hero" />
        </picture>
        <section className={cx("actions")}>
          <div>
            <div className={cx("title")}>
              {coordi.title}
              <button className={cx("url-copy")} onClick={handleCoordiCopyToClipboard} type="button">
                URL복사
              </button>
            </div>
            <pre className={cx("description")}>{coordi.description}</pre>
            <div className={cx("like")}>
              <CoordiLikeButton id={coordiId} title={coordi.title} likeCounts={coordi.likeCounts} />

              <button className={cx("url-copy")} onClick={handleCoordiCopyToClipboard} type="button">
                URL복사
              </button>
            </div>
          </div>
          <div>
            <div className={cx("tags")}>
              {coordi.tags.map(tag => (
                <Link
                  to={`/coordi${qs.stringify({ searchKeywords: [tag] }, { addQueryPrefix: true })}`}
                  className={cx("tag")}
                  key={tag}
                >
                  {tag}
                </Link>
              ))}
            </div>
            <hr className={cx("hr")} />

            <StoreRowWithBookmark
              id={store.id}
              name={store.name}
              group={store.group}
              photoUrl={store.photoUrl}
              serializedTags={store.serializedTags}
              genderId={store.genderId}
            />

            <hr className={cx("hr", "only-mobile")} />
          </div>
        </section>
      </section>

      <PageTitle title="코디 아이템" />

      <section className={cx("products")}>
        {coordi.products.map((product, i) => (
          <ProductCard product={product} review={reviews[i]} key={product.id} />
        ))}

        {hiddenFlexBoxes.map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`hidden-flex-box-${i}`} className={[ss("card"), cx("hide-border")].join(" ")} />
        ))}
      </section>

      <hr className={cx("hr")} />
      <div className={cx("title-wrapper")}>
        <PageTitle title="인기 코디" />
      </div>
      <section className={cx("trending")}>
        {trending.map(coordi => (
          <CoordiCard
            id={coordi.id}
            photoUrl={coordi.photoUrl}
            title={coordi.title}
            tags={coordi.tags}
            likeCounts={coordi.likeCounts}
            isLiked={getIsCoordiLike(coordi.id)}
            toggleLike={toggleLike}
            key={coordi.id}
          />
        ))}
      </section>
    </main>
  );
}
