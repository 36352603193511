import { ap, pipe, map, join, identity } from "ramda";
import { subMonths, startOfMonth, format } from "date-fns/fp";

export const getDefaultPeriod = pipe(
  () => [new Date()],
  ap([
    pipe(
      subMonths(2),
      startOfMonth
    ),
    identity
  ]),
  map(format("yyyy-MM-dd'T'HH:mm:ss'+09:00'")),
  join("/")
);
