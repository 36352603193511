import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";

import { imageResizeHelper } from "utils";

import { SALE } from "constant";

import { INewPinViewModel, IReviewsSummariesViewModelV3 } from "typings";
import { BrandIcon } from "components";

import share from "./ProductCardShare.module.scss";
import styles from "./ProductCardWithoutPin.module.scss";

const ss = classNames.bind(share);
const cx = classNames.bind(styles);

interface IProps {
  product: INewPinViewModel;
  review: IReviewsSummariesViewModelV3;
  isSelect?: boolean;
}

export const ProductCardWithoutPin: FC<IProps> = ({
  product: { id, name, priceDesc, info, store, status, shippingSpeed },
  review,
  isSelect
}) => {
  const soldOut = status !== SALE;
  const [productImage, setProductIamge] = useState(info?.images.main[0] || "");

  if (soldOut) {
    return (
      <section className={cx("card", "sold-out")} data-msg="품절상품">
        {isSelect && <div className={cx("isSelect")} />}
        <div className={ss("thumbnail")}>
          <picture className={ss("thumbnail-picture")}>
            {info && info.images.main.length > 1 ? (
              <img
                src={imageResizeHelper(productImage, 180)}
                onMouseOver={() => setProductIamge(info.images.main[1])}
                onFocus={() => {}}
                onBlur={() => {}}
                onMouseOut={() => setProductIamge(info.images.main[0])}
                className={ss("thumbnail-picture-img")}
                alt={name}
              />
            ) : (
              <img src={imageResizeHelper(productImage, 180)} className={ss("thumbnail-picture-img")} alt={name} />
            )}
          </picture>
        </div>

        <ul className={ss("desc")}>
          <li className={ss("store-name")}>{store.name}</li>
          <li className={ss("product-name")}>{name}</li>
          <li className={ss(["bold"], "price")}>{priceDesc}</li>
        </ul>
      </section>
    );
  }

  return (
    <section className={cx("card")}>
      {isSelect && <div className={cx("isSelect")} />}

      <div className={ss("thumbnail")}>
        <Link className={ss(["link"])} to={`/products/${id}`}>
          <picture className={ss("thumbnail-picture")}>
            {info && info.images.main.length > 1 ? (
              <img
                src={imageResizeHelper(productImage, 180)}
                onFocus={() => {}}
                onBlur={() => {}}
                onMouseOver={() => setProductIamge(info.images.main[1])}
                onMouseOut={() => setProductIamge(info.images.main[0])}
                className={cx("thumbnail-picture-img")}
                alt={name}
              />
            ) : (
              <img src={imageResizeHelper(productImage, 180)} className={cx("thumbnail-picture-img")} alt={name} />
            )}
          </picture>
        </Link>
        {review && review.totalCount > 0 && (
          <div className={ss("review-m")}>
            <div className={ss("star-icon")}>&#9733;</div>
            <div className={ss("satisfaction")}>{review.averageSatisfaction}</div>
            <div className={ss("total-count")}>
              {review.totalCount > 999 ? "(999+명 평가)" : `(${review.totalCount}명 평가)`}
            </div>
          </div>
        )}
      </div>

      <ul className={ss("desc")}>
        <Link className={ss(["link"], "flex-box")} to={{ pathname: `/stores/${store.id}` }}>
          <li className={ss("store-name")}>{store.name}</li>
          {store.group === "brand" && <BrandIcon />}
        </Link>

        <Link className={ss(["link"])} to={`/products/${id}`}>
          <li className={ss("product-name")}>{name}</li>
          <li className={ss("price-shipping-speed-wrapper")}>
            <div className={ss(["bold"], "price")}>{priceDesc}</div>
            {shippingSpeed && (
              <div className={ss(["bold"], "shipping-speed")}>
                {shippingSpeed === "fast" ? (
                  <div className={ss("shipping-fast")}>배송우수</div>
                ) : (
                  <div className={ss("shipping-slow")}>배송지연</div>
                )}
              </div>
            )}
          </li>
          {review && review.totalCount > 0 && (
            <li className={ss("review")}>
              <div className={ss("star-icon")}>&#9733;</div>
              <div className={ss("satisfaction")}>{review.averageSatisfaction}</div>
              <div className={ss("total-count")}>
                {review.totalCount > 999 ? "(999+명 평가)" : `(${review.totalCount}명 평가)`}
              </div>
            </li>
          )}
        </Link>
      </ul>
    </section>
  );
};
