import React, { FC } from "react";
import { RateStar } from "assets/icon";
import classNames from "classnames/bind";
import styles from "./SatisfactionInput.module.scss";

interface IProp {
  satisfaction: number;
  setSatisfaction: (num: number) => void;
}

const cx = classNames.bind(styles);

export const SatisfactionInput: FC<IProp> = ({ satisfaction, setSatisfaction }) => {
  return (
    <div className={cx("wrapper")}>
      {[1, 2, 3, 4, 5].map(val => (
        <button className={cx("button")} type="button" key={`start-${val}`} onClick={() => setSatisfaction(val)}>
          <RateStar isSelect={val <= satisfaction} />
        </button>
      ))}
    </div>
  );
};
