import React, { FC, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import classNames from "classnames/bind";
import { IStore } from "typings";

import { IconQuestionMark } from "assets/icon";

import { PointModal } from "components";
import styles from "./ExpectedOrderPoint.module.scss";

const cx = classNames.bind(styles);

interface IExpectedOrderPoint {
  price: number;
  point: {
    point: number;
    minPoint: number;
    maxPoint: number;
  };
}

export const ExpectedOrderPoint: FC<IExpectedOrderPoint> = ({ price, point }) => {
  const [hover, setHover] = useState(false);
  const token = useSelector<IStore, IStore["member"]["token"]>(({ member: { token } }) => token, shallowEqual);

  // TODO: 로그인했어도 적립금이 0원으로 보이는 시점에 대한 디버깅

  return (
    <div className={cx("expected-point-container")}>
      <span className={cx("method-txt")}>적립금</span>
      <span>
        {token
          ? `${point.point ? point.point.toLocaleString() : 0}`
          : `최소 ${point.minPoint}원 ~ 최대 ${point.maxPoint}`}
        원
        <button
          type="button"
          className={cx("question")}
          onClick={() => setHover(!hover)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <IconQuestionMark />
        </button>
        {hover && <PointModal price={price} />}
      </span>
    </div>
  );
};
