import * as api from "api";
import {
  IJSendSuccessResponse,
  IPage,
  IPageMeta,
  IProductQuestions,
  IQnAModel,
  IWithMeta,
  MetaViewModel
} from "typings";
import { useCallback, useState } from "react";
import { pipeWith, then } from "ramda";
import { productQnaViewModelCreator, myProductQnaViewModelCreator, qnaMetaViewModelCreator } from "view-model-creator";

export const useQnARead = () => {
  const [qnaList, setQnaList] = useState<IQnAModel[]>([]);
  const [qnaHiddenVisible, setQnaHiddenVisible] = useState<boolean>(false);
  const [qnaMeta, setQnaMeta] = useState<MetaViewModel>();
  const [qnaPage, setQnaPage] = useState<IPage>({
    number: 1,
    size: 6
  });

  const metaSetPiPe = useCallback((payload: IJSendSuccessResponse<IWithMeta<IProductQuestions[], IPageMeta>>) => {
    setQnaMeta(qnaMetaViewModelCreator(payload));

    return payload;
  }, []);

  const getProductQnaList = useCallback(
    async ({ page, productId, visible }: { page: IPage; productId: number; visible: boolean }) => {
      pipeWith(then)([api.api__v1_product_questions_get, metaSetPiPe, productQnaViewModelCreator, setQnaList])({
        page,
        productId,
        visible
      });
    },
    [metaSetPiPe]
  );

  const getMyProductQnaList = useCallback(
    ({ page }: { page: IPage }) => {
      pipeWith(then)([
        api.api__v1_members_product_questions_get,
        metaSetPiPe,
        myProductQnaViewModelCreator,
        setQnaList
      ])({
        page
      });
    },
    [metaSetPiPe]
  );

  const setQnaPageAndScrollPage = useCallback((page: number) => {
    const wrapper = document.querySelector(`#qna-wrapper`) as HTMLDivElement;
    const offset = wrapper.offsetTop;

    window.scroll(0, offset - 400);
    setQnaPage(qnaPage => {
      return {
        ...qnaPage,
        number: page
      };
    });
  }, []);

  return {
    qnaList,
    getProductQnaList,
    getMyProductQnaList,
    qnaPage,
    setQnaPage,
    setQnaHiddenVisible,
    qnaHiddenVisible,
    qnaMeta,
    setQnaPageAndScrollPage
  };
};
