import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./ShippingSpeedOptions.module.scss";

const cx = classNames.bind(styles);

interface IShippingSpeedOptions {
  shippingSpeed: "fast" | "slow";
}

export const ShippingSpeedOptions: FC<IShippingSpeedOptions> = ({ shippingSpeed }) => {
  return (
    <div className={cx("shipping-container")}>
      <span className={cx("method-txt")}>배송</span>
      <span>
        {shippingSpeed === "fast" ? (
          <>
            <span className={cx("shipping-fast")}>배송우수</span>
            <span className={cx("shipping-option-txt")}>결제 후 평균 1일 이내 발송되는 상품</span>
          </>
        ) : (
          <>
            <span className={cx("shipping-slow")}>배송지연</span>
            <span className={cx("shipping-option-txt")}>결제 후 평균 5일 이후 발송되는 상품</span>
          </>
        )}
      </span>
    </div>
  );
};
