import { INITIALIZE_SETS, LOGOUT, ADD_SETS_ITEM, REMOVE_SETS_ITEM, CHANGE_SETS_ITEM } from "constant";

import { ISetsState } from "typings";

const initialState: ISetsState = {
  bookmarks: new Set(),
  pins: new Set(),
  coordi: new Set(),
  reviewLikes: new Set(),
  myReviewWrite: new Map()
};

const keys = Object.keys(initialState);

export default (state = initialState, { type, payload }) => {
  const draft = Object.assign(
    {},
    {
      bookmarks: new Set([...state.bookmarks.values()]),
      pins: new Set([...state.pins.values()]),
      coordi: new Set([...state.coordi.values()]),
      reviewLikes: new Set([...state.reviewLikes.values()]),
      myReviewWrite: new Map([...state.myReviewWrite.entries()])
    }
  );

  switch (type) {
    case INITIALIZE_SETS: {
      return keys.reduce((acc, key) => {
        if (key === "myReviewWrite") {
          const map = new Map();
          payload.myReviewWrite.map(review => {
            map.set(review.id, review);
            return undefined;
          });
          acc[key] = map;
        } else if (payload[key]) {
          acc[key] = new Set(payload[key]);
        }
        return acc;
      }, {});
    }
    case ADD_SETS_ITEM:
      draft[payload.key].add(payload.value);
      return draft;
    case REMOVE_SETS_ITEM:
      draft[payload.key].delete(payload.value);
      return draft;
    case CHANGE_SETS_ITEM:
      if (payload.key === "myReviewWrite") {
        const map = new Map();
        payload.value.map(review => {
          map.set(review.id, review);
          return undefined;
        });
        draft[payload.key] = map;
      } else {
        draft[payload.key] = payload.value;
      }
      return draft;
    case LOGOUT:
      return { ...initialState };
    default:
      return draft;
  }
};
// produce(state, draft => {
//   switch (type) {
//     case INITIALIZE_SETS:
//       return reduce(
//         (acc, key) => {
//           if (key === "myReviewWrite") {
//             const map = new Map();
//             payload.myReviewWrite.map(review => {
//               map.set(review.id, review);
//               return undefined;
//             });
//             acc[key] = map;
//           } else if (payload[key]) {
//             acc[key] = new Set(payload[key]);
//           }
//           return acc;
//         },
//         draft,
//         keys
//       );
//     case ADD_SETS_ITEM:
//       draft[payload.key].add(payload.value);
//       break;
//     case REMOVE_SETS_ITEM:
//       draft[payload.key].delete(payload.value);
//       break;
//     case CHANGE_SETS_ITEM:
//       if (payload.key === "myReviewWrite") {
//         const map = new Map();
//         payload.value.map(review => {
//           map.set(review.id, review);
//           return undefined;
//         });
//         draft[payload.key] = map;
//       } else {
//         draft[payload.key] = payload.value;
//       }
//       break;
//     case LOGOUT:
//       return { ...initialState };
//   }
// });
