import { fetcher } from "utils";
import { IJSendSuccessResponse, IJSendFailResponse } from "typings";

type DeleteUserableLikeOrderProuctReviews = (param: {
  id: number;
}) => Promise<
  | IJSendSuccessResponse<null>
  | IJSendFailResponse<{
      userable_like_order_product_reviews: {};
    }>
>;

export const delete__userable_like__order_product_rivews: DeleteUserableLikeOrderProuctReviews = param =>
  fetcher.delete(`/userable_like/order_product_reviews/${param.id}`);
