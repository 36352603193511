import { camelizeKeys } from "humps";
import { omit } from "ramda";

import { IOptionValue, IPartnerOptionViewModel, ApiV1ProductSerialized, IProductDetailViewModel } from "typings";

import { SALE } from "constant";

type ProductDetailViewModelCreator = (product: ApiV1ProductSerialized) => IProductDetailViewModel;
type FlattenProductOptions = (options: IProductDetailViewModel["info"]["options"]) => Map<string, IOptionValue>;

export const flattenProductOptions: FlattenProductOptions = ({ data }) =>
  new Map(
    Object.entries(
      data.reduce<{ [key: string]: IOptionValue }>(
        (acc, { id: optionId, positions, data: { stockCount, optionPrice } }) =>
          positions.reduce((acc, v: string, i: number, o: string[]) => {
            const key = o.slice(0, i + 1).join(", ");

            if (acc[key]) acc[key].stockCount += stockCount;
            else acc[key] = { stockCount };

            if (i === o.length - 1) Object.assign(acc[key], { optionPrice, optionId });

            return acc;
          }, acc),
        {}
      )
    )
  );

export const productDetailViewModelCreator: ProductDetailViewModelCreator = product =>
  camelizeKeys(omit(["created_at", "updated_at", "status_text", "ship_ing_speed"], product)) as any;

type CreatePartnerOption = (params: {
  productId: number;
  productName: string;
  photoUrl: string;
  price: number;
  prevPrice: number;
  storeName: string;
}) => (params: {
  optionId: number;
  selectedCount: number;
  optionText?: string;
  optionPrice?: number;
}) => IPartnerOptionViewModel;

export const createPartnerOption: CreatePartnerOption = ({
  productId,
  productName,
  photoUrl,
  price,
  prevPrice,
  storeName
}) => ({ optionId, selectedCount, optionText, optionPrice }) => ({
  status: SALE,
  productId,
  productName,
  photoUrl,
  price,
  prevPrice: (prevPrice + (optionPrice || 0)) * selectedCount,
  storeName,
  checked: false,

  optionId,
  selectedCount,
  priceToBuy: (price + (optionPrice || 0)) * selectedCount,

  optionText,
  optionDesc: `${optionText ? `${optionText} / ` : ``}${selectedCount}개`,
  priceToBuyDesc: `${((price + (optionPrice || 0)) * selectedCount).toLocaleString()}원`
});
