import React, { FC } from "react";
import { ReviewShipping, ReviewColor, ReviewSize } from "typings";
import { PercentageRodGraph } from "components";
import classNames from "classnames/bind";
import styles from "./PercentSummary.module.scss";

interface IProp<T> {
  data: [T, number][] | [T, number][] | [T, number][];
  title: string;
}
const cx = classNames.bind(styles);

export const PercentSummary: FC<IProp<ReviewShipping | ReviewColor | ReviewSize>> = ({ data, title }) => {
  const largeText = data
    .map<[ReviewShipping | ReviewColor | ReviewSize, number]>(([key, value]) => [key, value * 100])
    .reduce(([accKey, accValue], [key, value]) => (accValue < value ? [key, value] : [accKey, accValue]))[0];
  return (
    <div className={cx("graph-data-wrapper")}>
      <header className={cx("graph-data-title")}>{title}</header>
      <section className={cx("rod-wrapper")}>
        {data.map(([text, value], index) => {
          return (
            <div className={cx("rod-contents")} key={text + value + index}>
              <PercentageRodGraph text={text} percentage={Math.floor(value * 100)} isBig={largeText === text} />
            </div>
          );
        })}
      </section>
    </div>
  );
};
