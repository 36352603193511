import { fetcher } from "utils";

interface IPostMembersOrderProductContactsRequest {
  orderProductOrderNum: string;
  typeof: string;
  contents: string;
}

export const post__members__order_product_contacts = (
  params: IPostMembersOrderProductContactsRequest
): Promise<"정상적으로 접수되었습니다"> => fetcher.post("/members/order_product_contacts", params);
