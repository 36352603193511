import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./NoticeHeader.module.scss";
import { ConditionComponent } from "../ConditionComponent/ConditionComponent";
import { ChevronBottomBig, ChevronTopBig } from "assets/icon";
interface IProp {
  title: string;
  toggleCondition: (payload: boolean) => void;
  condition: boolean;
}
const cx = classNames.bind(styles);
export const NoticeHeader: FC<IProp> = ({ title, toggleCondition, condition }) => {
  return (
    <div className={cx("info-head")} onClick={() => toggleCondition(!condition)}>
      <span className={cx("info-title")}>{title}</span>
      <ConditionComponent condition={condition} FallBackComponent={ChevronBottomBig}>
        <ChevronTopBig />
      </ConditionComponent>
    </div>
  );
};
