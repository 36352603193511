import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { pipe } from "ramda";

export const useOrderAction = () => {
  const history = useHistory();

  const historyPushOrderDetail = useCallback(
    (orderNum: string, productOrderNum: string) => () => {
      history.push(`/member/orders/${orderNum}/${productOrderNum}`);
    },
    [history]
  );

  const deliveryInfoModalOpen = useCallback(
    (trackingUrl: string) => () => {
      window.open(trackingUrl, "DeliveryInfo", "modal,alwaysRaised,width=500,height=714");
    },
    []
  );

  const createOptionArray = useCallback(
    (productOptions: string[]) => (productOptions.length === 0 ? ["옵션없음"] : productOptions),
    []
  );

  const concatOptionProductSelectedCount = useCallback(
    (productSelectedCount: number) => (productOptions: string[]) => [
      ...productOptions,
      `수량: ${productSelectedCount}`
    ],
    []
  );

  const joinOptionArray = useCallback((productOptions: string[]) => productOptions.join(" / "), []);

  const createOptionString = useCallback(
    (productOptions: string[], productSelectedCount: number) =>
      pipe(createOptionArray, concatOptionProductSelectedCount(productSelectedCount), joinOptionArray)(productOptions),
    [createOptionArray, concatOptionProductSelectedCount, joinOptionArray]
  );
  return {
    historyPushOrderDetail,
    deliveryInfoModalOpen,
    createOptionString
  };
};
