import React, { useState, useRef, useEffect } from "react";

import { PageTitle, StoreRowWithRank } from "components";

import { useBookmark } from "hooks";
import classnames from "classnames/bind";
import styles from "./StoresMobile.module.scss";
import { useBrands } from "./useBrands";
import { useShoppingmalls } from "./useShoppingmalls";
import { AirbridgeCustomEvent } from "utils";

const cx = classnames.bind(styles);

export function StoresMobile() {
  const $shoppingmalls = useRef<HTMLDivElement>(null);
  const $brands = useRef<HTMLDivElement>(null);
  const { shoppingmalls } = useShoppingmalls();
  const { brands } = useBrands();
  const { getIsBookmark } = useBookmark();
  const [target, setTarget] = useState("shoppingmalls");

  const changeTarget = event => setTarget(event.target.dataset.target);

  useEffect(() => {
    AirbridgeCustomEvent.mainScreen("home_stores");
  }, []);

  return (
    <main className={cx("main")}>
      <PageTitle title="스토어" />

      <section className={cx("types")}>
        <button
          type="button"
          className={cx("type", { active: target === "shoppingmalls" })}
          data-target="shoppingmalls"
          onClick={changeTarget}
        >
          쇼핑몰
        </button>
        <button
          type="button"
          className={cx("type", { active: target === "brands" })}
          data-target="brands"
          onClick={changeTarget}
        >
          브랜드
        </button>
      </section>

      <section className={cx("stores")} ref={{ shoppingmalls: $shoppingmalls, brands: $brands }[target]}>
        {{ shoppingmalls, brands }[target]?.map(
          ({ id, name, photoUrl, group, rank, message, serializedTags, isNew, genderId }) => (
            <StoreRowWithRank
              key={id}
              id={id}
              name={name}
              photoUrl={photoUrl}
              group={group}
              rank={rank}
              message={message}
              serializedTags={serializedTags}
              isNew={isNew}
              isStar={getIsBookmark(id)}
              genderId={genderId}
            />
          )
        )}
      </section>
    </main>
  );
}
