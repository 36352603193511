import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./NoticeAboutLookpin.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "typings";
import { modalOn } from "modules/ui";
import { UI__MODAL_LOGIN } from "constant";
import { NoticeHeader } from "./NoticeHeader";

const cx = classNames.bind(styles);

export const NoticeAboutLookpin = () => {
  const [isShowShipInfo, setIsShowShipInfo] = useState<boolean>(false);
  const [isShowClaimInfo, setIsShowClaimInfo] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { token } = useSelector<IStore, IStore["member"]>(({ member }) => member);

  const preLink = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (!token) {
        event.preventDefault();
        dispatch(modalOn(UI__MODAL_LOGIN));
      }
    },
    [dispatch, token]
  );
  return (
    <div className={cx("info-wrapper")}>
      <NoticeHeader title="배송 정보" condition={isShowShipInfo} toggleCondition={setIsShowShipInfo} />

      <ul className={cx("info-contents", { display: isShowShipInfo })}>
        <li className={cx("text")}>
          {" "}
          룩핀에서 판매되는 제품은 <span className={cx("bold")}>100% 무료배송</span>입니다.
          <br />
          <br /> 업체마다 개별 배송되며, 주문확인 후 <span className={cx("bold")}>영업일 기준 평균 1~3일</span>의
          상품준비 기간이 소요됩니다.
          <br />
          <br />
          제주도를 포함한 도서산간 지역의 경우 <span className={cx("bold")}>추가 배송비</span> 입금 요청이 있을 수
          있으며 <span className={cx("bold")}>추가 배송기간</span>이 소요될 수 있습니다.
        </li>
      </ul>

      <NoticeHeader title="교환, 환불 정보" condition={isShowClaimInfo} toggleCondition={setIsShowClaimInfo} />

      <ul className={cx("info-contents", { display: isShowClaimInfo })}>
        <li className={cx("text")}>
          <span className={cx("bold")}>배송완료일로부터 7일 이내</span>에{" "}
          <Link to="/member/orders" className={cx("link")} onClick={preLink}>
            MY룩핀 주문내역
          </Link>
          에서 교환/환불 접수 가능합니다
          <br />
          <br />
          반품택배비는 업체마다 다를 수 있으며, 상품 <span className={cx("bold")}>상세정보</span>에서{" "}
          <span className={cx("bold")}>반송지</span> 주소와&nbsp;
          <span className={cx("bold")}>반품택배비</span>를 확인할 수 있습니다.
          <br />
          <br />
          <span className={cx("bold")}>직접발송</span>으로 접수한 경우: 초기 배송 받았던 택배사 홈페이지/앱에서 반품예약
          (타택배사를 이용하는 경우 배송비는 선결제 해주세요. 착불로 발송될 경우 임의로 반송될 수 있습니다)
          <br />
          <br />
          <span className={cx("bold")}>자동수거</span>로 접수한 경우: 택배사에서 연락 후 방문수거(상세정보에 안내된
          택배비를 동봉 또는 입금해주세요.)
          <br />
          <br />
          판매자 사유(상품불량, 오배송 등)로 인한 교환/환불의 경우 반품택배비가 부과되지 않습니다.
          <br />
          <br />
          상품 개봉 후 소비자 부주의에 의한 파손이나 결함은 교환/환불 사유가 되지 않습니다.
          <br />
          <br />
          화면상의 사진과 상품의 색상은 개인 환경에 따라 다소 차이가 있을 수 있으며 미세한 색상, 주름 등의 차이는 제품
          이상으로 인한 교환/환불 사유가 되지 않습니다.
          <br />
          <br />
          특정 브랜드 및 업체의 상품 설명에 별도로 기입된 교환/환불/AS 기준을 우선으로 합니다.
        </li>
      </ul>
    </div>
  );
};
