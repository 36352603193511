import { fetcher } from "utils";

import { GetMembersOrdersOrderNumDetailResponse, GetMembersOrdersOrderNumDetailV3Response } from "typings";

export const get__members__orders__order_num__detail = (
  orderNum: number | string
): Promise<GetMembersOrdersOrderNumDetailResponse> => fetcher.get(`/members/orders/${orderNum}/detail`);

export const get__members__orders__order_num__detail_v3 = (
  orderNum: number | string
): Promise<GetMembersOrdersOrderNumDetailV3Response> => fetcher.get(`/api/v3/members/orders/${orderNum}/pay-result`);
