import { fetcher } from "utils";

import { DisplayType, StoreSerialized, GenderPartner } from "typings";

type GetStoresBrands = (params: {
  page?: number | string;
  displayType?: DisplayType;
  genderId: GenderPartner;
}) => Promise<StoreSerialized[]>;

export const get__stores__brands: GetStoresBrands = params =>
  fetcher.get("/stores/brands", {
    ...params,
    ...(!params?.displayType && { displayType: "lookpin_pay" })
  });
