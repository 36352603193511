import React, { FC, useCallback, useEffect, useState } from "react";
import { INoticeCard } from "typings";

import { NoticeCardDescription } from "./NoticeCardDescription";
import { NoticeCardSummary } from "./NoticeCardSummary";

export const NoticeCard: FC<INoticeCard> = ({ title, subTitle, htmlUrl }) => {
  const [isShow, setIsShow] = useState(false);
  const [description, setDescription] = useState("");

  const getContent = useCallback(() => {
    fetch(htmlUrl)
      .then(res => res.text())
      .then(text => setDescription(text));
  }, [htmlUrl]);

  useEffect(() => {
    if (isShow && description === "") getContent();
  }, [description, getContent, isShow]);

  return (
    <div>
      <NoticeCardSummary
        title={title}
        subTitle={subTitle}
        isShow={isShow}
        onClick={() => {
          setIsShow(prev => !prev);
        }}
      />
      {isShow && <NoticeCardDescription description={description} />}
    </div>
  );
};
