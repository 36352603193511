/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo } from "react";
import qs from "qs";
import classNames from "classnames/bind";
import { useEvents } from "hooks";
import { AirbridgeCustomEvent, imageResizeHelper } from "utils";
import { useHistory } from "react-router-dom";
import { EventPosition, IStore } from "typings";
import styles from "./Banners.module.scss";
import { shallowEqual, useSelector } from "react-redux";

const cx = classNames.bind(styles);

export const Banners = () => {
  const history = useHistory();
  const { position } = useMemo<{ position: EventPosition }>(
    () => qs.parse(history.location.search, { ignoreQueryPrefix: true }) as { position: EventPosition },
    [history.location.search]
  );
  const { genderId } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui, shallowEqual);

  const { eventBanners, openEventPage } = useEvents(position || "home_main_top");

  useEffect(() => {
    AirbridgeCustomEvent.mainScreen("home_event");
  }, []);

  return (
    <main className={cx("event-main")}>
      <div className={cx("header")}>이벤트</div>
      <div className={cx("banner-list")}>
        {eventBanners.map(({ eventUrl, imageUrl, id }) => (
          <div className={cx("banner")} key={id}>
            <a
              className={cx("banner-link")}
              href={`${eventUrl}?${qs.stringify({ platform: "web", gender_id: genderId, store_group_id: "default" })}`}
              onClick={openEventPage}
            >
              <img className={cx("banner-img")} src={imageResizeHelper(imageUrl)} alt={eventUrl} />
            </a>
          </div>
        ))}
      </div>
    </main>
  );
};
