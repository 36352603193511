import React from "react";
import { Helmet } from "react-helmet";

interface IProps {
  description?: string;
  title?: string;
  image?: string;
  url: string;
}

export const DynamicMeta: React.FC<IProps> = ({
  url,
  description = "룩핀 전상품 무료 배송, 여기저기 흩어진 구매후기 한번에 모아보기, 비슷한 옷 찾기 기능 제공",
  title = "룩핀",
  image = "https://www.lookpin.co.kr/img_ogtag.png"
}) => (
  <Helmet>
    <title>{title}</title>
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="룩핀" />
    <meta property="og:image" content={image} />
    <meta property="og:image:width" content="600" />
    <meta property="og:image:height" content="400" />
    <meta property="og:title" content={title} />
    <meta property="og:url" content={url} />
    <meta property="og:description" content={description} />

    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content="룩핀" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
  </Helmet>
);
