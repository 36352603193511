import { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { camelizeKeys } from "humps";
import omitEmpty from "omit-empty";

import {
  put__members__order_products__order_num,
  get__api__v1__pay__bank_names,
  get__api__v1__members__order_products__order_num,
  get__claim_prepare_notice,
  get__members__refund__accounts_v3
} from "api";

import { modalOn } from "actions/actionCreators";

import { UI__MODAL_PICKUP_ADDRESS, AUTO, SELF } from "constant";

import { IStore, OrderProductSerializedCamel, EFieldStatus, PayMethodKo, IRefundAccountV3 } from "typings";
import { AirbridgeEvent } from "utils";
import {
  post__members__refund__accounts_v3,
  post__members__refund_bank_accounts_v3
} from "api/members/refund_accounts/post";

// 임시 이벤트 삭제 예정
type IClaimNotice = {
  meta?: {
    notice?: string;
  };
};

export const useClaim = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderNum, orderProductOrderNum, status } = useParams() as {
    orderNum: string;
    orderProductOrderNum: string;
    status: string;
  };

  const { name, phoneNumber } = useSelector<IStore, IStore["member"]["shippingInfo"]>(
    ({ member }) => member.shippingInfo
  );
  const { main, detail, zipcode } = useSelector<IStore, IStore["pickUpAddress"]>(({ pickUpAddress }) => pickUpAddress);

  const [isCertifyLoading, setIsCertifyLoading] = useState(false);

  const [orderProduct, setOrderProduct] = useState<OrderProductSerializedCamel>();
  const [nextStatus, setNextStatus] = useState<OrderProductSerializedCamel["currentStatus"]["nextStatuses"][number]>();
  const [payMethodText, setPayMethodText] = useState<PayMethodKo>();
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [deliveryType, setDeliveryType] = useState<typeof AUTO | typeof SELF | "">("");
  const [banks, setBanks] = useState<string[]>([]);
  const [refundAccount, setRefundAccount] = useState<IRefundAccountV3>({
    holder: "",
    bankName: "",
    accountNum: ""
  });
  const [certify, setCertify] = useState<boolean | null>(null);
  const [claimNotice, setClaimNotice] = useState<IClaimNotice | null>(null);

  const handleReason = useCallback(event => setReason(event.target.value), []);
  const handleMessage = useCallback(event => setMessage(event.target.value), []);
  const handleRefundAccount = useCallback(
    event => {
      setRefundAccount({ ...refundAccount, [event.target.name]: event.target.value });
      setCertify(null);
    },
    [refundAccount]
  );
  const handleDeliveryType = useCallback(event => setDeliveryType(event.target.id), []);
  const modalOnPickUpAddress = useCallback(() => dispatch(modalOn(UI__MODAL_PICKUP_ADDRESS)), [dispatch]);

  const handleCancel = useCallback(() => {
    if (window.confirm("취소하시겠습니까?")) history.goBack();
  }, [history]);

  const handleSubmit = useCallback(() => {
    if (!orderProduct) return;
    if (!window.confirm("접수하시겠습니까?")) return;
    let params = {
      status,
      reason,
      message,
      beforeStatus: orderProduct?.currentStatus.status
    } as any;

    if (
      status !== EFieldStatus.changeWait &&
      (payMethodText === "가상계좌" || payMethodText === "핸드폰 결제") &&
      [EFieldStatus.cancelWait, EFieldStatus.refundWait, EFieldStatus.cancelSoonAuto].includes(status as EFieldStatus)
    ) {
      params = {
        ...params,
        refundAccount: {
          bank: refundAccount.bankName,
          account: refundAccount.accountNum,
          name: refundAccount.holder
        }
      };
    }

    if (deliveryType === AUTO) {
      params = {
        ...params,
        pickUpAddress: {
          main,
          detail,
          zipcode
        }
      };
    }

    put__members__order_products__order_num(orderProductOrderNum, params).then(() => {
      setTimeout(() => {
        history.replace(`/member/orders?filterGroup=claim&page=1&sort=updated_at`);
        window.scroll(0, 0);
      }, 500);
    });

    AirbridgeEvent.orderCanceled({
      semanticAttributes: {
        transactionID: orderProduct.orderNum.slice(0, 13),
        totalValue: orderProduct.paidPrice,
        totalQuantity: orderProduct.option.selectedCount,
        currency: "KRW",
        products: [
          {
            productID: orderProduct.product.id,
            name: orderProduct.product.name,
            price: orderProduct.product.price,
            currency: "KRW",
            quantity: orderProduct.option.selectedCount
          }
        ]
      }
    });
  }, [
    history,
    message,
    orderProduct,
    orderProductOrderNum,
    reason,
    status,
    payMethodText,
    refundAccount,
    deliveryType,
    main,
    detail,
    zipcode
  ]);

  const handleCertify = useCallback(async () => {
    setIsCertifyLoading(true);

    try {
      await post__members__refund_bank_accounts_v3(refundAccount);

      await post__members__refund__accounts_v3(refundAccount);

      setCertify(true);
      setIsCertifyLoading(false);
    } catch (error) {
      setCertify(false);
      setIsCertifyLoading(false);
    }
  }, [refundAccount]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { payload: target }
        } = await get__api__v1__members__order_products__order_num(orderProductOrderNum);

        if (!target) history.replace("/");
        else {
          const orderProduct = camelizeKeys(target) as any;
          setOrderProduct(orderProduct);
          setPayMethodText(orderProduct.order.payMethodDetail);

          const nextStatus = orderProduct.currentStatus.nextStatuses.find(nextStatus => nextStatus.status === status);

          if (nextStatus) setNextStatus(nextStatus);
          else history.replace("/");
        }
      } catch (error) {
        if (error) history.goBack();
      }
    })();
  }, [history, orderNum, orderProductOrderNum, status]);

  useEffect(() => {
    (async () => {
      const {
        data: {
          payload: { iamport }
        }
      } = await get__api__v1__pay__bank_names();
      const { data } = await get__claim_prepare_notice();
      setClaimNotice(data);
      setBanks(iamport);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const {
        data: { payload }
      } = await get__members__refund__accounts_v3();

      setRefundAccount({
        holder: payload.holder || "",
        bankName: payload.bank_name || "",
        accountNum: payload.account_num || ""
      });

      if (Object.keys(omitEmpty(payload)).length) setCertify(true);
    })();
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  const disabledSubmit = useMemo(() => {
    if (payMethodText === "가상계좌" || payMethodText === "핸드폰 결제") {
      if (status === EFieldStatus.changeWait) return !reason || !deliveryType;
      if (status === EFieldStatus.refundWait) return !reason || !deliveryType || !certify;

      return !reason || !certify;
    }

    switch (status) {
      case EFieldStatus.cancelSoonAuto:
        return !reason;
      case EFieldStatus.changeWait:
        return !reason || !deliveryType;
      case EFieldStatus.cancelWait:
        return !reason;
      case EFieldStatus.refundWait:
        return !reason || !deliveryType;
    }
  }, [payMethodText, status, certify, deliveryType, reason]);

  const isAuto = useMemo(
    () => nextStatus?.availableForm?.includes("pick_up_request") && orderProduct?.product.store.useAutoPickUp,
    [nextStatus]
  );

  const disabledCertify = useMemo(() => !(Object.keys(omitEmpty(refundAccount)).length === 3), [refundAccount]);

  return {
    orderNum,
    orderProductOrderNum,
    status,
    orderProduct,
    nextStatus,
    payMethodText,
    handleReason,
    reason,
    message,
    handleMessage,
    deliveryType,
    isAuto,
    handleDeliveryType,
    name,
    phoneNumber,
    main,
    detail,
    modalOnPickUpAddress,
    banks,
    certify,
    handleCancel,
    disabledSubmit,
    handleSubmit,
    refundAccount,
    handleRefundAccount,
    handleCertify,
    disabledCertify,
    isCertifyLoading,
    claimNotice
  };
};
