import { modalOff } from "actions/actionCreators";
import * as api from "api";
import classNames from "classnames/bind";
import { Checkbox } from "components";
import { NOTICE } from "constant";

import { always, filter, map, pipe, prop, sum } from "ramda";
import React, { Fragment, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { IPartnerOptionViewModel, IStore, OptionId } from "typings";
import { produce } from "utils";

import stylesWrapper from "../../ModalWrapper.module.scss";
import styles from "./CouponApply.module.scss";

const cxWrapper = classNames.bind(stylesWrapper);
const cx = classNames.bind(styles);

export const CouponApply: FC = () => {
  const dispatch = useDispatch();
  const { coupon, options, target, trigger, findAppliedOptions, findOption, findCoupon } = useSelector<
    IStore,
    IStore["applyCouponData"]
  >(({ applyCouponData }) => applyCouponData);

  const getOtherAppliedOptionIds: (optionIds: OptionId[]) => OptionId[] = useCallback(
    optionIds => {
      const [sample] = optionIds;
      const { couponId } = findOption![sample] as IPartnerOptionViewModel;

      if (!couponId) return [];

      return (findAppliedOptions![couponId] || []).filter((id: OptionId) => !optionIds.includes(id));
    },
    [findOption, findAppliedOptions]
  );

  const [partnerOptions, setPartnerOptions] = useState<IPartnerOptionViewModel[]>(
    produce(options!, draft => {
      draft
        .filter(({ optionId }) => target!.includes(optionId))
        .filter(option => !option.couponId || option.couponId === coupon!.couponId || option.optionId === trigger)
        .forEach(option => {
          option.checked = true;
        });
    })
  );

  const [discountPrice, setDiscountPrice] = useState(0);

  const sumSelectedOptionsPrice = useMemo(
    pipe(
      always(partnerOptions),
      filter(prop("checked")),
      map(({ price, selectedCount }) => price * selectedCount),
      sum
    ),
    [partnerOptions]
  );

  const checkOption = useCallback(
    ({ target: { dataset } }) => {
      const id = Number(dataset.id);

      setPartnerOptions(
        produce(partnerOptions, draft => {
          const target = draft.find(({ optionId }) => optionId === id);
          if (target) target.checked = !target.checked;
        })
      );
    },
    [partnerOptions]
  );

  const closeModal = useCallback(() => dispatch(modalOff()), [dispatch]);

  const handleApply = useCallback(async () => {
    const checklists = partnerOptions
      .filter(({ checked, couponId }) => checked && couponId)
      .filter(option => option.couponId !== coupon!.couponId)
      .reduce<{ [couponId: number]: number[] }>((acc, { optionId, couponId }) => {
        if (acc[couponId as number]) acc[couponId as number].push(optionId);
        else acc[couponId as number] = [optionId];
        return acc;
      }, {});

    Promise.all(
      Object.entries(checklists).map(async ([key, optionIds]) => {
        const otherAppliedOptionIds = getOtherAppliedOptionIds(optionIds);
        const id = Number(key);

        if (!otherAppliedOptionIds.length) {
          return Promise.resolve({ status: "clear", couponId: Number(key), target: optionIds, payload: {} });
        }

        return api
          .get__members__issued_discount_coupons__id__calculate_discount_price(id, {
            productOptionIds: otherAppliedOptionIds.flatMap(id => Array(findOption![id].selectedCount).fill(id))
          })
          .then(({ data: { payload, issued_discount_coupon_id } }) => ({
            status: "assign",
            couponId: issued_discount_coupon_id,
            target: otherAppliedOptionIds,
            payload: payload.reduce((acc, { product_option_id, value }) => {
              acc[product_option_id] = value;
              return acc;
            }, {})
          }))
          .catch(() => ({
            status: "alert",
            couponId: id,
            target: otherAppliedOptionIds,
            payload: {}
          }));
      })
    )
      .then(response => {
        const couponNames: string[] = [];

        const options = response.reduce((acc, { status, couponId, target, payload }) => {
          if (status === "alert") couponNames.push(findCoupon![couponId].coupon.name);

          if (status === "clear" || status === "alert") {
            return produce(acc, draft => {
              draft
                .filter(({ optionId }) => target.includes(optionId))
                .forEach(option => {
                  option.couponId = null;
                  option.couponDesc = null;
                  option.couponDiscountPrice = null;
                });
            });
          }

          return produce(acc, draft => {
            draft
              .filter(({ optionId }) => target.includes(optionId))
              .forEach(option => {
                option.couponDiscountPrice = payload[option.optionId];
              });
          }) as any;
        }, partnerOptions);

        if (
          (couponNames.length && window.confirm(NOTICE.RELEASABLE_COUPON(couponNames.join(", ")))) ||
          !couponNames.length
        ) {
          api
            .get__members__issued_discount_coupons__id__calculate_discount_price(coupon!.couponId, {
              productOptionIds: options
                .filter(({ checked }) => checked)
                .flatMap(({ optionId, selectedCount }) => Array(selectedCount).fill(optionId))
            })
            .then(({ data: { payload, issued_discount_coupon_id } }) => {
              const result = produce(options, draft => {
                const discountPriceTable = payload.reduce((acc, { product_option_id, value }) => {
                  acc[product_option_id] = value;
                  return acc;
                }, {});

                draft.filter(prop("checked")).forEach(option => {
                  if (option.checked) {
                    option.couponId = issued_discount_coupon_id;
                    option.couponDesc = findCoupon![issued_discount_coupon_id].coupon.name;
                    option.couponDiscountPrice = discountPriceTable[option.optionId];
                    option.checked = false;
                  }
                });
              }) as any;

              dispatch(modalOff(undefined, { partnerOptions: result }));
            })
            .catch(error => alert(error.data ? error.data.message : error));
        }
      })
      .catch(error => alert(error.data ? error.data.message : error));
  }, [coupon, dispatch, findCoupon, findOption, getOtherAppliedOptionIds, partnerOptions]);

  const getMessage = couponId => {
    if (!couponId) return "";
    if (couponId === coupon!.couponId) return "동일한 쿠폰이 적용되어있습니다";
    return `- ${findCoupon![couponId].coupon.name} 할인쿠폰 적용중`;
  };

  useEffect(() => {
    const checkedOptions = partnerOptions
      .filter(prop("checked"))
      .flatMap(({ optionId, selectedCount }) => Array(selectedCount).fill(optionId));

    if (checkedOptions.length) {
      api
        .get__members__issued_discount_coupons__id__calculate_discount_price(coupon!.couponId, {
          productOptionIds: checkedOptions
        })
        .then(({ data: { payload } }) => {
          setDiscountPrice(payload.reduce((acc, { value }) => acc + value, 0));
        });
    }
  }, [coupon, partnerOptions]);
  if (!options!.length || !coupon) return <Redirect to="/" />;

  const {
    conditionDesc,
    usableAtLimitText,
    coupon: { imageUrl, name, usablePriceLimitGte }
  } = coupon;
  const unusable = sumSelectedOptionsPrice < usablePriceLimitGte;

  return (
    <div className={cxWrapper("cover-container")}>
      <div className={cx("apply-coupons")}>
        <div className={cxWrapper("header")}>
          상품선택
          <button className={cxWrapper("close")} onClick={closeModal} type="button">
            {" "}
          </button>
        </div>

        <div className={cxWrapper("contents")}>
          <div className={cx("coupon-info")}>
            <div className={cx("coupon-image")}>
              <img src={imageUrl} alt="coupon" />
            </div>

            <ul>
              <li className={cx("name")}>{name}</li>
              <li className={cx("desc")}>
                {conditionDesc}&nbsp;({usableAtLimitText})
              </li>
            </ul>
          </div>

          <div className={cx("coupon-list")}>
            <div className={cx("title")}>쿠폰을&nbsp;적용할&nbsp;상품을&nbsp;선택해주세요.</div>
            <ul>
              {partnerOptions
                .filter(({ optionId }) => target!.includes(optionId))
                .map(({ checked, optionId, couponId, productName, price, selectedCount, optionDesc }) => (
                  <Fragment key={optionId}>
                    <li className={cx("option")} data-msg={getMessage(couponId)} key={optionId}>
                      <span className={cx("check-product")}>
                        <Checkbox checked={checked} id={optionId} handleClick={checkOption} />
                      </span>
                      <span className={cx("name")}>
                        {productName}&nbsp;{optionDesc && `(${optionDesc})`}
                      </span>
                      <span className={cx("price")}>{(price * selectedCount).toLocaleString()}원</span>
                    </li>
                    {optionId === trigger && (
                      <li className={cx("trigger")} key="trigger">
                        <span>현재 선택한 상품</span>
                      </li>
                    )}
                  </Fragment>
                ))}
            </ul>
          </div>

          <div className={cx("price-list")}>
            <ul>
              <li className={cx("selected")}>
                <span>선택&nbsp;상품금액</span>
                <span>{sumSelectedOptionsPrice.toLocaleString()}원</span>
              </li>
              <li>
                <span>쿠폰&nbsp;할인금액</span>
                <span className={cx("blue")}>
                  {unusable
                    ? `${usablePriceLimitGte.toLocaleString()}원 이상 선택시 할인`
                    : `-${discountPrice.toLocaleString()}원`}
                </span>
              </li>
            </ul>
          </div>

          <button
            type="button"
            className={cx("apply-btn", { unusable })}
            onClick={unusable ? () => alert(NOTICE.CHECK_CONDITION) : handleApply}
          >
            쿠폰&nbsp;적용하기
          </button>
        </div>
      </div>
    </div>
  );
};
