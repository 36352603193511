const getSize = (width: number, height: number) => {
  const MAX_SIZE_NUMBER = 600;
  let resizeWidth = width;
  let resizeHeight = height;
  if (width > height) {
    if (width > MAX_SIZE_NUMBER) {
      resizeHeight *= MAX_SIZE_NUMBER / width;
      resizeWidth = MAX_SIZE_NUMBER;
    }
  } else if (height > MAX_SIZE_NUMBER) {
    resizeWidth *= MAX_SIZE_NUMBER / height;
    resizeHeight = MAX_SIZE_NUMBER;
  }

  return [resizeWidth, resizeHeight];
};

export const resizeImage = (payload, maxWidth: number = 450, maxHeight: number = 450) => {
  const canvas = document.createElement("canvas");
  const [width, height] = getSize(maxWidth, maxHeight);
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  if (ctx) ctx.drawImage(payload, 0, 0, width, height);
  const dataURL = canvas.toDataURL("image/jpg");

  const byteString = atob(dataURL.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const file = new Blob([ab], { type: "image/jpg", endings: "native" });
  return file;
};
