import React, { memo, useCallback, useEffect, useMemo, useState, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteCart } from "actions/actionCreators";
import { History } from "history";

import { camelizeKeys } from "humps";
import * as api from "api";
import { assoc, always, filter, find, invoker, map, omit, pipe, prop, reject, sum, then, times } from "ramda";
import { Checkbox } from "components";
import { createStoreGroup, cartsViewModelCreator } from "view-model-creator";
import { imageResizeHelper, criteoEventPush, Tracking, produce, AirbridgeCustomEvent } from "utils";
import { NOTICE, SALE, SOLDOUT_OPTION, SOLDOUT_PRODUCT, CART } from "constant";
import { ICartViewModel, IPageCamel, IStore } from "typings";
import classNames from "classnames/bind";
import styles from "./Cart.module.scss";

const cx = classNames.bind(styles);

interface IProps {
  history: History;
}

export const Cart: FC<IProps> = memo(({ history }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [carts, setCarts] = useState<ICartViewModel[]>([]);
  const { email } = useSelector<IStore, IStore["member"]>(({ member }) => member);
  const { isMobile } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);

  const callGetCart = (number: number) => api.get__api__v1__members__carts({ page: { number, size: 50 } });

  const getCart = useCallback(() => {
    callGetCart(1).then(({ data: { payload, meta } }) => {
      const cart: IPageCamel = camelizeKeys(meta) as IPageCamel;

      setCarts(cartsViewModelCreator(payload));
      setIsLoading(false);

      if (cart.totalPages > 1) {
        const requests = times(v => v + 2, cart.totalPages - 1);

        requests.forEach(page => {
          callGetCart(page).then(({ data: { payload } }) => {
            const carts = cartsViewModelCreator(payload);
            setCarts(prevCarts => prevCarts.concat(carts));
          });
        });
      }
    });
  }, []);

  useEffect(getCart, [getCart]);

  useEffect(() => {
    const cartItems = carts.map(cart => {
      return { id: cart.productId, price: cart.price, quantity: cart.selectedCount };
    });
    if (process.env.REACT_APP_ENV === "production") {
      Tracking.viewCart();
      if (!cartItems.length) return;
      criteoEventPush({ email, event: "viewBasket", item: cartItems });
    } else {
      console.warn("viewCart");
    }
  }, [carts, email]);

  const selectedCarts = useMemo(pipe(always(carts), filter(prop("selected"))), [carts]);
  const selectedPartnerProducts: any[] = useMemo(
    pipe(
      always(selectedCarts as any),
      filter(({ status }) => status === SALE)
    ),
    [selectedCarts]
  );
  const isOrderble: boolean = useMemo(pipe(always(selectedPartnerProducts), prop("length"), Boolean), [
    selectedPartnerProducts
  ]);
  const totalPrice = useMemo(
    pipe(
      always(selectedPartnerProducts),
      map(({ priceToBuy }) => priceToBuy),
      sum,
      invoker(0, "toLocaleString")
    ),
    [selectedPartnerProducts]
  );

  const handleAllSelect = useCallback(
    event => {
      event.preventDefault();

      if (!carts.length) return;
      pipe(
        always(carts),
        reject(prop("selected")),
        prop("length"),
        Boolean,
        unselected => assoc("selected", unselected),
        func => map(func, carts),
        setCarts
      )();
    },
    [carts]
  );

  const selectCart = useCallback(
    event => {
      event.preventDefault();

      const targetId = Number(event.target.dataset.id);

      setCarts(
        produce(carts, draft => {
          const target = find(({ cartId }) => cartId === targetId, draft) as ICartViewModel;

          target.selected = !target.selected;
        })
      );
    },
    [carts]
  );

  const callDeleteCart = useCallback(
    async event => {
      event.preventDefault();

      if (!selectedCarts.length) return;
      setIsLoading(true);
      await pipe(always(selectedCarts), map(prop("cartId")), deleteCart, then(dispatch))();

      AirbridgeCustomEvent.cartRemove(
        selectedCarts.map(item => ({
          cart_id: item.cartId,
          productID: item.productId,
          name: item.productName,
          currency: "KRW",
          quantity: item.selectedCount
        }))
      );

      getCart();
    },
    [dispatch, selectedCarts, getCart]
  );

  const readyToOrder = useCallback(
    event => {
      event.preventDefault();

      if (selectedCarts.length !== selectedPartnerProducts.length) {
        if (window.confirm(NOTICE.FOR_ORDER(selectedCarts.length - selectedPartnerProducts.length))) {
          history.push("/order", {
            partnerOptions: map(omit(["cartId", "selected"]), selectedPartnerProducts),
            context: CART
          });
        }
      } else {
        history.push("/order", {
          partnerOptions: map(omit(["cartId", "selected"]), selectedPartnerProducts),
          context: CART
        });
      }
    },
    [history, selectedCarts.length, selectedPartnerProducts]
  );

  return (
    <main className={cx("cart-tab")}>
      <div className={cx(["bold"], "wrapper-head")}>장바구니</div>
      <div className={cx("head-bar")}>
        <Checkbox
          checked={!!carts.length && carts.length === pipe(always(carts), filter(prop("selected")), prop("length"))()}
          id="all"
          handleClick={handleAllSelect}
        />
        <button type="button" className={cx("btn-delete", { "btn-loading": isLoading })} onClick={callDeleteCart}>
          삭제
        </button>
      </div>
      <div className={cx("wrapper-list")}>
        {pipe(
          always(carts),
          createStoreGroup,
          map => [...map.entries()],
          map(([storeName, cartsInStore]: [string, ICartViewModel[]]) => (
            <ul key={storeName} className={cx("list")}>
              <div className={cx("title")}>{storeName}</div>
              {cartsInStore.map(
                ({
                  cartId,
                  productId,
                  selected,
                  status,
                  photoUrl,
                  productName,
                  optionDesc,
                  priceToBuyDesc,
                  prevPrice,
                  isOnEventSale
                }) => {
                  const soldOut = status !== SALE;
                  const optionInfo = optionDesc.split("/");
                  return (
                    <>
                      {isMobile ? (
                        <li className={cx("mobile-element")} key={cartId}>
                          <div className={cx("mobile-checkbox")}>
                            <Checkbox id={cartId} checked={selected} handleClick={selectCart} />
                          </div>
                          <div className={cx("mobile-item-box")}>
                            <Link to={`/products/${productId}`} className={cx("link", { "sold-out": soldOut })}>
                              <div className={cx("mobile-item-box-wrapper")}>
                                <div className={cx("thumbnail", { "sold-out": soldOut })}>
                                  <img src={imageResizeHelper(photoUrl, 60)} alt="상품사진" />
                                </div>
                                <div className={cx("info")}>
                                  <div className={cx("product-box")}>
                                    <span className={cx("product-name", { "sold-out": soldOut })}>{productName}</span>
                                    {status === SOLDOUT_PRODUCT && (
                                      <span className={cx("red")}>(품절된&nbsp;상품입니다.)</span>
                                    )}
                                  </div>

                                  <div className={cx("option-box")}>
                                    <span className={cx("option-name", { "sold-out": soldOut })}>{optionInfo[0]}</span>
                                    {status === SOLDOUT_OPTION && (
                                      <span className={cx("red")}>(품절된&nbsp;옵션입니다.)</span>
                                    )}
                                  </div>

                                  <div className={cx("price-box")}>
                                    {isOnEventSale && (
                                      <div className={cx("notice-sale-wrapper")}>
                                        <div className={cx("notice-sale")}>
                                          세일특가
                                          <span role="img" aria-label="Fire">
                                            🔥
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    <div className={cx("option-count-price")}>
                                      <div className={cx("option-count")}>{optionInfo[1]}</div>
                                      <div className={cx("price-wrapper")}>
                                        <div className={cx("prev-price")}>{`${prevPrice.toLocaleString()}원`}</div>
                                        <div className={cx("price")}>{priceToBuyDesc}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </li>
                      ) : (
                        <li className={cx("element")} key={cartId}>
                          <Checkbox id={cartId} checked={selected} handleClick={selectCart} />

                          <Link to={`/products/${productId}`} className={cx("link", { "sold-out": soldOut })}>
                            <div className={cx("thumbnail", { "sold-out": soldOut })}>
                              <img src={imageResizeHelper(photoUrl, 60)} alt="상품사진" />
                            </div>

                            <div className={cx("info")}>
                              <div>
                                <span className={cx("product-name", { "sold-out": soldOut })}>{productName}</span>
                                {status === SOLDOUT_PRODUCT && (
                                  <span className={cx("red")}>(품절된&nbsp;상품입니다.)</span>
                                )}
                              </div>

                              <div>
                                <span className={cx("option-name", { "sold-out": soldOut })}>{optionInfo[0]}</span>
                                {status === SOLDOUT_OPTION && (
                                  <span className={cx("red")}>(품절된&nbsp;옵션입니다.)</span>
                                )}
                              </div>
                              <div className={cx("price-count-wrapper")}>
                                {isOnEventSale && (
                                  <div className={cx("notice-sale-wrapper")}>
                                    <div className={cx("notice-sale")}>
                                      <span>세일특가</span>
                                      <span className={cx("fire-emoji")} role="img" aria-label="Fire">
                                        🔥
                                      </span>
                                    </div>
                                  </div>
                                )}
                                <div className={cx("option-count-price")}>
                                  <div className={cx("option-count")}>{optionInfo[1]}</div>
                                  <div className={cx("price-wrapper")}>
                                    <div className={cx("prev-price")}>{`${prevPrice.toLocaleString()}원`}</div>
                                    <div className={cx("price")}>{priceToBuyDesc}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </li>
                      )}
                    </>
                  );
                }
              )}
            </ul>
          ))
        )()}
      </div>
      <button type="button" className={cx("btn-order", { "not-orderable": !isOrderble })} onClick={readyToOrder}>
        {totalPrice}원&nbsp;주문하기
      </button>
    </main>
  );
});
