/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { FC, useCallback, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import { btnPinOff, btnPinOn } from "assets/img";
import { IconPin } from "assets/icon";

import { imageResizeHelper } from "utils";

import { SALE, IGNORE_PRODUCT_IDS } from "constant";

import { INewProductViewModel, IReviewsSummariesViewModelV3 } from "typings";

import { usePin } from "hooks";
import { BrandIcon } from "components";
import { LazyPhotoImage } from "components/presentation/photoReviewModal/LazyPhotoImage";
import share from "./ProductCardShare.module.scss";

const cx = classNames.bind(share);

interface IProps {
  product: INewProductViewModel;
  review: IReviewsSummariesViewModelV3;
  rank?: number;
  ignore?: "store" | "pin";
}

export const ProductCard: FC<IProps> = ({
  product: { id, name, price, priceDesc, info, store, status, shippingSpeed },
  review,
  rank,
  ignore
}) => {
  const { handlePin, getIsPin } = usePin();
  const togglePin = useCallback(() => handlePin(id, name, price), [handlePin, id, name, price]);
  const isPin = useMemo(() => getIsPin(id), [getIsPin, id]);
  const soldOut = status !== SALE;
  const [productImage, setProductIamge] = useState(info?.images.main[0] || "");

  const isIgnoreProduct = IGNORE_PRODUCT_IDS.findIndex(ignoreId => ignoreId === id) !== -1;
  if (isIgnoreProduct) {
    return null;
  }

  if (soldOut) {
    return (
      <section className={cx("card", "sold-out")} data-msg="품절상품">
        {rank && <div className={cx("rank", { hide: rank > 99 })}>{rank}</div>}
        <div className={cx("thumbnail")}>
          <picture className={cx("thumbnail-picture")}>
            {info && info.images.main.length > 1 ? (
              <LazyPhotoImage
                src={imageResizeHelper(productImage, 180)}
                onMouseOver={() => setProductIamge(info.images.main[1])}
                onMouseOut={() => setProductIamge(info.images.main[0])}
                className={cx("thumbnail-picture-img")}
                alt={name}
              />
            ) : (
              <LazyPhotoImage
                src={imageResizeHelper(productImage, 180)}
                className={cx("thumbnail-picture-img")}
                alt={name}
              />
            )}
          </picture>

          {ignore !== "pin" && (
            <button type="button" className={cx("pin")}>
              <img className={cx("pin-img", { animate: isPin })} src={isPin ? btnPinOn : btnPinOff} alt="룩핀 아이콘" />
            </button>
          )}
        </div>

        {ignore !== "store" && (
          <ul className={cx("desc")}>
            <li className={cx("store-name")}>{store.name}</li>
            <li className={cx("product-name")}>{name}</li>
            <li className={cx(["bold"], "price")}>{priceDesc}</li>
          </ul>
        )}
      </section>
    );
  }

  return (
    <section className={cx("card")}>
      {rank && <div className={cx("rank", { hide: rank > 99 })}>{rank}</div>}
      <div className={cx("thumbnail")}>
        <Link className={cx(["link"])} to={`/products/${id}`}>
          <picture className={cx("thumbnail-picture")}>
            {info && info.images.main.length > 1 ? (
              <LazyPhotoImage
                src={imageResizeHelper(productImage, 180)}
                onMouseOver={() => setProductIamge(info.images.main[1])}
                onMouseOut={() => setProductIamge(info.images.main[0])}
                className={cx("thumbnail-picture-img")}
                alt={name}
              />
            ) : (
              <LazyPhotoImage
                src={imageResizeHelper(productImage, 180)}
                className={cx("thumbnail-picture-img")}
                alt={name}
              />
            )}
          </picture>
        </Link>
        {review && review.totalCount > 0 && (
          <div className={cx("review-m")}>
            <div className={cx("star-icon")}>&#9733;</div>
            <div className={cx("satisfaction")}>{review.averageSatisfaction}</div>
            <div className={cx("total-count")}>
              {review.totalCount > 999 ? "(999+명 평가)" : `(${review.totalCount}명 평가)`}
            </div>
          </div>
        )}
        {ignore !== "pin" && (
          <button type="button" className={cx("pin")} onClick={togglePin}>
            <img className={cx("pin-img", { animate: isPin })} src={isPin ? btnPinOn : btnPinOff} alt="룩핀 아이콘" />
          </button>
        )}
      </div>

      <ul className={cx("desc")}>
        {ignore !== "store" && (
          <Link className={cx(["link"], "flex-box")} to={{ pathname: `/stores/${store.id}` }}>
            <li className={cx("store-name")}>{store.name}</li>
            {store.group === "brand" && <BrandIcon />}
          </Link>
        )}

        <Link className={cx(["link"])} to={`/products/${id}`}>
          <li className={cx("product-name")}>{name}</li>
          <li className={cx("price-shipping-speed-wrapper")}>
            <div className={cx(["bold"], "price")}>{priceDesc}</div>
            {shippingSpeed && (
              <div className={cx(["bold"], "shipping-speed")}>
                {shippingSpeed === "fast" ? (
                  <div className={cx("shipping-fast")}>배송우수</div>
                ) : (
                  <div className={cx("shipping-slow")}>배송지연</div>
                )}
              </div>
            )}
          </li>
          {review && review.totalCount > 0 && (
            <li className={cx("review")}>
              <div className={cx("star-icon")}>&#9733;</div>
              <div className={cx("satisfaction")}>{review.averageSatisfaction}</div>
              <div className={cx("total-count")}>
                {review.totalCount > 999 ? "(999+명 평가)" : `(${review.totalCount}명 평가)`}
              </div>
            </li>
          )}
        </Link>
      </ul>

      {ignore !== "pin" && (
        <button type="button" className={cx("m-pin", { red: isPin })} onClick={togglePin}>
          <IconPin />
          <span>핀하기</span>
        </button>
      )}
    </section>
  );
};
