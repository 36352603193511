import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./ContactButton.module.scss";
import { Link } from "react-router-dom";
import { IconChat } from "assets/icon";

interface IProp {
  orderNum: string | number;
  productOrderNum: string | number;
}

const cx = classNames.bind(styles);

export const ContactButton: FC<IProp> = ({ orderNum, productOrderNum }) => {
  return (
    <Link className={cx("button")} to={`/member/orders/${orderNum}/${productOrderNum}/contact`}>
      <IconChat />
      <span className={cx("text")}>문의하기</span>
    </Link>
  );
};
