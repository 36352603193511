import React, { ChangeEvent, FC, useCallback } from "react";
import classnames from "classnames/bind";

import { IconSearch } from "assets/icon";

import styles from "./SearchKeyword.module.scss";

const cx = classnames.bind(styles);

interface IProps {
  keyword: string;
  changeKeyword: (event: ChangeEvent<HTMLInputElement>) => void;
  handleKeyword: () => void;
}

export const SearchKeyword: FC<IProps> = ({ keyword, changeKeyword, handleKeyword }) => {
  const handleEnter = useCallback(
    ({ key, target: { name } }) => {
      if (key === "Enter" && name === "keyword") handleKeyword();
    },
    [handleKeyword]
  );

  return (
    <div className={cx("wrapper")}>
      <input
        className={cx("keyword")}
        type="search"
        name="keyword"
        placeholder="페이지 내 재검색"
        value={keyword}
        onChange={e => {
          if (e.target.value.length <= 50) changeKeyword(e);
        }}
        onKeyPress={handleEnter}
      />

      <button onClick={handleKeyword} type="button" className={cx("button")}>
        <IconSearch />
      </button>
    </div>
  );
};
