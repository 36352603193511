import React, { FC, useMemo, useState, useCallback } from "react";
import { IReviewViewModel, ReviewType } from "typings";

import classNames from "classnames/bind";
import { LevelIcon, ReviewStars, CountReviewSummary } from "components";
import { FillRecommend, NotFillRecommend } from "assets/icon";
import { modalOn, openPhotoDetailModal } from "actions/actionCreators";
import { useDispatch } from "react-redux";
import { LOOKPIN_REVIEW, UI__MODAL_DETAIL_PHOTO_REVIEW } from "constant";
import { useReviewLikers } from "hooks/useReviewLikers";
import styles from "./Review.module.scss";

const cx = classNames.bind(styles);
interface IProp {
  review: IReviewViewModel;
  reviewType: ReviewType;
}
interface IRenderRecommendProp {
  isLiked: boolean;
}

const RenderRecommend: FC<IRenderRecommendProp> = ({ isLiked }) => (isLiked ? <FillRecommend /> : <NotFillRecommend />);

export const Review: FC<IProp> = ({ review, reviewType }) => {
  const dispatch = useDispatch();
  const {
    id,
    satisfaction,
    color,
    size,
    shipping,
    authorLevel,
    author,
    weight,
    height,
    contents,
    images,
    createdAt,
    options,
    likeCount
  } = review;
  const { getIsReviewLiked, toggleReviewLikes } = useReviewLikers();

  const [likeCountState, setLikeCountState] = useState(likeCount);
  const isLiked = useMemo(() => {
    return getIsReviewLiked(id);
  }, [getIsReviewLiked, id]);

  const optionStr = useMemo<string>(() => {
    if (options) {
      return options.map((option, index) => `${index + 1}:${option}`).join("/");
    }
    return "";
  }, [options]);

  const isLookpinReview = useMemo(() => reviewType === LOOKPIN_REVIEW, [reviewType]);

  const toggleRecommend = useCallback(
    (id: number) => {
      if (isLookpinReview) {
        const diff = isLiked ? -1 : 1;
        toggleReviewLikes(id, () => setLikeCountState(likeCountState + diff));
      }
    },
    [isLiked, likeCountState, isLookpinReview, toggleReviewLikes]
  );

  const openPhotoDetail = useCallback(
    index => {
      dispatch(modalOn(UI__MODAL_DETAIL_PHOTO_REVIEW, { scrollY: window.scrollY }));
      dispatch(openPhotoDetailModal({ reviewDetail: review, select: index }));
    },
    [dispatch, review]
  );

  return (
    <div className={cx("review-wrapper", reviewType)}>
      <div className={cx("header")}>
        {authorLevel && <LevelIcon level={authorLevel} />}
        <span className={cx("author-name")}>{author}</span>
        {height && weight && (
          <span className={cx("author-specifications")}>
            {height}cm, {weight}kg
          </span>
        )}
      </div>
      <div>
        {isLookpinReview ? (
          <CountReviewSummary id={id} satisfaction={satisfaction} color={color} size={size} shipping={shipping} />
        ) : (
          <ReviewStars reviewId={id} isSmall starRating={satisfaction} />
        )}
      </div>
      {optionStr !== "" && <div className={cx("product-option-wrapper")}>구매 옵션 : {optionStr}</div>}
      <section className={cx("body")}>
        <pre className={cx("review-contents")}>{contents}</pre>
        {images && (
          <div className={cx("images-wrapper")}>
            {images.map((image, index) => (
              <picture
                key={image}
                onClick={() => {
                  openPhotoDetail(index);
                }}
              >
                <img className={cx("review-images")} src={image} alt={`${author}${index}`} />
              </picture>
            ))}
          </div>
        )}
      </section>
      <section className={cx("footer")}>
        <span>{createdAt}</span>
        <div className={cx("recommend-wrapper", { isLookpinReview })} onClick={() => toggleRecommend(id)}>
          {isLookpinReview && <RenderRecommend isLiked={isLiked} />}
          <span className={cx("like-count", { isLiked })}>추천 {likeCountState}</span>
        </div>
      </section>
    </div>
  );
};
