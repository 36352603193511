import React, { FC } from "react";
import classnames from "classnames/bind";
import { pipe, map } from "ramda";
import { ORDERS } from "constant";

import styles from "./SelectShare.module.scss";

const cx = classnames.bind(styles);

interface IProps {
  handleOrder: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  order: string;
  orders?: { [key: string]: string };
}

export const SelectOrder: FC<IProps> = ({ handleOrder, order, orders }) => (
  <select className={cx("select")} onChange={handleOrder} value={order}>
    {pipe(
      Object.entries,
      Array.from,
      map(([key, value]) => (
        <option key={key} value={key}>
          {value}
        </option>
      ))
    )(orders || ORDERS)}
  </select>
);
