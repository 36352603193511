import { orderPointsViewModelCreator } from "view-model-creator";

import { INITIALIZE, LOGOUT } from "constant";

import { IOrderPointsState } from "typings";

const initialState: IOrderPointsState = {
  pointsToNextLevel: 0,
  last2YearsPrice: 0,
  pointsToWillExpire: 0,
  levelScore: 0,
  limit: 0,
  point: 0,
  level: 1,
  color: "e6d345",
  levelPointRate: 0,
  nextLevelPercent: 0,
  currentLevelInfo: []
};

export default function orderPoints(state = initialState, { type, payload }) {
  if (type === INITIALIZE && payload.orderPoints) return orderPointsViewModelCreator(payload.orderPoints);

  if (type === LOGOUT) return initialState;

  return state;
}
