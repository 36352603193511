import { fetcher } from "utils";
import { ColorKey, IJSendSuccessResponse, ShippingKey, SizeKey } from "typings";

interface IReviewPatchRequest {
  id: number | string;
  order_product_order_num: number | string;
  param: {
    satisfaction: number;
    size?: SizeKey | null;
    color?: ColorKey | null;
    shipping?: ShippingKey | null;
    content?: string | null;
    photo_urls?: Array<String>;
    userable_info: {
      height: number | string;
      weight: number | string;
    };
  };
}
type ReviewPatchFetcher = ({
  order_product_order_num,
  param,
  id
}: IReviewPatchRequest) => Promise<IJSendSuccessResponse<{}>>;

export const api__patch_order_proudcts_reviews: ReviewPatchFetcher = ({ order_product_order_num, param, id }) =>
  fetcher.patchBody(`/api/v1/members/order_products/${order_product_order_num}/order_product_reviews/${id}`, param);
