import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import { map, times, take } from "ramda";

import {
  ProductCard,
  QuestionHoverBox,
  Loading,
  Pagination,
  SelectStoreCategory,
  SelectOrder,
  SearchKeyword,
  RangePrice,
  DisplayCondition,
  ProductsNotExist,
  BookmarkButton
} from "components";

import { useStoreProductSearch } from "hooks";

import { TRENDING } from "constant";

import { IStore, Order } from "typings";

import searchShare from "components/pages/search/SearchShare.module.scss";
import { DynamicMeta } from "components/base/dynamicMeta/DynamicMeta";
import { criteoEventPush, imageResizeHelper } from "utils";
import styles from "./StoreDetail.module.scss";

const ss = classNames.bind(searchShare);
const cx = classNames.bind(styles);

export function StoreDetail() {
  const genderId = useSelector<IStore, IStore["ui"]["genderId"]>(({ ui: { genderId } }) => genderId);
  const { email } = useSelector<IStore, IStore["member"]>(({ member }) => member);

  const base = useMemo(
    () => ({
      params: {
        order: TRENDING as Order,
        page: { number: 1, size: 100 },
        genderId
      },
      keyword: "",
      category: ""
    }),
    [genderId]
  );

  const {
    category,
    conditions,
    handleCategory,
    handleKeyword,
    handleOrder,
    handlePage,
    handlePrice,
    handlePriceReset,
    gte,
    lte,
    isLoading,
    keyword,
    order,
    number,
    size,
    products,
    reviews,
    resetCondition,
    totalCount,
    changeKeyword,
    maxPrice,
    categories,
    store,
    mainRef
  } = useStoreProductSearch(base);

  const isMobile = useSelector<IStore, IStore["ui"]["isMobile"]>(({ ui: { isMobile } }) => isMobile);

  const COLUMN = useMemo(() => (isMobile ? 3 : 5), [isMobile]);

  const hiddenFlexBoxes = useMemo(
    () => times(v => v + 1, products.length % COLUMN ? COLUMN - (products.length % COLUMN) : 0),
    [COLUMN, products.length]
  );

  useEffect(() => {
    if (products.length > 0) {
      const productsItems = take(3, products).map(product => product.id);
      criteoEventPush({ event: "viewList", email, item: productsItems });
    }
  }, [products, email]);

  return (
    <main className={cx("main")} ref={mainRef}>
      {!store || isLoading ? (
        <Loading />
      ) : (
        <>
          <DynamicMeta
            url={window.location.href}
            title={store.name}
            description={store.message || undefined}
            image={store.photoUrl ? imageResizeHelper(store.photoUrl, 300) : undefined}
          />
          <div className={cx("background")}>
            <div className={cx("wrapper")}>
              <div className={cx("thumbnail")}>
                <img src={store.photoUrl} alt="스토어 이미지" />
              </div>

              <div className={cx("store")}>
                <div className={cx("info")}>
                  <span className={cx("name")}>{store.name}</span>

                  <QuestionHoverBox info={store.partner?.extraInfo?.info} />

                  <BookmarkButton id={store.id} name={store.name} group={store.group} />
                </div>

                <div className={cx("message")}>
                  <span className={cx("message-text")}>{store.message}</span>
                </div>
              </div>
            </div>
          </div>

          <div className={ss("filter-container")}>
            <ul className={ss("filter-list")}>
              <li className={ss("filter-item")}>
                <SelectOrder handleOrder={handleOrder} order={order} />
              </li>

              <li className={ss("filter-item")}>
                <SelectStoreCategory categories={categories} category={category} handleCategory={handleCategory} />
              </li>

              <li className={ss("filter-item")}>
                <RangePrice
                  handlePrice={handlePrice}
                  handlePriceReset={handlePriceReset}
                  gte={gte}
                  lte={lte}
                  maxPrice={maxPrice}
                />
              </li>
            </ul>

            <SearchKeyword keyword={keyword} changeKeyword={changeKeyword} handleKeyword={handleKeyword} />
          </div>

          <DisplayCondition conditions={conditions} resetCondition={resetCondition} />
          <section className={ss("products")}>
            {products.length ? (
              <>
                {products.map((product, i) => (
                  <ProductCard key={product.id} product={product} review={reviews[i]} ignore="store" />
                ))}

                {map(
                  v => (
                    <div key={totalCount + v} className={ss("hidden-card")} />
                  ),
                  hiddenFlexBoxes
                )}
              </>
            ) : (
              <ProductsNotExist />
            )}
          </section>

          <Pagination totalCount={totalCount} handler={handlePage} number={number} size={size} />
        </>
      )}
    </main>
  );
}
