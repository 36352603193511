/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as api from "api";
import { always, pipe, pipeWith, then, map, path, take, times, identity } from "ramda";
import { imageResizeHelper, toPhoneNumberString } from "utils";
import { camelizeKeys } from "humps";
import { ProductCardWithoutPin, MyLookpinRecents, OrderProductSummary, MoreBox } from "components";
import { v4 as uuidV4 } from "uuid";
import {
  IStore,
  ICoordiViewModel,
  INewPinViewModel,
  IBookmarkViewModel,
  ICartViewModel,
  IPageCamel,
  IReviewsSummariesViewModelV3,
  IOrderProductSummary,
  NumberAuthCallBackFunction
} from "typings";
import { SALE, SOLDOUT_PRODUCT, SOLDOUT_OPTION, UI__MODAL_NUMBER_AUTH } from "constant";
import { initialize, modalCallbackFunctionSet, modalOff, modalOn } from "actions/actionCreators";
import { IconChevronRight, BadgeNew } from "assets/icon";
import { badgeNew } from "assets/img";
import {
  newPinsViewModelCreator,
  pinsMetaViewModelCreator,
  coordiViewModelCreator,
  coordiMetaViewModelCreator,
  bookmarksViewModelCreator,
  bookmarksMetaViewModelCreator,
  cartsViewModelCreator,
  reviewSummariesViewModelCreatorV3
} from "view-model-creator";

import classNames from "classnames/bind";
import styles from "./Member.module.scss";

const cx = classNames.bind(styles);

const lengths = {
  cart: 4,
  pin: {
    desktop: 5,
    mobile: 6
  },

  coordi: {
    desktop: 5,
    mobile: 4
  },

  bookmark: {
    desktop: 6,
    mobile: 3
  }
};

export function Member() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);
  const { level, point } = useSelector<IStore, IStore["orderPoints"]>(({ orderPoints }) => orderPoints, shallowEqual);
  const { email, phoneNumber } = useSelector<IStore, IStore["member"]>(({ member }) => member, shallowEqual);
  const cart = useSelector<IStore, IStore["cart"]>(({ cart }) => cart);
  const coupons = useSelector<IStore, IStore["coupons"]>(({ coupons }) => coupons);

  const [reviews, setReviews] = useState<IReviewsSummariesViewModelV3[]>([]);
  const [orderSummary, setOrderSummary] = useState<IOrderProductSummary>();

  const [pins, setPins] = useState<{ data: INewPinViewModel[]; totalCount: number }>({
    data: [],
    totalCount: 0
  });

  const [coordi, setCoordi] = useState<{ data: ICoordiViewModel[]; totalCount: number }>({
    data: [],
    totalCount: 0
  });

  const [bookmarks, setBookmars] = useState<{ data: IBookmarkViewModel[]; totalCount: number }>({
    data: [],
    totalCount: 0
  });

  const [carts, setCarts] = useState<ICartViewModel[]>([]);
  const [hasNewQna, setHasNewQna] = useState<Boolean>(false);
  const [hasNewContacts, setHasNewContacts] = useState<Boolean>(false);

  useEffect(() => {
    Promise.all([
      api.get__members__issued_discount_coupons(),
      api.get__members__order_points__simple(),
      api.get__members__order_points__detail(),
      api.get__users__coordi({ page: { number: 1, size: lengths.coordi[isMobile ? "mobile" : "desktop"] } }),
      api.get__api__v1__userables__stores({
        page: { number: 1, size: lengths.coordi[isMobile ? "mobile" : "desktop"] }
      }),
      api.get__api__v1__userables__products({
        page: { number: 1, size: lengths.pin[isMobile ? "mobile" : "desktop"] }
      }),
      api.get__api__v1__members__carts({ page: { number: 1, size: lengths.cart } })
    ]).then(
      ([
        coupons,
        simple,
        detail,
        {
          data: { coordinations, meta: coordiMeta }
        },
        {
          data: { payload: bookmarks, meta: bookmarksMeta }
        },
        {
          data: { payload: pins, meta: pinsMeta }
        },
        {
          data: { payload: carts, meta: cartsMeta }
        }
      ]) => {
        const cart: IPageCamel = camelizeKeys(cartsMeta) as IPageCamel;

        setCoordi({
          data: coordiViewModelCreator(coordinations),
          totalCount: coordiMetaViewModelCreator(coordiMeta).totalCount
        });

        setBookmars({
          data: bookmarksViewModelCreator(bookmarks),
          totalCount: bookmarksMetaViewModelCreator(bookmarksMeta).totalCount
        });

        setPins({
          data: newPinsViewModelCreator(pins),
          totalCount: pinsMetaViewModelCreator(pinsMeta).totalCount
        });
        setCarts(cartsViewModelCreator(carts));

        dispatch(initialize({ cart, coupons, orderPoints: { simple, detail } }));
      }
    );
  }, [dispatch, isMobile]);

  useEffect(() => {
    const pinsIds = pins.data.map(pin => pin.id);

    if (!pinsIds.length) return;
    pipeWith(then)([
      api.get__products__id__reviews_summaries_v3,
      path(["data", "payload"]),
      reviewSummariesViewModelCreatorV3,
      setReviews
    ])(pinsIds);
  }, [pins]);

  const getOrderSummary = useCallback(async () => {
    const nowDate = new Date();
    const gteDate = new Date();
    gteDate.setMonth(nowDate.getUTCMonth() - 1);
    const createdAt = {
      lte: nowDate,
      gte: gteDate
    };
    const { data } = await api.api__get__api_vi_members_orderproducts_summary({
      created_at: createdAt
    });
    setOrderSummary(data.payload);
  }, []);

  useEffect(() => {
    getOrderSummary();
  }, [getOrderSummary]);

  useEffect(() => {
    api
      .api__v1_member_product_qustions_badge_get()
      .then(({ data: { payload } }) => (payload > 0 ? setHasNewQna(true) : setHasNewQna(false)));

    api
      .get__members__badges__order_product_contacts()
      .then(({ unread_count }) => (unread_count > 0 ? setHasNewContacts(true) : setHasNewContacts(false)));
  }, []);

  const authClickEventListner = useCallback(() => {
    const modalCallbackFunction: NumberAuthCallBackFunction = async ({ phoneNumber, marketingAgree }) => {
      const {
        data: { payload }
      } = await api.api__member_put_update({ phone_number: phoneNumber, confirm_marketing_sms: marketingAgree });

      dispatch(initialize({ member: payload }));
      dispatch(modalOff());
    };

    dispatch(modalCallbackFunctionSet(modalCallbackFunction));
    dispatch(modalOn(UI__MODAL_NUMBER_AUTH));
  }, [dispatch]);

  return (
    <main className={cx("member-tab")} role="main">
      <header className={cx(["bold"], "title")}>마이룩핀</header>

      <section className={cx("dashboard")}>
        <div className={cx("menu")}>
          <section className={cx("account")}>
            <div className={cx("info")}>
              <div className={cx("info-key-value")}>
                <span className={cx("info-key")}>아이디</span>
                <span className={cx("info-value")}>{email} 님</span>&nbsp;
              </div>
              <button type="button" className={cx("account-button")} onClick={() => history.push("/member/accounts")}>
                계정
              </button>
            </div>
            <div className={cx("info")}>
              <div className={cx("info-key-value")}>
                <span className={cx("info-key")}>핸드폰 번호</span>
                <span className={cx("info-value", { needAuth: !phoneNumber })}>
                  {phoneNumber ? toPhoneNumberString(phoneNumber) : "인증해주세요"}
                </span>
              </div>
              <button type="button" className={cx("account-button")} onClick={authClickEventListner}>
                {phoneNumber ? "재인증" : "인증"}
              </button>
            </div>
          </section>
          <ul>
            {isMobile ? (
              <>
                {[
                  ["level", "레벨", `Lv. ${level}`],
                  ["coupons", "쿠폰함", `${coupons.length}개`],
                  ["point", "적립금", `${point.toLocaleString()}원`],
                  ["qna", "상품 Q&A"],
                  ["contacts", "상품 1:1 문의"]
                  // ["review", "상품 리뷰"]
                ].map(([url, key, value]) => (
                  <Link to={`/member/${url}`} className={cx("link")} key={key}>
                    <li>
                      <div className={cx("list-key")}>
                        {key}

                        {key === "상품 Q&A" && (
                          <div className={cx("new-icon-qna")}>
                            {hasNewQna && <img src={badgeNew} className={cx("badgeNew-icon")} alt="newQna" />}
                          </div>
                        )}

                        {key === "상품 1:1 문의" && (
                          <div className={cx("new-icon-contacts")}>
                            {hasNewContacts && <img src={badgeNew} className={cx("badgeNew-icon")} alt="newQna" />}
                          </div>
                        )}
                      </div>
                      <div className={cx("list-right")}>
                        {value && <span className={cx("list-value")}>{value}</span>}
                        <span className={cx("chevron")}>
                          <IconChevronRight />
                        </span>
                      </div>
                    </li>
                  </Link>
                ))}
              </>
            ) : (
              <>
                <Link to="/member/level" className={cx("link")} key="레벨">
                  <li>
                    <div className={cx("list-key")}>레벨</div>
                    <div className={cx("list-right")}>
                      {level && <span className={cx("list-value")}>Lv. {level}</span>}
                      <span className={cx("chevron")}>
                        <IconChevronRight />
                      </span>
                    </div>
                  </li>
                </Link>
                <div className={cx("link-division-wrapper")}>
                  <Link to="/member/point" className={cx("link-division", "division-left")} key="적립금">
                    <li>
                      <div className={cx("list-key")}>적립금</div>
                      <div className={cx("list-right")}>
                        {point && <span className={cx("list-value")}>{`${point.toLocaleString()}원`}</span>}
                        <span className={cx("chevron", "division-chevron")}>
                          <IconChevronRight />
                        </span>
                      </div>
                    </li>
                  </Link>
                  <Link to="/member/coupons" className={cx("link-division")} key="쿠폰함">
                    <li>
                      <div className={cx("list-key", "division-right-key")}>쿠폰함</div>
                      <div className={cx("list-right")}>
                        {coupons && <span className={cx("list-value")}>{`${coupons.length}개`}</span>}
                        <span className={cx("chevron")}>
                          <IconChevronRight />
                        </span>
                      </div>
                    </li>
                  </Link>
                </div>
                <div className={cx("link-division-wrapper")}>
                  <Link to="/member/qna" className={cx("link-division", "division-left")} key="상품Q&A">
                    <li>
                      <div className={cx("list-key")}>상품 Q&A</div>
                      <div className={cx("new-icon-qna")}>{hasNewQna && <BadgeNew />}</div>
                      <div className={cx("list-right")}>
                        <span className={cx("chevron", "division-chevron")}>
                          <IconChevronRight />
                        </span>
                      </div>
                    </li>
                  </Link>
                  <Link to="/member/contacts" className={cx("link-division")} key="상품1:1문의">
                    <li>
                      <div className={cx("list-key", "division-right-key")}>상품 1:1 문의</div>
                      <div className={cx("new-icon-contacts")}>{hasNewContacts && <BadgeNew />}</div>
                      <div className={cx("list-right")}>
                        <span className={cx("chevron")}>
                          <IconChevronRight />
                        </span>
                      </div>
                    </li>
                  </Link>
                </div>
              </>
            )}
          </ul>
        </div>
        {!isMobile && <MyLookpinRecents />}
      </section>
      <section className={cx("order-section")}>
        {orderSummary && <OrderProductSummary orderSummary={orderSummary} />}
      </section>
      <section className={cx("cart", "bottom80")}>
        <header className={cx("header")}>
          <div>
            <span className={cx(["bold"], "sub-title")}>장바구니</span>
            <span className={cx("count")}>{cart.totalCount}개</span>
          </div>
          <MoreBox to="/member/cart" />
        </header>

        <ul className={cx("list")}>
          {pipe(
            always(carts),
            map(({ status, productId, productName, photoUrl, optionDesc, priceToBuyDesc }) => {
              const soldOut = status !== SALE;

              return (
                <Link
                  to={`/products/${productId}`}
                  className={cx("link", "button")}
                  style={{ pointerEvents: status === SOLDOUT_PRODUCT ? "none" : "auto" }}
                  key={uuidV4()}
                >
                  <li>
                    <div
                      className={cx("image", { "sold-out": soldOut })}
                      style={{
                        backgroundImage: `url(${imageResizeHelper(photoUrl, 60)})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                      }}
                    />

                    <div className={cx("info")}>
                      <div>
                        <span className={cx({ "sold-out": soldOut })}>{productName}</span>
                        {status === SOLDOUT_PRODUCT && (
                          <span className={cx("red")}>&nbsp;(품절된&nbsp;상품입니다.)</span>
                        )}
                      </div>

                      <div>
                        <span className={cx({ "sold-out": soldOut })}>{optionDesc}</span>
                        {status === SOLDOUT_OPTION && (
                          <span className={cx("red")}>&nbsp;(품절된&nbsp;옵션입니다.)</span>
                        )}
                      </div>
                    </div>

                    <div className={cx("price", { "sold-out": soldOut })}>{priceToBuyDesc}</div>
                  </li>
                </Link>
              );
            })
          )()}
        </ul>
      </section>

      <section className={cx("pin", "bottom80")}>
        <header className={cx("header")}>
          <div>
            <span className={cx(["bold"], "sub-title")}>핀</span>
            <span className={cx("count")}>{pins.totalCount}개</span>
          </div>
          <MoreBox to="/member/pins" />
        </header>

        <article>
          {pins.data.map((pin, i) => (
            <ProductCardWithoutPin product={pin} key={`${pin.id}-${i}`} review={reviews[i]} />
          ))}

          {map(
            i => (
              <div key={`pin-hidden-${i}`} className={cx("pin-card")} />
            ),
            times(identity, (isMobile ? 3 : 5) - (pins.data.length % (isMobile ? 3 : 5)))
          )}
        </article>
      </section>

      <section className={cx("coordi", "bottom80")}>
        <header className={cx("header")}>
          <div>
            <span className={cx(["bold"], "sub-title")}>코디 좋아요</span>
            <span className={cx("count")}>{coordi.totalCount}개</span>
          </div>
          <MoreBox to="/member/coordi" />
        </header>

        <article>
          {map(
            ({ id, photoUrl, title }) => (
              <Link to={`/coordi/${id}`} key={id} className={cx("link", "coordi-card")}>
                <picture>
                  <img src={imageResizeHelper(photoUrl, 180)} alt="coordi" />
                </picture>

                <ul>
                  <li className={cx("coordi-title")}>{title}</li>
                </ul>
              </Link>
            ),
            coordi.data
          )}

          {map(
            i => (
              <div key={`coordi-hidden-${i}`} className={cx("coordi-card")} />
            ),
            times(identity, (isMobile ? 2 : 5) - (coordi.data.length % (isMobile ? 2 : 5)))
          )}
        </article>
      </section>

      <section className={cx("bookmark", "bottom80")}>
        <header className={cx("header")}>
          <div>
            <span className={cx(["bold"], "sub-title")}>즐겨찾기</span>
            <span className={cx("count")}>{bookmarks.totalCount}개</span>
          </div>
          <MoreBox to="/member/bookmarks" />
        </header>

        <article>
          {pipe(
            take(lengths.bookmark[isMobile ? "mobile" : "desktop"]),
            map(({ id, name, photoUrl, newProductsCnt }) => (
              <Link
                to={{ pathname: `/stores/${id}` }}
                key={id}
                className={cx("link", "store")}
                style={{
                  backgroundImage: `url(${imageResizeHelper(photoUrl, 320)})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat"
                }}
              >
                <ul className={cx("modal-bg")}>
                  <li className={cx(["bold"], "name")}>{name}</li>
                  {newProductsCnt > 0 && (
                    <li className={cx("new-products")}>
                      신상품 <span>+{newProductsCnt}</span>
                    </li>
                  )}
                </ul>
              </Link>
            ))
          )(bookmarks.data)}
        </article>
      </section>
      <section>{isMobile && <MyLookpinRecents />}</section>
    </main>
  );
}
