import { SALE } from "constant";
import React from "react";
import classNames from "classnames/bind";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IStore } from "typings";
import { imageResizeHelper } from "utils";
import styles from "./MyLookpinRecents.module.scss";

const cx = classNames.bind(styles);

export const MyLookpinRecents = () => {
  const recents = useSelector<IStore, IStore["recents"]>(({ recents }) => recents);
  return (
    <div className={cx("recent")}>
      <div className={cx("sub-title")}>최근 본 상품</div>
      <div className={cx("images")}>
        {recents.map(({ productId, name, photoUrl, status }) => {
          const soldOut = status !== SALE;

          return (
            <button
              key={productId}
              type="button"
              style={{ pointerEvents: soldOut ? "none" : "auto" }}
              className={cx({ "sold-out": soldOut })}
              data-msg="품절상품"
            >
              <Link to={`/products/${productId}`} key={productId}>
                <picture>
                  <img src={imageResizeHelper(photoUrl, 120)} alt={name} />
                </picture>
              </Link>
            </button>
          );
        })}
      </div>
    </div>
  );
};
