import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import qs from "qs";
import { compose, concat, pipeWith, then, map, path, take, objOf, mergeDeepWith } from "ramda";
import * as api from "api";
import { camelizeKeys } from "humps";
import Loader from "react-loader-spinner";
import { useCoordi, useEvents } from "hooks";
import {
  newProductViewModelCreator,
  coordiViewModelCreator,
  reviewSummariesViewModelCreatorV3
} from "view-model-creator";
import { AirbridgeCustomEvent, AirbridgeEvent, imageResizeHelper } from "utils";
import { EventBanner, PopularSearchTermMobile, CoordiCard, ProductCard } from "components";
import {
  IStore,
  INewProductViewModel,
  ICoordiViewModel,
  StoreSerializedCamel,
  IReviewsSummariesViewModelV3
} from "typings";
import { NEWEST, TRENDING } from "constant";
import { coordi_tags } from "assets/remote_config/data.json";
import { IconChevronRight } from "assets/icon";
import classnames from "classnames/bind";
import styles from "./Home.module.scss";

const cx = classnames.bind(styles);

const lengths = {
  trending: {
    desktop: 10,
    mobile: 9
  },

  newest: {
    desktop: 10,
    mobile: 9
  }
};

type CoordiTable = {
  women: { [key: string]: ICoordiViewModel[] };
  men: { [key: string]: ICoordiViewModel[] };
};

export function Home() {
  const { isMobile, genderId } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);

  const [keywords, setKeywords] = useState<string[]>([]);

  const [rankingProducts, setRankingProducts] = useState<INewProductViewModel[]>([]);
  const [newestProducts, setNewestProducts] = useState<INewProductViewModel[]>([]);

  const [rankingProductsReviews, setRankingProductsReviews] = useState<IReviewsSummariesViewModelV3[]>([]);
  const [newestProductsreviews, setNewestProductsReviews] = useState<IReviewsSummariesViewModelV3[]>([]);
  const [shopingmalls, setShopingmalls] = useState<StoreSerializedCamel[]>([]);
  const [brands, setBrands] = useState<StoreSerializedCamel[]>([]);
  const [coordi, setCoordi] = useState<CoordiTable>({ women: {}, men: {} });

  const [tagState, setTagState] = useState("전체");
  const [isLoading, setIsLoading] = useState(true);

  const { getIsCoordiLike, toggleLike } = useCoordi();
  const { eventBanners, openEventPage } = useEvents("home_main_top");

  useEffect(() => {
    setTagState("전체");

    pipeWith(then)([api.get__api__v1__search__keywords, path(["data", "payload", "keywords"]), setKeywords])(genderId);

    async function setStateProducts(sort, setStateCallback) {
      const products = await pipeWith(then)([
        api.get__products__search,
        path(["data", "payload"]),
        newProductViewModelCreator
      ])({
        order: sort,
        genderId,
        page: {
          number: 1,
          size:
            sort === NEWEST
              ? lengths.newest[isMobile ? "mobile" : "desktop"]
              : lengths.trending[isMobile ? "mobile" : "desktop"]
        }
      });
      setStateCallback(products);
    }

    setStateProducts(TRENDING, setRankingProducts);
    setStateProducts(NEWEST, setNewestProducts);

    pipeWith(then)([api.get__stores, take(10), camelizeKeys, setShopingmalls])({
      page: 1,
      genderId
    });

    pipeWith(then)([api.get__stores__brands, take(10), camelizeKeys, setBrands])({
      page: 1,
      genderId
    });
  }, [genderId, isMobile]);

  useEffect(() => {
    const productsIds = rankingProducts.map(product => product.id);
    if (!productsIds.length) return;
    pipeWith(then)([
      api.get__products__id__reviews_summaries_v3,
      path(["data", "payload"]),
      reviewSummariesViewModelCreatorV3,
      setRankingProductsReviews
    ])(productsIds);
  }, [rankingProducts]);

  useEffect(() => {
    const productsIds = newestProducts.map(product => product.id);
    if (!productsIds.length) return;
    pipeWith(then)([
      api.get__products__id__reviews_summaries_v3,
      path(["data", "payload"]),
      reviewSummariesViewModelCreatorV3,
      setNewestProductsReviews
    ])(productsIds);
  }, [newestProducts]);

  useEffect(() => {
    if (coordi[genderId][tagState]) return;
    setIsLoading(true);
    if (tagState === "전체") {
      pipeWith(then)([
        api.get__coordi,
        coordiViewModelCreator,
        compose(objOf(genderId), objOf(tagState)),
        mergeDeepWith(concat, coordi),
        setCoordi,
        () => setIsLoading(false)
      ])({
        order: TRENDING,
        genderId,
        page: 1,
        per: 6
      });
    } else if (!coordi_tags.best[genderId].includes(tagState)) {
      setIsLoading(false);
    } else {
      pipeWith(then)([
        api.api__v1__coordi__search_get,
        path(["data", "payload"]),
        coordiViewModelCreator,
        compose(objOf(genderId), objOf(tagState)),
        mergeDeepWith(concat, coordi),
        setCoordi,
        () => setIsLoading(false)
      ])({
        order: TRENDING,
        genderId,
        page: {
          number: 1,
          size: 6
        },
        searchKeywords: [tagState]
      });
    }
  }, [genderId, tagState]); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    AirbridgeEvent.homeViewed();
    AirbridgeCustomEvent.mainScreen("home_home");
  }, []);

  const changeTagState = useCallback(tag => setTagState(tag), []);

  return (
    <main className={cx("main")}>
      <section className={cx("mobile")}>
        <EventBanner eventBanners={eventBanners} eventClickListner={openEventPage} />

        <PopularSearchTermMobile keywords={keywords} />
      </section>

      <section className={cx("desktop")}>
        <EventBanner eventBanners={eventBanners} eventClickListner={openEventPage} />

        <section className={cx("popular")}>
          <header className={cx("popular-header")}>인기검색어</header>

          <hr className={cx("popular-hr")} />

          <div className={cx("popular-flex")}>
            <ul className={cx("column-direction")}>
              {keywords.map((keyword, i) => (
                <Link
                  to={{
                    pathname: "/search/product",
                    search: qs.stringify({ keyword })
                  }}
                  key={keyword}
                >
                  <li className={cx("keyword")}>
                    <span className={cx("keyword-rank")}>{i + 1}.</span>&nbsp;
                    <span className={cx("keyword-text")}>{keyword}</span>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </section>
      </section>

      <div className={cx("title-box", "product-ranking")}>
        <span className={cx("title")}>상품랭킹</span>

        <Link to="/search/ranking" className={cx(["link"], "more-box")}>
          더보기
          <IconChevronRight />
        </Link>
      </div>

      <section className={cx("products")}>
        {rankingProducts.map((product, i) => (
          <ProductCard product={product} review={rankingProductsReviews[i]} key={product.id} />
        ))}
      </section>

      <div className={cx("title-box", "margin-top-68")}>
        <span className={cx("title")}>인기코디</span>

        <Link to="/coordi" className={cx(["link"], "more-box")}>
          더보기
          <IconChevronRight />
        </Link>
      </div>

      <div className={cx("tags")}>
        <button
          type="button"
          className={cx("tag", { active: tagState === "전체" })}
          onClick={() => changeTagState("전체")}
        >
          전체
        </button>

        {coordi_tags.best[genderId]?.map(tag => (
          <button
            onClick={() => changeTagState(tag)}
            key={tag}
            type="button"
            className={cx("tag", { active: tagState === tag })}
          >
            {tag}
          </button>
        ))}
      </div>

      <hr className={cx("tags-hr")} />

      <div className={cx("coordi", { loading: isLoading })}>
        {isLoading ? (
          <Loader type="Oval" color="#4285f4" height={100} width={100} visible />
        ) : (
          map(
            coordi => (
              <CoordiCard
                id={coordi.id}
                photoUrl={coordi.photoUrl}
                title={coordi.title}
                tags={coordi.tags}
                likeCounts={coordi.likeCounts}
                isLiked={getIsCoordiLike(coordi.id)}
                toggleLike={toggleLike}
                key={coordi.id}
              />
            ),
            coordi[genderId][tagState] || []
          )
        )}
      </div>

      <div className={cx("title-box", "margin-top-68")}>
        <span className={cx("title")}>쇼핑몰 순위</span>

        <Link to="/stores" className={cx(["link"], "more-box")}>
          더보기
          <IconChevronRight />
        </Link>
      </div>

      <div className={cx("stores")}>
        {shopingmalls.map((store, i) => (
          <Link to={`stores/${store.id}`} className={cx("store")} key={store.name}>
            <div className={cx("store-rank", { blue: i < 3 })}>{i + 1}위</div>
            <picture className={cx("store-picture")}>
              <img className={cx("store-img")} src={imageResizeHelper(store.photoUrl, 56)} alt="스토어" />
            </picture>

            <div className={cx("store-name")}>{store.name}</div>
          </Link>
        ))}
      </div>

      <div className={cx("title-box", "margin-top-68")}>
        <span className={cx("title")}>브랜드 순위</span>

        <Link to="/stores" className={cx(["link"], "more-box")}>
          더보기
          <IconChevronRight />
        </Link>
      </div>

      <div className={cx("stores")}>
        {brands.map((store, i) => (
          <Link to={`stores/${store.id}`} className={cx("store")} key={store.name}>
            <div className={cx("store-rank", { blue: i < 3 })}>{i + 1}위</div>
            <picture className={cx("store-picture")}>
              <img className={cx("store-img")} src={imageResizeHelper(store.photoUrl, 56)} alt="스토어" />
            </picture>
            <div className={cx("store-name")}>{store.name}</div>
          </Link>
        ))}
      </div>

      <div className={cx("title-box", "margin-top-68")}>
        <span className={cx("title")}>신상 업데이트</span>

        <Link to="/search/newest" className={cx(["link"], "more-box")}>
          더보기
          <IconChevronRight />
        </Link>
      </div>

      <section className={cx("products")}>
        {newestProducts.map((product, i) => (
          <ProductCard product={product} review={newestProductsreviews[i]} key={product.id} />
        ))}
      </section>
    </main>
  );
}
