import React, { Fragment, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { History } from "history";
import * as api from "api";
import { KeyValuePair, always, applyTo, forEach, fromPairs, ifElse, invoker, map, pipe, prop, sum, then } from "ramda";
import { modalOn } from "modules/ui";
import { createStoreGroup } from "view-model-creator";
import { IconArrowLeft } from "assets/icon";
import { NOTICE, UI__MODAL_APPLY_COUPON } from "constant";
import { CouponSelectHelper, IPartnerOptionViewModel, OptionId } from "typings";
import classNames from "classnames/bind";
import { applyCouponDataSet } from "actions/actionCreators";
import { produce } from "utils";
import styles from "./CouponSelect.module.scss";
import { getCouponSelectHelper } from "./couponSelectHelper";
const cx = classNames.bind(styles);

type CouponId = number;

interface IProps {
  history: History<{ partnerOptions: IPartnerOptionViewModel[] }>;
}

export const CouponSelect: FC<IProps> = ({ history }) => {
  const options = useMemo<IPartnerOptionViewModel[]>(() => {
    if (!history.location.state || !history.location.state.partnerOptions) return [];
    return history.location.state.partnerOptions;
  }, [history.location.state]);
  const dispatch = useDispatch();
  const [partnerOptions, setPartnerOptions] = useState<IPartnerOptionViewModel[]>(options);
  useEffect(() => {
    setPartnerOptions(options);
  }, [history, options]);

  const [findOption, setFindOption] = useState<{ [optionId: number]: IPartnerOptionViewModel }>(
    partnerOptions.reduce((acc, option) => {
      acc[option.optionId] = option;
      return acc;
    }, {})
  );
  const [findAppliedOptions, setFindAppliedOptions] = useState<{ [couponId: number]: OptionId[] }>({});

  const [{ findCoupon, findAppliableOptionIds, usableCouponIds, findPossibleCouponIds }, setHelper] = useState<
    CouponSelectHelper
  >({ findCoupon: {}, findAppliableOptionIds: {}, usableCouponIds: [], findPossibleCouponIds: new Map() });
  useEffect(() => {
    pipe(
      always(partnerOptions),
      ifElse(prop("length"), pipe(getCouponSelectHelper, then(setHelper)), always(undefined))
    )();
  }, [partnerOptions, partnerOptions.length]);

  const totalDiscountPrice = useMemo(
    pipe(
      always(partnerOptions),
      map(({ couponDiscountPrice }) => couponDiscountPrice || 0),
      sum,
      invoker(0, "toLocaleString")
    ),
    [partnerOptions]
  );

  const getOtherAppliedOptionIds: (optionIds: OptionId[]) => OptionId[] = useCallback(
    optionIds => {
      const [sample] = optionIds;
      const { couponId } = findOption[sample] as IPartnerOptionViewModel;

      if (!couponId) return [];

      return (findAppliedOptions[couponId] || []).filter(id => !optionIds.includes(id));
    },
    [findOption, findAppliedOptions]
  );

  const createFindAppliedOptions = useCallback(
    (partnerOptions: IPartnerOptionViewModel[]) => {
      setFindAppliedOptions(
        usableCouponIds.reduce((acc, couponId) => {
          acc[couponId] = partnerOptions.filter(option => option.couponId === couponId).map(({ optionId }) => optionId);
          return acc;
        }, {})
      );
    },
    [usableCouponIds]
  );

  const resetCoupon = useCallback(
    (optionIds: number[], options?: IPartnerOptionViewModel[]): IPartnerOptionViewModel[] =>
      produce(options || partnerOptions, draft => {
        draft
          .filter(({ optionId }) => optionIds.includes(optionId))
          .forEach(target => {
            target.couponId = null;
            target.couponDesc = null;
            target.couponDiscountPrice = null;
          });
      }) as any,
    [partnerOptions]
  );

  const sendToCouponApply = useCallback(
    (trigger: OptionId, couponId: CouponId): void => {
      dispatch(modalOn(UI__MODAL_APPLY_COUPON));

      dispatch(
        applyCouponDataSet({
          trigger,
          target: findAppliableOptionIds[couponId],
          options: partnerOptions,
          coupon: findCoupon[couponId],

          findAppliedOptions,
          findCoupon,
          findOption
        })
      );
    },
    [dispatch, findCoupon, findAppliableOptionIds, findAppliedOptions, findOption, partnerOptions]
  );

  const clearCoupon = useCallback(
    async (optionId: OptionId): Promise<void | IPartnerOptionViewModel[]> => {
      const { couponId } = findOption[optionId];

      if (!couponId) return partnerOptions;

      const type = findCoupon[couponId].coupon.usableProductOptionsCountType;

      if (type === "single") return resetCoupon([optionId]);

      const otherAppliedOptionIds = getOtherAppliedOptionIds([optionId]);

      if (otherAppliedOptionIds.length) {
        try {
          const {
            data: { payload }
          } = await api.get__members__issued_discount_coupons__id__calculate_discount_price(
            findOption[optionId].couponId as number,
            { productOptionIds: otherAppliedOptionIds.flatMap(id => Array(findOption[id].selectedCount).fill(id)) }
          );

          const discountPriceTable = payload.reduce((acc, { product_option_id, value }) => {
            acc[product_option_id] = value;
            return acc;
          }, {});

          return resetCoupon(
            [optionId],
            produce(partnerOptions, draft => {
              draft
                .filter(({ optionId }) => otherAppliedOptionIds.includes(optionId))
                .forEach(option => {
                  option.couponDiscountPrice = discountPriceTable[option.optionId];
                });
            }) as any
          );
        } catch (error) {
          if (window.confirm(NOTICE.RELEASABLE_COUPON(findCoupon[couponId].coupon.name))) {
            return resetCoupon(
              partnerOptions
                .filter(({ optionId }) => findAppliedOptions[couponId].includes(optionId))
                .map(prop("optionId"))
            );
          }
        }
      } else {
        return resetCoupon([optionId]);
      }
    },
    [findAppliedOptions, findCoupon, findOption, getOtherAppliedOptionIds, partnerOptions, resetCoupon]
  );

  const applySingleCoupon = useCallback(
    async (optionId: OptionId, couponId: CouponId): Promise<void> => {
      let options = await clearCoupon(optionId);

      if (options) {
        const { selectedCount } = findOption[optionId];

        const [id] = findAppliedOptions[couponId] || [0];

        api
          .get__members__issued_discount_coupons__id__calculate_discount_price(couponId, {
            productOptionIds: Array(selectedCount).fill(optionId)
          })
          .then(response => {
            const {
              data: {
                payload: [{ value }]
              }
            } = response;

            if (id) {
              if (window.confirm(NOTICE.BRING_COUPON))
                options = resetCoupon([id], options as IPartnerOptionViewModel[]);
              else return;
            }

            setPartnerOptions(
              produce(options as IPartnerOptionViewModel[], draft => {
                const target = draft.find(option => option.optionId === optionId) as IPartnerOptionViewModel;
                target.couponId = couponId;
                target.couponDesc = findCoupon[couponId].coupon.name;
                target.couponDiscountPrice = value;
              })
            );
          })
          .catch(error => alert(error.data ? error.data.message : error));
      }
    },
    [clearCoupon, findAppliedOptions, findCoupon, findOption, resetCoupon]
  );

  const handleSelect = useCallback(
    async ({
      target: {
        dataset: { optionId: optId },
        value
      }
    }: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
      const [optionId, couponId, appliedCouponId] = [optId, value, optId ? findOption[optId].couponId : 0].map(Number);
      const couponType = couponId && findCoupon[couponId].coupon.usableProductOptionsCountType;

      if (couponId === appliedCouponId) return;
      if (couponType === "multiple") sendToCouponApply(optionId, couponId);
      else if (couponType === "single") applySingleCoupon(optionId, couponId);
      else {
        const options = await clearCoupon(optionId);
        if (options) setPartnerOptions(options);
      }
    },
    [findOption, findCoupon, sendToCouponApply, applySingleCoupon, clearCoupon]
  );

  const sendToOrder = useCallback(() => {
    history.replace({
      pathname: "/order",
      state: { partnerOptions }
    });
  }, [history, partnerOptions]);

  useEffect(() => {
    forEach(applyTo(partnerOptions), [
      pipe(
        map(
          (option: IPartnerOptionViewModel) =>
            [option.optionId, option] as KeyValuePair<number, IPartnerOptionViewModel>
        ),
        fromPairs,
        setFindOption
      ),
      createFindAppliedOptions
    ]);
  }, [partnerOptions, createFindAppliedOptions]);

  if (!options || !findPossibleCouponIds || !findPossibleCouponIds.size) return null;

  return (
    <main className={cx("coupon-tab")}>
      <div className={cx("wrapper-head")}>
        <button type="button" onClick={history.goBack}>
          <span className={cx("back")}>
            <IconArrowLeft />
          </span>
          <span className={cx(["bold"], "head")}>쿠폰선택</span>
        </button>
      </div>

      <div className={cx("list-contents")}>
        {pipe(
          always(partnerOptions),
          createStoreGroup,
          map => [...map.entries()],
          map(([storeName, options]) => (
            <ul key={storeName} className={cx("list")}>
              <div className={cx("title")}>{storeName}</div>
              {options.map(
                ({
                  optionId,
                  photoUrl,
                  productName,
                  optionDesc,
                  price,
                  couponDiscountPrice,
                  selectedCount,
                  couponId
                }) => (
                  <Fragment key={optionId}>
                    <li key={optionId}>
                      <div className={cx("thumbnail")}>
                        <img src={photoUrl} alt="상품사진" />
                      </div>

                      <div className={cx("link", "info")}>
                        <div>
                          <span>{productName}</span>
                        </div>
                        <div>
                          <span>{optionDesc}</span>
                        </div>
                      </div>

                      <div className={cx("price")}>
                        <span className={cx({ prev: Boolean(couponDiscountPrice) })}>
                          {(price * selectedCount).toLocaleString()}원
                        </span>

                        {couponDiscountPrice ? (
                          <span>&nbsp;&nbsp;{(price * selectedCount - couponDiscountPrice).toLocaleString()}원</span>
                        ) : null}
                      </div>
                    </li>

                    <select required onChange={handleSelect} data-option-id={optionId} value={couponId || 0}>
                      <option disabled value="">
                        쿠폰&nbsp;적용
                      </option>

                      <option value="0">선택 안함</option>

                      {(findPossibleCouponIds.get(optionId) || []).map(({ couponId }) => (
                        <option key={couponId} value={couponId}>
                          {findCoupon[couponId].coupon.name}
                        </option>
                      ))}
                    </select>
                  </Fragment>
                )
              )}
            </ul>
          ))
        )()}
      </div>

      <button type="button" className={cx("btn-select")} onClick={sendToOrder}>
        <div>총&nbsp;{totalDiscountPrice}원&nbsp;할인받기</div>
      </button>
    </main>
  );
};
