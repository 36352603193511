import React, { FC, useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Range, getTrackBackground } from "react-range";

import classnames from "classnames/bind";

import { IStore } from "typings";

import { IconThinClose } from "assets/icon";

import share from "../SelectShare.module.scss";
import styles from "./RangePrice.module.scss";

const sx = classnames.bind(share);
const cx = classnames.bind(styles);

const STEP = 0.01;
const MIN = 0;
const MAX = 1;

interface IProps {
  handlePrice: (gte: number, lte: number) => void;
  handlePriceReset: () => void;
  gte: number;
  lte: number;
  maxPrice: number;
}

export const RangePrice: FC<IProps> = ({ handlePrice, handlePriceReset, gte, lte, maxPrice }) => {
  const isMobile = useSelector<IStore, IStore["ui"]["isMobile"]>(({ ui: { isMobile } }) => isMobile);

  const [isShow, setIsShow] = useState(false);
  const [prices, setPrices] = useState([0, 1]);

  const realMaxPrice = useMemo(() => (lte && lte > maxPrice ? lte : maxPrice), [lte, maxPrice]);

  const handleApply = () => {
    const [g, l] = prices.map(v => Math.ceil((v * realMaxPrice) / 1000) * 1000);

    handlePrice(g, l);
    setIsShow(false);
  };

  const handleReset = () => {
    handlePriceReset();
    setIsShow(false);
  };

  const handleClick = event => {
    if (isMobile) window.document.body.style.overflow = isShow ? "visible" : "hidden";

    const $box = document.querySelector("#price-box");

    if ($box && !$box.contains(event.target)) setIsShow(!isShow);
  };

  const handleClose = useCallback(() => setIsShow(false), []);

  useEffect(() => setPrices([Number.isNaN(gte / realMaxPrice) ? 0 : gte / realMaxPrice, 1]), [gte, lte, realMaxPrice]);

  return (
    <section className={[sx("select"), cx("price")].join(" ")} onClick={handleClick}>
      <span className={cx("text")}>가격</span>

      <div className={cx({ hidden: !isShow })}>
        <div className={cx("dimmed-layer")} />

        <div className={cx("box")} id="price-box">
          <header className={cx("header")}>
            <div className={cx("sub-title")}>가격 설정하기</div>
            <button type="button" className={cx("close")} onClick={handleClose}>
              <IconThinClose />
            </button>
          </header>

          <div className={cx("range-wrapper")}>
            <div className={cx("price-value")}>
              {prices.map(v => `${(Math.ceil((v * realMaxPrice) / 1000) * 1000).toLocaleString()}원`).join(" ~ ")}
            </div>

            <Range
              values={prices}
              step={STEP}
              min={MIN}
              max={MAX}
              onChange={setPrices}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={props.style}
                  className={cx("track-wrapper")}
                >
                  <div
                    ref={props.ref}
                    style={{
                      background: getTrackBackground({
                        values: prices,
                        colors: ["#ccc", "#548BF4", "#ccc"],
                        min: MIN,
                        max: MAX
                      })
                    }}
                    className={cx("track")}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props }) => <div {...props} style={props.style} className={cx("oval")} />}
            />
          </div>

          <footer className={cx("button-wrapper")}>
            <button className={cx("button", "reset")} type="button" onClick={handleReset}>
              초기화
            </button>
            <button className={cx("button", "apply")} type="button" onClick={handleApply}>
              적용
            </button>
          </footer>
        </div>
      </div>
    </section>
  );
};
