import React, { FC, useState } from "react";
import classNames from "classnames/bind";
import styles from "./ProductQnaCard.module.scss";
import { ProductQuestionType } from "typings";
import { QnaLevelIcon, StoreAnswer } from "components";
import { formatDateTime } from "utils";
import { LockIcon } from "assets/icon";

interface IProp {
  createdAt: string;
  typeOf: ProductQuestionType;
  contents: string;
  hidden: boolean;
  isMine: boolean;
  member: {
    id: number;
    level: number;
    email: string;
  };
  store: {
    id: number;
    name: string;
  };
  answer: {
    id: number;
    contents: string;
    updatedAt: string;
  } | null;
}
const cx = classNames.bind(styles);
export const ProductQnaCard: FC<IProp> = ({ createdAt, typeOf, contents, member, answer, store, hidden, isMine }) => {
  const [visibleAnswer, setVisibleAnswer] = useState<boolean>(false);
  return (
    <div className={cx("wrapper")}>
      <div className={cx("question-wrapper")}>
        <section className={cx("header")}>
          <div className={cx("type")}>
            {hidden && (
              <div className={cx("lock-icon-wrapper")}>
                <LockIcon />
              </div>
            )}
            <div className={cx("type-title")}>{typeOf} 문의</div>
          </div>
          <div className={cx("member")}>
            <QnaLevelIcon level={member.level} />
            <span className={cx("email")}>{member.email}</span>
          </div>
        </section>
        <div className={cx("body", { hidden: hidden && !isMine })}>{contents}</div>
        <section className={cx("footer")}>
          <div className={cx("answer-store")} onClick={() => setVisibleAnswer(visible => !visible)}>
            답변 {answer ? 1 : 0}
          </div>
          <div className={cx("created")}>
            {formatDateTime(createdAt, "date")} {formatDateTime(createdAt, "timeLocalStr")}
          </div>
        </section>
      </div>
      <div className={cx("answer-wrapper")}>
        {answer && <StoreAnswer visible={visibleAnswer} answer={answer} store={store} />}
      </div>
    </div>
  );
};
