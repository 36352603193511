import { IJSendSuccessResponse, IPage, IPageMeta, IProductQuestions, IWithMeta } from "typings";
import { fetcher } from "utils";

type ProductQuestionsGet = (payload: {
  page: IPage;
  productId: number;
  visible: boolean;
}) => Promise<IJSendSuccessResponse<IWithMeta<IProductQuestions[], IPageMeta>>>;
export const api__v1_product_questions_get: ProductQuestionsGet = payload => {
  return fetcher.get("/api/v1/product_questions", payload);
};
