/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from "react";
import classNames from "classnames/bind";
import Slider from "react-slick";
import { imageResizeHelper } from "utils";
import { IconPopUpClose, PopupLeftArrowIcon, PopupRightArrowIcon } from "assets/icon";
import { useScrollHidenEvent, useEvents } from "hooks";
import qs from "qs";
import { shallowEqual, useSelector } from "react-redux";
import { IStore } from "typings";

import { ConditionComponent } from "../ConditionComponent/ConditionComponent";

import styles from "./PopUpEventModal.module.scss";

const cx = classNames.bind(styles);
interface IProp {
  onClose?: () => void;
  onTodayNoSee?: () => void;
}
export const PopUpEventModal: FC<IProp> = ({ onClose, onTodayNoSee }) => {
  if(window.location.pathname === "/2021chuseok") return null;
  const { eventBanners, openEventPage } = useEvents("launch_popup");
  const { genderId } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui, shallowEqual);
  const WrapLeftArrowIcon = props => (
    <div className={cx("popup-prev-wrapper")} onClick={props.onClick}>
      <PopupLeftArrowIcon />
    </div>
  );
  const WrapRightArrowIcon = props => (
    <div className={cx("popup-next-wrapper")} onClick={props.onClick}>
      <PopupRightArrowIcon />
    </div>
  );
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => <div style={{ paddingBottom: "30px" }}>{dots}</div>,
    prevArrow: <WrapLeftArrowIcon />,
    nextArrow: <WrapRightArrowIcon />,
    style: {
      position: "relative"
    }
  };
  useScrollHidenEvent(eventBanners.length > 0);

  return (
    <ConditionComponent condition={eventBanners.length > 0} FallBackComponent={() => <></>}>
      <div className={cx("modal-wrapper")}>
        <div className={cx("wrapper")}>
          <div className={cx("event-wrapper")}>
            <div className={cx("header-wrapper")}>
              <div className={cx("close-wrapper")} onClick={onClose}>
                <IconPopUpClose />
              </div>
            </div>
            <div>
              <Slider {...settings}>
                {eventBanners.map(event => (
                  <a
                    href={`${event.eventUrl}?${qs.stringify({
                      platform: "web",
                      gender_id: genderId,
                      store_group_id: "default"
                    })}`}
                    key={event.eventUrl}
                    onClick={openEventPage}
                  >
                    <img className={cx("image")} src={imageResizeHelper(event.imageUrl)} alt={event.eventUrl} />
                  </a>
                ))}
              </Slider>
            </div>
            <div>
              <button className={cx("today-no-see")} type="button" onClick={onTodayNoSee}>
                오늘 하루 보지 않기
              </button>
            </div>
          </div>
        </div>
      </div>
    </ConditionComponent>
  );
};
