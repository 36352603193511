import React, { FC, MouseEvent, useCallback } from "react";

import classNames from "classnames/bind";

import { IDiscountCouponViewModel, IssaubleStatus, IIssuedDiscountCouponViewModel } from "typings";
import { IconDownload } from "assets/icon";
import styles from "./IssuableCoupon.module.scss";

const cx = classNames.bind(styles);

interface IISuableCoupon {
  discountCoupon: IDiscountCouponViewModel;
  issuableStatus: IssaubleStatus;
  issuedDiscountCoupon: IIssuedDiscountCouponViewModel;
  issueCoupon: (event: MouseEvent) => void;
}

export const IssuableCoupon: FC<IISuableCoupon> = ({
  discountCoupon: {
    couponId,
    name,
    formatDiscountValue,
    usablePriceLimitGte,
    formatUsablePriceLimitGte,
    formatDiscountPriceLimitLte,
    issuableDateLte
  },
  issuedDiscountCoupon,
  issuableStatus,
  issueCoupon
}) => {
  const issueButton = useCallback(() => {
    switch (issuableStatus) {
      case "already_issued":
        return (
          <div className={cx(["right"], "already-issued")}>
            <span>받기완료</span>
          </div>
        );
      case "issuable":
        return (
          <button type="button" className={cx(["right"], "download")} onClick={issueCoupon} data-coupon-id={couponId}>
            <IconDownload />
          </button>
        );
    }
  }, [couponId, issuableStatus, issueCoupon]);

  const couponInfo = useCallback(() => {
    switch (issuableStatus) {
      case "already_issued":
        return <li className={cx("usable-at")}>{issuedDiscountCoupon?.usableAtLimitDesc}</li>;
      case "issuable":
        return <li className={cx("issuable-date")}>{issuableDateLte}</li>;
      case "not_issuable":
        return <li className={cx("not-issuable")}>발급 가능한 쿠폰이 모두 발급되었습니다.</li>;
    }
  }, [issuableDateLte, issuableStatus, issuedDiscountCoupon]);

  return (
    <div className={cx(["ticket-container"], "discount-coupon")}>
      <ul>
        <li className={cx("name")}>{name}</li>

        <li className={cx(["bold"], "discount")}>{formatDiscountValue}</li>

        <li className={cx("desc")}>
          <div className={cx({ invisible: usablePriceLimitGte === 0 })}>{formatUsablePriceLimitGte}</div>

          <div>{formatDiscountPriceLimitLte}</div>
        </li>

        {couponInfo()}
      </ul>

      {issueButton()}
    </div>
  );
};
