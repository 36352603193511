import { IAuthenticationState } from "typings";

const AUTHENTICATION__SET = "AUTHENTICATION__SET";
const AUTHENTICATION__VERIFY__CLEAR = "AUTHENTICATION__VERIFY__CLEAR";

const initialState = {
  provider: null,
  providerToken: null,
  email: null
};

export default function authentication(state = initialState, { type, payload }): IAuthenticationState {
  switch (type) {
    case AUTHENTICATION__VERIFY__CLEAR:
      return initialState;
    case AUTHENTICATION__SET:
      return { ...state, ...payload };
    default:
      return state;
  }
}

export const setAuthentication = payload => ({ type: AUTHENTICATION__SET, payload });
export const clearAuthentication = () => ({ type: AUTHENTICATION__VERIFY__CLEAR });
