import { fetcher } from "utils";

import { DisplayType, GenderPartner, StoreSerialized } from "typings";

type GetStores = (params: {
  page?: number | string;
  displayType?: DisplayType;
  genderId: GenderPartner;
}) => Promise<StoreSerialized[]>;

export const get__stores: GetStores = params =>
  fetcher.get("/stores", {
    ...params,
    ...(!params?.displayType && { displayType: "lookpin_pay" })
  });
