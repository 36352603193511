import React, { FC, useMemo } from "react";
import { CloseQna } from "assets/icon";
import { useReviewWrite } from "hooks/useReviewWrite";
import { modalOff } from "modules/ui";
import { useDispatch, useSelector } from "react-redux";
import { IReviewWrite, IStore } from "typings";
import classNames from "classnames/bind";
import { ReviewWriteFooter, SimpleProductRow, UserInput, SatisfactionInput } from "components";

import { AddPhotoInput } from "components/presentation/review-write/AddPhotoInput";
import { ReviewWritePhoto } from "components/presentation/review-write/ReviewWritePhoto";
import { useSets } from "hooks";
import styles from "./NormalPhotoReview.module.scss";

interface IProp {
  callbackFunction?: (arg: any) => void;
}
const cx = classNames.bind(styles);
export const NormalPhotoReview: FC<IProp> = ({ callbackFunction }) => {
  const dispatch = useDispatch();
  const { id: reviewId, product, orderProductOrderNum } = useSelector<IStore, IStore["reviewWrite"]>(
    ({ reviewWrite }) => reviewWrite
  );

  const { getTargetMyWriteReview } = useSets();

  const targetReview = useMemo<IReviewWrite | undefined>(() => {
    if (reviewId !== undefined && reviewId !== null) {
      return getTargetMyWriteReview(reviewId);
    }
    return undefined;
  }, [getTargetMyWriteReview, reviewId]);

  const {
    userableHeightChange,
    userableWeightChange,
    userableInfo,
    satisfaction,
    setSatisfaction,
    content,
    setContent,
    addPhoto,
    photoDatas,
    deletePhoto,
    submitReview
  } = useReviewWrite(orderProductOrderNum, product?.productId, targetReview, callbackFunction);

  return (
    <div className={cx("wrapper")}>
      <header className={cx("header")}>
        <span className={cx("title")}>일반 / 포토 리뷰</span>
        <button type="button" className={cx("button")} onClick={() => dispatch(modalOff({ isClear: true }))}>
          <CloseQna />
        </button>
      </header>
      <section className={cx("product-wrapper")}>{product && <SimpleProductRow product={product} />}</section>
      <section className={cx("user-wrapper")}>
        <div className={cx("sub-title")}>내정보</div>
        <div className={cx("user-info-input-wrapper")}>
          <UserInput value={userableInfo.height} onChange={userableHeightChange} title="키" unit="cm" />
          <UserInput value={userableInfo.weight} onChange={userableWeightChange} title="몸무게" unit="kg" />
        </div>
        <div className={cx("description")}>
          입력하신 정보(키, 몸무게 등)는 다른 구매 고객들에게 참고용으로 <span>상품 상세페이지 &gt; 구매후기</span> 에
          노출될 수 있습니다.
        </div>
      </section>
      <section>
        <div className={cx("sub-title", "normal-review-margin")}>
          일반리뷰 <span>(적립금 +300원)</span>
        </div>
        <div className={cx("satisfaction-wrapper")}>
          <div className={cx("input-title")}>만족도</div>
          <SatisfactionInput satisfaction={satisfaction} setSatisfaction={setSatisfaction} />
        </div>
        <div className={cx("input-wrapper")}>
          <textarea
            className={cx("content-input")}
            value={content}
            onChange={e => setContent(e.target.value)}
            placeholder="상품에 대한 구매후기를 작성해주세요. (10자 이상)"
          />
        </div>

        <div className={cx("sub-title", "normal-review-margin")}>
          <div className={cx("photo-review-title")}>
            포토리뷰 <span>(적립금 +400원)</span>
          </div>
          <div className={cx("photo-input-wrapper")}>
            <AddPhotoInput onFileUpload={addPhoto} />
            {photoDatas.map(({ url }, index) => (
              <ReviewWritePhoto key={url} url={url} onClick={() => deletePhoto(index)} />
            ))}
          </div>
        </div>
      </section>
      <section className={cx("submit-wrapper")}>
        <button className={cx("submit-button")} type="button" onClick={submitReview}>
          완료
        </button>
      </section>
      <section>
        <ReviewWriteFooter />
      </section>
    </div>
  );
};
