import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams, Link, Redirect } from "react-router-dom";
import { camelizeKeys } from "humps";
import {
  get__members__order_product_contacts__new,
  post__members__order_product_contacts,
  get__api__v1__members__order_products__order_num
} from "api";
import { Loading, PreviousPageMobile } from "components";
import { OrderProductSerializedCamel } from "typings";
import classnames from "classnames/bind";
import styles from "./Contact.module.scss";

const cx = classnames.bind(styles);

export function Contact() {
  const history = useHistory();
  const { orderNum, orderProductOrderNum } = useParams() as any;
  const [orderProduct, setOrderProduct] = useState<OrderProductSerializedCamel>();
  const [types, setTypes] = useState<string[]>([]);
  const [type, setType] = useState("");
  const [contents, setContents] = useState("");

  const fetchOrderProduct = useCallback(async () => {
    try {
      const {
        data: { payload: target }
      } = await get__api__v1__members__order_products__order_num(orderProductOrderNum);

      if (target) setOrderProduct(camelizeKeys(target) as never);
    } catch (error) {
      if (error) history.goBack();
    }
  }, [history, orderProductOrderNum]);

  const handleType = event => setType(event.target.value);
  const handleContents = event => setContents(event.target.value);

  const fetchTypes = useCallback(async () => {
    try {
      const { typeof: types } = await get__members__order_product_contacts__new();

      setTypes(types);
    } catch (error) {
      if (error) history.goBack();
    }
  }, [history]);

  useEffect(() => {
    fetchOrderProduct();
    fetchTypes();
  }, [fetchOrderProduct, fetchTypes]);

  const handleCancel = useCallback(() => {
    if (window.confirm("취소하시겠습니까?")) history.goBack();
  }, [history]);

  const messageValidationCheck = useCallback((message: string) => message.length >= 10 && message.length <= 300, []);

  const handleSubmit = useCallback(async () => {
    if (messageValidationCheck(contents)) {
      const message = await post__members__order_product_contacts({
        orderProductOrderNum,
        typeof: type,
        contents
      });

      alert(message);

      history.replace(`/member/orders/${orderNum}/${orderProductOrderNum}`);
    } else {
      alert("내용은 10자 이상 300자 이내여야 합니다");
    }
  }, [history, orderNum, orderProductOrderNum, contents, type, messageValidationCheck]);

  if (!orderNum || !orderProductOrderNum) return <Redirect to="/" />;

  if (!orderProduct || !types.length) return <Loading />;

  return (
    <main className={cx("main")}>
      <header className={cx("header")}>
        <div className={cx("title")}>
          <PreviousPageMobile />
          문의하기
        </div>

        <div className={cx("info")}>
          <div className={cx("info-property")}>
            <span className={cx("property")}>업체명</span> {orderProduct.product.store.name}
          </div>

          <div className={cx("info-property")}>
            <span className={cx("property")}>상품명</span>
            <picture className={cx("info-product-picture")}>
              <img src={orderProduct.product.images.main[0]} className={cx("info-product-img")} alt="product" />
            </picture>{" "}
            {orderProduct.product.name}
          </div>

          <div className={cx("info-property")}>
            <span className={cx("property")}>옵션</span>{" "}
            {orderProduct.option.positions.length ? orderProduct.option.positions.join(", ") : "옵션없음"}
          </div>
        </div>
      </header>

      <div className={cx("type")}>
        <select className={cx("select")} onChange={handleType} value={type}>
          <option value="" key="default">
            문의유형을 선택해주세요.
          </option>

          {types.map(option => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div className={cx("area")}>
        <textarea
          value={contents}
          onChange={handleContents}
          className={cx("contents")}
          placeholder="문의사항을 입력해주세요. (10자 이상)"
        />
      </div>

      <div className={cx("notice")}>
        * 문의하신 내역은 판매자와 고객님만 확인가능하며,{" "}
        <Link to="/member/contacts" className={cx("contacts")}>
          MY룩핀&gt;상품문의내역
        </Link>
        에서 확인하실 수 있습니다.
      </div>

      <div className={cx("actions")}>
        <button onClick={handleCancel} className={cx("cancel")} type="button">
          취소
        </button>
        <button onClick={handleSubmit} className={cx("submit")} type="button">
          문의하기
        </button>
      </div>
    </main>
  );
}
