import { IJSendSuccessResponse } from "typings";
import { fetcher } from "utils";

type V1PinDelete = (param: {
  type: "all" | "include" | "exclude";
  ids: number[];
}) => Promise<IJSendSuccessResponse<{}>>;

export const api_v1__pin_delete: V1PinDelete = param => {
  return fetcher.deleteBody("/api/v1/userables/products/bulk_destroy", param);
};
