import { useState, useMemo, useCallback, useEffect, useRef } from "react";
import qs from "qs";
import * as api from "api";
import {
  IMemeberOrderViewModelReNewal,
  OrderDateAtType,
  IOrderProductSummary,
  IOrderProductNextStatusCamel,
  FilterGroupType
} from "typings";
import { useHistory } from "react-router-dom";
import { ordersViewModelCreatorRenewal } from "view-model-creator";
import { ORDER_PRODUCT_QUERY_KEYS, BUY_CONFIRM } from "constant";

interface QueryStringTypes {
  key: "monthDiff" | "page" | "sort" | "filterGroup";
  value?: string | number;
}
export const useProductOrders = (paramFilterGroup?: FilterGroupType) => {
  const PER_PAGE = 5;
  const history = useHistory();
  const pending = useRef(false);

  const queryObjects = useMemo<any>(() => qs.parse(history.location.search, { ignoreQueryPrefix: true }), [
    history.location.search
  ]);

  const monthDiff = useMemo(() => queryObjects.monthDiff || 1, [queryObjects]);
  const getGteDate = useCallback(
    (date: Date) => {
      const diffDate = new Date();
      if (parseInt(monthDiff, 10)) diffDate.setMonth(date.getUTCMonth() - monthDiff);
      return diffDate;
    },
    [monthDiff]
  );

  const getCreatedAt = useCallback(() => {
    const nowDate = new Date();
    return parseInt(monthDiff, 10)
      ? {
          lte: nowDate,
          gte: getGteDate(nowDate)
        }
      : undefined;
  }, [getGteDate, monthDiff]);

  const filterGroup = useMemo<string>(() => queryObjects.filterGroup || paramFilterGroup, [
    paramFilterGroup,
    queryObjects.filterGroup
  ]);
  const sort = useMemo<OrderDateAtType>(() => queryObjects.sort || "created_at", [queryObjects]);

  const [orders, setOrders] = useState<IMemeberOrderViewModelReNewal[]>([]);
  const [orderSummary, setOrderSummary] = useState<IOrderProductSummary>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  const orderSummaryFetch = useCallback(async createdAt => {
    const { data } = await api.api__get__api_vi_members_orderproducts_summary({
      created_at: createdAt
    });
    setOrderSummary(data.payload);
  }, []);

  const getOrderSummary = useCallback(() => {
    orderSummaryFetch(getCreatedAt());
  }, [orderSummaryFetch, getCreatedAt]);

  useEffect(getOrderSummary, [history, queryObjects]);

  const getMembersOrders = useCallback(
    pageNumber => () => {
      const param = {
        page: { number: pageNumber, size: PER_PAGE },
        order: sort,
        created_at: getCreatedAt(),
        filter_group: filterGroup,
        filter: undefined
      };
      setIsPaginationLoading(true);
      setIsLoading(true);
      api.get__api_v1_members_orderproducts(param as any).then(({ data }) => {
        const { payload, meta } = data;
        setOrders(ordersViewModelCreatorRenewal(payload));
        setTotalCount(meta.total_count);
        setIsLoading(false);
        setIsPaginationLoading(false);
      });
    },
    [getCreatedAt, filterGroup, sort]
  );

  const pageNumber = useMemo(() => {
    const { page } = queryObjects;
    return parseInt(page, 10) || 1;
  }, [queryObjects]);

  useEffect(getMembersOrders(pageNumber), [pageNumber, queryObjects]);

  const createSearchString = useCallback(
    ({ key, value }: QueryStringTypes) =>
      key === ORDER_PRODUCT_QUERY_KEYS.PAGE
        ? qs.stringify({ ...queryObjects, [key]: value })
        : qs.stringify({ ...queryObjects, [key]: value, page: 1 }),
    [queryObjects]
  );

  const pushPage = useCallback(
    ({ key, value }: QueryStringTypes) => {
      const search = createSearchString({ key, value });
      history.push({ search });
      window.scroll(0, 0);
    },
    [createSearchString, history]
  );

  const confirmMessage = useCallback(
    (status, otherMessage) => window.confirm(status === "confirm" ? BUY_CONFIRM : otherMessage),
    []
  );

  const memberStatusSetFetch = useCallback(
    async ({ productOrderNum, beforeStatus, status }) => {
      await api.put__members__order_products__order_num(productOrderNum as string, {
        beforeStatus,
        status
      });
      if (pageNumber === 1) {
        getMembersOrders(pageNumber)();
      } else {
        pushPage({
          key: "page",
          value: 1
        });
      }
    },
    [pushPage, pageNumber, getMembersOrders]
  );

  const actionClickEvent = useCallback(
    ({ orderNum, productOrderNum, beforeStatus }) => async ({
      actionType,
      status,
      extraInfo
    }: IOrderProductNextStatusCamel) => {
      if (!pending.current) {
        if (actionType === "dialog") {
          pending.current = true;
          if (confirmMessage(status, extraInfo.message))
            await memberStatusSetFetch({ productOrderNum, beforeStatus, status, extraInfo });
          pending.current = false;
        } else {
          history.push({
            pathname: `/member/orders/${orderNum}/${productOrderNum}/claim/${status}`
          });
        }
      }
    },
    [history, memberStatusSetFetch, confirmMessage]
  );

  return {
    isLoading,
    isPaginationLoading,
    orders,
    totalCount,
    pageNumber,
    orderSummary,
    monthDiff,
    pushPage,
    filterGroup,
    sort,
    actionClickEvent,
    getMembersOrders,
    PER_PAGE
  };
};
