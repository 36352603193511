/* eslint-disable react/no-danger */
import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./NoticeCardDescription.module.scss";

interface IProp {
  description: string;
}
const cx = classNames.bind(styles);

export const NoticeCardDescription: FC<IProp> = ({ description }) => {
  return <div className={cx("wrapper")} dangerouslySetInnerHTML={{ __html: description }} />;
};
