import { IJSendSuccessResponse, IPageMeta, IProductQuestions, IWithMeta, IQnAModel, MetaViewModel } from "typings";

type QnAMetaViewModelCreator = (
  payload: IJSendSuccessResponse<IWithMeta<IProductQuestions[], IPageMeta>>
) => MetaViewModel;
export const qnaMetaViewModelCreator: QnAMetaViewModelCreator = ({
  data: {
    meta: { current_page, total_count, prev_page, next_page, total_pages }
  }
}) => ({
  currentPage: current_page,
  totalCount: total_count,
  totalPages: total_pages,
  prevPage: prev_page,
  nextPage: next_page
});

type ProductQnaViewModelCreator = (
  payload: IJSendSuccessResponse<IWithMeta<IProductQuestions[], IPageMeta>>
) => IQnAModel[];
export const productQnaViewModelCreator: ProductQnaViewModelCreator = ({ data: { payload } }) => {
  return payload.map(
    ({ id, typeof: typeOf, created_at, my_unread, contents, member, store, answer, hidden, is_mine }) => {
      const answerViewModel = answer
        ? {
            id: answer.id,
            contents: answer.contents,
            updatedAt: answer.updated_at
          }
        : null;
      return {
        id,
        hidden,
        isMine: is_mine,
        typeOf,
        createdAt: created_at,
        myUnread: my_unread,
        contents,
        member,
        store,
        answer: answerViewModel
      };
    }
  );
};

type MyProductQnaViewModelCreator = (
  payload: IJSendSuccessResponse<IWithMeta<IProductQuestions[], IPageMeta>>
) => IQnAModel[];
export const myProductQnaViewModelCreator: MyProductQnaViewModelCreator = ({ data: { payload } }) =>
  payload.map(
    ({ id, typeof: typeOf, created_at, my_unread, contents, member, store, answer, product, hidden, is_mine }) => {
      const answerViewModel = answer
        ? {
            id: answer.id,
            contents: answer.contents,
            updatedAt: answer.updated_at
          }
        : null;
      const productViewModel = product && {
        id: product.id,
        name: product.name,
        photoUrl: product.photo_url
      };
      return {
        id,
        hidden,
        isMine: is_mine,
        typeOf,
        createdAt: created_at,
        myUnread: my_unread,
        contents,
        member,
        store,
        answer: answerViewModel,
        product: productViewModel
      };
    }
  );
