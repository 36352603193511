import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./QnaLevelIcon.module.scss";

const cx = classNames.bind(styles);
interface IProp {
  level: number;
}
export const QnaLevelIcon: FC<IProp> = ({ level }) => {
  return <div className={cx("iconWrapper", `lv${level}`)}>Lv.{level}</div>;
};
