import * as api from "api";
import { path, pipeWith, then } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IReviewsSummariesViewModelV3 } from "typings";
import { reviewSummariesViewModelCreatorV3 } from "view-model-creator";

export const useReviews = () => {
  const { productId } = useParams() as { productId: string };
  const [reviewSummary, setReviewSummary] = useState<IReviewsSummariesViewModelV3>();

  const initReviewFetch = useCallback(() => {
    if (productId) {
      pipeWith(then)([
        api.get__products__id__reviews_summaries_v3,
        path(["data", "payload"]),
        reviewSummariesViewModelCreatorV3,
        path([0]),
        setReviewSummary
      ])([parseInt(productId, 10)]);
    }
  }, [productId]);

  useEffect(initReviewFetch, [productId]);

  return {
    reviewSummary
  };
};
