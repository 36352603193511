import { omit, map } from "ramda";
import { camelizeKeys } from "humps";

import { ProductSerialized, IProductViewModel } from "typings";
import { productStatusParser } from "view-model-creator";

type ProductViewModelCreator = (products: ProductSerialized[]) => IProductViewModel[];

export const productViewModelCreator: ProductViewModelCreator = map(product => ({
  ...(camelizeKeys(omit(["created_at", "updated_at", "status_text", "ship_ing_speed"], product)) as any),
  status: productStatusParser(product.status, product.sold_out),
  priceDesc: `${product.price.toLocaleString()}원`
}));
