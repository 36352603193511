import { IJSendSuccessResponse, IDateRange, IOrderProductSummary } from "typings";
import { fetcher } from "utils";

type MemberOrderProductsSummaryFetcher = (params: {
  created_at?: IDateRange;
}) => Promise<
  IJSendSuccessResponse<{
    payload: IOrderProductSummary;
  }>
>;
export const api__get__api_vi_members_orderproducts_summary: MemberOrderProductsSummaryFetcher = params =>
  fetcher.get("/api/v1/members/order_products/summary", params);
