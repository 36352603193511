import React, { Dispatch, SetStateAction, FC, useCallback } from "react";
import classNames from "classnames/bind";
import { IconMinus, IconPlus } from "assets/icon";
import { IProductDetailViewModel } from "typings";

import styles from "./SingleCounter.module.scss";

const cx = classNames.bind(styles);

interface ISingleCounter {
  price: IProductDetailViewModel["price"];
  stockCount: IProductDetailViewModel["info"]["options"]["data"][0]["data"]["stockCount"];
  singleSelectedCount: number;
  setSingleSelectedCount: Dispatch<SetStateAction<number>>;
  isMobile?: boolean;
}

export const SingleCounter: FC<ISingleCounter> = ({
  price,
  stockCount,
  singleSelectedCount,
  setSingleSelectedCount,
  isMobile
}) => {
  const plusSingleSelectedCount = useCallback(() => {
    if (singleSelectedCount < stockCount) {
      setSingleSelectedCount(singleSelectedCount + 1);
    }
  }, [singleSelectedCount, stockCount, setSingleSelectedCount]);
  const minusSingleSelectedCount = useCallback(() => {
    if (singleSelectedCount > 1) setSingleSelectedCount(singleSelectedCount - 1);
  }, [singleSelectedCount, setSingleSelectedCount]);

  return (
    <div className={cx("count-single")}>
      <div className={cx("counter")}>
        <button type="button" className={cx("count-btn")} onClick={minusSingleSelectedCount}>
          <IconMinus />
        </button>
        <span className={cx("number")}>{singleSelectedCount}</span>
        <button type="button" className={cx("count-btn")} onClick={plusSingleSelectedCount}>
          <IconPlus />
        </button>
      </div>
      {!isMobile && <span className={cx("price")}>{(price * singleSelectedCount).toLocaleString()}원</span>}
    </div>
  );
};
