import { IBookmarkViewModel, IPageMeta, UsersStoreSerialized } from "typings";

export const bookmarksViewModelCreator = (stores: UsersStoreSerialized[]): IBookmarkViewModel[] =>
  stores.map(({ id, name, photo_url, new_products_cnt, message, serialized_tags, gender_id }) => ({
    id,
    name,
    photoUrl: photo_url,
    newProductsCnt: new_products_cnt,
    message,
    serializedTags: serialized_tags,
    genderId: gender_id
  }));

export const bookmarksMetaViewModelCreator = ({ total_count }: IPageMeta) => ({
  totalCount: total_count
});
