import React, { useState } from "react";
import classNames from "classnames/bind";
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import qs from "qs";

import { IStore } from "typings";

import styles from "./Feedback.module.scss";

const cx = classNames.bind(styles);

interface IFeedback {
  name: string;
  message: string;
}

export function Feedback() {
  const history = useHistory();
  const { email } = useSelector<IStore, IStore["member"]>(({ member }) => member, shallowEqual);
  const [feedback, setFeedback] = useState<IFeedback>({ name: email, message: "" });

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: qs.stringify({ "form-name": "contact", ...feedback })
    })
      .then(() => {
        alert("소중한 리뷰 감사합니다!");
        history.replace("/member");
      })
      .catch(error => console.error(error));

    e.preventDefault();
  };

  const handleChange = ({ target: { name, value } }) => setFeedback({ ...feedback, ...{ [name]: value } });

  return (
    <main className={cx("feedback-tab")}>
      <header>리뷰</header>
      <p className={cx("desc")}>안녕하세요, {email}님!</p>
      <p className={cx("desc")}>룩핀 웹 어떠셨나요? 사용후기를 들려주세요.</p>

      <form name="contact" method="post" data-netlify="true" onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="contact" />
        <p>
          <textarea name="message" onChange={handleChange} />
        </p>
        <p>
          <button type="submit">보내기</button>
        </p>
      </form>
    </main>
  );
}
