import React from "react";
import classNames from "classnames/bind";
import styles from "./Inspection.module.scss";

const cx = classNames.bind(styles);

export default function Inspection({ message }) {
  return (
    <div className={cx("wrapper")}>
      <img src="/lookpin_logo_168.png" alt="logo" />
      <h1 className={cx("inspection-title")}>룩핀 서버 점검 안내</h1>
      <div className={cx("description-wrapper")}>
        <div className={cx("description")}>서비스 이용에 불편을 드린 점 양해를 부탁 드립니다.</div>
      </div>
      <pre>{message}</pre>
      <div className={cx("ment")}>점검 이후에 더 나은 모습으로 찾아 뵙겠습니다.</div>
      <div className={cx("ment")}>감사합니다.</div>
    </div>
  );
}
