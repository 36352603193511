import { always, pipe, sort, map, reduce } from "ramda";

import { IGetProductCombinationCategories } from "typings";

type CategoriesViewModelCreator = (response: IGetProductCombinationCategories) => Map<string, string[]>;

export const categoriesViewModelCreator: CategoriesViewModelCreator = ({ data: { product_combination_categories } }) =>
  pipe(
    always(product_combination_categories),
    sort(({ id: idl }, { id: idr }) => idl - idr),
    map(({ name, product_main_category_name }) => [product_main_category_name, name]),
    reduce((acc, [main, sub]) => {
      if (acc.has(main)) acc.set(main, acc.get(main).concat(sub));
      else acc.set(main, [sub]);
      return acc;
    }, new Map())
  )();
