import airbridge from "airbridge-web-sdk-loader";

import {
  AIRBRIDGE_PROD_APP_ID,
  AIRBRIDGE_PROD_WEB_TOKEN,
  AIRBRIDGE_DEV_APP_ID,
  AIRBRIDGE_DEV_WEB_TOKEN
} from "constant";

interface MemberSetting {
  id: number;
  email: string;
  phoneNumber?: string;
}

class Airbridge {
  static init() {
    airbridge.init({
      app: process.env.REACT_APP_ENV === "production" ? AIRBRIDGE_PROD_APP_ID : AIRBRIDGE_DEV_APP_ID,
      webToken: process.env.REACT_APP_ENV === "production" ? AIRBRIDGE_PROD_WEB_TOKEN : AIRBRIDGE_DEV_WEB_TOKEN,
      userHash: true,
      utmParsing: true,
      urlQueryMapping: {
        channel: "utm_source",
        campaign: "utm_campaign",
        sub_id_1: "internal_code"
      },
      useProtectedAttributionWindow: true
    });
  }

  static memberSetting({ id, email, phoneNumber }: MemberSetting) {
    airbridge.setUserId(String(id));
    airbridge.setUserEmail(email);
    airbridge.setUserPhone(phoneNumber || "");
  }

  static setDeepLinks(buttonId: string, pathname: string = "/home/home") {
    airbridge.setDeeplinks({
      buttonID: buttonId,
      deeplinks: {
        ios: `lookpin://lookpin.co.kr${pathname}`,
        android: `lookpin://lookpin.co.kr${pathname}`
      },
      fallbacks: {
        ios: "itunes-appstore",
        android: "google-play"
      }
    });
  }

  static setDownloads(buttonId: string, callback: () => void) {
    airbridge.setDownloads({
      buttonID: buttonId
    });

    callback();
  }
}

export default Airbridge;
