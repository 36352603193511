import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames/bind";

import { modalOff, userPasswordChange } from "actions/actionCreators";

import { formChecker, useInputs } from "utils";

import {
  UI__FORM_FAILURE_PASSWORD_INVALID,
  UI__FORM_FAILURE_PASSWORD_CONFIRM_INVALID,
  UI__FORM_FAILURE_PASSWORD_NOT_EQUAL,
  UI__FORM_FAILURE_PASSWORD_NOT_CHANGE,
  UI__FORM_FAILURE_PASSWORD_CURRENT_NOT_CORRECT
} from "constant";

import styles from "../../ModalWrapper.module.scss";

const cx = classNames.bind(styles);

export function UpdatePassword() {
  const dispatch = useDispatch();
  const hasPassword = useSelector(({ member: { hasPassword } }) => hasPassword);
  const [state, onChange] = useInputs(
    hasPassword
      ? {
          password: "",
          passwordConfirm: "",
          passwordCurrent: ""
        }
      : {
          password: "",
          passwordConfirm: ""
        }
  );
  const [formFailure, setFormFailure] = useState({});
  const checker = formChecker(setFormFailure);
  const { password, passwordConfirm, passwordCurrent } = state;

  const nextPasswordRef = useRef();
  const prevPasswordRef = useRef();

  const handleSubmit = async event => {
    event.preventDefault();

    if (checker(state)) {
      try {
        await dispatch(userPasswordChange(password, hasPassword ? passwordCurrent : undefined));
        await dispatch(modalOff());
      } catch (e) {
        if (hasPassword && prevPasswordRef.current) prevPasswordRef.current.focus();
        else if (nextPasswordRef.current) nextPasswordRef.current.focus();
      }
    }
  };

  const action = hasPassword ? "변경" : "설정";

  return (
    <div className={cx("container")}>
      <div className={cx("header")}>
        비밀번호 {action}
        <button className={cx("close")} onClick={() => dispatch(modalOff())} type="button">
          {" "}
        </button>
      </div>
      <div className={cx("contents")}>
        <form onSubmit={handleSubmit}>
          {hasPassword && (
            <input
              placeholder="현재 비밀번호"
              type="password"
              name="passwordCurrent"
              value={passwordCurrent}
              onChange={onChange}
              ref={prevPasswordRef}
            />
          )}
          {formFailure.UI__FORM_FAILURE_PASSWORD_CURRENT_NOT_CORRECT && (
            <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_PASSWORD_CURRENT_NOT_CORRECT}</div>
          )}

          <input
            placeholder="신규 비밀번호 (8~20자 영문, 숫자 조합)"
            type="password"
            name="password"
            value={password}
            onChange={onChange}
            ref={nextPasswordRef}
          />
          {formFailure.UI__FORM_FAILURE_PASSWORD_INVALID && (
            <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_PASSWORD_INVALID}</div>
          )}
          {formFailure.UI__FORM_FAILURE_PASSWORD_NOT_CHANGE && (
            <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_PASSWORD_NOT_CHANGE}</div>
          )}

          <input
            placeholder="비밀번호 확인"
            type="password"
            name="passwordConfirm"
            value={passwordConfirm}
            onChange={onChange}
          />
          {formFailure.UI__FORM_FAILURE_PASSWORD_CONFIRM_INVALID && (
            <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_PASSWORD_CONFIRM_INVALID}</div>
          )}
          {formFailure.UI__FORM_FAILURE_PASSWORD_NOT_EQUAL && (
            <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_PASSWORD_NOT_EQUAL}</div>
          )}

          <div className={cx("btnWrapper")}>
            <button className={cx("cancel")} type="button" onClick={() => dispatch(modalOff())}>
              취소
            </button>
            <button className={cx("modify02")} type="submit">
              {action}하기
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
