import { modalOn } from "actions/actionCreators";
import { IconPin } from "assets/icon";
import { lookpinLogoText, silverCoin } from "assets/img";
import classnames from "classnames/bind";
import { Recents } from "components";
import { PRODUCT, STORE, UI__MODAL_LOGIN } from "constant";
import { useNavbar } from "hooks";
import { map } from "ramda";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import styles from "./NavbarDesktop.module.scss";

const cx = classnames.bind(styles);

export const NavbarDesktop: FC = () => {
  const {
    pathname,
    isShowRecents,
    target,
    keyword,
    token,
    cart,
    recent,
    level,
    point,
    nextLevelPercent,
    color,
    toggleRecents,
    targetOnProduct,
    targetOnStore,
    handleKeyword,
    handleFind,
    handleEnter,
    handleLogout,
    handleLogin,
    placeholder
  } = useNavbar();

  const dispatch = useDispatch();

  return (
    <>
      <header className={cx("header")}>
        <div className={cx("width-box")}>
          <div className={cx("flex-wrapper")}>
            <Link to="/" className={cx("logo-wrapper")}>
              <img src={lookpinLogoText} alt="logo" />
            </Link>

            <button
              type="button"
              className={cx("recents-wrapper")}
              onClick={toggleRecents}
              onMouseEnter={toggleRecents}
              onMouseLeave={toggleRecents}
            >
              <div className={cx("thumbnail-wrapper")}>
                {recent?.photoUrl ? (
                  <img className={cx("thumbnail")} src={recent.photoUrl} alt="최근 본 상품" />
                ) : (
                  <IconPin />
                )}
              </div>
              최근 본 상품
              {isShowRecents && <Recents />}
            </button>

            {token ? (
              <NavLink to="/member/cart" className={cx("cart-wrapper")} activeClassName={cx("active")} exact>
                장바구니
                {cart.totalCount > 0 && <div className={cx("cart-badge")}>{cart.totalCount}</div>}
              </NavLink>
            ) : (
              <button onClick={() => dispatch(modalOn(UI__MODAL_LOGIN))} className={cx("cart-wrapper")} type="button">
                장바구니
              </button>
            )}

            <div className={cx("division")} />

            {token ? (
              <NavLink to="/member" className={cx("member")} activeClassName={cx("active")} exact>
                마이룩핀
              </NavLink>
            ) : (
              <button onClick={() => dispatch(modalOn(UI__MODAL_LOGIN))} className={cx("member")} type="button">
                마이룩핀
              </button>
            )}

            <div className={cx("division")} />

            {token ? (
              <>
                <Link to={token ? "/member/level" : `/${pathname}?login=on`} className={cx("level-wrapper")}>
                  <i className={cx("level-bg")} style={{ backgroundColor: color }}>
                    <IconPin />
                  </i>

                  <div className={cx("level-data")}>
                    <div className={cx("border-base")}>
                      <span
                        className={cx("border-percent")}
                        style={{
                          backgroundColor: color,
                          width: `${nextLevelPercent || 1}%`
                        }}
                      />
                      <span className={cx("level")}>Lv.{level}</span>
                      <span className={cx("percent")}>{nextLevelPercent}%</span>
                    </div>
                  </div>
                </Link>

                <Link to={token ? "/member/point" : `/${pathname}?login=on`} className={cx("point-wrapper")}>
                  <img className={cx("point-icon")} src={silverCoin} alt="point" />
                  <div className={cx("point-text-wrapper")}>
                    <span className={cx("point-text")}>{point.toLocaleString()}</span>원
                  </div>
                </Link>

                <div className={cx("division")} />

                <button className={cx("sign")} onClick={handleLogout} type="button">
                  로그아웃
                </button>
              </>
            ) : (
              <button className={cx("sign")} onClick={handleLogin} type="button">
                로그인
              </button>
            )}
          </div>
        </div>
      </header>

      <nav className={cx("nav")}>
        <div className={cx("width-box")}>
          <div className={cx("flex-wrapper")}>
            <section className={cx("routes")}>
              {map(
                ([to, ko, exact]: [string, string, boolean]) => (
                  <NavLink to={to} key={to} className={cx("route")} activeClassName={cx("active")} exact={exact}>
                    {ko}
                  </NavLink>
                ),
                [
                  ["/search/ranking", "상품랭킹", false],
                  ["/search/newest", "신상품", false],
                  ["/coordi", "코디", true],
                  ["/stores", "스토어", true],
                  ["/banners", "이벤트", true]
                ]
              )}
            </section>

            <div className={cx("space-between")}>
              <section className={cx("search")}>
                <div className={cx("condition-wrapper")}>
                  <button
                    onClick={targetOnProduct}
                    className={cx("product", { active: target === PRODUCT })}
                    type="button"
                  >
                    상품
                  </button>
                  <button onClick={targetOnStore} className={cx("store", { active: target === STORE })} type="button">
                    스토어
                  </button>
                </div>

                <div className={cx("keyword-wrapper")}>
                  <input
                    onChange={handleKeyword}
                    onKeyPress={handleEnter}
                    value={keyword}
                    className={cx("keyword")}
                    placeholder={placeholder}
                    type="search"
                  />
                </div>

                <div className={cx("find-wrapper")}>
                  <button onClick={handleFind} className={cx("find")} type="button">
                    검색
                  </button>
                </div>
              </section>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
