export default function callPostcode(func: (zipcode: string, main: string) => void, focus?: () => void) {
  /* global daum */
  new daum.Postcode({
    // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분
    oncomplete({ userSelectedType, roadAddress, jibunAddress, bname, buildingName, zonecode: zipcode }) {
      // 각 주소의 노출 규칙에 따라 주소를 조합한다.
      // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.

      // 최종 주소 변수
      // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다. (R: 도로명, J: 지번)
      let main = userSelectedType === "R" ? roadAddress : jibunAddress;

      // 사용자가 선택한 주소가 도로명 타입일때 조합한다.
      if (userSelectedType === "R") {
        // 조합형 주소 변수
        let extra = "";

        // 법정동명이 있을 경우 추가한다.
        if (bname) extra = bname;

        // 건물명이 있을 경우 추가한다.
        if (buildingName) extra += `${extra && ", "}${buildingName}`;

        // 조합형주소의 유무에 따라 양쪽에 괄호를 추가하여 최종 주소를 만든다.
        main += extra && ` (${extra})`;
      }

      func(zipcode, main);

      if (focus) focus();
    }
  }).open();
}
