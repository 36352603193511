import React from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames/bind";
import qs from "qs";

import { post__help__new_store } from "api";

import { mapWithRedPointer } from "assets/img";

import styles from "./StoresNotExist.module.scss";

const cx = classnames.bind(styles);

export const StoresNotExist = () => {
  const { search } = useLocation();
  const { keyword } = qs.parse(search, { ignoreQueryPrefix: true });
  const request = async () => {
    await post__help__new_store(keyword as string);

    alert("추가 요청이 완료되었습니다.");
  };

  return (
    <div className={cx("wrapper")}>
      <img src={mapWithRedPointer} className={cx("asset")} alt="map" />

      <div className={cx("notice")}>검색한 스토어가 없습니다.</div>

      <div className={cx("advise")}>
        철자 및 띄어쓰기를 확인해주시거나,
        <br />
        스토어를 추가 요청해주세요.
      </div>

      <button onClick={request} type="button" className={cx("request")}>
        &apos;{keyword}&apos; 스토어 추가 요청하기
      </button>
    </div>
  );
};
