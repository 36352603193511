import * as Sentry from "@sentry/browser";
import { Dispatch } from "redux";
import { camelizeKeys } from "humps";
import { historyContext } from "contexts";
import { clearAuthentication, setAuthentication } from "modules/authentication";
import { modalCallbackFunctionSet, modalOff, modalOn } from "modules/ui";

import * as api from "api";

import {
  INITIALIZE,
  LOGOUT,
  UI__MODAL_ALREADY_ACCOUNT_LOGIN,
  UI__MODAL_FILL_EMAIL,
  UI__IS_LOADING,
  SYNC,
  INITIALIZE_SETS,
  PHOTO_REVIEW_FETCH_REQUEST,
  PHOTO_REVIEW_INIT_REQUSET,
  PHOTO_REVIEW_DETAIL_CHANGE_BY_ARROW,
  PHOTO_REVIEW_CLOSE_GRID_MODAL,
  PHOTO_REVIEW_OPEN_DETAIL_MODAL,
  PHOTO_REVIEW_CLOSE_DETAIL_MODAL,
  PHOTO_REVIEW_ONLY_CLOSE_DETAIL_MODAL,
  ADD_SETS_ITEM,
  REMOVE_SETS_ITEM,
  PHOTO_REVIEW_SET__PHOTO_REVIEW_TOTAL_COUNT,
  PHOTO_REVIEW_FETCH_SUCCESS,
  PHOTO_GRID_PAGE_SIZE,
  RECOMMENDING,
  UI__MODAL_NUMBER_AUTH,
  APPLY__COUPON_DATA_SET,
  CHANGE_SETS_ITEM,
  REVIEW_WRITE_DATA_SET
} from "constant";

import { pipeWith, then, path, map } from "ramda";
import { reviewModelCreatorFromOrderProduct, reviewModelCreatorFromCremaProduct } from "view-model-creator";
import { AirbridgeEvent, Tracking } from "utils";

import {
  IPostSocialAccountsFail,
  IPostV1SocialAccountsSuccess,
  Provider,
  IInitializeCase,
  IInitializeSetCase,
  ISyncCase,
  IPageCamel,
  IReviewViewModel,
  IStore,
  ISetsActionArgument,
  IBasePhotoReviews,
  NumberAuthCallBackFunction,
  IReviewWriteData
} from "typings";

export * from "modules/authentication";
export * from "modules/coupons";
export * from "modules/ui";

const serverIndependentErrorHandler = error => {
  const { headers, response } = error;

  if (!headers) {
    alert("에러가 발생하였습니다.");

    if (
      process.env.REACT_APP_ENV === "production" &&
      (response?.status ?? 500) !== 401 &&
      (!response.data || !response.data.message)
    ) {
      Sentry.captureException(`In serverIndependentErrorHandler:: ${error}`);
    }
  }

  return Promise.reject(error);
};

export const initialize = (payload: IInitializeCase) => ({ type: INITIALIZE, payload });

export const initializeSet = (payload: IInitializeSetCase) => ({ type: INITIALIZE_SETS, payload });

export const sync = (payload: ISyncCase) => ({ type: SYNC, payload });

// TODO: member 등으로 이동
export const logout = () => ({ type: LOGOUT });

export const userLogin = (email: string, password: string) => dispatch =>
  api
    .post__members__auth__sign_in({ email, password })
    .then(member => {
      dispatch({ type: UI__IS_LOADING });

      dispatch(modalOff({ isClear: true }));

      dispatch(initialize({ member }));

      Tracking.login(member.token);
      AirbridgeEvent.signin({ type: "email" });
    })
    .catch(() => {});

// UserSignUp

export const userSignUp = (
  email: string,
  password: string,
  phoneNumber: string,
  confirmMarketingSms: boolean
) => async (dispatch: Dispatch) => {
  try {
    const member = await api.post_members_auth({
      email,
      password,
      phoneNumber,
      passwordConfirmation: password,
      confirmMarketingSms
    });
    dispatch(modalOff({ isClear: true }));

    dispatch(initialize({ member }));

    Tracking.completeRegistration();
    AirbridgeEvent.signup({ type: "email" });
  } catch ({ data: { errors } }) {
    if ((errors as any)?.email) alert((errors as any).email);
  }
};

export const userPasswordChange = (password: string, current_password?: string) => (
  dispatch: Dispatch
): Promise<any> => {
  const params = {
    password,
    password_confirmation: password,
    ...(current_password && { current_password })
  };

  return api
    .patch__members__change_password(params)
    .then(({ data: { member } }) => dispatch(initialize({ member })))
    .catch(serverIndependentErrorHandler);
};

export const addCart = (optionIds: number[]) => (dispatch: Dispatch) =>
  api
    .post__members__carts(optionIds)
    .then(() =>
      api
        .get__api__v1__members__carts({ page: { number: 1, size: new Set(optionIds).size } })
        .then(({ data: { payload, meta } }) => {
          const cart: IPageCamel = camelizeKeys(meta) as any;
          dispatch(initialize({ cart }));

          const airbridgeAttrs = payload.reduce(
            (acc, item) => {
              acc.products.push({
                productId: item.product.id,
                name: item.product.name,
                price: item.product.price,
                quantity: item.option.selected_count,
                position: acc.cartPosition++,
                currency: "KRW"
              });
              acc.totalValue += item.option.selected_count * item.product.price;

              return acc;
            },
            {
              cartPosition: 1,
              products: [] as Record<string, number | string>[],
              totalValue: 0
            }
          );

          AirbridgeEvent.productAddedToCart({
            semanticAttributes: {
              products: airbridgeAttrs.products,
              totalValue: airbridgeAttrs.totalValue,
              currency: "KRW"
            }
          });

          alert("장바구니에 상품이 담겼습니다!");
        })
    )
    .catch(serverIndependentErrorHandler);

export const deleteCart = async (cartIds: number[]) => {
  await Promise.all(cartIds.map(api.delete__members__carts));

  return api.get__api__v1__members__carts({ page: { number: 1, size: 1 } }).then(({ data: { meta } }) => {
    const cart: IPageCamel = camelizeKeys(meta) as any;
    return initialize({ cart });
  });
};

export const connectSocialAccount = (provider: Provider, providerToken: string) => async (dispatch: Dispatch) => {
  const {
    data: { member }
  } = await api.post__members__social_accounts(provider, providerToken);

  dispatch(initialize({ member }));

  AirbridgeEvent.signin({ type: provider === "kakao" ? "social_kakao" : "social_naver" });
};

export const disconnectSocialAccount = (provider: Provider) => (dispatch: Dispatch) =>
  api.delete__members__social_accounts(provider).then(({ data: { member } }) => dispatch(initialize({ member })));

export const socialSignup = (
  provider: Provider,
  providerToken: string,
  email?: string,
  numberAuthCallback?: {
    phoneNumber: string;
    marketingAgree: boolean;
  }
) => async dispatch => {
  try {
    const { status, data } = await api.post__api_v1_social_accounts(
      provider,
      providerToken,
      email,
      numberAuthCallback?.phoneNumber,
      numberAuthCallback?.marketingAgree
    );
    const isSuccess = status === "success";

    if (isSuccess) {
      const {
        payload,
        meta: { is_already_registered }
      } = data as IPostV1SocialAccountsSuccess["data"];

      dispatch(clearAuthentication());
      dispatch(initialize({ member: payload }));

      Tracking.login(payload.token);
      AirbridgeEvent.signin({ type: provider === "kakao" ? "social_kakao" : "social_naver" });

      if (!is_already_registered) {
        Tracking.completeRegistration();
        AirbridgeEvent.signup({ type: provider === "kakao" ? "social_kakao" : "social_naver" });
      }

      dispatch(modalOff({ isClear: true }));
    } else {
      const {
        email,
        meta: { is_already_registered },
        verified_phone_number
      } = data as IPostSocialAccountsFail["data"];

      dispatch(setAuthentication({ provider, providerToken, ...(email && { email }) }));

      if (is_already_registered) {
        dispatch(modalOn(UI__MODAL_ALREADY_ACCOUNT_LOGIN));
        return isSuccess;
      }

      if (!email) {
        dispatch(modalOn(UI__MODAL_FILL_EMAIL));
        return isSuccess;
      }

      if (!verified_phone_number) {
        dispatch(modalOn(UI__MODAL_NUMBER_AUTH));
        const callbackFunction: NumberAuthCallBackFunction = async payload => {
          const isSuccess = await socialSignup(provider, providerToken, email as string, payload)(dispatch);

          if (isSuccess) historyContext.replace("/member");
        };
        dispatch(modalCallbackFunctionSet(callbackFunction));
        return isSuccess;
      }
      Sentry.captureException(new Error("socialSignup"));
    }

    return isSuccess;
  } catch ({ data }) {
    if ((data as any).message) {
      alert((data as any).message);
    } else {
      alert("에러가 발생했습니다.");
    }
    dispatch(modalOff({ isClear: true }));
    historyContext.replace("/");
  }
};

export const openPhotoDetailModal = (payload: {
  reviewDetail: IReviewViewModel;
  select: number;
  isOpenGrid?: boolean;
}) => dispatch => {
  dispatch({
    type: PHOTO_REVIEW_OPEN_DETAIL_MODAL,
    payload
  });
};

export const closeOnlyPhotoDetailModal = () => ({
  type: PHOTO_REVIEW_ONLY_CLOSE_DETAIL_MODAL
});

export const closePhotoDetailModal = () => ({
  type: PHOTO_REVIEW_CLOSE_DETAIL_MODAL
});

export const closePhotoGridModal = () => ({
  type: PHOTO_REVIEW_CLOSE_GRID_MODAL
});

export const addSetsItemAction = (payload: ISetsActionArgument) => {
  return {
    type: ADD_SETS_ITEM,
    payload
  };
};

export const removeSetsIteAction = (payload: ISetsActionArgument) => {
  return {
    type: REMOVE_SETS_ITEM,
    payload
  };
};

export const addReviewLikers = (payload: number) => async (dispatch: Dispatch) => {
  await api.post__userable_like__order_product_reviews({ id: payload });
  dispatch(addSetsItemAction({ key: "reviewLikes", value: payload }));
};

export const removeReviewLikers = (payload: number) => async (dispatch: Dispatch) => {
  await api.delete__userable_like__order_product_rivews({ id: payload });
  dispatch(removeSetsIteAction({ key: "reviewLikes", value: payload }));
};

export const photoReviewRequestDispatch = ({ productId, page, genderId }) => async dispatch => {
  const paramsObj = {
    productId,
    page: {
      number: page,
      size: PHOTO_GRID_PAGE_SIZE
    },
    sort: RECOMMENDING,
    genderId
  };

  const prePhotoReviewDataSet = ({ meta, base_photo_reviews }) => {
    dispatch({
      type: PHOTO_REVIEW_SET__PHOTO_REVIEW_TOTAL_COUNT,
      payload: meta.total_image_count
    });
    return base_photo_reviews;
  };

  const createReviewModel = type => {
    return type === "order_product_review" ? reviewModelCreatorFromOrderProduct : reviewModelCreatorFromCremaProduct;
  };

  const flatPhotoReviewByImages = (payload: IReviewViewModel[]) => {
    return payload.flatMap(review =>
      review.images.map(
        image =>
          ({
            ...review,
            images: [image]
          } as IReviewViewModel)
      )
    );
  };

  const concatPhotoReview = (payload: IReviewViewModel[]) => {
    dispatch({
      type: PHOTO_REVIEW_FETCH_SUCCESS,
      payload
    });
  };

  await pipeWith(then)([
    api.get__base_photo_reviews__search,
    path(["data"]),
    prePhotoReviewDataSet,
    map((model: IBasePhotoReviews) => createReviewModel(model.type)(model.data as any)),
    flatPhotoReviewByImages,
    concatPhotoReview
  ])(paramsObj);
};

export const getInitPhotoReviewRequest = (productId: number) => (dispatch, getState) => {
  const { ui, photoReview }: IStore = getState();
  const { isLoading } = photoReview;
  dispatch({ type: PHOTO_REVIEW_INIT_REQUSET, payload: productId });
  if (isLoading) {
    alert("새로고침 후 이용해 주시기 바랍니다");
  } else {
    dispatch(photoReviewRequestDispatch({ productId, page: 1, genderId: ui.genderId }));
  }
};

export const getMorePhotoReviewsRequest = () => async (dispatch, getState) => {
  const { photoReview, ui }: IStore = getState();
  const { isLoading, isMorePhotoReview, photoReviewPage, nowProductId } = photoReview;
  if (!isLoading && isMorePhotoReview) {
    dispatch({
      type: PHOTO_REVIEW_FETCH_REQUEST
    });
    await dispatch(
      photoReviewRequestDispatch({ productId: nowProductId, page: photoReviewPage, genderId: ui.genderId })
    );
  }
};

export const photoDetailChangeByArrow = (payload: number) => async (dispatch, getState) => {
  const { photoReview }: IStore = getState();
  const { selectedPhotoNumber, photoReviews } = photoReview;
  const nextIndex = selectedPhotoNumber + payload;
  if (!photoReviews[nextIndex]) {
    await dispatch(getMorePhotoReviewsRequest());
  }
  dispatch({
    type: PHOTO_REVIEW_DETAIL_CHANGE_BY_ARROW,
    payload
  });
};

export const applyCouponDataSet = payload => ({
  type: APPLY__COUPON_DATA_SET,
  payload
});

export const setsItemChangeAction = (payload: { key: keyof IStore["sets"]; value: any }) => ({
  type: CHANGE_SETS_ITEM,
  payload
});

export const setReviewWriteAction = (payload: IReviewWriteData) => ({
  type: REVIEW_WRITE_DATA_SET,
  payload
});
