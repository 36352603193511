import React, { FC, useCallback, useState, useMemo } from "react";
import classNames from "classnames/bind";
import { WhiteRecommendIcon, GrayRecommendIcon } from "assets/icon";
import { useReviewLikers } from "hooks/useReviewLikers";
import styles from "./RecommendButton.module.scss";

const cx = classNames.bind(styles);
interface IProp {
  reviewId: number;
  recommendCount: number;
}
export const RecommendButton: FC<IProp> = ({ reviewId, recommendCount }) => {
  const [likeCountState, setLikeCountState] = useState(recommendCount);
  const { getIsReviewLiked, toggleReviewLikes } = useReviewLikers();
  const isLiked = useMemo(() => {
    return getIsReviewLiked(reviewId);
  }, [getIsReviewLiked, reviewId]);

  const toggleRecommend = useCallback(
    (id: number) => {
      const diff = isLiked ? -1 : 1;
      toggleReviewLikes(id, () => setLikeCountState(likeCountState + diff));
    },
    [isLiked, likeCountState, toggleReviewLikes]
  );

  return (
    <button className={cx("buttonWrapper", { isLiked })} onClick={() => toggleRecommend(reviewId)}>
      {isLiked ? <WhiteRecommendIcon /> : <GrayRecommendIcon />}
      <span className={cx("recommend-text")}>추천 {likeCountState}</span>
    </button>
  );
};
