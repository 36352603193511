import React, { FC } from "react";
import classnames from "classnames/bind";

import { useCoordi } from "hooks";

import { IconThumb } from "assets/icon";

import styles from "./CoordiLikeButton.module.scss";

interface IProps {
  id: number;
  title: string;
  likeCounts: number;
}

const cx = classnames.bind(styles);

export const CoordiLikeButton: FC<IProps> = ({ id, title, likeCounts }) => {
  const { getIsCoordiLike, toggleLike } = useCoordi();
  const isLiked = getIsCoordiLike(id);

  return (
    <button
      onClick={toggleLike}
      data-id={id}
      data-title={title}
      className={cx("like-button", { active: isLiked })}
      type="button"
    >
      <i className={cx("thumb")}>
        <IconThumb />
      </i>
      좋아요&nbsp;{likeCounts + Number(isLiked)}
    </button>
  );
};
