import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames/bind";
import { map } from "ramda";

import { IStore } from "typings";

import styles from "./Recents.module.scss";

const cx = classnames.bind(styles);

export const Recents = () => {
  const recents = useSelector<IStore, IStore["recents"]>(({ recents }) => recents, shallowEqual);

  return (
    <section className={cx("recents")}>
      {map(
        ({ name, photoUrl, price, productId }) => (
          <Link to={`/products/${productId}`} className={cx("recent")} key={productId}>
            <div className={cx("product")}>
              <img className={cx("thumbnail")} src={photoUrl} alt="recent" />

              <span className={cx("product-name")}>{name}</span>
            </div>

            <div className={cx("price")}>{price.toLocaleString()}원</div>
          </Link>
        ),
        recents
      )}
    </section>
  );
};
