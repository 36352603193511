import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { storageContext } from "contexts";
import { Loading } from "components";

import getAccessTokenFromNaver from "services/naver";

import { connectSocialAccount, socialSignup } from "actions/actionCreators";

import { NAVER } from "constant";

import { IStore } from "typings";

export default function Naver() {
  const dispatch = useDispatch();
  const history = useHistory();

  const token = useSelector<IStore, IStore["member"]["token"]>(({ member: { token } }) => token);

  const link = useCallback(async () => {
    try {
      await dispatch(connectSocialAccount(NAVER, await getAccessTokenFromNaver()));

      alert("성공적으로 연동되었습니다!");
    } catch (error) {
      alert("연동이 실패하였습니다!");
    } finally {
      history.replace("/member/accounts");
    }
  }, [dispatch, history]);

  const signup = useCallback(async () => {
    const redirectPath = localStorage.getItem("lp_naver_redirectPath") || "/";

    try {
      const isSuccess = await socialSignup(NAVER, await getAccessTokenFromNaver())(dispatch);

      if (isSuccess) history.replace(redirectPath);
    } catch (error) {
      alert("인증 과정에서 문제가 발생했습니다!");
    }
    localStorage.removeItem("lp_naver_redirectPath");
  }, [dispatch, history]);

  useEffect(() => {
    if (!storageContext.token) signup();
    else if (!token) link();
  }, [link, signup, token]);

  return <Loading />;
}
