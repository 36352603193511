import { useCallback } from "react";
import { useSelector } from "react-redux";
import { IStore } from "typings";

export const useCallAction = () => {
  const { isMobile } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);
  const callButtonAction = useCallback(
    callNumber => () => {
      if (isMobile) {
        const anchor = document.createElement("a");
        anchor.href = `tel:${callNumber}`;
        anchor.click();
        anchor.remove();
      }
    },
    [isMobile]
  );
  return {
    callButtonAction
  };
};
