import React, { FC } from "react";
import classNames from "classnames/bind";
import { ReviewStars } from "components";
import { SATISFACTION } from "constant";
import styles from "./CountReviewSummary.module.scss";

const cx = classNames.bind(styles);
interface IProps {
  id: number;
  satisfaction: number;
  size: string | null;
  color: string | null;
  shipping: string | null;
}
export const CountReviewSummary: FC<IProps> = ({ id, satisfaction, size, color, shipping }) => {
  return (
    <section className={cx("summary-wrapper")}>
      <div className={cx("reviewStarWrapper")}>
        <ReviewStars reviewId={id} starRating={satisfaction} isSmall />
      </div>
      {size && (
        <div>
          <span className={cx("summary-text", SATISFACTION[size])}>사이즈 {size}</span>
        </div>
      )}
      {color && (
        <div>
          <span className={cx("summary-text", SATISFACTION[color])}>색상 {color}</span>
        </div>
      )}
      {shipping && (
        <div>
          <span className={cx("summary-text", SATISFACTION[shipping])}>배송 {shipping}</span>
        </div>
      )}
    </section>
  );
};
