import React from "react";
import classnames from "classnames/bind";

import { BOOKMARK } from "constant";

import styles from "./SelectShare.module.scss";

const cx = classnames.bind(styles);

export const SelectGroupWithBookmark = ({ group, handleGroup }) => (
  <select className={cx("select", "group")} onChange={handleGroup} value={group}>
    {[
      ["", "쇼핑몰/브랜드/즐겨찾기"],
      ["default", "쇼핑몰"],
      ["brand", "브랜드"],
      [BOOKMARK, "즐겨찾기"]
    ].map(([en, ko]) => (
      <option key={en} value={en}>
        {ko}
      </option>
    ))}
  </select>
);
