import * as api from "api";
import qs from "qs";
import { storageContext } from "contexts";
import { pipeWith, then } from "ramda";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { EventPosition, IEventBannerViewModel, IStore } from "typings";
import { eventBannerViewModelCreator } from "view-model-creator";
import { EVENT_POSITIONS } from "constant";

export const useEvents = (eventPosition: EventPosition) => {
  const [eventBanners, setEventBanners] = useState<IEventBannerViewModel[]>([]);
  const { genderId } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui, shallowEqual);
  const openEventPage = () => {
    storageContext.popUpCloseCancel();
  };

  useEffect(() => {
    pipeWith(then)([api.get__event_banners, eventBannerViewModelCreator, setEventBanners])({
      genderId: genderId || "men",
      storeGroupId: "default",
      position: EVENT_POSITIONS.includes(eventPosition) ? eventPosition : "home_main_top",
      page: {
        number: 1,
        size: 50
      }
    });
  }, [eventPosition, genderId]);

  return {
    eventBanners,
    openEventPage
  };
};
