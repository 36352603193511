import React, { useEffect } from "react";

import classnames from "classnames/bind";
import { PageTitle, SearchBar, StoreRowWithRank } from "components";

import { useBookmark, useStoreSearch } from "hooks";

import { useShoppingmalls } from "./useShoppingmalls";
import { useBrands } from "./useBrands";
import { AirbridgeCustomEvent } from "utils";

import styles from "./StoresDesktop.module.scss";

const cx = classnames.bind(styles);

/* eslint-disable react-hooks/exhaustive-deps */

export function StoresDesktop() {
  const { getIsBookmark } = useBookmark();
  const { keyword, changeKeyword, handleKeyword, handleEnter } = useStoreSearch();

  const { shoppingmalls } = useShoppingmalls();
  const { brands } = useBrands();

  useEffect(() => {
    AirbridgeCustomEvent.mainScreen("home_stores");
  }, []);

  return (
    <main>
      <PageTitle title="스토어" />

      <SearchBar
        keyword={keyword}
        changeKeyword={changeKeyword}
        handleKeyword={handleKeyword}
        handleEnter={handleEnter}
      />

      <section className={cx("stores")}>
        <div className={cx("shoppingmall")}>
          <div className={cx("title")}>쇼핑몰</div>

          <div>
            {shoppingmalls.map(({ id, name, photoUrl, group, rank, message, serializedTags, isNew, genderId }) => (
              <StoreRowWithRank
                key={id}
                id={id}
                name={name}
                photoUrl={photoUrl}
                group={group}
                rank={rank}
                message={message}
                serializedTags={serializedTags}
                isNew={isNew}
                isStar={getIsBookmark(id)}
                genderId={genderId}
              />
            ))}
          </div>
        </div>

        <div className={cx("brands")}>
          <div className={cx("title")}>브랜드</div>

          <div>
            {brands.map(({ id, name, photoUrl, group, rank, message, serializedTags, isNew, genderId }) => (
              <StoreRowWithRank
                key={id}
                id={id}
                name={name}
                photoUrl={photoUrl}
                group={group}
                rank={rank}
                message={message}
                serializedTags={serializedTags}
                isNew={isNew}
                isStar={getIsBookmark(id)}
                genderId={genderId}
              />
            ))}
          </div>
        </div>
      </section>
    </main>
  );
}
