import React, { FC, useCallback, useMemo } from "react";
import classNames from "classnames/bind";
import { IOrderProductReviewsCamel, IStore } from "typings";
import { formatDateTime, imageResizeHelper } from "utils";
import { NotFillRecommend } from "assets/icon";
import { useDispatch, useSelector } from "react-redux";
import { modalCallbackFunctionSet, modalOn } from "modules/ui";
import { UI__MODAL_DETAIL_PHOTO_REVIEW, UI__MODAL_REVIEW_REPLACE } from "constant";
import { openPhotoDetailModal, setReviewWriteAction } from "actions/actionCreators";
import { myReviewToPhotoDetailViewModelCreator } from "view-model-creator";
import { Link } from "react-router-dom";
import { CountReviewSummary } from "./CountReviewSummary";
import styles from "./MyReview.module.scss";

interface IProp {
  review: IOrderProductReviewsCamel;
  reviewSubmitCallback?: () => void;
}

const cx = classNames.bind(styles);

export const MyReview: FC<IProp> = ({ review, reviewSubmitCallback }) => {
  const dispatch = useDispatch();
  const optionStr = useMemo(() => review.orderProduct.oldProductOptions.positions.join(" / "), [review]);
  const { isMobile } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);
  const reviewNormalPhotoReviewClick = useCallback(() => {
    dispatch(modalOn(UI__MODAL_REVIEW_REPLACE));
    dispatch(
      setReviewWriteAction({
        id: review?.id,
        product: {
          productId: review.product.id,
          productTitle: review.product.name,
          productImage: review.product.photoUrl,
          storeName: review.product.store.name
        },
        orderProductOrderNum: review.orderProduct.orderNum
      })
    );
    if (reviewSubmitCallback) dispatch(modalCallbackFunctionSet(reviewSubmitCallback));
  }, [dispatch, reviewSubmitCallback, review]);

  const photoReviewDetailOpen = useCallback(
    (index: number) => () => {
      if (isMobile) {
        window.history.pushState(null, "", window.location.href);
      }
      dispatch(modalOn(UI__MODAL_DETAIL_PHOTO_REVIEW, { data: window.scrollY }));
      dispatch(
        openPhotoDetailModal({
          reviewDetail: myReviewToPhotoDetailViewModelCreator(review),
          select: index
        })
      );
    },

    [dispatch, isMobile, review]
  );
  return (
    <div className={cx("wrapper")}>
      <Link to={`/products/${review.product.id}`} className={cx("header", "section")}>
        <img className={cx("product-image")} src={review.product.photoUrl} alt={review.product.name} />
        <span className={cx("product-name")}>{review.product.name}</span>
      </Link>
      <div className={cx("section")}>
        <CountReviewSummary
          id={review.id}
          satisfaction={review.satisfaction}
          color={review.color}
          size={review.size}
          shipping={review.shipping}
        />
      </div>
      {optionStr !== "" && <div className={cx("product-option", "section")}>구매 옵션 : {optionStr}</div>}
      <div className={cx("body", "section")}>
        <pre className={cx("review-contents")}>{review.content}</pre>
      </div>
      <div className={cx("images-wrapper", "section")}>
        {review.photoUrls?.map((image, index) => (
          <picture key={image} className={cx("picture")} onClick={photoReviewDetailOpen(index)}>
            <img className={cx("review-images")} src={imageResizeHelper(image, 300)} alt={`${image}`} />
          </picture>
        ))}
      </div>
      <div className={cx("section")}>
        <button type="button" className={cx("change-button")} onClick={reviewNormalPhotoReviewClick}>
          수정하기
        </button>
      </div>
      <div className={cx("bottom-wrapper")}>
        <span className={cx("bottom-str")}>{formatDateTime(review.createdAt, "date")}</span>
        <span className={cx("bottom-str")}>
          <NotFillRecommend /> <span className={cx("margin-text")}>추천 {review.likeCount}</span>
        </span>
      </div>
    </div>
  );
};
