import { fetcher } from "utils";

import { IDiscountCouponPrice, IJSendSuccessResponse } from "typings";

export const get__members__issued_discount_coupons__id__calculate_discount_price = (
  id: number,
  params: {
    productOptionIds: number[];
  }
): Promise<IJSendSuccessResponse<{ issued_discount_coupon_id: number; payload: IDiscountCouponPrice[] }>> =>
  fetcher.get(`/members/issued_discount_coupons/${id}/calculate_discount_price`, params, true);
