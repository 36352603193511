import { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";

import { storageContext } from "contexts";

import { modalOn, logout, sync } from "actions/actionCreators";

import { UI__MODAL_LOGIN, PRODUCT, STORE } from "constant";

import { IStore } from "typings";
import { Tracking } from "utils";

export const useNavbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, search } = useLocation();

  const [isShowRecents, setIsShowRecent] = useState(false);
  const [isShowCategories, setIsShowCategories] = useState(false);
  const [target, setTarget] = useState<typeof PRODUCT | typeof STORE>(PRODUCT);
  const [keyword, setKeyword] = useState("");

  const { keyword: q } = qs.parse(search, { ignoreQueryPrefix: true });

  const token = useSelector<IStore, IStore["member"]["token"]>(({ member: { token } }) => token);
  const recent = useSelector<IStore, IStore["recents"]>(({ recents }) => recents)[0];
  const cart = useSelector<IStore, IStore["cart"]>(({ cart }) => cart);
  const { level, point, nextLevelPercent, color } = useSelector<IStore, IStore["orderPoints"]>(
    ({ orderPoints }) => orderPoints
  );

  const toggleRecents = useCallback(() => {
    if (isShowCategories) setIsShowCategories(false);
    setIsShowRecent(!isShowRecents);
    setIsShowCategories(false);
  }, [isShowCategories, isShowRecents]);
  const toggleCategories = useCallback(() => {
    if (isShowRecents) setIsShowRecent(false);
    setIsShowCategories(!isShowCategories);
  }, [isShowCategories, isShowRecents]);
  const targetOnProduct = useCallback(() => {
    setTarget(PRODUCT);
  }, []);
  const targetOnStore = useCallback(() => {
    setTarget(STORE);
  }, []);
  const handleKeyword = useCallback(
    event => event.target.value.length <= 50 && setKeyword(event?.target?.value ?? ""),
    []
  );
  const handleFind = useCallback(() => {
    switch (target) {
      case PRODUCT:
        history.push({
          pathname: `/search/product`,
          search: qs.stringify({ ...(keyword && { keyword }) })
        });
        break;
      case STORE:
        if (keyword) {
          history.push({
            pathname: `/search/store`,
            search: qs.stringify({ keyword })
          });
        } else {
          history.push({
            pathname: `/stores`
          });
        }
        break;
    }

    window.scrollTo(0, 0);
  }, [history, keyword, target]);
  const handleEnter = useCallback(
    event => {
      if (event.key === "Enter") handleFind();
    },
    [handleFind]
  );
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);
  const handleLogin = useCallback(() => dispatch(modalOn(UI__MODAL_LOGIN)), [dispatch]);

  const placeholder = useMemo(() => {
    switch (target) {
      case PRODUCT:
        return "어떤 상품을 찾으시나요?";
      case STORE:
        return "어떤 스토어를 찾으시나요?";
      default:
        return "";
    }
  }, [target]);

  useEffect(() => {
    if (!token) dispatch(sync({ recents: storageContext.recents }));
  }, [dispatch, token]);

  useEffect(() => {
    setIsShowRecent(false);
    setIsShowCategories(false);
  }, [pathname, token]);

  useEffect(() => {
    if (pathname === "/search/product") {
      setTarget(PRODUCT);
      setKeyword((q as string) ?? "");
    } else if (pathname === "/search/store") {
      setTarget(STORE);
      setKeyword((q as string) ?? "");
    }
  }, [pathname, q]);

  useEffect(() => {
    if (!q) return;
    if (process.env.REACT_APP_ENV === "production") {
      Tracking.search(q);
    } else {
      Tracking.search(q);
      console.warn("search", "useNavbar");
      console.warn({ keyword: q });
    }
  }, [q]);

  history.listen((location, action) => {
    if ((action === "PUSH" && keyword && !qs.parse(location.search, { ignoreQueryPrefix: true })?.keyword) ?? "") {
      setKeyword("");
    }
  });

  return {
    pathname,
    isShowRecents,
    isShowCategories,
    target,
    keyword,
    token,
    cart,
    recent,
    level,
    point,
    nextLevelPercent,
    color,
    toggleRecents,
    toggleCategories,
    targetOnProduct,
    targetOnStore,
    handleKeyword,
    handleFind,
    handleEnter,
    handleLogout,
    handleLogin,
    placeholder
  };
};
