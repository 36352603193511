import React, { Component } from "react";
import { string } from "prop-types";

class StatusIcon extends Component {
  render() {
    const { statusValue } = this.props;
    const iconStyle = {
      display: "inline-block",
      verticalAlign: "text-top",
      lineHeight: "27px",
      marginRight: "5px",
      paddingLeft: "8px"
    };

    return (
      <div style={iconStyle}>
        {statusValue.match(/claim|refund|change|cancel/) && (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
            <path
              fill="#D91A1D"
              fillRule="evenodd"
              d="M4.584 12.792h-.459A1.623 1.623 0 0 1 2.5 11.167V9.542C2.5 5.655 5.413 2.5 9 2.5s6.5 3.155 6.5 7.042v1.625a1.623 1.623 0 0 1-1.625 1.625h-.46C11.96 15.302 9.677 15.5 9 15.5c-.676 0-2.959-.199-4.416-2.708zm9.059-3.404c-.349.138-.737-.105-.737-.465a5.33 5.33 0 0 0-.03-.535c-1.022-.148-2.1-1.124-2.147-2.174C9.966 7.385 8.103 8.65 6.64 8.65c.63-.408 1.067-1.619 1.104-2.048-.337.638-1.682 1.831-2.64 2.036l-.011.286c0 .361-.389.602-.737.465v2.202c.167.15.367.271.604.347.13.042.24.131.303.248C6.544 14.547 8.413 14.57 9 14.57c.54 0 2.158-.02 3.41-1.85-.62.253-1.314.353-2.18.381-.216.35-.686.593-1.23.593-.748 0-1.354-.457-1.354-1.021 0-.565.606-1.022 1.354-1.022.541 0 1.009.24 1.226.586 1.102-.015 2.516-.195 3.417-1.544V9.388zm-6.268-.117c.372 0 .673.364.673.812 0 .449-.301.813-.673.813-.372 0-.673-.364-.673-.813 0-.448.301-.812.673-.812zm3.923.812c0 .449-.301.813-.673.813-.372 0-.673-.364-.673-.813 0-.448.301-.812.673-.812.372 0 .673.364.673.812zm2.505-2.012h.768A5.673 5.673 0 0 0 9 3.43a5.673 5.673 0 0 0-5.571 4.64h.768C4.492 5.996 6.13 4.088 9 4.088s4.508 1.908 4.803 3.983z"
            />
          </svg>
        )}
        {statusValue === "ship_ing" && (
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18" height="18">
            <defs>
              <circle id="a" cx="11.6" cy="7.3" r="1.8" />
              <circle id="b" cx="3.2" cy="7.3" r="1.8" />
            </defs>
            <g fill="none" fillRule="evenodd">
              <path stroke="#495057" strokeWidth=".6" d="M.6 13.175a.3.3 0 1 1 0 .6.3.3 0 0 1 0-.6z" />
              <path stroke="#495057" strokeLinecap="round" strokeWidth=".6" d="M16.65 13.55H12m-1.733 0H2.4" />
              <path stroke="#30D2F2" strokeWidth="3" d="M13 10.3H2.55" opacity=".9" />
              <path
                stroke="#495057"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".8"
                d="M13.067 9.773V6.588A2.088 2.088 0 0 0 10.979 4.5H4.488A2.088 2.088 0 0 0 2.4 6.588v5.398l10.667.112V9.773z"
              />
              <g transform="matrix(-1 0 0 1 17 4.5)">
                <use fill="#FFF" xlinkHref="#a" />
                <circle cx="11.6" cy="7.3" r="1.4" stroke="#495057" strokeWidth=".8" />
              </g>
              <path
                stroke="#495057"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".8"
                d="M16.06 11.649h.784V9.128c0-.42-.046-.996-.139-1.731a1.8 1.8 0 0 0-1.713-1.575L13.2 5.75v5.899h2.86z"
              />
              <g transform="matrix(-1 0 0 1 17 4.5)">
                <use fill="#FFF" xlinkHref="#b" />
                <circle cx="3.2" cy="7.3" r="1.4" stroke="#495057" strokeWidth=".8" />
              </g>
            </g>
          </svg>
        )}
        {statusValue === "ship_wait" && (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
            <g fill="none" fillRule="evenodd">
              <path
                fill="#FFF"
                stroke="#495057"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".8"
                d="M15 4H5.25L2 7.505l4.875.088 4.875.074zm0 0l-3.25 3.721v7.28L15 11.442z"
              />
              <path stroke="#FF8066" strokeWidth="3" d="M6.845 14.8V7.667L9.671 4.42l1.389-1.389" opacity=".9" />
              <path fill="#FFF" d="M7 1h7v3H7z" />
              <path
                stroke="#495057"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth=".8"
                d="M11.75 7.667H2V15h9.75zM15 4H5.25L2 7.505l9.75.005z"
              />
            </g>
          </svg>
        )}
        {["ship_done", "confirm"].includes(statusValue) && (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
            <g fill="none" fillRule="evenodd">
              <path stroke="#82D982" strokeWidth="4" d="M2 10h13.519" opacity=".9" />
              <path
                stroke="#495057"
                d="M12.052 3.54L5.889 3.5l-1.52.46A2.63 2.63 0 0 0 2.5 6.476v6.398c0 1.105.894 2.001 1.999 2.004l8.936.026h.006a2.004 2.004 0 0 0 2.004-2.004V6.518A2.63 2.63 0 0 0 13.575 4l-1.523-.46z"
              />
              <path
                stroke="#495057"
                d="M5.5 3.8c.053.697.676 2.097 1.763 2.646 1.018.514 2.575.494 3.507 0 .99-.526 1.614-1.51 1.73-2.646"
              />
              <path fill="#495057" d="M8.283 5.5h1.43a.5.5 0 1 0 0-1h-1.43a.5.5 0 1 0 0 1z" />
            </g>
          </svg>
        )}
        {statusValue === "pay_success" && (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
            <g fill="none" fillRule="evenodd" transform="translate(1.5 1.5)">
              <path
                stroke="#495057"
                strokeDasharray="39.285"
                strokeLinecap="round"
                strokeWidth="1.1"
                d="M7.32.414c3.813 0 6.904 3.135 6.904 7.002s-3.09 7.002-6.904 7.002c-3.812 0-6.903-3.135-6.903-7.002S3.507.414 7.32.414z"
              />
              <ellipse cx="5.518" cy=".621" fill="#495057" rx="1" ry="1" />
              <circle cx="7.3" cy="7.5" r="5" fill="#F8E71C" fillOpacity=".8" stroke="#495057" />
              <text
                fill="#495057"
                fontFamily="AppleSDGothicNeo-ExtraBold, Apple SD Gothic Neo"
                fontSize="7"
                fontWeight="600"
              >
                <tspan x="4.473" y="10.5">
                  ￦
                </tspan>
              </text>
            </g>
          </svg>
        )}
        {statusValue === "pay_wait" && (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
            <g fill="none" fillRule="evenodd" transform="translate(1.5 1.5)">
              <path
                stroke="#495057"
                strokeDasharray="39.285"
                strokeLinecap="round"
                strokeWidth="1.1"
                d="M7.32.414c3.813 0 6.904 3.135 6.904 7.002s-3.09 7.002-6.904 7.002c-3.812 0-6.903-3.135-6.903-7.002S3.507.414 7.32.414z"
              />
              <ellipse cx="5.518" cy=".621" fill="#495057" rx="1" ry="1" />
              <circle cx="7.3" cy="7.5" r="5" fill="#C7CDD4" stroke="#495057" />
              <text
                fill="#495057"
                fontFamily="AppleSDGothicNeo-ExtraBold, Apple SD Gothic Neo"
                fontSize="7"
                fontWeight="600"
              >
                <tspan x="4.473" y="10.5">
                  ￦
                </tspan>
              </text>
            </g>
          </svg>
        )}
      </div>
    );
  }
}

StatusIcon.propTypes = {
  statusValue: string.isRequired
};

export default StatusIcon;
