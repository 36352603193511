import { LookpinLogo } from "assets/icon";
import classNames from "classnames/bind";
import React, { useCallback, useState } from "react";
import styles from "./Review.module.scss";

const cx = classNames.bind(styles);

export const MemberReview = () => {
  const [mode, setMode] = useState<"write" | "read">("write");

  const changeMode = useCallback(() => {
    setMode(prev => (prev === "write" ? "read" : "write"));
  }, []);

  return (
    <main className={cx("wrapper")}>
      <div className={cx("title-text")}>상품 리뷰</div>
      <div className={cx("select-menu-wrapper")}>
        <button type="button" className={cx("select-menu-button", { isSelect: mode === "write" })} onClick={changeMode}>
          리뷰 작성
        </button>
        <button type="button" className={cx("select-menu-button", { isSelect: mode === "read" })} onClick={changeMode}>
          리뷰 내역
        </button>
      </div>

      <div className={cx("review-content-wrapper")}>
        <LookpinLogo />
        <div className={cx("review-content")}>
          {mode === "write" ? "리뷰 작성이 가능한 상품은" : "작성하신 리뷰 내역은"}
          <br />
          룩핀 앱에서 확인하실 수 있습니다.
        </div>
      </div>
    </main>
  );
};
