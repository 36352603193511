import { storageContext } from "contexts";
import { recentsViewModelCreator } from "view-model-creator";
import { INITIALIZE, LOGOUT, SYNC } from "constant";

const initialState = [];

export default function recents(state = initialState, { type, payload }) {
  switch (type) {
    case INITIALIZE:
      if (!payload || !payload.recents) return state;
      return recentsViewModelCreator(payload.recents);

    case SYNC:
      if (!payload || !payload.recents) return state;
      return payload.recents;

    case LOGOUT:
      return initialState;

    default:
      if (!payload || !payload.recents) return state;
      return storageContext.recents;
  }
}
