import React, { useCallback, useMemo } from "react";

import { shallowEqual, useSelector } from "react-redux";
import {
  Pagination,
  OrderProductCard,
  OrderNavBar,
  SortSelectButton,
  DateSelect,
  OrderProductCardMobileView,
  LoadingIcon,
  EmptyProductOrder
} from "components";
import { IStore } from "typings";

import classNames from "classnames/bind";
import { ORDER_PRODUCT_STATUS, ORDER_PRODUCT_SORT, ORDER_PRODUCT_QUERY_KEYS } from "constant";
import styles from "./Orders.module.scss";
import { useProductOrders } from "./useProductOrders";

const cx = classNames.bind(styles);

const PER_PAGE = 5;

export function Orders() {
  const isMobile = useSelector<IStore, IStore["ui"]["isMobile"]>(({ ui: { isMobile } }) => isMobile, shallowEqual);

  const {
    orders,
    isLoading,
    totalCount,
    pushPage,
    pageNumber,
    orderSummary,
    monthDiff,
    filterGroup,
    sort,
    actionClickEvent,
    getMembersOrders
  } = useProductOrders();

  const filterOrPageClickEventListner = useCallback(
    key => value => {
      pushPage({ key, value });
    },
    [pushPage]
  );

  const ProductCardView = useMemo(() => (isMobile ? OrderProductCardMobileView : OrderProductCard), [isMobile]);

  const navBarTitleCreator = useCallback((title: string) => (isMobile ? title.replace(/\s/g, "") : title), [isMobile]);

  return (
    <main className={cx("orders-tab")}>
      <header className={cx(["bold"], "title")}>주문내역</header>

      <section className={cx("list")}>
        <div className={cx("select-toggle-wrapper")}>
          <div className={cx("margin-wrapper")}>
            <SortSelectButton
              value={sort}
              title={ORDER_PRODUCT_SORT[sort]}
              onClick={filterOrPageClickEventListner(ORDER_PRODUCT_QUERY_KEYS.SORT)}
            />
          </div>
          <DateSelect
            options={[
              { key: "1", value: "1개월" },
              { key: "3", value: "3개월" },
              { key: "6", value: "6개월" },
              { key: "all", value: "전체" }
            ]}
            select={monthDiff.toString()}
            onChange={filterOrPageClickEventListner(ORDER_PRODUCT_QUERY_KEYS.MONTH_DIFF)}
          />
        </div>

        {orderSummary && (
          <nav className={cx("nav-wrapper")}>
            {Object.keys(ORDER_PRODUCT_STATUS).map(key => (
              <OrderNavBar
                key={key}
                value={key}
                count={orderSummary[key]}
                title={navBarTitleCreator(ORDER_PRODUCT_STATUS[key])}
                onClick={filterOrPageClickEventListner(ORDER_PRODUCT_QUERY_KEYS.FILTER_GROUP)}
                isSelected={filterGroup === key}
              />
            ))}
          </nav>
        )}

        {isLoading ? (
          <div className={cx("loading-wrapper")}>
            <LoadingIcon />
          </div>
        ) : (
          <>
            <div className={cx("list-wrapper")}>
              {orders.length > 0 ? (
                orders.map(order => (
                  <ProductCardView
                    key={order.productOrderNum}
                    order={order}
                    actionClickEvent={actionClickEvent(order)}
                    reviewSubmitCallback={getMembersOrders(pageNumber)}
                    type="order"
                  />
                ))
              ) : (
                <EmptyProductOrder filterGroup={filterGroup} />
              )}
            </div>
            <div className={cx("footer")}>
              <Pagination
                totalCount={totalCount}
                handler={filterOrPageClickEventListner(ORDER_PRODUCT_QUERY_KEYS.PAGE)}
                number={pageNumber}
                size={PER_PAGE}
              />
            </div>
          </>
        )}
      </section>
    </main>
  );
}
