import { useReducer } from "react";

const reducer = (state, { name, value }) => ({ ...state, [name]: value });

export const useInputs = initialForm => {
  const [state, dispatch] = useReducer(reducer, initialForm);
  const onChange = ({ target }) => dispatch(target);

  return [state, onChange];
};
