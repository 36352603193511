import { storageContext } from "contexts";

export function isSuggestMobileApp(obj: string): boolean {
  const todayDate = new Date().getUTCDate();
  const params = { date: todayDate, count: 1 };
  if (obj) {
    const { date, count } = JSON.parse(obj);
    if (todayDate !== date) {
      storageContext.suggestMobileAppLimit = JSON.stringify(params);
      return true;
    }
    
    if (count < 2) {
      Object.assign(params, { count: count + 1 });
      storageContext.suggestMobileAppLimit = JSON.stringify(params);
      return true;
    }
    return false;
    
  }
  storageContext.suggestMobileAppLimit = JSON.stringify(params);
  return true;
}
