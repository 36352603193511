import React, { FC } from "react";
import classNames from "classnames/bind";
import stlyes from "./BuyConfirmButton.module.scss";

const cx = classNames.bind(stlyes);
export const BuyConfirmButton: FC<React.HTMLAttributes<HTMLButtonElement>> = ({ onClick }) => {
  return (
    <button type="button" className={cx("wrapper")} onClick={onClick}>
      구매 확정
    </button>
  );
};
