import { ICartViewModel, CartSerialized, IOptionViewModel, ProductStatus } from "typings";

import { SALE, SOLDOUT_OPTION, SOLDOUT_PRODUCT, SALE_STOP } from "constant";

export const optionStatusParser = ({ option, product }: CartSerialized, selectedCount: number): ProductStatus => {
  if (product.sold_out) return SOLDOUT_PRODUCT;

  if (option.data.stock_count >= selectedCount) return SALE;

  return SOLDOUT_OPTION;
};

export const productStatusParser = (status, soldOut): ProductStatus => {
  if (status === "sale_stop") return SALE_STOP;
  if (soldOut) return SOLDOUT_PRODUCT;
  return SALE;
};

export const newProductStatusParser = (status): ProductStatus => {
  if (status === "sale_stop") return SALE_STOP;
  if (status === "sold_out") return SOLDOUT_PRODUCT;
  return SALE;
};

export const createCart = (option: CartSerialized): ICartViewModel => {
  const {
    id: cartId,
    option: {
      id: optionId,
      positions,
      data: { option_price },
      selected_count: selectedCount
    },
    product: {
      id: productId,
      name: productName,
      info: {
        images: { main }
      },
      price,
      prev_price: prevPrice,
      store: { name: storeName },
      is_on_event_sale: isOnEventSale
    }
  } = option;

  const priceToBuy = (price + option_price) * selectedCount;
  const optionText = positions.join(", ");

  return {
    cartId,
    status: optionStatusParser(option, selectedCount),
    optionId,
    productId,
    productName,
    photoUrl: main[0],
    selectedCount,
    optionText,
    optionDesc: `${optionText ? `${optionText} / ` : ``}${selectedCount}개`,
    price,
    prevPrice,
    priceToBuy,
    priceToBuyDesc: `${priceToBuy.toLocaleString()}원`,
    storeName,
    selected: false,
    isOnEventSale
  };
};

export const createStoreGroup = <T extends IOptionViewModel[]>(options: T): Map<string, T> =>
  options.reduce<Map<string, T>>((acc, option) => {
    const { storeName } = option;

    if (acc.has(storeName)) (acc.get(storeName) as T).push(option);
    else acc.set(storeName, [option] as T);

    return acc;
  }, new Map());

export const cartsViewModelCreator = (options: CartSerialized[]): ICartViewModel[] => options.map(createCart);
