import { fetcher } from "utils";

interface IPixelConversion {
  event_id: string;
  event_name: string;
  event_source_url: string;
  content_type?: string;
  facebook?: {
    fbp?: string;
    fbc?: string;
    event_cost: number;
    test_event_code?: string;
  };
  contents?: { id: number; item_price: number; quantity: number }[];
}

export const post__api__v1__pixel__action = (params: IPixelConversion) => {
  return fetcher.post("/api/v1/pixels/action", params);
};
