import { produce } from "utils";

import { historyContext, modalContext, storageContext } from "contexts";

import {
  INITIALIZE,
  UI__IS_LOADING,
  UI__MODAL_CALLBACK_SET,
  UI__MODAL_OFF,
  UI__MODAL_ON,
  UI__SET_GENDER,
  UI__SET_MOBILE
} from "constant";

import { IUIState, Gender, ModalStatus } from "typings";

const isMobile = window.matchMedia("(max-width: 450px)").matches;

// TODO: 여기서의 genderId는 userable, member의 genderId는 member가 될 수 있도록 구분
const initialState: IUIState = {
  genderId: storageContext.genderId, // TODO: localStorage가 아닌 GET /userable_info을 통해 파악하도록 하자!
  isLoading: false,
  isMobile,
  modalStatus: null
};

export default function ui(state = initialState, { type, payload }): IUIState {
  return produce(state, draft => {
    switch (type) {
      case UI__SET_MOBILE:
        draft.isMobile = payload as boolean;
        break;
      case UI__SET_GENDER:
        storageContext.genderId = payload;
        draft.genderId = payload as Gender;
        break;
      case UI__MODAL_OFF:
        draft.modalStatus = null;
        draft.modalCallbackFunction = undefined;
        break;
      case UI__MODAL_ON:
        draft.modalStatus = payload as ModalStatus;
        break;
      case UI__IS_LOADING:
        draft.isLoading = true;
        break;
      case UI__MODAL_CALLBACK_SET:
        draft.modalCallbackFunction = payload;
        break;
      case INITIALIZE:
        if (payload.member) return { ...state, isLoading: false, genderId: payload.member.genderId || draft.genderId };
        draft.isLoading = false;
        break;
    }
  }) as any;
}

export const setMobile = (payload: boolean) => ({ type: "UI__SET_MOBILE", payload });
export const setGender = (payload: Gender) => ({ type: "UI__SET_GENDER", payload });

export const modalOff = (
  { isClear, isNext }: { isClear?: boolean; isNext?: boolean } = {
    isClear: false,
    isNext: false
  },
  data?: object
) => {
  const { pathname, search, state } = historyContext.location;
  if (isClear) modalContext.index = -1;
  else if (isNext) modalContext.index += 1;
  else modalContext.index -= 1;

  if (modalContext.index === -1) {
    modalContext.stack = [];
    modalContext.url = "";
    if (modalContext.scrollY) {
      window.scroll(0, modalContext.scrollY);
      modalContext.scrollY = 0;
    }
  }

  const status = modalContext.stack[modalContext.index] || null;

  historyContext.replace({
    pathname,
    search,
    state: { ...(status && { modal: status }), ...((state || {}) as {}), ...(data || {}) }
  });

  return status === null ? { type: UI__MODAL_OFF } : { type: UI__MODAL_ON, payload: status as ModalStatus };
};

export const modalOn = (modalStatus: ModalStatus, data?: any) => {
  const { pathname, search, state } = historyContext.location;

  modalContext.index += 1;
  if (data?.scrollY) modalContext.scrollY = data.scrollY;
  modalContext.stack.push(modalStatus);

  if (data?.scrollY) modalContext.scrollY = data.scrollY;

  historyContext.push({
    pathname,
    search,
    state: {
      modal: modalStatus,
      ...((state || {}) as any),
      ...(data || {})
    }
  });

  if (!modalContext.url || modalContext.url !== pathname) modalContext.url = pathname;

  return { type: UI__MODAL_ON, payload: modalStatus };
};

export const modalCallbackFunctionSet = (payload: (payload?: any) => void) => {
  return {
    type: UI__MODAL_CALLBACK_SET,
    payload
  };
};
