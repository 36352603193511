import React, { FC, useCallback } from "react";
import { subMonths, startOfMonth, endOfMonth, getMonth, format } from "date-fns/fp";
import { map, pipe, join, times, identity, ap } from "ramda";
import classnames from "classnames/bind";

import styles from "./SelectShare.module.scss";

type HandlePeriod = (period: string) => void;

interface IProps {
  period: string;
  handlePeriod: HandlePeriod;
}

const cx = classnames.bind(styles);

const now = pipe(
  () => [new Date()],
  ap([pipe(subMonths(2), startOfMonth), identity]),
  map(format("yyyy-MM-dd'T'HH:mm:ss'+09:00'")),
  join("/")
)();

const periods = map(
  (n: number) =>
    pipe(
      () => new Date(),
      subMonths(n),
      t => [
        `${getMonth(t) + 1}월`,
        pipe(ap([startOfMonth, endOfMonth]), map(format("yyyy-MM-dd'T'HH:mm:ss'+09:00'")), join("/"))([t])
      ]
    )(),
  times(identity, 3)
);

export const SelectPeriod: FC<IProps> = ({ period, handlePeriod }) => {
  const changePeriod = useCallback(({ target: { value } }) => handlePeriod(value), [handlePeriod]);

  return (
    <select className={cx("select", "period")} value={period} onChange={changePeriod}>
      <option key="최근 3개월" value={now}>
        최근 3개월
      </option>

      {periods.map(([ko, value]) => (
        <option key={ko} value={value}>
          {ko}
        </option>
      ))}
    </select>
  );
};
