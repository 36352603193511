import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { camelizeKeys } from "humps";

import { get__stores__search } from "api";

import { IStore, StoreSerializedCamel } from "typings";
import { AirbridgeEvent } from "utils";

export const useStoreSearch = () => {
  const genderId = useSelector<IStore, IStore["ui"]["genderId"]>(({ ui: { genderId } }) => genderId);

  const { search } = useLocation();
  const { keyword: kw }: { keyword: string } = qs.parse(search, { ignoreQueryPrefix: true }) as { keyword: string };

  const [keyword, setKeyword] = useState<string>(kw || "");
  const [stores, setStores] = useState<StoreSerializedCamel[]>([]);
  const history = useHistory();

  const changeKeyword = event => setKeyword(event.target.value);

  const handleKeyword = useCallback(() => {
    if (keyword) history.push({ pathname: "/search/store", search: qs.stringify({ keyword }) });
    else history.push({ pathname: "/stores" });
  }, [history, keyword]);

  const handleEnter = useCallback(
    ({ key, target: { name } }) => {
      if (key === "Enter" && name === "keyword") handleKeyword();
    },
    [handleKeyword]
  );

  const resetKeyword = useCallback(() => {
    setKeyword("");
    history.push({ pathname: "/stores" });
  }, [history]);

  useEffect(() => {
    (async () => {
      const stores = await get__stores__search({ query: kw, genderId });

      setStores(stores.map(store => camelizeKeys(store) as StoreSerializedCamel));

      AirbridgeEvent.searchResultsViewed({
        semanticAttributes: {
          query: kw,
          products: stores.map((item, index) => ({
            productID: item.id,
            name: item.name,
            position: index + 1
          }))
        }
      });

      setKeyword(kw);
    })();
  }, [genderId, kw]);

  return { stores, keyword, changeKeyword, handleKeyword, handleEnter, resetKeyword };
};
