import React from "react";
import Loader from "react-loader-spinner";
import { useHistory, Redirect } from "react-router-dom";
import classNames from "classnames/bind";
import { createStoreGroup } from "view-model-creator";
import { IPartnerOptionViewModel } from "typings";
import { NOTICE } from "constant";
import { naverPayBadge } from "assets/img";
import { IconArrowLeft } from "assets/icon";
import { useOrder } from "./useOrder";
import styles from "./Order.module.scss";

const cx = classNames.bind(styles);

export function Order() {
  const history = useHistory();

  const {
    partnerOptions,
    appliedCouponSize,
    coupons,
    couponUnusable,
    handleCoupon,
    point,
    isUnusableOrderPoint,
    inputPoint,
    handleInputOrderPoint,
    handleConsumedOrderPoint,
    setMaxPoint,
    sumPrevPrice,
    sumStoreDiscountPrice,
    sumCouponDiscountPrice,
    consumedPoint,
    paidAmount,
    expectedOrderPoint,
    name,
    updateShippingInfo,
    phoneNumber,
    zipcode,
    zipcodeEl,
    setZipcodeAndMain,
    postcodeEl,
    main,
    mainEl,
    detail,
    detailEl,
    updateDetail,
    trimDetail,
    message,
    payMethods,
    handlePayMethod,
    payMethod,
    canPay,
    requestPay,
    isLoading
  } = useOrder();

  return !partnerOptions.length ? (
    <Redirect to="/" />
  ) : (
    <main className={cx("order-tab")}>
      <div className={cx("wrapper-head")}>
        <button type="button" onClick={history.goBack}>
          <span className={cx("back")}>
            <IconArrowLeft />
          </span>
          <span className={cx(["bold"], "head")}>주문하기</span>
        </button>
      </div>

      <div className={cx("list-head", "bold")}>주문&nbsp;상품&nbsp;내역</div>
      <div className={cx("list-contents")}>
        {Array.from(createStoreGroup(partnerOptions).entries()).map(
          ([storeName, PartnerProductsInStore]: [string, IPartnerOptionViewModel[]]) => (
            <ul key={storeName} className={cx("list")}>
              <div className={cx("title")}>{storeName}</div>

              {PartnerProductsInStore.map(
                ({
                  optionId,
                  photoUrl,
                  productName,
                  optionDesc,
                  priceToBuyDesc,
                  couponDiscountPrice,
                  priceToBuy,
                  couponDesc
                }) => (
                  <li key={optionId}>
                    <div className={cx("thumbnail")}>
                      <img src={photoUrl} alt="상품사진" />
                    </div>
                    <div className={cx("info")}>
                      <div>
                        <span>{productName}</span>
                      </div>

                      <div>
                        <span>{optionDesc}</span>
                      </div>
                    </div>

                    <div className={cx("price")} data-coupon={couponDesc ? `${couponDesc} 적용중` : ``}>
                      <span className={cx({ prev: Boolean(couponDiscountPrice) })}>{priceToBuyDesc}</span>
                      {couponDiscountPrice && (
                        <span>&nbsp;&nbsp;{(priceToBuy - couponDiscountPrice).toLocaleString()}원</span>
                      )}
                    </div>
                  </li>
                )
              )}
            </ul>
          )
        )}
      </div>

      <div className={cx("container")}>
        <div className={cx("member-info")}>
          <div className={cx("coupon")}>
            <div
              className={cx("box-head", "bold")}
              data-coupon={appliedCouponSize ? `${appliedCouponSize}개 쿠폰 적용됨` : ``}
            >
              쿠폰
            </div>
            <div className={cx("box", "coupon-contents")}>
              <div className={cx("count")}>
                <span>보유&nbsp;쿠폰:&nbsp;{coupons.length}개</span>
              </div>
              <button type="button" className={cx("blue-btn", { unusable: couponUnusable })} onClick={handleCoupon}>
                <span>쿠폰선택</span>
              </button>
            </div>
          </div>

          <div className={cx("box", "point")}>
            <div className={cx("box-head", "bold")}>적립금</div>
            <div className={cx("point-left")}>보유적립금 {point.toLocaleString()}원</div>

            <div className={cx("point-contents")}>
              <div className={cx("point-box")}>
                <input
                  className={cx({ unusable: isUnusableOrderPoint })}
                  id="point_use"
                  type="number"
                  placeholder="0"
                  value={inputPoint}
                  onChange={handleInputOrderPoint}
                  onBlur={handleConsumedOrderPoint}
                  disabled={isUnusableOrderPoint}
                  min={1000}
                  step={10}
                />
              </div>

              <button
                type="button"
                className={cx("blue-btn", { unusable: isUnusableOrderPoint })}
                onClick={
                  isUnusableOrderPoint
                    ? () => alert(point < 1000 ? NOTICE.LACK_ORDER_POINT : NOTICE.UNUSABLE_ORDER_POINT)
                    : setMaxPoint
                }
              >
                모두사용
              </button>
            </div>

            <div className={cx("point-desc")}>
              <div>
                (최소)1,000원&nbsp;~&nbsp;(최대)상품&nbsp;가격의&nbsp;10%까지&nbsp;사용&nbsp;가능합니다.
                <br />
                적립금은&nbsp;10원&nbsp;단위로&nbsp;사용&nbsp;가능합니다.
              </div>
              <div />
            </div>
          </div>

          <div className={cx("box", "payment", "inner")}>
            <div className={cx("box-head", "bold")}>결제금액</div>
            <div className={cx("payment-contents")}>
              <div>
                <span>상품금액</span>
                <span>{sumPrevPrice.toLocaleString()}원</span>
              </div>
              <div>
                <span>할인금액</span>
                <span>{sumStoreDiscountPrice.toLocaleString()}원</span>
              </div>
              <div>
                <span>쿠폰&nbsp;사용</span>
                <span>{sumCouponDiscountPrice.toLocaleString()}원</span>
              </div>
              <div>
                <span>적립금&nbsp;사용</span>
                <span>{consumedPoint.toLocaleString()}원</span>
              </div>

              <hr />

              <div className={cx("total")}>
                <span className={cx("red")}>총&nbsp;결제금액</span>
                <span className={cx("red")}>{paidAmount.toLocaleString()}원</span>
              </div>

              <div className={cx("points")}>
                <span className={cx("blue")}>예상&nbsp;적립금</span>
                <span className={cx("blue")}>{expectedOrderPoint.toLocaleString()}원</span>
              </div>
            </div>
          </div>

          <div className={cx("box", "address")}>
            <div className={cx("box-head", "bold")}>배송지 정보</div>
            <div className={cx("address-contents")}>
              <div className={cx("row")}>
                <div className={cx("title")}>이름</div>
                <input
                  className={cx("input-name")}
                  placeholder="이름"
                  name="name"
                  value={name}
                  onChange={updateShippingInfo}
                />
              </div>

              <div className={cx("row")}>
                <div className={cx("title")}>연락처</div>
                <input
                  className={cx("input-number")}
                  placeholder="예) 01012345678"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={updateShippingInfo}
                  type="tel"
                  pattern="[0-9]{9,11}"
                  required
                />
              </div>

              <div className={cx("row", "post-num")}>
                <div className={cx("title")}>우편번호</div>
                <input
                  id="zipcode"
                  className={cx("input-post-num")}
                  placeholder="우편번호"
                  name="zipcode"
                  value={zipcode}
                  ref={zipcodeEl}
                  disabled
                />
                <button
                  type="button"
                  className={cx("blue-btn", "post-search")}
                  onClick={setZipcodeAndMain}
                  ref={postcodeEl}
                >
                  주소검색
                </button>
              </div>

              <div className={cx("row")}>
                <div className={cx("title")}>주소</div>
                <input
                  id="main"
                  className={cx("input-address", "background-gray")}
                  placeholder="주소"
                  name="main"
                  value={main}
                  ref={mainEl}
                  disabled
                />
              </div>

              <div className={cx("row")}>
                <div className={cx("title")}>&nbsp;</div>
                <input
                  id="detail"
                  className={cx("input-address", { "background-gray": !zipcode && !main })}
                  placeholder="상세 주소"
                  name="detail"
                  value={detail}
                  ref={detailEl}
                  onChange={updateDetail}
                  onBlur={trimDetail}
                  disabled={!zipcode && !main}
                />
              </div>

              <div className={cx("row")}>
                <div className={cx("title")}>배송요청사항</div>
                <input
                  className={cx("input-request")}
                  placeholder="요청사항을 적어주세요."
                  name="message"
                  value={message}
                  onChange={updateShippingInfo}
                />
              </div>
            </div>
          </div>

          <div className={cx("box", "pay-method")}>
            <div className={cx("box-head", "bold", "pay-method-title")}>결제방법</div>
            <div className={cx("pay-method-contents")}>
              {payMethods.map(([ko, en]) => (
                <div
                  key={en}
                  className={cx("btn-general-pay", {
                    naver: en === "naverpay"
                  })}
                >
                  <label htmlFor={en} className={cx("label")}>
                    <input
                      type="radio"
                      id={en}
                      className={cx("radio")}
                      onChange={handlePayMethod}
                      checked={en === payMethod}
                      data-method={en}
                    />
                    {en !== "naverpay" ? ko : <img src={naverPayBadge} alt="네이버 페이" />}
                  </label>
                </div>
              ))}
            </div>

            {payMethod === "naverpay" && (
              <div className={cx("naver-pay-desc")}>
                <p className={cx("head")}>네이버페이 안내문구</p>
                <p className={cx("body")}>
                  <span>
                    · 주문 변경 시 카드사 혜택 및 할부 적용 여부는 해당 카드사 정책에 따라 변경될 수 있습니다.
                  </span>
                  <span>
                    · 네이버페이는 네이버ID로 별도 앱 설치 없이 신용카드 또는 은행계좌 정보를 등록하여 네이버페이
                    비밀번호로 결제할 수 있는 간편결제 서비스입니다.
                  </span>
                  <span>· 결제 가능한 신용카드: 신한, 삼성, 현대, BC, 국민, 하나, 롯데, NH농협, 씨티</span>
                  <span>
                    · 결제 가능한 은행: NH농협, 국민, 신한, 우리, 기업, SC제일, 부산, 경남, 수협, 우체국, 미래에셋대우,
                    광주, 대구, 전북, 새마을금고, 제주은행, 신협, 하나은행
                  </span>
                  <span>
                    · 네이버페이 카드 간편결제는 네이버페이에서 제공하는 카드사 별 무이자, 청구할인 혜택을 받을 수
                    있습니다.
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>

        <div className={cx("box", "payment", "stick")}>
          <div className={cx("box-head", "bold")}>결제금액</div>
          <div className={cx("payment-contents")}>
            <div>
              <span>상품금액</span>
              <span>{sumPrevPrice.toLocaleString()}원</span>
            </div>
            <div>
              <span>할인금액</span>
              <span>{sumStoreDiscountPrice.toLocaleString()}원</span>
            </div>
            <div>
              <span>쿠폰&nbsp;사용</span>
              <span>{sumCouponDiscountPrice.toLocaleString()}원</span>
            </div>
            <div>
              <span>적립금&nbsp;사용</span>
              <span>{consumedPoint.toLocaleString()}원</span>
            </div>

            <hr />

            <div className={cx("total")}>
              <span className={cx("red")}>총&nbsp;결제금액</span>
              <span className={cx("red")}>{paidAmount.toLocaleString()}원</span>
            </div>

            <div className={cx("points")}>
              <span className={cx("blue")}>예상&nbsp;적립금</span>
              <span className={cx("blue")}>{expectedOrderPoint.toLocaleString()}원</span>
            </div>
          </div>

          <button
            type="button"
            className={cx("btn-order", { unpayable: !canPay })}
            onClick={requestPay}
            disabled={isLoading}
          >
            {isLoading ? <Loader type="Oval" color="#ffffff" height={20} width={20} /> : "결제하기"}
          </button>
        </div>
      </div>
    </main>
  );
}
