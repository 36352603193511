import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useSelector } from "react-redux";
import * as api from "api";
import { AirbridgeEvent, produce, Tracking } from "utils";
import qs from "qs";
import { pipeWith, then, path } from "ramda";

import { newProductViewModelCreator, reviewSummariesViewModelCreatorV3 } from "view-model-creator";

import { historyContext } from "contexts";

import { BOOKMARK } from "constant";

import {
  Order,
  IPage,
  GetProductsSearchParams,
  GenderPartner,
  INewProductViewModel,
  StoreGroupId,
  IStore,
  IReviewsSummariesViewModelV3
} from "typings";

import {
  setQuery,
  getConditions,
  handlePageHelper,
  handlePriceHelper,
  handlePriceResetHelper,
  updateStateByValueFromInputTag,
  changeName,
  processingQuery
} from "./productSearchShare";

interface ISearchParams extends GetProductsSearchParams {
  order: Order;
  page: IPage;
  genderId: GenderPartner;
  period?: string;
  price?: { gte?: number; lte?: number };
  name?: string;
  storeGroupId?: StoreGroupId;
  onlyLikeStores?: boolean;
}

interface IBase {
  params: ISearchParams;
  category?: string;
  keyword?: string;
  main?: string;
}

export const useProductSearch = (base: IBase) => {
  const mainRef = useRef<HTMLElement>(null);

  /* eslint-disable-next-line */
  const processedQuery = useMemo(() => processingQuery(historyContext.location.search, base), [
    base,
    historyContext.location.search
  ]);

  const {
    params,
    params: {
      order,
      price,
      page: { number, size },
      period,
      storeGroupId,
      onlyLikeStores
    },
    keyword: kw,
    category: cg,
    main
  } = useMemo(() => processedQuery, [processedQuery]);

  const isNavSearch = useMemo(() => {
    const searchedQs = Object.keys(qs.parse(historyContext.location.search));
    return searchedQs.length === 1 && searchedQs.some(key => key === "?keyword");
  }, []);

  const [products, setProducts] = useState<INewProductViewModel[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [reviews, setReviews] = useState<IReviewsSummariesViewModelV3[]>([]);
  const [gte, setGte] = useState(price?.gte ?? 0);
  const [lte, setLte] = useState(price?.lte ?? 0);
  const [maxPrice, setMaxPrice] = useState(100);
  const [keyword, setKeyword] = useState(kw || "");
  const [isLoading, setIsLoading] = useState(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [category, setCategory] = useState(cg);

  const { genderId } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);

  const conditions = useMemo(() => getConditions({ keyword: kw, gte, lte }), [gte, kw, lte]);
  const query = qs.parse(historyContext.location.search, { ignoreQueryPrefix: true });

  const resetCondition = useCallback(
    () => setQuery({ ...base, ...query, params: { order, period, storeGroupId, onlyLikeStores }, keyword: "" }),
    [base, onlyLikeStores, order, period, query, storeGroupId]
  );
  const changeKeyword = useCallback(updateStateByValueFromInputTag(setKeyword), []);
  const handlePage = useCallback(handlePageHelper(processedQuery), [processedQuery]);
  const handlePrice = useCallback(handlePriceHelper(processedQuery), [processedQuery]);
  const handlePriceReset = useCallback(handlePriceResetHelper(processedQuery), [processedQuery]);

  const handleKeyword = useCallback(() => {
    setQuery(produce(processedQuery, draft => changeName(draft, { category, keyword, main })));
  }, [category, keyword, main, processedQuery]);

  const handleCategory = useCallback(
    event => {
      const keyWord = event.target.value === "전체" ? null : event.target.value;
      setQuery(
        produce(processedQuery, draft => {
          changeName(draft, { category: keyWord, keyword, main });
        })
      );
    },
    [keyword, main, processedQuery]
  );

  const handleOrder = useCallback(
    ({ target: { value } }) => {
      setQuery(
        produce(processedQuery, draft => {
          draft.params.order = value;
          draft.params.page.number = 1;
        })
      );
    },
    [processedQuery]
  );

  const handlePeriod = useCallback(
    (period: string) => {
      setQuery(
        produce(processedQuery, draft => {
          draft.params.period = period;
        })
      );
    },
    [processedQuery]
  );

  const handleGroup = useCallback(
    event => {
      const { value } = event.target;

      setQuery(
        produce(processedQuery, draft => {
          if (value === BOOKMARK) delete draft.params.storeGroupId;
          else draft.params.storeGroupId = value;

          draft.params.onlyLikeStores = value === BOOKMARK;
          draft.params.page.number = 1;
        })
      );
    },
    [processedQuery]
  );

  const group = useMemo(() => (onlyLikeStores ? BOOKMARK : storeGroupId || ""), [onlyLikeStores, storeGroupId]);

  const setDataFromQuery = useCallback(() => {
    setIsPaginationLoading(true);

    setIsLoading(true);

    setKeyword(kw || "");
    setCategory(cg || "");
    setGte(price?.gte ?? 0);
    setLte(price?.lte ?? 0);

    api.get__products__search({ ...params, genderId }).then(
      ({
        data: {
          payload,
          meta: { total_count, max_price }
        }
      }) => {
        setProducts(newProductViewModelCreator(payload));
        setTotalCount(total_count);
        setMaxPrice(max_price || 0);
        setIsLoading(false);
        setIsPaginationLoading(false);

        if (mainRef.current) mainRef.current.style.minHeight = "auto";

        AirbridgeEvent.searchResultsViewed({
          semanticAttributes: {
            query: kw,
            products: payload.map((item, index) => ({
              productID: item.id,
              name: item.name,
              price: item.price,
              currency: "KRW",
              position: index + 1
            }))
          }
        });
      }
    );
  }, [cg, genderId, kw, params, price]);

  useEffect(() => {
    const productsIds = products.map(product => product.id);

    if (productsIds.length > 0) {
      pipeWith(then)([
        api.get__products__id__reviews_summaries_v3,
        path(["data", "payload"]),
        reviewSummariesViewModelCreatorV3,
        setReviews
      ])(productsIds);
    }
  }, [products]);

  useEffect(setDataFromQuery, [processedQuery.params]);

  useEffect(() => {
    if (!main) return;
    const keyword = cg || main;

    if (process.env.REACT_APP_ENV === "production") {
      Tracking.search(keyword);
    } else {
      console.warn("search", "useProductSearch");
      console.warn({ keyword });
    }
  }, [main, cg]);

  return {
    handlePeriod,
    period,
    handlePrice,
    handlePriceReset,
    gte,
    lte,
    main,
    order,
    handleOrder,
    keyword,
    changeKeyword,
    handleKeyword,
    category,
    handleCategory,
    conditions,
    resetCondition,
    isLoading,
    isPaginationLoading,
    products,
    reviews,
    totalCount,
    handlePage,
    number,
    size,
    maxPrice,
    group,
    handleGroup,
    mainRef
  };
};
