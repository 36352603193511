import {
  IReviewViewModel,
  IOrderProductReviews,
  ICremaProductReviews,
  IOrderProductReviewsCamel,
  IReviewsSummariesV3,
  IReviewsSummariesViewModelV3
} from "typings";
import { formatDateTime } from "utils";
import { LOOKPIN_REVIEW, CREMA_REVIEW } from "constant";

type ReviewsSummaryViewModelCreatorV3 = (reviews: Array<IReviewsSummariesV3>) => IReviewsSummariesViewModelV3[];

type LookpinReviewListViewModelCreator = (arg: Array<IOrderProductReviews>) => IReviewViewModel[];

type CremaReviewListViewModelCreator = (arg: Array<ICremaProductReviews>) => IReviewViewModel[];

type MyReviewToPhotoDetailViewModelCreator = (arg: IOrderProductReviewsCamel) => IReviewViewModel;

export const reviewModelCreatorFromOrderProduct = ({
  created_at,
  id,
  member,
  satisfaction,
  content,
  like_count,
  color,
  size,
  shipping,
  userable_info,
  order_product,
  images
}) => {
  const imageList = images === null ? [] : images.resized?.map(image => image[300]);

  return {
    id,
    author: member ? member.email_masked : "",
    authorLevel: member ? member.level : "",
    authorId: member ? member.id : "",
    satisfaction,
    type: LOOKPIN_REVIEW,
    contents: content,
    createdAt: formatDateTime(created_at),
    likeCount: like_count,
    color,
    size,
    shipping,
    weight: userable_info && userable_info.weight,
    height: userable_info && userable_info.height,
    options: order_product.old_product_options.positions,
    images: imageList
  };
};

export const reviewModelCreatorFromCremaProduct = ({
  user_name,
  created_at_by_crema,
  images,
  id_by_crema,
  score,
  message,
  likes_count
}) => {
  const userName = user_name
    .split("")
    .map((char, index) => (index === 0 ? char : "*"))
    .join("");

  const imageList = images === null ? [] : images.map(image => image.url);
  return {
    id: parseInt(id_by_crema, 10),
    author: userName,
    authorLevel: null,
    authorId: null,
    satisfaction: score,
    type: CREMA_REVIEW,
    contents: message,
    createdAt: formatDateTime(created_at_by_crema),
    likeCount: likes_count,
    color: null,
    images: imageList,
    height: null,
    weight: null
  } as IReviewViewModel;
};

export const reviewSummariesViewModelCreatorV3: ReviewsSummaryViewModelCreatorV3 = reviews => {
  return reviews?.map(({ total_count, crema_product_review_summary, order_product_review_summary }) => {
    const cremaSatisfaction = crema_product_review_summary?.score || 0;
    const cremaTotalCount = crema_product_review_summary?.total_count || 0;
    const lookpinSatisfaction = order_product_review_summary?.satisfaction || 0;
    const lookpinReviewTotalCount = order_product_review_summary?.total_count || 0;

    const averageSatisfaction =
      ((lookpinSatisfaction * lookpinReviewTotalCount + cremaSatisfaction * cremaTotalCount) / total_count).toFixed(
        1
      ) || "0.0";

    return {
      totalCount: total_count,
      averageSatisfaction
    };
  });
};

export const lookpinReviewListViewModelCreator: LookpinReviewListViewModelCreator = payload =>
  payload.map(reviewModelCreatorFromOrderProduct);

export const cremaReviewListViewModelCreator: CremaReviewListViewModelCreator = payload =>
  payload.map(reviewModelCreatorFromCremaProduct);

export const myReviewToPhotoDetailViewModelCreator: MyReviewToPhotoDetailViewModelCreator = payload => ({
  id: payload.id,
  author: "",
  authorId: null,
  authorLevel: null,
  images: payload.images?.resized ? payload.images.resized.map(img => img[300]) : [""],
  satisfaction: payload.satisfaction,
  type: "lookpin",
  contents: payload.content,
  createdAt: payload.createdAt,
  color: payload.color,
  size: payload.size,
  shipping: payload.shipping,
  options: null,
  weight: payload.userableInfo.weight,
  height: payload.userableInfo.height,
  likeCount: 0
});
