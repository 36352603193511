import { initialize } from "actions/actionCreators";
import ReactGA from "react-ga";
import * as api from "api";
import animationData from "assets/animation/ani_order_fin.json";
import animationLoadingData from "assets/animation/ani_page_loading.json";
import className from "classnames/bind";
import { KeyValueTemplate } from "components";
import { kakaoPixelContext, storageContext } from "contexts";
import { History } from "history";
import { camelizeKeys } from "humps";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { match } from "react-router";
import { Link } from "react-router-dom";
import { IOrderResultViewModelV3, IPageCamel, IPartnerOptionViewModel, IStore } from "typings";
import { AirbridgeEvent, Tracking, criteoEventPush } from "utils";
import { orderResultViewModelCreatorV3 } from "view-model-creator";
import styles from "./OrderResult.module.scss";

const cx = className.bind(styles);

function useInterval(callback, delay) {
  const savedCallback = useRef(() => ({}));

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

interface IProps {
  history: History<{ partnerOptions: IPartnerOptionViewModel[] }>;
  match: match<{ orderNum: string }>;
}

export const OrderResult: FC<IProps> = ({
  history,
  match: {
    params: { orderNum }
  }
}) => {
  const dispatch = useDispatch();
  const { email } = useSelector<IStore, IStore["member"]>(state => state.member);
  const [orderResult, setOrderResult] = useState<IOrderResultViewModelV3>();
  const [isFinished, setIsFinished] = useState(false);
  const orderOptions = useMemo(() => storageContext.getOrderOptions(), []);

  const getMembersOrdersOrderNumDetailV3 = useCallback(() => {
    api
      .get__members__orders__order_num__detail_v3(orderNum)
      .then(({ data: { payload } }) => {
        Promise.all([
          api.get__members__issued_discount_coupons(),
          api.get__api__v1__members__carts({ page: { number: 1, size: 1 } })
        ]).then(([coupons, carts]) => {
          const cart: IPageCamel = camelizeKeys(carts.data.meta) as IPageCamel;
          dispatch(initialize({ cart, coupons }));
        });

        const orderResult = orderResultViewModelCreatorV3(payload);
        setOrderResult(orderResult);

        return orderResult;
      })
      .then(orderResult => {
        if (orderResult.title === "주문이 완료되었습니다!" && orderOptions.length) {
          Tracking.purchase(
            {
              id: orderResult.paymentInfo[0][1],
              revenue: orderResult.totalPriceKV[1]
            },
            orderOptions.map(
              ({ productName, optionId, priceToBuy, storeName, optionDesc, selectedCount, productId }) => ({
                name: productName,
                id: optionId,
                price: priceToBuy,
                brand: storeName,
                variant: optionDesc,
                quantity: selectedCount,
                product_id: productId
              })
            )
          );
          setIsFinished(true);
        }
      })
      .catch(() => {
        alert("결제 실패. 다시 시도해 주세요.");
        history.replace({ pathname: "/order", state: { partnerOptions: orderOptions } });
      });
  }, [dispatch, history, orderNum, orderOptions]);

  useEffect(() => {
    setIsFinished(false);

    getMembersOrdersOrderNumDetailV3();
  }, [getMembersOrdersOrderNumDetailV3]);

  useEffect(() => {
    if (isFinished && orderResult && orderOptions.length) {
      const {
        paymentInfo,
        totalPriceKV: [_, totalPriceValue]
      } = orderResult;

      const paymentNumber = paymentInfo[0][1];
      const totalValue = totalPriceValue;

      Tracking.purchaseResult(orderResult.totalPriceKV[1]);

      AirbridgeEvent.orderCompleted({
        semanticAttributes: {
          inAppPurchased: false,
          totalValue,
          transactionID: paymentNumber,
          currency: "KRW",
          products: orderOptions.map(({ productName, priceToBuy, selectedCount, productId }, index) => ({
            productID: productId,
            name: productName,
            currency: "KRW",
            price: priceToBuy,
            position: index + 1,
            quantity: selectedCount
          }))
        }
      });

      if (process.env.REACT_APP_ENV === "production") {
        ReactGA.plugin.require("ecommerce");
        ReactGA.plugin.execute("ecommerce", "addTransacton", {
          id: paymentNumber,
          revenue: totalValue,
          tax: 0,
          shipping: 0,
          currency: "KRW"
        });
        orderOptions.map(({ productName, priceToBuy, storeName, selectedCount }) =>
          ReactGA.plugin.execute("ecommerce", "addItem", {
            id: paymentNumber,
            name: productName,
            sku: "--",
            price: priceToBuy,
            store: storeName,
            quantity: selectedCount
          })
        );

        const criteoSaleItem = orderOptions.map(option => {
          return {
            id: option.productId,
            price: option.price,
            quantity: option.selectedCount
          };
        });
        ReactGA.plugin.execute("ecommerce", "send", "ecommerce");

        criteoEventPush({ email, event: "trackTransaction", item: criteoSaleItem, id: paymentNumber });

        if (kakaoPixelContext) {
          kakaoPixelContext.purchase({
            total_price: String(totalValue),
            total_quantity: String(orderOptions.reduce((acc, { selectedCount }) => acc + selectedCount, 0)),
            products: orderOptions.map(
              ({ productName, optionId, priceToBuy, storeName, optionDesc, selectedCount }) => ({
                name: productName,
                id: optionId,
                price: String(priceToBuy),
                brand: storeName,
                variant: optionDesc,
                quantity: String(selectedCount)
              })
            )
          });
        }
      }

      storageContext.removeOrderOptions();
    }
  }, [orderResult, isFinished, email, orderOptions]);

  const getIsOrderResultProceeding = () => {
    return orderResult?.isProceeding === true && orderResult?.isSuccess === false;
  };

  const refTickLimit = useRef(0);

  useInterval(
    () => {
      getMembersOrdersOrderNumDetailV3();
      refTickLimit.current++;
    },
    getIsOrderResultProceeding() && refTickLimit.current < 10 ? 1000 : null
  );

  if (!orderResult) return null;

  const {
    title,
    paymentInfo,
    totalPriceKV: [totalPriceKey, totalPriceDesc],
    userInfo
  } = orderResult;

  if (getIsOrderResultProceeding()) {
    return (
      <main className={cx("order-result-tab")}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationLoadingData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
          width={60}
          height={60}
        />
        <br />
        <h3>결제가 진행 중입니다. 잠시만 기다려 주세요.</h3>
      </main>
    );
  }

  return (
    <main className={cx("order-result-tab")}>
      <section className={cx("section")}>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
          height={60}
          width={60}
        />

        <div className={cx("title")}>
          <div className={cx("head", ["middle"])}>{title}</div>
        </div>

        <div className={cx("receipt-box")}>
          <div className={cx("box")}>
            <div className={cx(["middle"], "head")}>결제정보</div>

            <KeyValueTemplate list={paymentInfo} />

            <div className={cx("total-price")}>
              <span className={cx(["middle"], "key")}>{totalPriceKey}</span>
              <span className={cx(["bold"], "value")}>{totalPriceDesc}</span>
            </div>
          </div>

          <div className={cx("box")}>
            <div className={cx(["middle"], "head")}>주문자&nbsp;정보</div>
            <KeyValueTemplate list={userInfo} />
          </div>
        </div>

        <div className={cx("btns")}>
          <Link to="/member" className={cx("link", "btn")}>
            마이룩핀
          </Link>

          <Link to="/" className={cx("link", "btn", "blue", "white")}>
            메인으로
          </Link>
        </div>
      </section>
    </main>
  );
};
