import { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import qs from "qs";

import { coordiViewModelCreator } from "view-model-creator";

import { api__v1__coordi__search_get } from "api";

import { TRENDING, NEWEST, COORDI_VIEW_SIZE } from "constant";

import { IStore, ICoordiViewModel } from "typings";
import { produce, Tracking } from "utils";

export const useCoordiSearch = () => {
  const mainRef = useRef<HTMLElement>(null);
  const history = useHistory();
  const { search, pathname } = useLocation();
  const { isMobile, genderId } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);

  const query = qs.parse(search, { ignoreQueryPrefix: true });

  const tags: Set<string> = new Set((query?.searchKeywords as string[]) ?? []);

  const [coordi, setCoordi] = useState<ICoordiViewModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [totalCount, setTotalCount] = useState(COORDI_VIEW_SIZE);

  const order = (query?.order as any) ?? TRENDING;
  const page = Number(query?.page ?? 1);

  const orders = {
    [TRENDING]: "인기순",
    [NEWEST]: "신규순"
  };

  const createSearch = func =>
    qs.stringify(
      {
        searchKeywords: [...(produce(tags, func) as any)],
        order,
        page: 1
      },
      { addQueryPrefix: true }
    );

  const handleOrder = event => {
    history.push({
      pathname,
      search: qs.stringify({ searchKeywords: [...tags], order: event.target.value, page: 1 })
    });
  };

  const changeKeyword = event => {
    if (tags.size === 3) alert("검색 키워드는 최대 3개여야 합니다");
    else setKeyword(event.target.value);
  };

  useEffect(() => {
    setIsPaginationLoading(true);

    setIsLoading(true);

    (async () => {
      const {
        data: { payload, meta }
      } = await api__v1__coordi__search_get({
        searchKeywords: [...tags],
        order,
        page: {
          number: page,
          size: 24
        },
        keywordsCombineOption: "or",
        genderId
      });
      setCoordi(coordiViewModelCreator(payload));
      setTotalCount(meta.total_count);
      setIsLoading(false);
      setIsPaginationLoading(false);

      if (mainRef.current) mainRef.current.style.minHeight = "auto";
    })();
  }, [search, order, genderId, isMobile]); /* eslint-disable-line react-hooks/exhaustive-deps */

  const handlePage = (page: number) => {
    history.push({
      pathname,
      search: qs.stringify({ searchKeywords: [...tags], order, page })
    });

    window.scrollTo(0, 0);
  };

  const toggleTag = event => {
    const { tag } = event.currentTarget.dataset;

    if (tags.has(tag)) {
      history.push({
        pathname,
        search: createSearch(draft => {
          draft.delete(tag);
        })
      });
    } else if (tags.size === 3) {
      alert("검색 키워드는 최대 3개여야 합니다");
    } else {
      if (tag) {
        if (process.env.REACT_APP_ENV === "production") {
          Tracking.search(tag);
        } else {
          console.warn("search", "useCoordiSearch", "toggleTag");
          console.warn({ keyword: tag });
        }
      }

      history.push({
        pathname,
        search: createSearch(draft => {
          draft.add(tag);
        })
      });

      if (tags.size === 2) setKeyword("");
    }
  };

  const addTag = event => {
    const keyword = event.target.value.trim();

    if (event.key === " " && keyword) {
      if (tags.size === 3) alert("검색 키워드는 최대 3개여야 합니다");
      else {
        if (process.env.REACT_APP_ENV === "production") {
          Tracking.search(keyword);
        } else {
          console.warn("search", "useCoordiSearch", "addTag");
          console.warn({ keyword });
        }

        history.push({
          pathname,
          search: createSearch(draft => {
            draft.add(keyword);
          })
        });

        setKeyword("");
      }
    }
  };

  const deleteTag = event => {
    if (event.key === "Backspace" && !event.target.value && tags.size) {
      history.push({
        pathname,
        search: createSearch(draft => {
          draft.delete([...draft.values()][draft.size - 1]);
        })
      });
    }
  };

  const handleEnter = event => {
    const keyword = event?.target?.value?.trim();

    if (event.key === "Enter" && (tags.size || keyword)) {
      if (keyword) {
        if (process.env.REACT_APP_ENV === "production") {
          Tracking.search(keyword);
        } else {
          console.warn("search", "useCoordiSearch", "handleEnter");
          console.warn({ keyword });
        }
      }

      history.push({
        pathname,
        search: createSearch(draft => {
          if (keyword) draft.add(keyword);
          setKeyword("");
        })
      });
    }
  };

  return {
    tags,
    toggleTag,
    addTag,
    deleteTag,
    keyword,
    changeKeyword,
    handleEnter,
    coordi,
    isLoading,
    isPaginationLoading,
    order,
    handleOrder,
    orders,
    handlePage,
    page,
    totalCount,
    mainRef
  };
};
