import { fetcher } from "utils";
import {
  IPage,
  IDateRange,
  FilterGroupType,
  OrderDateAtType,
  IJSendSuccessResponse,
  IOrderProductHistorySerialized,
  IWithMeta,
  IPageMeta
} from "typings";

type MemberOrderProductsFetcher = (params: {
  page: IPage;
  created_at?: IDateRange;
  filter_group?: FilterGroupType;
  order?: OrderDateAtType;
  order_order_num?: string; // 주문 내 주문상품들을 볼때 넣어준다.
  filter?: "reviewable";
}) => Promise<IJSendSuccessResponse<IWithMeta<IOrderProductHistorySerialized[], IPageMeta>>>;

export const get__api_v1_members_orderproducts: MemberOrderProductsFetcher = params =>
  fetcher.get("/api/v1/members/order_products", params);
