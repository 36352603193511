import { fetcher } from "utils";

export const get__products__order_points__range = (params: {
  productIds: Array<{
    productId: number | string;
    optionId?: number | string;
  }>;
  discount_price?: number;
}): Promise<{
  min_point: number;
  max_point: number;
}> => fetcher.get("/products/order_points/range", params);
