import { useCallback, useRef } from "react";

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import * as api from "api";

import { modalOn, addSetsItemAction, removeSetsIteAction } from "actions/actionCreators";
import { UI__MODAL_LOGIN } from "constant";

import { IStore, HandlePin } from "typings";
import { Tracking, AirbridgeCustomEvent } from "utils";
import { useSets } from "./useSets";

export const usePin = () => {
  const dispatch = useDispatch();
  const pending = useRef(false);
  const token = useSelector<IStore, IStore["member"]["token"]>(({ member: { token } }) => token, shallowEqual);
  const { pins } = useSets();

  const getIsPin = useCallback((id: number) => pins.has(id), [pins]);

  const deletePin = useCallback(
    async (...ids: number[]) => {
      await api.api_v1__pin_delete({ type: "include", ids });
      ids.forEach(id => {
        dispatch(removeSetsIteAction({ key: "pins", value: id }));
      });
    },
    [dispatch]
  );

  const handlePin: HandlePin = useCallback(
    async (id, name, price) => {
      if (!pending.current) {
        pending.current = true;
        if (!token) dispatch(modalOn(UI__MODAL_LOGIN));
        else if (getIsPin(id)) await deletePin(id);
        else {
          await api.post__users__products({ productId: id });
          dispatch(addSetsItemAction({ key: "pins", value: id }));
          Tracking.addToWishList({
            content_name: name,
            content_ids: [String(id)],
            content_type: "product",
            value: Number(price),
            currency: "KRW"
          });
          AirbridgeCustomEvent.like("product", {
            id,
            name,
            price
          });
        }
        pending.current = false;
      }
    },
    [token, dispatch, deletePin, getIsPin]
  );

  return { getIsPin, deletePin, handlePin };
};
