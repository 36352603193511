import axios from "axios";
import { decamelizeKeys } from "humps";
import qs from "qs";
import * as Sentry from "@sentry/browser";

import { API_PRO_URL, API_DEV_URL } from "api/urls";

import { GUEST_TOKEN } from "constant";

import { historyContext, storageContext } from "contexts";
import { version } from "../../package.json";

export const client = axios.create({
  baseURL: process.env.REACT_APP_ENV === "production" ? API_PRO_URL : API_DEV_URL,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: "brackets" }),
  headers: {
    "Lookpin-Platform": "Web",
    "Lookpin-App-Version": `${version}`
  }
});

client.interceptors.request.use(request => {
  const { params, data } = request;

  if (params) request.params = decamelizeKeys(params);
  else if (data && !(data instanceof FormData)) {
    request.data = decamelizeKeys(data);
  }

  request.headers.Authorization = `Token token=${storageContext.token || GUEST_TOKEN}`;
  request.headers["Lookpin-Device-UUID"] = storageContext.uuid;

  return request;
});

// client.interceptors.response.use(response => camelizeKeys(response));

export default function request(options) {
  if (process.env.REACT_APP_ENV !== "production") console.log("OPTION::", options);

  const onSuccess = response => {
    const totalCount = response.headers["x-lookpin-total-count"];
    const { data } = response;

    if (process.env.REACT_APP_ENV !== "production") {
      console.log("Request Successful!", response);
    }

    if (data?.data?.meta?.notice) alert(data.data.meta.notice);

    return totalCount ? { data, totalCount } : data;
  };

  const onError = error => {
    if (!error.response) {
      Sentry.captureException(`!error.response:: ${error}`);
      return Promise.reject(error);
    }

    const { headers, message, status, data } = error.response;

    if (!data) {
      Sentry.captureException(`!error.response.data:: ${error}`);
      return Promise.reject(error.response);
    }

    if (data.status === "fail") return Promise.resolve(data);
    if (data === "Error was silenced") return Promise.reject(data);
    if (options.isCustomErrorHandle) return Promise.reject(error.response);
    if (process.env.REACT_APP_ENV === "production" && status !== 401 && !navigator.userAgent.includes("Prerender")) {
      Sentry.captureException(error);
      console.error("Request Failed:", error.config);
      console.error("Headers:", headers);
      console.error("Error message:", message);
      console.error("Status:", status);
      console.error("Data:", data);
    }

    if (data.message) alert(data.message);
    else if (Array.isArray(data.errors)) alert(data.errors.join("\n"));
    else if (status === 500) alert("에러가 발생하였습니다.");
    else if (!data.status && typeof data === "string") alert(data);
    else if (data?.data?.meta?.notice) alert(data.data.meta.notice);

    if (status === 404) historyContext.replace("/");

    if (status === 401 && storageContext.token) historyContext.replace("/logout");

    return Promise.reject(error.response);
  };

  return client(options)
    .then(onSuccess)
    .catch(onError);
}

// TODO: 데이터를 받자마자 에러 핸들링이 필요한 500같은 케이스는 catcher에서 관리하고, 그렇지 않은 케이스를 onError에서 처리해야 할 것으로 보임

// import { camelizeKeys } from "humps";

// const underToCamel = response => {
//   return produce(response, ({ data }) => {
//     if (data) {
//       if (!(data instanceof Array)) data = camelizeKeys(data);
//       else if (data instanceof Array && data[0] instanceof Object)
//         data = camelizeKeys(data);
//     }
//   });
// };

// client.interceptors.response.use(underToCamel, catcher);
