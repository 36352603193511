import React, { FC } from "react";

import classNames from "classnames/bind";

import styles from "./PointTooltip.module.scss";

const cx = classNames.bind(styles);

interface IProps {
  price: number;
}

export const PointTooltip: FC<IProps> = ({ price }) => (
  <div className={cx("wrapper")}>
    <ul>
      <li className={cx("header")}>
        <span className={cx("level")}>레벨</span>
        <span className={cx("percent")}>적립률</span>
        <span>적립금</span>
      </li>

      <li className={cx("content")}>
        <span id="lv1" className={cx("level")}>
          Lv.1 - 4
        </span>
        <span className={cx("percent")}>1%</span>
        <span>{Math.floor(price * 0.01).toLocaleString()}원</span>
      </li>

      <li>
        <span id="lv5" className={cx("level")}>
          Lv.5 - 7
        </span>
        <span className={cx("percent")}>1.5%</span>
        <span>{Math.floor(price * 0.015).toLocaleString()}원</span>
      </li>

      <li>
        <span id="lv8" className={cx("level")}>
          Lv.8 - 10
        </span>
        <span className={cx("percent")}>2%</span>
        <span>{Math.floor(price * 0.02).toLocaleString()}원</span>
      </li>

      <li>
        <span id="lv11" className={cx("level")}>
          Lv.11 - 12
        </span>
        <span className={cx("percent")}>2.5%</span>
        <span>{Math.floor(price * 0.025).toLocaleString()}원</span>
      </li>

      <li>
        <span id="lv13" className={cx("level")}>
          Lv.13이상
        </span>
        <span className={cx("percent")}>3%</span>
        <span>{Math.floor(price * 0.03).toLocaleString()}원</span>
      </li>
    </ul>
  </div>
);
