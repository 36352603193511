/* eslint-disable react/no-danger */
import { getInitPhotoReviewRequest, modalOn } from "actions/actionCreators";
import { CheckedIcon, FillRateStar, IconPin, UncheckedIcon } from "assets/icon";
import classNames from "classnames/bind";
import {
  ChoiceOptions,
  CouponIssuableButton,
  DynamicMeta,
  ExpectedOrderPoint,
  Loading,
  NoticeAboutLookpin,
  NoticeAboutProduct,
  Pagination,
  ProductQnaCard,
  ReviewTotal,
  SelectedOptions,
  ShippingSpeedOptions,
  SingleCounter,
  StoreRowWithBookmark
} from "components";
import { NOTICE_ABOUT_PRODUCT_BOLD_KEYS, UI__MODAL_PRODUCT_QNA } from "constant";
import { useQnARead } from "hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IStore } from "typings";
import { AirbridgeCustomEvent, criteoEventPush, imageLazyLoading, imageResizeHelper } from "utils";
import copyToClipboard from "utils/copyToClipboard";
import styles from "./ProductDetailDesktop.module.scss";
import { useProductDetail } from "./useProductDetail";
import { useReviews } from "./useReviews";

const cx = classNames.bind(styles);

type InfoTab = "상품정보" | "Q&A";

export function ProductDetailDesktop() {
  const dispatch = useDispatch();

  const { productId } = useParams() as { productId: string };
  const productDetailRef = useRef<HTMLDivElement>(null);

  const {
    product,
    store,
    hero,
    setHero,
    point,
    isIssuable,
    singleSelectedCount,
    selectedOptions,
    isPin,
    togglePin,
    handleCart,
    optionChecker,
    optionStack,
    optionStackSetter,
    setSingleSelectedCount,
    setSelectedOptions,
    purchase,
    isLoading,
    totalPrice,
    cartId
  } = useProductDetail();

  const { reviewSummary } = useReviews();

  const {
    qnaList,
    getProductQnaList,
    qnaPage,
    setQnaHiddenVisible,
    qnaHiddenVisible,
    qnaMeta,
    setQnaPageAndScrollPage
  } = useQnARead();

  useEffect(() => {
    if (product?.id) {
      getProductQnaList({
        page: qnaPage,
        visible: qnaHiddenVisible,
        productId: product.id
      });
    }
  }, [qnaPage, qnaHiddenVisible, getProductQnaList, product]);

  const [zoom, setZoom] = useState(false);
  const [zoomHeight, setZoomHeight] = useState(0);
  const [infoTab, setInfoTab] = useState<InfoTab>("상품정보");
  const { email } = useSelector<IStore, IStore["member"]>(({ member }) => member);

  const zoomOut = useCallback(() => setZoom(false), []);

  const handleProductCopyToClipboard = useCallback(() => {
    copyToClipboard("상품");
    AirbridgeCustomEvent.share("product", {
      channel: "링크"
    });
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    setInfoTab("상품정보");
    if (product?.id) dispatch(getInitPhotoReviewRequest(product.id));
  }, [dispatch, product]);

  // TODO: 나중에 useZoom 훅으로 빼면 좋을듯 (이미지 zoom)
  const updateScreenSize = useCallback(() => {
    const e = document.documentElement;
    const b = document.getElementsByTagName("body")[0];
    const height = window.outerHeight || e.clientHeight || b.clientHeight;

    setZoomHeight(height);
  }, []);

  useEffect(() => {
    updateScreenSize();

    window.addEventListener("resize", updateScreenSize);
    window.addEventListener("scroll", zoomOut);

    return () => {
      window.removeEventListener("resize", updateScreenSize);
      window.removeEventListener("scroll", zoomOut);
    };
  }, [updateScreenSize, zoomOut]);

  useEffect(() => {
    if (product) criteoEventPush({ email, event: "viewItem", item: product.id });
  }, [product, email]);
  useEffect(() => {
    if (productDetailRef.current) imageLazyLoading(productDetailRef.current);
  }, [product, store, infoTab]);

  if (isLoading || !product || !store) return <Loading />;

  return (
    <>
      <DynamicMeta
        description={`${product.price}원`}
        title={product.name}
        image={product.photoUrl ? imageResizeHelper(product.photoUrl, 300) : undefined}
        url={window.location.href}
      />

      <main className={cx("itemDetail")}>
        <div className={cx("view")}>
          <div className={cx("imageTab")}>
            <button
              type="button"
              className={cx("imageBtn", { zoom })}
              onClick={() => setZoom(!zoom)}
              style={zoom ? { height: zoomHeight } : { height: "inherit" }}
            >
              {zoom && <div className={cx("zoom-bg")} />}
              <picture>
                <img src={imageResizeHelper(hero)} className={cx("image")} alt={product.name} />
              </picture>
            </button>

            <div className={cx("thumbnails")}>
              {product.info.images.main.map(image => (
                <button type="button" key={image} onClick={() => setHero(image)}>
                  <picture>
                    <img src={imageResizeHelper(image)} alt={image} />
                  </picture>
                </button>
              ))}
            </div>

            <StoreRowWithBookmark
              id={store.id}
              name={store.name}
              group={store.group}
              photoUrl={store.photoUrl}
              serializedTags={store.serializedTags}
              genderId={store.genderId}
            />
          </div>

          <div className={cx("textTab")}>
            <div className={cx("discountText")}>
              {product.discountRate && <span>{product.discountRate}할인&nbsp;</span>}
              <span className={cx("free")}>무료배송</span>
              <button
                type="button"
                className={cx("copy")}
                onClick={handleProductCopyToClipboard}
                onKeyDown={handleProductCopyToClipboard}
              >
                URL 복사
              </button>
            </div>

            <span className={cx("title")}>{product.name}</span>

            <div className={cx("priceTab", ["bold"])}>
              <div>
                <span className={cx("price")}>{product.price.toLocaleString()}원</span>
                &nbsp;
                {product.prevPrice !== product.price && (
                  <span className={cx("prevPrice")}>{product.prevPrice.toLocaleString()}원</span>
                )}
              </div>
              {reviewSummary && reviewSummary.totalCount > 0 && (
                <div className={cx("rating")}>
                  <div className={cx("starCount")}>
                    <FillRateStar />
                    <span>{reviewSummary.averageSatisfaction}</span>
                  </div>
                  <span className={cx("rateCountText")}>{reviewSummary.totalCount.toLocaleString()}명 평가</span>
                </div>
              )}
            </div>

            <div className={cx("methods-descripton")}>
              {product.shippingSpeed && <ShippingSpeedOptions shippingSpeed={product.shippingSpeed} />}
              <ExpectedOrderPoint price={product.price} point={point} />

              <CouponIssuableButton isIssuable={isIssuable} />
            </div>

            <hr className={cx("hr")} />

            {product.info.options.type === "single" ? (
              <SingleCounter
                price={product.price}
                stockCount={product.info.options.data[0].data.stockCount}
                singleSelectedCount={singleSelectedCount}
                setSingleSelectedCount={setSingleSelectedCount}
              />
            ) : (
              <>
                <div className={cx("count-multiple")}>
                  <p className={cx("text-optionChoice")}>옵션선택</p>
                  <ChoiceOptions
                    options={product.info.options}
                    optionChecker={optionChecker}
                    optionStack={optionStack}
                    optionStackSetter={optionStackSetter}
                  />
                </div>
                <SelectedOptions
                  product={product}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  optionChecker={optionChecker}
                />
              </>
            )}

            <div className={cx("total")}>
              <span className={cx("totalText")}>총 상품금액</span>
              <span className={cx("totalPrice")}>{totalPrice.toLocaleString()}원</span>
            </div>

            {product.soldOut ? (
              <div className={cx("soldOut")}>품절된 상품입니다.</div>
            ) : (
              <div className={cx("actions")}>
                <button className={cx("pin", { isPin })} onClick={togglePin} type="button">
                  <IconPin />
                  핀하기
                </button>

                <button className={cx("cart")} onClick={handleCart} type="button" id={cartId}>
                  장바구니
                </button>

                <button className={cx("purchase")} onClick={purchase} type="button">
                  구매하기
                </button>
              </div>
            )}
          </div>
        </div>
        {reviewSummary && reviewSummary.totalCount > 0 && <ReviewTotal reviewSummary={reviewSummary} />}

        <div className={cx("desktopTab-wrapper")}>
          <div
            role="button"
            tabIndex={0}
            className={cx({ selectedTab: infoTab === "상품정보" })}
            onClick={_ => setInfoTab("상품정보")}
            onKeyDown={() => {}}
          >
            상품정보
          </div>

          <div
            role="button"
            tabIndex={0}
            className={cx({ selectedTab: infoTab === "Q&A" })}
            onClick={_ => setInfoTab("Q&A")}
            onKeyDown={() => {}}
          >
            Q&A ({qnaMeta && qnaMeta.totalCount.toLocaleString()})
          </div>
        </div>

        {infoTab === "상품정보" && (
          <>
            <div
              ref={productDetailRef}
              className={cx("details")}
              dangerouslySetInnerHTML={{ __html: product.info.html }}
            />

            <div className={cx("infoTab")}>
              <NoticeAboutProduct product={product} boldKeys={NOTICE_ABOUT_PRODUCT_BOLD_KEYS} />
              <hr className={cx("hr")} />
              <NoticeAboutLookpin />
            </div>
          </>
        )}

        {infoTab === "Q&A" && (
          <div id="qna-wrapper" className={cx("desktopQna-wrapper")}>
            <div className={cx("qna-title")}>Q&A ({qnaMeta && qnaMeta.totalCount.toLocaleString()})</div>
            <div className={cx("qna-header")}>
              <button
                type="button"
                className={cx("qna-button")}
                onClick={() =>
                  dispatch(
                    modalOn(UI__MODAL_PRODUCT_QNA, {
                      productId,
                      productTitle: product.name,
                      productImage: hero,
                      storeName: store.name
                    })
                  )
                }
              >
                상품 문의하기
              </button>
              <label className={cx("qna-hidden-label")} onClick={() => setQnaHiddenVisible(qnaHidden => !qnaHidden)}>
                <div>{qnaHiddenVisible ? <CheckedIcon /> : <UncheckedIcon />}</div>
                <span className={cx("qna-checkbox-title")}>비공개 문의 제외</span>
              </label>
            </div>
            {qnaList.length > 0 ? (
              <>
                <div className={cx("qna-list-wrapper")}>
                  {qnaList.map(qna => (
                    <ProductQnaCard key={qna.id} {...qna} />
                  ))}
                </div>
                <div className={cx("pagination-wrapper")}>
                  {qnaMeta && (
                    <Pagination
                      totalCount={qnaMeta.totalCount}
                      number={qnaPage.number}
                      size={qnaPage.size}
                      handler={setQnaPageAndScrollPage}
                    />
                  )}
                </div>
              </>
            ) : (
              <div className={cx("qna-list-no-exist")}>문의내역이 없습니다.</div>
            )}
          </div>
        )}
      </main>
    </>
  );
}
