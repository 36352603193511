import { fetcher } from "utils";

type GetMembersOrderPointsValid = (params: {
  optionIds: number[];
  consumedPoint?: number;
  discountPrice?: number;
}) => Promise<{
  status: "success" | "error";
  message: null | string;
  consumed_point: null | number;
  is_max_point: boolean;
}>;

export const get__members__order_points__valid: GetMembersOrderPointsValid = params =>
  fetcher.get("/members/order_points/valid", params);
