import { fetcher } from "utils";

type GetUserableLikeOrderProuctReviews = () => Promise<number[]>;

export const get__userable_like__order_product_reviews: GetUserableLikeOrderProuctReviews = async () => {
  const reviewLikeFetch = async ({ number = 1, size = 50 }) => {
    const {
      data: { order_product_reviews }
    } = await fetcher.get("/userable_like/order_product_reviews", {
      page: { number, size }
    });
    const fetchIds = order_product_reviews.map(reviewData => reviewData.id);
    if (fetchIds.length !== 50) return [...fetchIds];

    const memozationData = await reviewLikeFetch({ number: number + 1, size: 50 });
    return [...fetchIds, ...memozationData];
  };
  const resData = await reviewLikeFetch({ number: 1, size: 50 });
  return resData;
};
