import {
  WOMEN,
  MEN,
  KAKAO,
  NAVER,
  SALE,
  SOLDOUT_OPTION,
  SOLDOUT_PRODUCT,
  SALE_STOP,
  TRENDING,
  NEWEST,
  EXPENSIVE,
  CHEAP,
  RISING,
  UI__MODAL_ALREADY_ACCOUNT_LOGIN,
  UI__MODAL_DISCONNECT_PROVIDER,
  UI__MODAL_EMAIL_SIGNUP,
  UI__MODAL_FILL_EMAIL,
  UI__MODAL_FIND_PASSWORD,
  UI__MODAL_LOGIN,
  UI__MODAL_UPDATE_PASSWORD,
  UI__MODAL_ISSUABLE_COUPON,
  UI__MODAL_APPLY_COUPON,
  UI__MODAL_COUPON_REGISTER,
  UI__MODAL_PICKUP_ADDRESS,
  UI__FORM_FAILURE_EMAIL_INVALID,
  UI__FORM_FAILURE_PASSWORD_BLANK,
  UI__FORM_FAILURE_PASSWORD_INVALID,
  UI__FORM_FAILURE_PASSWORD_CONFIRM_INVALID,
  UI__FORM_FAILURE_PASSWORD_NOT_EQUAL,
  UI__FORM_FAILURE_PASSWORD_NOT_CHANGE,
  UI__FORM_FAILURE_PASSWORD_CURRENT_NOT_CORRECT,
  CART,
  PRODUCT_DETAIL,
  UI__MODAL_PRODUCT_QNA,
  UI__MODAL_NUMBER_AUTH,
  UI__MODAL_SIMPLE_REVIEW,
  UI__MODAL_NORMAL_PHOTO_REVIEW,
  UI__MODAL_DETAIL_PHOTO_REVIEW,
  UI__MODAL_GRID_PHOTO_REVIEW,
  UI__MODAL_REVIEW_REPLACE
} from "constant";

import {
  NewProductSerialized,
  ProductSerialized,
  StoreSerialized,
  UsersStoreSerialized,
  ProductDetailSerialized,
  CoordinationSerialized,
  DiscountCouponSerialized,
  IssuedDiscountCouponSerialized,
  ProductOptionSerialized,
  MemberSerialized,
  IPageMeta
} from "typings/serialized";

import {
  IShippingInfoViewModel,
  IIssuedDiscountCouponViewModel,
  IRecentViewModel,
  IReviewViewModel,
  IPartnerOptionViewModel
} from "typings/viewModel";
import { IOrderProductReviewsCamel } from "./camel";

export * from "typings/api";
export * from "typings/camel";
export * from "typings/serialized";
export * from "typings/viewModel";
export * from "typings/hooks";

export type ModalStatus =
  | typeof UI__MODAL_ALREADY_ACCOUNT_LOGIN
  | typeof UI__MODAL_DISCONNECT_PROVIDER
  | typeof UI__MODAL_EMAIL_SIGNUP
  | typeof UI__MODAL_FILL_EMAIL
  | typeof UI__MODAL_FIND_PASSWORD
  | typeof UI__MODAL_LOGIN
  | typeof UI__MODAL_UPDATE_PASSWORD
  | typeof UI__MODAL_ISSUABLE_COUPON
  | typeof UI__MODAL_APPLY_COUPON
  | typeof UI__MODAL_COUPON_REGISTER
  | typeof UI__MODAL_PICKUP_ADDRESS
  | typeof UI__MODAL_PRODUCT_QNA
  | typeof UI__MODAL_NUMBER_AUTH
  | typeof UI__MODAL_SIMPLE_REVIEW
  | typeof UI__MODAL_NORMAL_PHOTO_REVIEW
  | typeof UI__MODAL_DETAIL_PHOTO_REVIEW
  | typeof UI__MODAL_GRID_PHOTO_REVIEW
  | typeof UI__MODAL_REVIEW_REPLACE;

export type PhotoModalStatus = "grid" | "detail" | null;
export interface IFormFailure {
  UI__FORM_FAILURE_EMAIL_INVALID?: typeof UI__FORM_FAILURE_EMAIL_INVALID;
  UI__FORM_FAILURE_PASSWORD_BLANK?: typeof UI__FORM_FAILURE_PASSWORD_BLANK;
  UI__FORM_FAILURE_PASSWORD_INVALID?: typeof UI__FORM_FAILURE_PASSWORD_INVALID;
  UI__FORM_FAILURE_PASSWORD_CONFIRM_INVALID?: typeof UI__FORM_FAILURE_PASSWORD_CONFIRM_INVALID;
  UI__FORM_FAILURE_PASSWORD_NOT_EQUAL?: typeof UI__FORM_FAILURE_PASSWORD_NOT_EQUAL;
  UI__FORM_FAILURE_PASSWORD_NOT_CHANGE?: typeof UI__FORM_FAILURE_PASSWORD_NOT_CHANGE;
  UI__FORM_FAILURE_PASSWORD_CURRENT_NOT_CORRECT?: typeof UI__FORM_FAILURE_PASSWORD_CURRENT_NOT_CORRECT;
}

export type Provider = typeof KAKAO | typeof NAVER;

export type Gender = typeof WOMEN | typeof MEN;
export type GenderMember = Gender | null;
export type GenderPartner = Gender | "both"; // both는 브랜드, Client에서 보여줄 때는 쇼핑몰만이 성별과 유관

export type JSendStatus = "success" | "fail" | "error";

export type StoreGroupId = "default" | "brand";

export type DisplayType = "both" | "lookpin_pay";

export type Order = typeof TRENDING | typeof NEWEST | typeof EXPENSIVE | typeof CHEAP | typeof RISING;

export type EventPosition =
  | "today_top"
  | "today_bottom"
  | "rank_bottom"
  | "sale_middle"
  | "launch_popup"
  | "home_main_top";

export type PayMethod =
  | ["카드결제", "card"]
  | ["가상계좌", "vbank"]
  | ["페이코", "payco"]
  | ["네이버페이", "naverpay"]
  | ["카카오페이", "kakaopay"]
  | ["토스페이", "tosspay"]
  | ["핸드폰 결제", "phone"];
export type PayMethodKo = "카드결제" | "가상계좌" | "페이코" | "네이버페이" | "카카오페이" | "토스페이" | "핸드폰 결제";
export type PayMethodEn = "card" | "vbank" | "payco" | "naverpay" | "kakaopay" | "tosspay" | "phone";
export interface PayMethodV3 {
  text: PayMethodKo;
  code: PayMethodEn;
}

export type ProductStatus = typeof SALE | typeof SOLDOUT_OPTION | typeof SOLDOUT_PRODUCT | typeof SALE_STOP;
export type ShipingSpeed = "fast" | "slow" | null;
export type ReviewSize = "좋아요" | "크네요" | "작네요";
export type ReviewColor = "똑같아요" | "비슷해요" | "다르네요";
export type ReviewShipping = "빠르네요" | "괜찮네요" | "느리네요";
export type ReviewSort = "recommending" | "newest";

export type ShippingKey = "fast" | "fine" | "slow";
export type ColorKey = "same" | "fine" | "different";
export type SizeKey = "fine" | "big" | "small";

export type UserableType = "Member" | "User";

export type Context = typeof CART | typeof PRODUCT_DETAIL;

export type FieldStatus =
  | "pay_wait"
  | "pay_success"
  | "pay_cancel"
  | "re_ship"
  | "refund_wait"
  | "ship_wait"
  | "ship_ing"
  | "ship_done"
  | "cancel_soon_auto"
  | "cancel_wait"
  | "change_wait"
  | "confirm"
  | "claim"
  | "refund_done";

export enum EFieldStatus {
  cancelSoonAuto = "cancel_soon_auto",
  cancelWait = "cancel_wait",
  changeWait = "change_wait",
  refundWait = "refund_wait"
}

export type StatusText =
  | "입금대기"
  | "결제완료"
  | "배송준비중"
  | "배송중"
  | "배송완료"
  | "주문무효(입금기한 만료)"
  | "취소접수"
  | "취소철회"
  | "취소완료(1~3일 소요)"
  | "교환접수"
  | "수거완료"
  | "재배송처리"
  | "교환보류"
  | "교환철회"
  | "교환완료(1~3일 소요)"
  | "환불접수"
  | "환불예정"
  | "환불완료(1~3일 소요)"
  | "환불보류"
  | "환불철회"
  | "환불예정(판매자취소)"
  | "구매확정";

export type NumberAuthCallBackFunction = (payload: { phoneNumber: string; marketingAgree: boolean }) => void;
export type ReviewType = "cremaReview" | "lookpinReview";

export type FilterGroupType = "pay" | "ship_wait" | "ship_ing" | "ship_done" | "claim";

export type OrderDateAtType = "created_at" | "updated_at";

export type AgreeMentChangeKeys = keyof SignUpAgreeMent | "allAgree" | "allDisAgree";
export type ProductQuestionType = "상품" | "배송" | "재고" | "기타";
export type OrderProductCardType = "review" | "order";
export interface IJSendSuccessResponse<T> {
  status: "success";
  data: T;
}

export interface IJSendFailResponse<T> {
  status: "fail";
  data: T;
}

export interface IJSendErrorResponse<T> {
  status: "error";
  message: string;
  code?: number;
  data?: T;
}

export interface IWithMeta<T, M> {
  payload: T;
  meta: M;
}

export interface IPaginationWithHeader<T> {
  data: T;
  totalCount: number;
}

/**
 * @deprecated IRefundAccountV3 이용
 */
export interface IRefundAccount {
  name: string;
  bank: string;
  account: string;
}

export interface IRefundAccountV3 {
  /** 은행명 */
  bankName: string;
  /** 계좌번호 */
  accountNum: string;
  /** 예금주 */
  holder: string;
}

export interface IRefundAccountEntityV3 {
  /** 은행명 */
  bank_name: string;
  /** 계좌번호 */
  account_num: string;
  /** 예금주 */
  holder: string;
}

export interface IAddress {
  main: string;
  detail: string;
  zipcode: string;
}

// 입점사 정보
export interface IPartner {
  id: number;
  extra_info: {
    info?: [string, string][]; // 예시 : { info: [["업체명", "주식회사"], ...]}
  };
  is_auto_thumbnail: boolean;
  coordi_limit: number;
  store: StoreSerialized;
}

export type GetMembersIssuedDiscountCouponsResponse = IJSendSuccessResponse<
  IWithMeta<IssuedDiscountCouponSerialized[], IPageMeta>
>;

export interface IAppliedDiscountCouponParam {
  id: number;
  optionIds: number[];
}

export interface IDiscountCouponPrice {
  product_option_id: number;
  value: number;
}

export interface IPage {
  number: number;
  size: number;
}

export interface IPriceParam {
  gte?: number;
  lte?: number;
}

export interface IRangeParam {
  gte: number;
  lte: number;
}

export interface IDateRange {
  gte?: string | Date;
  lte?: string | Date;
}

export type GetUserablesProductsRecentResponse = IJSendSuccessResponse<{ products: ProductDetailSerialized[] }>;

export interface IAuthenticationState {
  provider: null | Provider;
  providerToken: null | string;
  email: null | string;
}

export interface IPostSocialAccountsSuccess {
  status: "success";
  data: {
    member: MemberSerialized;
    meta: {
      is_already_registered: boolean;
    };
  };
}

export interface IPostV1SocialAccountsSuccess {
  status: "success";
  data: {
    payload: MemberSerialized;
    meta: {
      is_already_registered: boolean;
    };
  };
}

export type IPostSocialAccountsFail = IJSendFailResponse<{
  email: string | null;
  verified_phone_number: boolean;
  meta: {
    is_already_registered: boolean;
  };
}>;

// TODO: 미완성
export interface INextStatus {
  text: "취소접수" | "환불접수" | "구매확정";
  partner_text?: string;
  action_type: "form" | "dialog";
  btn_text: "취소" | "교환" | "환불" | "구매확정";
  color: string;
  extra_info: {
    title?: string;
    reasons?: string[];
    policy_photo_url?: string;
    policy_photo_urls?: IPolicyPhotoUrls;
    message?: "주문을 취소하시겠습니까?" | "구매를 확정하시겠습니까?";
  };
  available_form?: ["pick_up_request"];
  status: FieldStatus;
}

// TODO: 서버에 요청해서 웹에서만 쓰였을 original_price_with_text, discount_amount, consumed_point 삭제 요청
export interface IMembersOrdersOrderNumDetail {
  title: "주문이 완료되었습니다!" | "주문이 실패했습니다";
  payment_info: Array<[string, string, string?]>;
  is_success: boolean;
  price_info: Array<[string, string]>;
  user_info: Array<[string, string]>;
  total_price: [string, string];
  original_price: string;
  // original_price_with_text: [string, string];
  // discount_amount: [string, string];
  // consumed_point: [string, string];
}

export interface IMembersOrdersOrderNumDetailV3 {
  title: "주문이 완료되었습니다!" | "주문이 실패했습니다";
  payment_info: Array<[string, string, string?]>;
  is_success: boolean;
  is_proceeding: boolean;
  price_info: Array<[string, string]> | null;
  user_info: Array<[string, string]>;
  total_price: [string, string] | null;
  original_price: string | null;
  original_price_with_text: [string, string] | null;
  discount_amount: [string, string] | null;
  consumed_point: [string, string] | null;
}

export interface IMembersOrderPoints {
  id: number;
  created_at: string;
  message: string;
  value: number;
  price: number | null;
  expired_at: string | null;
}

export interface IMembersOrderPointsSimple {
  point: number;
  level: number;
  color: string;
  level_point_rate: number;
  next_level_percent: number;
}

export interface IMembersOrderPointsDetail {
  level: number;
  level_score: number;
  points_to_next_level: number;
  point: number;
  last_2_years_price: number;
  points_to_will_expire: number;
}

export interface ImemberState {
  id: MemberSerialized["id"];
  email: MemberSerialized["email"];
  genderId: MemberSerialized["gender_id"];
  token: MemberSerialized["token"];
  hasPassword: MemberSerialized["has_password"];
  connectedSocialAccounts: MemberSerialized["connected_social_accounts"];
  shippingInfo: IShippingInfoViewModel;
  phoneNumber: MemberSerialized["phone_number"];
}

export interface ICartState {
  totalCount: number;
}

export type GetDiscountCouponsResponse = IJSendSuccessResponse<IWithMeta<DiscountCouponSerialized[], IPageMeta>>;

export interface IOptionValue {
  stockCount: number;
  optionId?: number;
  optionPrice?: number;
}

export interface ISelectedOption {
  optionId: number;
  optionText: string;
  optionPrice: number;
  selectedCount: number;
}

// not_member: 비회원 상태
// already_issued: 이미 발급된 상태
// not_issuable: 발급 불가 상태(발급수량 초과, 날짜 벗어남, 이미 발급)
// issuable: 발급 가능 상태

export type IssaubleStatus = "not_member" | "already_issued" | "not_issuable" | "issuable";

export type GetDiscountCouponsCheckIssuableResponse = IJSendSuccessResponse<{
  payload: {
    [key: string]: {
      status: IssaubleStatus;
    };
  };
}>;

export type GetMembersIssuedDiscountCouponsPossibleCouponsResponse = IJSendSuccessResponse<
  IWithMeta<
    {
      product_option_id: ProductOptionSerialized["id"];
      issued_discount_coupon_ids: IssuedDiscountCouponSerialized["id"][];
    }[],
    IPageMeta
  >
>;

export type OptionId = number;

export type FindCoupon = { [couponId: number]: IIssuedDiscountCouponViewModel };
export type FindPossibleCouponIds = Map<OptionId, IIssuedDiscountCouponViewModel[]>;
export type FindAppliableOptionIds = { [couponId: number]: OptionId[] };
export type CouponSelectHelper = {
  findCoupon: FindCoupon;
  findAppliableOptionIds: FindAppliableOptionIds;
  usableCouponIds: number[];
  findPossibleCouponIds: FindPossibleCouponIds;
};

export type GetUsersCoordiResponse = IJSendSuccessResponse<{
  coordinations: CoordinationSerialized[];
  meta: {
    current_page?: number;
    next_page?: number;
    prev_page?: number | null;
    total_pages?: number;
    total_count?: number;
  };
}>;

export interface IMembersShippingInfosFail {
  shipping_info: {
    name?: ["에 내용을 입력해 주세요"];
    phone_number?: Array<
      | "에 내용을 입력해 주세요"
      | "전화번호는 기호(-)나 공백없이 숫자여야 합니다"
      | "전화번호는 10자 혹은 11자 이어야합니다"
    >;
    address?: Array<" address는 main 은 비어있을 수 없습니다." | " zipcode는 5자리 또는 6자리 여야 합니다.">;
  };
}

export type GetMembersOrdersOrderNumDetailResponse = IJSendSuccessResponse<IMembersOrdersOrderNumDetail>;
export type GetMembersOrdersOrderNumDetailV3Response = IJSendSuccessResponse<{
  payload: IMembersOrdersOrderNumDetailV3;
}>;

export type GetProductsSearchV2Response = IJSendSuccessResponse<
  IWithMeta<
    ProductSerialized[],
    {
      max_price: null | number;
      total_count: number;
    }
  >
>;

export type GetProductsSearchV1Response = IJSendSuccessResponse<
  IWithMeta<
    NewProductSerialized[],
    {
      max_price: null | number;
      total_count: number;
    }
  >
>;

export type IUIState = {
  genderId: Gender;
  isLoading: boolean;
  isMobile: boolean;
  modalStatus: null | ModalStatus;
  modalCallbackFunction?: (payload: any) => void;
};

export type IPhotoReviewState = {
  photoModalDetail: IReviewViewModel | null;
  photoReviews: IReviewViewModel[];
  isLoading: boolean;
  isMorePhotoReview: boolean;
  photoReviewPage: number;
  photoReviewsTotalCount: number;
  nowProductId: number;
  selectedPhotoNumber: number;
};

export type IGetProductCombinationCategories = IJSendSuccessResponse<{
  product_combination_categories: {
    id: number;
    name: string;
    product_main_category_name: string;
    gender_id: Gender;
    ordering: number;
    displayed: true;
  }[];
}>;

export interface IGetMemberOrderProducts {
  meta: {
    current_page: number;
    next_page: number | null;
    prev_page: number | null;
    total_pages: number;
    total_count: number;
  };
  order_product_reviews: IOrderProductReviews[];
}

// Reviews
export interface ICremaImage {
  url: string;
  width: number;
  height: number;
  gallery_url: string;
  portrait_url: string;
  gallery_width: number;
  thumbnail_url: string;
  gallery_height: number;
  portrait_width: number;
  portrait_height: number;
  thumbnail_width: number;
  thumbnail_height: number;
}

export interface IOrderProductReviews {
  id: number;
  satisfaction: number;
  content: string;
  created_at: string;
  updated_at: string;
  images_count: number;
  images: null | {
    resized?: [
      {
        "300": string;
        "450": string;
        "900": string;
      }
    ];
  };
  size: ReviewSize;
  color: ReviewColor;
  shipping: ReviewShipping;
  like_count: number;
  userable_info: IReviewUserableInfo;
  product: ProductSerialized;
  order_product: {
    id: number;
    old_product_options: {
      id: number;
      data: {
        stock_count: number;
        option_price: number;
      };

      is_show: boolean;
      positions: [string, string];
      created_at: string;
      deleted_at: null | string;
      updated_at: string;
      selected_count: number;
      partner_product_info_id: number;
    };
    order_num: string;
  };
  member: IReviewMember;
}

export interface ICremaProductReviews {
  id: number;
  id_by_crema: string;
  user_name: string;
  message: string;
  score: number;
  likes_count: number;
  plus_likes_count: number;
  review_options: {};
  product_options: {};
  images: ICremaImage[] | null; // source 파라미터를 넘기면 images가 null이 아닌 것만 넘어옴
  images_count: number;
  created_at_by_crema: string;
  updated_at_by_crema: string;
}
export interface IReviewUserableInfo {
  id: number;
  age: null | number;
  sizes: null | string;
  height: number;
  weight: number;
  gender_id: GenderPartner;
  created_at: string;
  updated_at: string;
  userable_id: number;
  userable_type: UserableType;
  store_group_id: StoreGroupId;
}

export interface IReviewMember {
  id: number;
  email_masked: string;
  level: number;
}

export interface IReviewsSummariesV3 {
  product_id: number;
  total_count: number;
  order_product_review_summary: {
    total_count: number;
    satisfaction: number;
    detail_satisfactions: {
      code: string;
      title: string;
      text: string;
      percent: number;
    };
  } | null;
  crema_product_review_summary: {
    total_count: number;
    score: number;
  } | null;
}

export interface IOrderPointsState {
  point: IMembersOrderPointsSimple["point"];
  level: IMembersOrderPointsSimple["level"];
  color: IMembersOrderPointsSimple["color"];
  levelPointRate: IMembersOrderPointsSimple["level_point_rate"];
  nextLevelPercent: IMembersOrderPointsSimple["next_level_percent"];

  pointsToNextLevel: IMembersOrderPointsDetail["points_to_next_level"];
  last2YearsPrice: IMembersOrderPointsDetail["last_2_years_price"];
  pointsToWillExpire: IMembersOrderPointsDetail["points_to_will_expire"];
  levelScore: IMembersOrderPointsDetail["level_score"];

  limit: number;
  currentLevelInfo: [string, string][];
}

export interface ITimerState {
  timer: NodeJS.Timeout | null;
}

export interface IReviewWriteData {
  id?: number;
  product?: { productId: number; productTitle: string; productImage?: string; storeName: string };
  orderProductOrderNum?: string;
}

export interface IStore {
  ui: IUIState;
  member: ImemberState;
  orderPoints: IOrderPointsState;
  authentication: IAuthenticationState;
  coupons: IIssuedDiscountCouponViewModel[];
  sets: ISetsState;
  pickUpAddress: IAddress;
  cart: ICartState;
  recents: IRecentViewModel[];
  photoReview: IPhotoReviewState;
  timer: ITimerState;
  applyCouponData: ICouponApplyData;
  reviewWrite: IReviewWriteData;
}

export interface IInitializeCase {
  member?: MemberSerialized;
  orderPoints?: {
    simple: IMembersOrderPointsSimple;
    detail: IMembersOrderPointsDetail;
  };
  recents?: GetUserablesProductsRecentResponse;
  coupons?: GetMembersIssuedDiscountCouponsResponse;
  bookmarks?: UsersStoreSerialized[];
  cart?: ICartState;
}

export interface IInitializeSetCase {
  pins?: number[];
  coordi?: number[];
  bookmarks?: number[];
  reviewLikes?: number[];
  myReviewWrite?: IOrderProductReviewsCamel[];
}

export interface ISyncCase {
  recents?: IRecentViewModel[];
  pickUpAddress?: IAddress;
}

export interface ISetsState {
  bookmarks: Set<number>;
  pins: Set<number>;
  coordi: Set<number>;
  reviewLikes: Set<number>;
  myReviewWrite: Map<number, IOrderProductReviewsCamel>;
}

export enum ERemoteConfig {
  boldText = "product_details_text_style_bold"
}

export interface IPolicyPhotoUrls {
  ios: string;
  android: string;
  pc: string;
}
export interface ISetsActionArgument {
  key: keyof ISetsState;
  value: number;
}

export interface IOptionObject {
  key: string | number;
  value: string;
}

export interface SignUpAgreeMent {
  upperFourteen: boolean;
  serviceAgree: boolean;
  privacyAgree: boolean;
  usePrivacyAgree: boolean;
  marketingAgree: boolean;
}

export interface INoticeCard {
  title: string;
  subTitle: string;
  htmlUrl: string;
}

export interface IReviewWrite {
  id: number;
  satisfaction: number;
  size: SizeKey | null;
  color: ColorKey | null;
  shipping: ShippingKey | null;
  content?: string | null;
  photoURLs?: string[];
  userableInfo: {
    height: number | string;
    weight: number | string;
  };
}
export interface ICouponApplyData {
  trigger: OptionId | null;
  target: number[] | null;
  options: IPartnerOptionViewModel[] | null;
  coupon: IIssuedDiscountCouponViewModel | null;

  findAppliedOptions: FindAppliableOptionIds | null;
  findCoupon: FindCoupon | null;
  findOption: { [optionId: number]: IPartnerOptionViewModel } | null;
}
