import { useCallback, useRef, useState } from "react";
import * as api from "api";
import { ColorKey, IReviewWrite, ShippingKey, SizeKey } from "typings";
import { useDispatch } from "react-redux";
import { modalOff } from "modules/ui";
import { camelizeKeys } from "humps";
import { useSets } from "./useSets";
import { AirbridgeCustomEvent } from "utils";

export const useReviewWrite = (
  orderProductOrderNum: string = "",
  productId: number = 0,
  prevReview?: IReviewWrite,
  submitCallback?: (arg?: any) => void
) => {
  const dispatch = useDispatch();
  const { setsItemChange } = useSets();
  const pendding = useRef(false);
  const [satisfaction, setSatisfaction] = useState(prevReview?.satisfaction || 0);
  const [size, setSize] = useState<SizeKey | null>(prevReview?.size || null);
  const [color, setColor] = useState<ColorKey | null>(prevReview?.color || null);
  const [shipping, setShipping] = useState<ShippingKey | null>(prevReview?.shipping || null);
  const [content, setContent] = useState<string | undefined>(prevReview?.content || undefined);
  const [userableInfo, setUserableInfo] = useState<{ height: number | string; weight: number | string }>(
    prevReview?.userableInfo || { height: "", weight: "" }
  );

  const [photoDatas, setPhotoDatas] = useState<{ url: string; data: File | null | Blob }[]>(
    prevReview && prevReview.photoURLs ? prevReview.photoURLs.map(url => ({ url, data: null })) : []
  );

  const addPhoto = useCallback((target: File | Blob) => {
    const objURL = URL.createObjectURL(target);
    setPhotoDatas(prev => {
      if (prev.length >= 3) {
        alert("사진은 3장까지 등록 가능합니다.");
        return prev;
      }
      return [...prev, { url: objURL, data: target }];
    });
  }, []);

  const deletePhoto = useCallback((index: number) => {
    setPhotoDatas(prev => prev.filter((_, i) => i !== index));
  }, []);

  const photoUpdate = useCallback(async () => {
    const prevUrls = photoDatas.filter(({ data }) => data === null).map(({ url }) => url);
    const onlyFiles = photoDatas.filter(({ data }) => data !== null);
    if (onlyFiles.length !== 0) {
      const res = await api.post__image_upload(onlyFiles.map(data => data.data!));
      onlyFiles.forEach(blobData => {
        URL.revokeObjectURL(blobData.url);
      });
      const afterUploadURLs = res.data.payload.images;
      return [...prevUrls, ...afterUploadURLs];
    }
    return prevUrls;
  }, [photoDatas]);

  const getParams = useCallback(
    (photos: string[]) => ({
      satisfaction,
      size: size === null ? undefined : size,
      color: color === null ? undefined : color,
      shipping: shipping === null ? undefined : shipping,
      content,
      userable_info: { height: Number(userableInfo.height), weight: Number(userableInfo.weight) },
      photo_urls: photos
    }),
    [color, content, satisfaction, shipping, size, userableInfo.height, userableInfo.weight]
  );

  const submitReview = useCallback(async () => {
    if (pendding.current) {
      alert("업로드 중 입니다.");
      return;
    }
    pendding.current = true;
    const photos = await photoUpdate();

    if (userableInfo.height.toString().length === 0 || userableInfo.weight.toString().length === 0) {
      alert("키와 몸무게는 반드시 입력하셔야합니다");
      pendding.current = false;
      return;
    }
    try {
      if (prevReview?.id) {
        await api.api__patch_order_proudcts_reviews({
          id: prevReview.id,
          order_product_order_num: orderProductOrderNum,
          param: getParams(photos)
        });
      } else {
        await api.api__post_order_proudcts_reviews({
          order_product_order_num: orderProductOrderNum,
          param: getParams(photos)
        });

        AirbridgeCustomEvent.reviewWrite({
          product_id: productId,
          rating_value: satisfaction
        });
      }
    } finally {
      pendding.current = false;
    }

    setTimeout(async () => {
      const res = await api.api__get_members_order_product_reviews();
      setsItemChange({ key: "myReviewWrite", value: res.map(review => camelizeKeys(review)) });
      if (submitCallback) submitCallback();
    }, 1000);
    alert("리뷰가 정상 등록 되었습니다.");

    dispatch(modalOff({ isClear: true }));
  }, [
    dispatch,
    getParams,
    orderProductOrderNum,
    photoUpdate,
    prevReview,
    setsItemChange,
    submitCallback,
    userableInfo
  ]);

  const userableHeightChange = useCallback(
    (height: number | string) => {
      setUserableInfo(prev => ({
        ...prev,
        height
      }));
    },
    [setUserableInfo]
  );

  const userableWeightChange = useCallback(
    (weight: number | string) => {
      setUserableInfo(prev => ({
        ...prev,
        weight
      }));
    },
    [setUserableInfo]
  );

  return {
    photoDatas,
    satisfaction,
    setSatisfaction,
    addPhoto,
    deletePhoto,
    submitReview,
    setSize,
    setColor,
    setShipping,
    setContent,
    setUserableInfo,
    color,
    content,
    size,
    userableInfo,
    userableHeightChange,
    userableWeightChange,
    photoUpdate,
    shipping
  };
};
