import React, { ButtonHTMLAttributes, FC } from "react";
import classNames from "classnames/bind";
import styles from "./EvaluationButton.module.scss";

interface IProp extends ButtonHTMLAttributes<{}> {
  isSelect: boolean;
}

const cx = classNames.bind(styles);

export const EvaluationButton: FC<IProp> = ({ children, onClick, isSelect }) => {
  return (
    <button className={cx("button", { isSelect })} type="button" onClick={onClick}>
      {children}
    </button>
  );
};
