import { IJSendSuccessResponse } from "typings";
import { fetcher } from "utils";

type ProductQuestionsBadgeGet = () => Promise<
  IJSendSuccessResponse<{
    payload: number;
  }>
>;

export const api__v1_member_product_qustions_badge_get: ProductQuestionsBadgeGet = () => {
  return fetcher.get("/api/v1/members/product_questions/badge");
};
