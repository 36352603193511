/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { always, map, pipe, pipeWith, then } from "ramda";
import * as api from "api";
import { SidebarItem } from "components";
import { categoriesViewModelCreator } from "view-model-creator";
import { IStore } from "typings";
import classNames from "classnames/bind";
import styles from "./Sidebar.module.scss";

const cx = classNames.bind(styles);

export const Sidebar = () => {
  if( window.location.pathname === '/2021chuseok') return null

  const location = useLocation();
  const [categories, setCategories] = useState<Map<string, string[]>>(new Map());
  const genderId =
    useSelector<IStore, IStore["ui"]["genderId"]>(({ ui: { genderId } }) => genderId, shallowEqual) || "men";

  useEffect(() => {
    pipeWith(then)([api.get__product_combination_categories, categoriesViewModelCreator, setCategories])({
      genderId
    });
  }, [genderId]);

  const memberNav = [
    ["/member", "마이룩핀"],
    ["/member/accounts", "계정"],
    ["/member/level", "레벨"],
    ["/member/point", "적립금"],
    ["/member/coupons", "쿠폰함"],
    ["-", "-"],
    ["/member/orders", "주문내역"],
    ["/member/qna", "상품 Q&A"],
    ["/member/contacts", "상품 1:1 문의"],
    ["/member/review", "상품 리뷰"],
    ["-", "-"],
    ["/member/cart", "장바구니"],
    ["/member/pins", "핀"],
    ["/member/coordi", "코디좋아요"],
    ["/member/bookmarks", "즐겨찾기"],
    ["-", "-"],
    ["/member/notice", "공지사항"],
    ["/member/FAQ", "자주 묻는 질문"]
  ];

  if (
    location.pathname.includes("/member") ||
    location.pathname.includes("/claim") ||
    location.pathname.includes("/contact")
  ) {
    return (
      <aside className={cx("sidebar")}>
        <ul className={cx("mylookpin")}>
          {memberNav.map(([url, name], index) =>
            name === "-" ? (
              <li className={cx("border-mylookpin")} key={`${name}-${index}`} />
            ) : (
              <li className={cx(name === "마이룩핀" ? "title" : "")} key={`${name}-${index}`}>
                <NavLink
                  exact={name === "마이룩핀"}
                  to={url}
                  className={cx(["link"])}
                  activeClassName={cx(["bold", "blue"])}
                >
                  {name}
                </NavLink>
              </li>
            )
          )}
        </ul>
      </aside>
    );
  }

  return (
    <aside className={cx("sidebar")}>
      <ul>
        {pipe(
          always(categories),
          Array.from,
          (args: Array<any>) => [...args],
          map(([main, subs]: [string, string[]]) => (
            <div key={main} className={cx("categoryWrapper")}>
              <SidebarItem main={main} />

              {pipe(
                always(subs),
                map(sub => <SidebarItem main={main} sub={sub} key={sub} />)
              )()}
            </div>
          ))
        )()}
      </ul>
    </aside>
  );
};
