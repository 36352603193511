const dateTimeHelper = (n: number) => (n < 10 ? `0${n}` : n.toString());

type dateType =
  | "year"
  | "month"
  | "day"
  | "date"
  | "time"
  | "timeStr"
  | "hour"
  | "dateStr"
  | "yearDateTimeStr"
  | "timeLocalStr";

export default function formatDateTime(datetime: string, type: dateType = "date", joinFlag: string = ".") {
  const d = new Date(/^[0-9]*$/.test(datetime) ? Number(datetime) : datetime);
  const curr = new Date(datetime);
  const utc = curr.valueOf();
  // 3. UTC to KST (UTC + 9시간)
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const kr_curr = /^[0-9]*$/.test(datetime) ? new Date(Number(datetime) + KR_TIME_DIFF) : new Date(utc + KR_TIME_DIFF);
  switch (type) {
    case "year":
      return d.getUTCFullYear().toString();
    case "month":
      return dateTimeHelper(d.getUTCMonth() + 1);
    case "day":
      return dateTimeHelper(d.getUTCDate());
    case "date":
      return [d.getUTCFullYear(), dateTimeHelper(d.getUTCMonth() + 1), dateTimeHelper(d.getUTCDate())].join(joinFlag);
    case "dateStr":
      return [`${dateTimeHelper(d.getUTCMonth() + 1)}월`, `${dateTimeHelper(d.getUTCDate())}일`].join(" ");
    case "time":
      return `${dateTimeHelper(d.getUTCHours())}:${dateTimeHelper(d.getUTCMinutes())}`;
    case "timeStr":
      return `${dateTimeHelper(d.getUTCHours())}시 ${dateTimeHelper(d.getUTCMinutes())}분`;
    case "timeLocalStr":
      return `${dateTimeHelper(d.getUTCHours() + 9)}시 ${dateTimeHelper(d.getUTCMinutes())}분`;
    case "hour":
      return `${dateTimeHelper(d.getUTCHours())}시`;
    case "yearDateTimeStr":
      return [
        `${kr_curr.getUTCFullYear()}년`,
        `${dateTimeHelper(kr_curr.getUTCMonth() + 1)}월`,
        `${dateTimeHelper(kr_curr.getUTCDate())}일`,
        `${dateTimeHelper(kr_curr.getUTCHours())}시`,
        `${dateTimeHelper(kr_curr.getUTCMinutes())}분`,
        `${dateTimeHelper(kr_curr.getUTCSeconds())}초`
      ].join(" ");
    default:
      return [d.getUTCFullYear(), dateTimeHelper(d.getUTCMonth() + 1), dateTimeHelper(d.getUTCDate())].join(joinFlag);
  }
}
