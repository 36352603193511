import { UI__MODAL_GRID_PHOTO_REVIEW } from "constant";
import { modalContext } from "contexts";
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { IStore } from "typings";

export const usePhotoReviews = () => {
  const photoModalGridStatus = useMemo(
    () => modalContext.stack.findIndex(value => value === UI__MODAL_GRID_PHOTO_REVIEW) !== -1,
    []
  );
  const { photoModalDetail, photoReviewsTotalCount, selectedPhotoNumber } = useSelector<IStore, IStore["photoReview"]>(
    ({ photoReview }) => photoReview
  );
  const [imageIndex, setImageIndex] = useState(photoModalGridStatus ? 0 : selectedPhotoNumber);

  const viewTotalCount = useMemo<number>(() => {
    if (photoModalGridStatus) return photoReviewsTotalCount;
    if (photoModalDetail) return photoModalDetail.images.length;
    return 0;
  }, [photoModalGridStatus, photoModalDetail, photoReviewsTotalCount]);

  const viewSelectNumber = useMemo<number>(() => {
    if (photoModalGridStatus) return selectedPhotoNumber;
    return imageIndex + 1;
  }, [selectedPhotoNumber, imageIndex, photoModalGridStatus]);

  return {
    photoModalDetail,
    photoModalGridStatus,
    photoReviewsTotalCount,
    selectedPhotoNumber,
    imageIndex,
    setImageIndex,
    viewTotalCount,
    viewSelectNumber
  };
};
