import React, { FC } from "react";
import { SATISFACTION_KEY, SATISFACTION_VALUE } from "constant";
import { ColorKey, ShippingKey, SizeKey } from "typings";
import classNames from "classnames/bind";
import styles from "./Evaluation.module.scss";
import { EvaluationButton } from "./EvaluationButton";

interface IProp {
  part: "size" | "shipping" | "color";
  select: ColorKey | SizeKey | ShippingKey | null;
  onChange: (str: any) => void;
}

const cx = classNames.bind(styles);

export const Evaluation: FC<IProp> = ({ part, select, onChange }) => {
  return (
    <div className={cx("wrapper")}>
      <EvaluationButton
        onClick={() => onChange(SATISFACTION_KEY[part].good)}
        isSelect={select === SATISFACTION_KEY[part].good}
      >
        {SATISFACTION_VALUE[part].good}
      </EvaluationButton>
      <EvaluationButton
        onClick={() => onChange(SATISFACTION_KEY[part].soso)}
        isSelect={select === SATISFACTION_KEY[part].soso}
      >
        {SATISFACTION_VALUE[part].soso}
      </EvaluationButton>
      <EvaluationButton
        onClick={() => onChange(SATISFACTION_KEY[part].bad)}
        isSelect={select === SATISFACTION_KEY[part].bad}
      >
        {SATISFACTION_VALUE[part].bad}
      </EvaluationButton>
    </div>
  );
};
