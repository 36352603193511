import React, { FC, useMemo, useState } from "react";
import classNames from "classnames/bind";
import { IProductDetailViewModel } from "typings";
import styles from "./NoticeAboutProduct.module.scss";
import { NoticeHeader } from "./NoticeHeader";

const cx = classNames.bind(styles);

interface INoticeAboutProduct {
  product: IProductDetailViewModel;
  boldKeys: string[];
}

export const NoticeAboutProduct: FC<INoticeAboutProduct> = ({ product, boldKeys }) => {
  const [isShowStoreinfo, setIsShowStoreInfo] = useState<boolean>(false);
  const [isShowProductInfo, setIsShowProductInfo] = useState<boolean>(false);

  const storeDetailPair = useMemo(() => {
    const { store } = product;
    const pair: Array<[string, string]> = [];

    pair.push(["업체명", store.extraInfo.companyName]);
    pair.push(["AS전화번호", store.refundInfo?.phoneNumber || ""]);
    pair.push(["운영시간", store.extraInfo.businessHours]);
    pair.push(["반송지", store.refundInfo?.address || ""]);
    if (store.refundInfo)
      pair.push([
        "교환/반품택배비",
        `상품 발송시 선불로 결제한 경우:\n ${store.refundInfo.prepaidDeliveryCost}원\n상품 발송시 착불로 선택한 경우:\n ${store.refundInfo.collectDeliveryCost}원\n(동봉 혹은 입금)`
      ]);
    if (store.refundInfo?.bank)
      pair.push(["입금계좌", `${store.refundInfo.bank} ${store.refundInfo.account}\n${store.refundInfo.name}`]);
    pair.push(["사업자등록번호", store.extraInfo.companyRegistrationNumber]);
    pair.push(["대표자명", store.extraInfo.ceo]);
    pair.push(["전자우편주소", store.extraInfo.email]);
    pair.push(["통신판매신고번호", store.extraInfo.mailOrderNumber]);

    return pair;
  }, [product]);
  return (
    <div className={cx("wrapper")}>
      <NoticeHeader title="업체 정보" condition={isShowStoreinfo} toggleCondition={setIsShowStoreInfo} />

      <div className={cx("info", { display: isShowStoreinfo })}>
        {(storeDetailPair || []).map(([key, value]) => (
          <div key={key} className={cx("box", [{ bold: boldKeys.includes(key) }])}>
            <span className={cx("key")}>{key || "/"}</span>
            <pre className={cx("value")}>{value}</pre>
          </div>
        ))}
      </div>

      <NoticeHeader title="상품정보고시 정보" condition={isShowProductInfo} toggleCondition={setIsShowProductInfo} />

      <div className={cx("info", { display: isShowProductInfo })}>
        {product.info.extraInfo.info.map(([key, value]) => (
          <div key={key} className={cx("box")}>
            <span className={cx("key")}>{key}</span>
            <pre className={cx("value")}>{value}</pre>
          </div>
        ))}
      </div>
    </div>
  );
};
