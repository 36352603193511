import React, { FC, useMemo } from "react";
import classNames from "classnames/bind";
import styles from "./OrderIcon.module.scss";
import {
  mylookpinClaimIcon,
  mylookpinPayOrderIcon,
  mylookpinShipDoneIcon,
  mylookpinShippingIcon,
  mylookpinShipWaitIcon
} from "assets/img";
import { ORDER_PRODUCT_LINK_URL, ORDER_PRODUCT_STATUS } from "constant";
import { Link } from "react-router-dom";

interface IProp {
  type: "claim" | "ship_wait" | "ship_ing" | "ship_done" | "pay";
  count: number;
  highLighting: boolean;
}

const cx = classNames.bind(styles);
export const OrderIcon: FC<IProp> = ({ type, count, highLighting }) => {
  const imgSrc = {
    pay: mylookpinPayOrderIcon,
    ship_wait: mylookpinShipWaitIcon,
    ship_done: mylookpinShipDoneIcon,
    ship_ing: mylookpinShippingIcon,
    claim: mylookpinClaimIcon
  };

  const countView = useMemo(() => (count > 999 ? "+999건" : `${count}건`), [count]);

  return (
    <Link to={ORDER_PRODUCT_LINK_URL[type]} className={cx("wrapper")} onClick={() => window.scroll(0, 0)}>
      <div>
        <img src={imgSrc[type]} className={cx("img")} alt={ORDER_PRODUCT_STATUS[type]}></img>
      </div>
      <div className={cx("text-wrapper")}>
        <span className={cx("target-title", { highLighting })}>{ORDER_PRODUCT_STATUS[type].replace(/ /g, "")}</span>
        <span className={cx("count", { highLighting })}>{countView}</span>
      </div>
    </Link>
  );
};
