import React, { useState } from "react";
import classNames from "classnames/bind";
import { Faq, Notice } from "components";
import styles from "./Help.module.scss";

const cx = classNames.bind(styles);

export const Help = () => {
  const [targetPage, setTargetPage] = useState<"notice" | "FAQ">("FAQ");
  return (
    <main className={cx("wrapper")}>
      <div className={cx("button-wrapper")}>
        <button
          className={cx("button", { isSelect: targetPage === "notice" })}
          type="button"
          onClick={() => setTargetPage("notice")}
        >
          공지사항
        </button>
        <button
          className={cx("button", { isSelect: targetPage === "FAQ" })}
          type="button"
          onClick={() => setTargetPage("FAQ")}
        >
          자주 묻는 질문
        </button>
      </div>
      {targetPage === "notice" ? <Notice other /> : <Faq other />}
    </main>
  );
};
