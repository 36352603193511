import React from "react";
import classnames from "classnames/bind";

import { hanger } from "assets/img";

import styles from "./ProductsNotExist.module.scss";

const cx = classnames.bind(styles);

export const ProductsNotExist = () => (
  <div className={cx("wrapper")}>
    <img src={hanger} className={cx("asset")} alt="hanger" />

    <div className={cx("notice")}>검색 결과가 없습니다.</div>

    <div className={cx("advise")}>
      다른 검색어를 입력하시거나,
      <br />
      철자 및 띄어쓰기를 확인해주세요.
    </div>
  </div>
);
