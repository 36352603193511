import React, { FC } from "react";
import classNames from "classnames/bind";
import styles from "./FAQCategories.module.scss";

interface IProp {
  categories: string[];
  selectCategory: string;
  setCategory: (str: string) => void;
}

const cx = classNames.bind(styles);
export const FAQCategories: FC<IProp> = ({ categories, selectCategory, setCategory }) => {
  return (
    <div className={cx("category-button-wrapper")}>
      {categories.map(category => (
        <button
          key={category}
          className={cx("category-button", { textSelect: category === selectCategory })}
          type="button"
          onClick={() => setCategory(category)}
        >
          {category}
          {category === selectCategory && <div className={cx({ isSelect: category === selectCategory })} />}
        </button>
      ))}
    </div>
  );
};
