import React, { FC, useCallback, useMemo } from "react";
import classNames from "classnames/bind";
import { map, times } from "ramda";

import { IconChevronLeft, IconChevronRight } from "assets/icon";

import styles from "./Pagination.module.scss";

const cx = classNames.bind(styles);

interface IProps {
  totalCount: number;
  number: number;
  size: number;
  handler: (page: number) => void;
  pageSetSize?: number;
}

export const Pagination: FC<IProps> = ({ totalCount, number, size, handler, pageSetSize = 5 }) => {
  const PAGE_SET_SIZE = pageSetSize;
  const totalPageSize = useMemo(() => Math.ceil(totalCount / size), [size, totalCount]);
  const currentPageSetCursor = useMemo(() => Math.ceil(number / PAGE_SET_SIZE), [number, PAGE_SET_SIZE]);
  const totalPageSetSize = useMemo(() => Math.ceil(totalPageSize / PAGE_SET_SIZE), [totalPageSize, PAGE_SET_SIZE]);
  const numberCarry = useMemo(
    () => (number % PAGE_SET_SIZE === 0 ? Math.floor(number / PAGE_SET_SIZE) - 1 : Math.floor(number / PAGE_SET_SIZE)),
    [number, PAGE_SET_SIZE]
  );

  const pageSize = (() => {
    const sizeCarry = numberCarry * PAGE_SET_SIZE;

    if (totalPageSize <= 1) return 0;

    if (totalPageSize - sizeCarry > PAGE_SET_SIZE) return PAGE_SET_SIZE;

    return totalPageSize - sizeCarry;
  })();

  const base = PAGE_SET_SIZE * (number / PAGE_SET_SIZE === numberCarry ? number / PAGE_SET_SIZE - 1 : numberCarry);

  const changePageNumber = useCallback((page: number) => event => handler(page), [handler]);
  const goPrevPageSet = useCallback(() => handler(number - PAGE_SET_SIZE), [handler, number, PAGE_SET_SIZE]);
  const goNextPageSet = useCallback(() => handler(Math.ceil(number / PAGE_SET_SIZE) * PAGE_SET_SIZE + 1), [
    handler,
    number,
    PAGE_SET_SIZE
  ]);

  return (
    <section className={cx("wrapper", { hide: totalCount < size })}>
      <>
        {number > PAGE_SET_SIZE && (
          <button className={cx("button", "left")} type="button" onClick={goPrevPageSet}>
            <IconChevronLeft />
          </button>
        )}

        {map(
          (page: number) => (
            <button
              key={page}
              className={cx("button", "page", { current: page === number })}
              type="button"
              onClick={changePageNumber(page)}
            >
              {page}
            </button>
          ),
          times(v => v + 1 + base, pageSize)
        )}

        {currentPageSetCursor < totalPageSetSize && (
          <button className={cx("button", "right")} type="button" onClick={goNextPageSet}>
            <IconChevronRight />
          </button>
        )}
      </>
    </section>
  );
};
