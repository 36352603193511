import { fetcher } from "utils";

import { GenderPartner, StoreSerialized } from "typings";

interface IGetStoresSearch {
  query?: string;
  isNew?: boolean;
  storeTags?: string[];
  genderId?: GenderPartner;
}

export const get__stores__search = (params: IGetStoresSearch): Promise<StoreSerialized[]> =>
  fetcher.get("/stores/search", params);
