import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import reducers from "reducers";

const middlewares = [thunk];

if (process.env.REACT_APP_ENV === "development") {
  middlewares.push(createLogger({ duration: true })); // diff: true
}

const store = createStore(reducers, composeWithDevTools(applyMiddleware(...middlewares)));

export default store;
