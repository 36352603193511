import { fetcher } from "utils";

import {
  Gender,
  EventBannerSerialized,
  IJSendSuccessResponse,
  IPage,
  IWithMeta,
  StoreGroupId,
  EventPosition,
  IPageMeta
} from "typings";

type GetEventBanners = (params: {
  genderId: Gender;
  storeGroupId: StoreGroupId;
  title?: string;
  page: IPage;
  position: EventPosition;
}) => Promise<IJSendSuccessResponse<IWithMeta<EventBannerSerialized[], IPageMeta>>>;

export const get__event_banners: GetEventBanners = params => fetcher.get("/event_banners", params);
