import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { storageContext } from "contexts";
import { camelizeKeys } from "humps";
import { IStore, IPageCamel } from "typings";
import { initialize } from "actions/actionCreators";

import * as api from "api";
import { useSets } from "hooks";

export default function Base() {
  const dispatch = useDispatch();
  const { token } = useSelector<IStore, IStore["member"]>(({ member }) => member);
  const { initSets } = useSets();

  useEffect(() => {
    if (storageContext.token) api.get__members().then(member => dispatch(initialize({ member })));
  }, [dispatch]);

  const initSet = useCallback(async () => {
    if (!token) return;
    Promise.all([
      api.get__userables__products__recent(),
      api.get__members__order_points__simple(),
      api.get__members__order_points__detail(),
      api.get__api__v1__userables__products__only_ids(),
      api.get__api__v1__userables__coordi__only_ids(),
      api.get__api__v1__userables__stores__only_ids(),
      api.get__api__v1__members__carts({ page: { number: 1, size: 1 } }),
      api.get__userable_like__order_product_reviews(),
      api.api__get_members_order_product_reviews()
    ]).then(
      ([
        recents,
        simple,
        detail,
        {
          data: { payload: pins }
        },
        {
          data: { payload: coordi }
        },
        {
          data: { payload: bookmarks }
        },
        { data: carts },
        reviewLikes,
        myReviewWrite
      ]) => {
        const cart: IPageCamel = camelizeKeys(carts.meta) as any;
        dispatch(
          initialize({
            cart,
            recents,
            orderPoints: { simple, detail }
          })
        );
        initSets({
          pins,
          coordi,
          bookmarks,
          reviewLikes,
          myReviewWrite: myReviewWrite.map(review => camelizeKeys(review) as any)
        });
      }
    );
  }, [dispatch, initSets, token]);

  useEffect(() => {
    // TODO: App의 componentDidMount 내용을 가져와야 할 곳!
    initSet();
  }, [initSet]);

  return null;
}
