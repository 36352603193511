import React, { FC, useEffect, useState } from "react";
import * as api from "api";
import classNames from "classnames/bind";
import { NoticeCard } from "components";
import { INoticeCard } from "typings";

import { formatDateTime } from "utils";
import styles from "./Notice.module.scss";

const cx = classNames.bind(styles);

interface IProp {
  other?: boolean;
}

export const Notice: FC<IProp> = ({ other }) => {
  const [noticeList, setNoticeList] = useState<INoticeCard[]>([]);

  useEffect(() => {
    api.api__v1_get_notice_for_userables().then(res => {
      const nextNotice = res.data.payload.map(({ title, created_at, html_url }) => ({
        title,
        htmlUrl: html_url,
        subTitle: formatDateTime(created_at, "date")
      }));
      setNoticeList(nextNotice);
    });
  }, []);
  return (
    <main className={cx("wrapper", { other })}>
      {!other && <div className={cx("title")}>공지사항</div>}
      <div>
        {noticeList.map(({ title, subTitle, htmlUrl }) => (
          <NoticeCard key={`${title}-${htmlUrl}`} title={title} subTitle={subTitle} htmlUrl={htmlUrl} />
        ))}
      </div>
    </main>
  );
};
