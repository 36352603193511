import { useRef, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { IStore } from "typings";
import { modalOn } from "modules/ui";
import { UI__MODAL_LOGIN } from "constant";
import { removeReviewLikers, addReviewLikers } from "actions/actionCreators";
import { useSets } from "./useSets";

export const useReviewLikers = () => {
  const dispatch = useDispatch();
  const pending = useRef(false);
  const token = useSelector<IStore, IStore["member"]["token"]>(({ member: { token } }) => token, shallowEqual);
  const { reviewLikes } = useSets();

  const getIsReviewLiked = useCallback(
    id => {
      return reviewLikes.has(id);
    },
    [reviewLikes]
  );
  const toggleReviewLikes = useCallback(
    async (id: number, callback?: () => void) => {
      if (!pending.current) {
        pending.current = true;
        if (!token) dispatch(modalOn(UI__MODAL_LOGIN));
        else if (getIsReviewLiked(id)) {
          await dispatch(removeReviewLikers(id));
        } else {
          await dispatch(addReviewLikers(id));
        }

        if (callback) callback();
        pending.current = false;
      }
    },
    [dispatch, getIsReviewLiked, token]
  );
  return {
    reviewLikes,
    getIsReviewLiked,
    toggleReviewLikes
  };
};
