import React, { memo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { modalOff, socialSignup } from "actions/actionCreators";
import { formChecker, useInputs } from "utils";
import { UI__FORM_FAILURE_EMAIL_INVALID } from "constant";

import classNames from "classnames/bind";
import styles from "../../ModalWrapper.module.scss";
import { ConditionComponent } from "components/role/ConditionComponent/ConditionComponent";
import { NumberAuth } from "components";

const cx = classNames.bind(styles);

export const FillEmail = memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { provider, providerToken } = useSelector(({ authentication }) => authentication);
  const [state, onChange] = useInputs({ email: "" });
  const [formFailure, setFormFailure] = useState({});
  const [isNumberAuth, setIsNumberAuth] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isMarketingAgree, setIsMarketingAgree] = useState(false);
  const checker = formChecker(setFormFailure);
  const { email } = state;

  const numberAuthCallbackFunction = useCallback(({ phoneNumber, marketingAgree }) => {
    setPhoneNumber(phoneNumber);
    setIsMarketingAgree(marketingAgree);
    setIsNumberAuth(true);
  }, []);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      if (checker(state)) {
        try {
          await dispatch(
            socialSignup(provider, providerToken, email, { phoneNumber, marketingAgree: isMarketingAgree })
          );
          history.replace("/member");
        } catch (e) {
          console.error(e);
        }
      }
    },
    [dispatch, provider, providerToken, email, phoneNumber, isMarketingAgree, history, checker, state]
  );

  const closeModal = () => {
    dispatch(modalOff({ isClear: true }));
    history.replace("/");
  };

  return (
    <ConditionComponent
      condition={isNumberAuth}
      FallBackComponent={() => <NumberAuth callbackFunction={numberAuthCallbackFunction} />}
    >
      <div className={cx("container")}>
        <div className={cx("header")}>
          로그인
          <button className={cx("close")} onClick={closeModal} type="button">
            {" "}
          </button>
        </div>

        <div className={cx("contents")}>
          <form onSubmit={handleSubmit}>
            <input type="email" placeholder="이메일 주소" name="email" value={email} onChange={onChange} />
            {formFailure.UI__FORM_FAILURE_EMAIL_INVALID && (
              <div className={cx("invalid-txt")}>{UI__FORM_FAILURE_EMAIL_INVALID}</div>
            )}

            <div className={cx("btnWrapper")}>
              <button className={cx("login")} type="submit">
                로그인
              </button>
            </div>
          </form>
        </div>
      </div>
    </ConditionComponent>
  );
});
