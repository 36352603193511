import { useRef, useCallback } from "react";

export const useThrottle = (fn: (arg?) => void, delay = 300) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const clearThrottle = useCallback(() => {
    clearTimeout(timerRef.current!);
  }, []);

  const throttleCallback = useCallback(
    (throttleArg?) => {
      if (!timerRef.current) {
        fn(throttleArg);
        timerRef.current = setTimeout(() => {
          clearTimeout(timerRef.current!);
          timerRef.current = null;
        }, delay);
      }
    },
    [fn, delay]
  );

  return [throttleCallback, clearThrottle];
};
