import { fetcher } from "utils";

import { IJSendFailResponse, IJSendSuccessResponse, IMembersShippingInfosFail } from "typings";

interface IMembersShippingInfosParams {
  name: string;
  phoneNumber: string;
  address: {
    main: string;
    detail: string;
    zipcode: string;
  };
  message?: string;
}

type PostMembersShippingInfos = (
  params: IMembersShippingInfosParams
) => Promise<IJSendSuccessResponse<null> | IJSendFailResponse<IMembersShippingInfosFail>>;

export const post__members__shipping_infos: PostMembersShippingInfos = params =>
  fetcher.post("/members/shipping_infos", params);
