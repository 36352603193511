import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames/bind";
import { modalOff } from "modules/ui";
import { IStore, ProductQuestionType } from "typings";
import { ConditionComponent } from "components/role/ConditionComponent/ConditionComponent";
import { useQnAWrite, useNavbar } from "hooks";
import { Link } from "react-router-dom";
import { SimpleProductRow } from "components/presentation/qna/SimpleProductRow";
import { BtnBack, UncheckedIcon, CheckedIcon, CloseQna } from "assets/icon";
import styles from "./ProductQnA.module.scss";

const cx = classNames.bind(styles);
export const ProductQnA = () => {
  const { token, handleLogin } = useNavbar();
  const dispatch = useDispatch();
  const { isMobile } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);
  const { contents, setContents, typeOf, setTypeOf, submitListner, product, hiddenFlag, setHiddenFlag } = useQnAWrite();
  const qnaTypeOptions: ProductQuestionType[] = ["상품", "배송", "재고", "기타"];
  const isDisabled = useMemo(() => contents.length < 5 || !typeOf, [contents, typeOf]);
  return (
    <div className={cx("wrapper")}>
      <div className={cx("header")}>
        <div className={cx("title", "bold")}>
          {isMobile && (
            <button type="button" className={cx("close")} onClick={() => dispatch(modalOff({ isClear: true }))}>
              <BtnBack />
            </button>
          )}
          <div className={cx("title-text")}>상품 문의하기</div>
        </div>
        <ConditionComponent
          condition={!isMobile}
          FallBackComponent={() => (
            <button type="button" className={cx("completed")} disabled={isDisabled} onClick={submitListner}>
              완료
            </button>
          )}
        >
          <button type="button" className={cx("close")} onClick={() => dispatch(modalOff({ isClear: true }))}>
            <CloseQna />
          </button>
        </ConditionComponent>
      </div>
      <div className={cx("product-wrapper")}>{product && <SimpleProductRow product={product} />}</div>
      <div className={cx("body")}>
        <div className={cx("title")}>문의 유형</div>
        <div className={cx("option-wrapper")}>
          <select
            className={cx("type")}
            onChange={event => setTypeOf(event.target.value as ProductQuestionType)}
            value={typeOf}
          >
            <option className={cx("placeholder")} value="" disabled>
              선택해주세요.
            </option>
            {qnaTypeOptions.map(option => (
              <option value={option} key={option}>
                {option}문의
              </option>
            ))}
          </select>
        </div>
        <div className={cx("claim-description")}>
          취소/교환/환불 관련 문의는{" "}
          {token ? (
            <Link className={cx("claim-link")} to="/member/orders">
              MY룩핀 &gt; 주문내역
            </Link>
          ) : (
            <button type="button" className={cx("claim-link")} onClick={handleLogin}>
              MY룩핀 &gt; 주문내역
            </button>
          )}
          에서 해당 상품의 [1:1 문의] 버튼으로 문의해주세요.
        </div>
        <div className={cx("title", "space-between")}>
          <div>
            문의 내용 <span className={cx("description")}>(5자 이상)</span>
          </div>
          <label
            className={cx("secret-label")}
            onClick={() => {
              setHiddenFlag(!hiddenFlag);
            }}
          >
            <div>{hiddenFlag ? <CheckedIcon /> : <UncheckedIcon />}</div>
            <span className={cx("secret-title")}>비공개</span>
          </label>
        </div>
        <textarea
          className={cx("contents")}
          onChange={event => setContents(event.target.value)}
          value={contents}
          placeholder={`-상품(사이즈,재고,재입고 등) 관련 문의\n-배송(발송일정 등) 관련 문의`}
        />
      </div>
      {!isMobile && (
        <div className={cx("footer")}>
          <button type="button" className={cx("completed")} disabled={isDisabled} onClick={submitListner}>
            문의하기
          </button>
        </div>
      )}
    </div>
  );
};
