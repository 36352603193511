import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageResizeHelper } from "utils";
import { UI__MODAL_COUPON_REGISTER } from "constant";
import { modalOn, updateCoupons } from "actions/actionCreators";
import { IStore } from "typings";
import { grayCoupon } from "assets/img";
import classNames from "classnames/bind";
import styles from "./Coupons.module.scss";

const cx = classNames.bind(styles);

export function Coupons() {
  const dispatch = useDispatch();

  const coupons = useSelector<IStore, IStore["coupons"]>(({ coupons }) => coupons);

  const openCouponRegister = useCallback(() => dispatch(modalOn(UI__MODAL_COUPON_REGISTER)), [dispatch]);

  useEffect(() => {
    dispatch(updateCoupons());
  }, [dispatch]);

  return (
    <>
      <main className={cx("coupons-tab")}>
        <header className={cx(["bold"], "title")}>
          쿠폰함
          <button onClick={openCouponRegister} type="button" className={cx("coupon-register")}>
            쿠폰등록
          </button>
        </header>

        <section>
          {coupons.length ? (
            coupons.map(({ coupon: { name, imageUrl, id }, usableAtLimitDesc, conditionDesc }) => (
              <div key={id} className={cx("discount-coupon", "ticket-container")}>
                <img src={imageResizeHelper(imageUrl, 120)} alt={name} />
                <ul>
                  <li className={cx(["middle"], "name")}>{name}</li>
                  <li className={cx("desc")}>{conditionDesc}</li>
                  <li className={cx("usable-date")}>{usableAtLimitDesc}</li>
                </ul>
              </div>
            ))
          ) : (
            <div className={cx("no-list")}>
              <div>
                <img src={grayCoupon} alt="coupon" />
              </div>

              <div className={cx("no-list-text")}>보유한 쿠폰이 없습니다.</div>
            </div>
          )}
        </section>
      </main>
    </>
  );
}
