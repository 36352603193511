import React, { useMemo, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import classnames from "classnames/bind";
import { PreviousPageMobile, Loading } from "components";
import { AUTO, SELF } from "constant";
import { EFieldStatus, IStore } from "typings";
import { imageResizeHelper } from "utils";
import { useClaim } from "./useClaim";
import styles from "./Claim.module.scss";

const cx = classnames.bind(styles);

export function Claim() {
  const {
    orderNum,
    orderProductOrderNum,
    status,
    orderProduct,
    nextStatus,
    payMethodText,
    handleReason,
    reason,
    message,
    handleMessage,
    deliveryType,
    isAuto,
    handleDeliveryType,
    name,
    phoneNumber,
    main,
    detail,
    modalOnPickUpAddress,
    banks,
    certify,
    handleCancel,
    disabledSubmit,
    handleSubmit,
    refundAccount,
    handleRefundAccount,
    handleCertify,
    disabledCertify,
    isCertifyLoading,
    claimNotice
  } = useClaim();
  const { isMobile } = useSelector<IStore, IStore["ui"]>(({ ui }) => ui);

  const isMostBeRefundAccount = useMemo<boolean>(
    () => payMethodText === "가상계좌" || payMethodText === "핸드폰 결제",
    [payMethodText]
  );
  // 임시 이벤트 삭제 예정
  useEffect(() => {
    if (claimNotice && claimNotice.meta && claimNotice.meta.notice) alert(claimNotice.meta.notice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!orderNum || !orderProductOrderNum || !status) return <Redirect to="/" />;

  if (!orderProduct || !nextStatus) return <Loading />;
  const {
    product: {
      store: { refundInfo }
    }
  } = orderProduct;

  return (
    <main className={cx("main")}>
      <div className={cx("title")}>
        <PreviousPageMobile />
        {nextStatus.extraInfo.title}
      </div>

      <div className={cx("info")}>
        <div className={cx("container")}>
          <span className={cx("property")}>업체명</span> {orderProduct.product.store.name}
        </div>

        <div className={cx("container")}>
          <span className={cx("property")}>상품명</span>
          <picture className={cx("product-picture")}>
            <img
              src={imageResizeHelper(orderProduct.product.images.main[0], 26)}
              className={cx("product-img")}
              alt="product"
            />
          </picture>
          {orderProduct.product.name}
        </div>

        <div className={cx("container")}>
          <span className={cx("property")}>옵션</span>{" "}
          {orderProduct.option.positions.length ? orderProduct.option.positions.join(", ") : "옵션없음"}
        </div>
      </div>

      <div className={cx("reason")}>
        <select className={cx("select")} onChange={handleReason} value={reason}>
          <option value="" key="default" disabled>
            사유를 선택해주세요.
          </option>

          {nextStatus.extraInfo?.reasons?.map(option => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div className={cx("detailed-description")}>
        <textarea value={message} onChange={handleMessage} className={cx("message")} placeholder="상세설명" />
      </div>

      {![EFieldStatus.cancelSoonAuto, EFieldStatus.cancelWait].includes(status as EFieldStatus) && (
        // [결제완료, 배송준비중] 상태에서의 취소는 pick_up_address 불필요
        <>
          <div className={cx("delivery-method")}>발송 방법</div>

          <div className={cx("delivery", { selected: deliveryType })}>
            {isAuto && (
              <div className={cx(AUTO, { selected: deliveryType === AUTO })}>
                <label
                  onClick={handleDeliveryType}
                  htmlFor={AUTO}
                  className={cx("label", { selected: deliveryType === AUTO })}
                >
                  <div>
                    <input type="radio" id={AUTO} name="delivery" className={cx("input")} />
                    <span className={cx("choice")}>수거 해주세요!</span>
                  </div>
                  <div className={cx("description")}>택배사에서 고객님께 연락 후 직접 방문합니다.</div>
                  <div className={cx("description-additional")}>(영업일 기준 1~3일 소요)</div>
                </label>

                {deliveryType === AUTO && (
                  <>
                    <p className={cx("description-precautions")}>
                      <span className={cx("return-delivery-fee")}>
                        반품택배비 선불 {Number(refundInfo.prepaidDeliveryCost).toLocaleString()}원 또는 착불{" "}
                        {Number(refundInfo.collectDeliveryCost).toLocaleString()}원을
                      </span>
                      <span className={cx("return-delivery-fee-additional")}> 상품과 함께 동봉 또는 입금해주세요.</span>
                      {refundInfo.bank && refundInfo.account && refundInfo.name ? (
                        <span className={cx("info-wrapper")}>
                          <span className={cx("description-info")}>반품 택배비를 입금하기 원하는 경우</span>
                          <span className={cx("description-info-additional")}>
                            {refundInfo.bank}&nbsp;
                            {refundInfo.account}&nbsp;
                            {refundInfo.name}으로 입금해주세요.
                          </span>
                        </span>
                      ) : (
                        <span className={cx("info-wrapper")}>
                          <span className={cx("description-info")}>
                            반품택배비를 입금하기 원하는 경우 주문내역의 [문의하기]
                          </span>
                          <span className={cx("description-info-additional")}>로 쇼핑몰에 계좌번호를 문의해주세요</span>{" "}
                        </span>
                      )}
                    </p>
                    <div className={cx("pickup-info")}>
                      <div className={cx("member-info")}>
                        {name}, {phoneNumber}
                        <br /> {main} {detail}
                      </div>

                      <button onClick={modalOnPickUpAddress} className={cx("address-change")} type="button">
                        변경
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}

            <div className={cx(SELF, { selected: deliveryType === SELF })}>
              <label
                onClick={handleDeliveryType}
                htmlFor={SELF}
                className={cx("label", { selected: deliveryType === SELF })}
              >
                <div>
                  <input type="radio" id={SELF} name="delivery" className={cx("input")} />
                  <span className={cx("choice")}>직접 발송할게요!</span>
                </div>
                <div className={cx("description")}>초기 배송 받았던 택배사에서 반품예약 해주세요.</div>
                <div className={cx("description-additional")}>
                  (타택배사를 이용하는 경우에는 배송비를 선결제 해주세요. 착불로 발송될 경우 임의로 반송될 수 있습니다)
                </div>
              </label>

              {deliveryType === SELF && (
                <>
                  <p className={cx("description-precautions")}>
                    <span className={cx("return-delivery-fee")}>
                      택배사에 개인택배가 아닌 꼭! 반품예약으로 접수해주세요.
                    </span>
                    <span className={cx("info-wrapper")}>
                      <span className={cx("description-info")}>
                        초기 배송받은 택배사가 아닌 타택배사를 이용하는 경우 추가 택배비가 부담될 수 있습니다.
                      </span>
                    </span>
                  </p>
                  <div className={cx("refund-info")}>
                    <ul>
                      <li className={cx("element")}>
                        <span className={cx("refund-property")}>상호명</span>
                        <span className={cx("refund-text")}>{orderProduct.product.store.name}</span>
                      </li>
                      <li className={cx("element")}>
                        <span className={cx("refund-property")}>업체명</span>
                        <span className={cx("refund-text")}>{orderProduct.product.store.extraInfo.companyName}</span>
                      </li>

                      <li className={cx("element")}>
                        <span className={cx("refund-property")}>반송지</span>
                        <span className={cx("refund-text")}>{refundInfo.address}</span>
                      </li>

                      <li className={cx("element")}>
                        <span className={cx("refund-property")}>반품택배비</span>
                        <span className={cx("refund-text")}>
                          <span className={cx("refund-price")}>
                            선불 {Number(refundInfo.prepaidDeliveryCost).toLocaleString()}원
                          </span>
                          <span className={cx("refund-price")}>
                            착불 {Number(refundInfo.collectDeliveryCost).toLocaleString()}원
                          </span>
                        </span>
                      </li>
                      {refundInfo.bank && refundInfo.account && refundInfo.name && (
                        <li className={cx("element")}>
                          <span className={cx("refund-property")}>임금계좌</span>
                          <span className={cx("refund-text")}>
                            <span className={cx("refund-account")}>{refundInfo.account}</span>
                            <span className={cx("refund-bank")}>
                              {` (은행: ${refundInfo.bank} 예금주 : ${refundInfo.name})`}
                            </span>
                          </span>
                        </li>
                      )}
                      <li className={cx("element")}>
                        <span className={cx("refund-property")}>전화번호</span>
                        <span className={cx("refund-text")}>{refundInfo.phoneNumber}</span>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {status !== EFieldStatus.changeWait &&
        isMostBeRefundAccount &&
        [EFieldStatus.refundWait, EFieldStatus.cancelWait, EFieldStatus.cancelSoonAuto].includes(
          status as EFieldStatus
        ) && (
          <>
            <div className={cx("refund-account-info")}>환불계좌 정보</div>

            <div className={cx("account")}>
              <ul>
                <li className={cx("bank-area")}>
                  <label className={cx("account-property")} htmlFor="bank">
                    은행명
                  </label>

                  <select
                    onChange={handleRefundAccount}
                    name="bankName"
                    value={refundAccount.bankName}
                    className={cx("bank")}
                    id="bank"
                  >
                    <option disabled value="">
                      은행을 선택해주세요.
                    </option>

                    {banks.map(bank => (
                      <option value={bank} key={bank}>
                        {bank}
                      </option>
                    ))}
                  </select>
                </li>

                <li className={cx("account-number-area")}>
                  <label className={cx("account-property")} htmlFor="account-number">
                    계좌번호
                  </label>

                  <input
                    onChange={handleRefundAccount}
                    name="accountNum"
                    value={refundAccount.accountNum}
                    className={cx("account-number")}
                    type="text"
                  />
                </li>

                <li className={cx("holder-area")}>
                  <label className={cx("account-property")} htmlFor="holder">
                    예금주
                  </label>

                  <input
                    onChange={handleRefundAccount}
                    name="holder"
                    value={refundAccount.holder}
                    className={cx("holder")}
                    type="text"
                  />
                  <button
                    onClick={handleCertify}
                    disabled={disabledCertify || isCertifyLoading}
                    className={cx("certify", { "disabled-certify": disabledCertify })}
                    type="button"
                  >
                    {isCertifyLoading ? <Loader type="Oval" color="#ffffff" height={20} width={20} /> : "인증하기"}
                  </button>
                </li>

                <li>
                  {certify === null ? (
                    <p className={cx("certify-notice", "certified")}>[인증하기] 버튼으로 계좌정보를 인증해주세요.</p>
                  ) : (
                    <p className={cx("certify-notice", { certified: certify, "not-certified": !certify })}>
                      {certify ? "인증되었습니다." : "인증실패하였습니다. 정보를 확인해주세요."}
                    </p>
                  )}
                </li>
              </ul>
            </div>
          </>
        )}

      <div className={cx("notice")}>
        * 실제 환불은 영업일 기준 0~3일 내에 처리됩니다.
        <br />* 각 업체의 교환/환불 정책을 확인 후 진행해주세요. (상품페이지의 상품설명, 추가정보 확인)
      </div>
      <div className={cx("policy")}>
        {isMobile ? (
          <img src={imageResizeHelper(nextStatus.extraInfo.policyPhotoUrls?.android ?? "")} alt="환불안내" />
        ) : (
          <img
            className={cx({ box: status === EFieldStatus.cancelSoonAuto || status === EFieldStatus.cancelWait })}
            src={imageResizeHelper(nextStatus.extraInfo.policyPhotoUrls?.pc ?? "")}
            alt="환불안내"
          />
        )}
      </div>

      <div className={cx("actions")}>
        <button onClick={handleCancel} className={cx("cancel")} type="button">
          취소
        </button>

        <button
          disabled={disabledSubmit}
          onClick={handleSubmit}
          className={cx("submit", { disabled: disabledSubmit })}
          type="button"
        >
          요청하기
        </button>
      </div>
    </main>
  );
}
