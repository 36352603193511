import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { map } from "ramda";
import { useCoordi } from "hooks";
import { coordi_tags } from "assets/remote_config/data.json";

import { PageTitle, CoordiCard, SelectOrder, ProductsNotExist, Pagination, Loading } from "components";
import { COORDI_VIEW_SIZE } from "constant";
import { IStore } from "typings";
import classnames from "classnames/bind";

import share from "components/pages/search/SearchShare.module.scss";
import styles from "./Coordi.module.scss";
import { useCoordiSearch } from "./useCoordiSearch";
import { AirbridgeCustomEvent } from "utils";

const cx = classnames.bind(styles);
const ss = classnames.bind(share);

export function Coordi() {
  const {
    tags,
    toggleTag,
    addTag,
    deleteTag,
    keyword,
    changeKeyword,
    handleEnter,
    coordi,
    isLoading,
    order,
    handleOrder,
    orders,
    handlePage,
    page,
    totalCount,
    mainRef
  } = useCoordiSearch();
  const { getIsCoordiLike, toggleLike } = useCoordi();

  const genderId = useSelector<IStore, IStore["ui"]["genderId"]>(({ ui: { genderId } }) => genderId);
  const coordiTags = coordi_tags[genderId];

  const hiddenFlexBoxes = Array(coordi.length % 3 ? 3 - (coordi.length % 3) : 0).fill(0);

  useEffect(() => {
    AirbridgeCustomEvent.mainScreen("coordi");
  }, []);

  return (
    <main className={cx("main")} ref={mainRef}>
      <header>
        <PageTitle title="코디" />

        <div className={cx("search")}>
          {Array.from(tags).map(tag => (
            <button onClick={toggleTag} data-tag={tag} key={tag} type="button" className={cx("tag")}>
              <span className={cx("delete")}>+</span>
              <span className={cx("tag-name")}>{tag}</span>
            </button>
          ))}

          <input
            onKeyUp={addTag}
            onKeyDown={deleteTag}
            value={keyword}
            onChange={e => {
              if (e.target.value.length <= 5) changeKeyword(e);
            }}
            onKeyPress={handleEnter}
            className={cx("input")}
            placeholder={tags.size ? "" : "상품 및 태그 검색 (최대 3개)"}
            type="search"
          />

          <button className={cx("submit")} type="button">
            검색
          </button>
        </div>
      </header>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <section className={cx("table")}>
            <div className={cx("head-wrapper")}>
              {coordiTags.map(({ title }) => (
                <div className={cx("head")} key={title}>
                  {title}
                </div>
              ))}
            </div>

            <div className={cx("keywords-wrapper")}>
              {coordiTags.map(({ title, keywords }) => (
                <div className={cx("keywords")} key={title}>
                  {keywords.map(keyword => (
                    <button
                      onClick={toggleTag}
                      data-tag={keyword}
                      className={cx("body", { active: tags.has(keyword) })}
                      key={keyword}
                      type="button"
                    >
                      {keyword}
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </section>

          <div className={ss("filter-container")}>
            <ul className={ss("filter-list")}>
              <li className={ss("filter-item")}>
                <SelectOrder order={order as string} handleOrder={handleOrder} orders={orders} />
              </li>
            </ul>
          </div>

          <section className={cx("coordi")}>
            {coordi.length > 0 ? (
              <>
                {coordi.map(coordi => (
                  <CoordiCard
                    id={coordi.id}
                    photoUrl={coordi.photoUrl}
                    title={coordi.title}
                    tags={coordi.tags}
                    likeCounts={coordi.likeCounts}
                    isLiked={getIsCoordiLike(coordi.id)}
                    toggleLike={toggleLike}
                    key={coordi.id}
                  />
                ))}

                {map(
                  v => (
                    <div key={`hidden_coordi_${v}`} className={cx("hidden-card")} />
                  ),
                  hiddenFlexBoxes
                )}
              </>
            ) : (
              <ProductsNotExist />
            )}
          </section>
        </>
      )}

      <Pagination totalCount={totalCount} handler={handlePage} number={page} size={COORDI_VIEW_SIZE} />
    </main>
  );
}
