import React, { FC, useCallback, useRef } from "react";
import classNames from "classnames/bind";
import { BlueSearchButton, GrayXmarkButton } from "assets/icon";
import styles from "./FAQForm.module.scss";

interface IProp {
  search: string;
  setSearch: (s: string) => void;
  onSubmit?: (e: string) => void;
  onClear?: () => void;
}
const cx = classNames.bind(styles);
export const FAQForm: FC<IProp> = ({ search, setSearch, onSubmit, onClear }) => {
  const timeOutCallback = useRef<NodeJS.Timeout>();

  const valueChange = useCallback(
    e => {
      if (timeOutCallback.current) clearTimeout(timeOutCallback.current);
      const { value } = e.target;
      timeOutCallback.current = setTimeout(() => onSubmit && onSubmit(value), 1000);
      setSearch(e.target.value);
    },
    [onSubmit, setSearch]
  );

  const submitListner = useCallback(
    e => {
      e.preventDefault();
      if (timeOutCallback.current) clearTimeout(timeOutCallback.current);
      if (onSubmit) onSubmit(search);
    },
    [onSubmit, search]
  );

  return (
    <form className={cx("input-wrapper")} onSubmit={submitListner}>
      <input className={cx("search-input")} placeholder="질문 검색" type="text" value={search} onChange={valueChange} />
      {search.length > 0 && (
        <div className={cx("icon-wrapper")}>
          <button type="button" className={cx("icon-button")} onClick={onClear}>
            <GrayXmarkButton />
          </button>
          <button type="submit" className={cx("icon-button")}>
            <BlueSearchButton />
          </button>
        </div>
      )}
    </form>
  );
};
