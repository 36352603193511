import { FAQSerialized, IJSendSuccessResponse, IPage, IPageMeta, IWithMeta } from "typings";
import { fetcher } from "utils";

type FAQListGetRequest = (params: {
  page: IPage;
  category?:
    | "order"
    | "product"
    | "delivery"
    | "cancel_or_refund"
    | "exchange"
    | "account"
    | "coupon"
    | "lookpin_guide"
    | "etc";
  platform: "web" | "app" | "all";
  title_query?: string;
}) => Promise<IJSendSuccessResponse<IWithMeta<FAQSerialized[], IPageMeta>>>;

export const api__v1_get_faq_for_userables: FAQListGetRequest = params =>
  fetcher.get("/api/v1/faq_for_userables", params);
